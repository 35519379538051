import {Modal} from '@/components/common-components';
import {faCaretDown, faCircleCheck, faSortDown, faSortUp, faXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ButtonComponent from '@/components/common-components/components/button-component';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {DatePicker, Dropdown, Empty, Input, InputNumber, Menu, Checkbox, Radio, Spin, TimePicker, Tooltip, Upload} from 'antd';
import type {RangePickerProps} from 'antd/es/date-picker';
import {faBars, faCalendar, faCircleInfo, faImage, faLayerGroup, faLocationDot} from '@fortawesome/pro-duotone-svg-icons';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import {useStore} from '@/store/root-store';
import {faArrowRight, faMagnifyingGlass, faTrash, faRotate as faRegRotate, faEye, faCartShopping, faNewspaper, faCalendar as faCalendarReg, faCircleInfo as faCircleInfoReg} from '@fortawesome/pro-regular-svg-icons';
import {notification} from '@/utils/notification-v2';
import {useRouter} from 'next/router';
import {DescriptionWrapper, FieldContainer, ImageWrapper, StyledIssuesTable, StyledTrashIcon, TypeWrapper} from '../../../../style';
import {apiError} from '@/utils/api';
import {StyledSpin} from '../../../../Utils/styledSpin';
import {getSingleUrlParam} from '@/utils/url';
import {convertArrayObjectKeysToSnakeCase} from '@/utils/arrays';
import {toJS} from 'mobx';
import {getOttoTablePageSize} from '@/api/common-utils';
import {getStandAloneLocations} from '@/utils/functions';
import {optionsCTA} from '../../../../Constants';
const {Dragger} = Upload;

interface GenerateGbpPostsModalProps {
  openGenerateGbpPostsModal?: boolean;
  setOpenGenerateGbpPostsModal?: (value: boolean) => void;
  showConfirmationPopup?: any;
  deleteItem?: any;
  editing?: boolean;
  setEditing?: (value: boolean) => void;
  standaloneTool?: any;
}
const step1Options = {
  imageOption: {
    option: 'ai-generate',
  },
  contentOption: {
    option: 'write-with-ai',
  },
  postOption: {
    option: 'all-together',
  },
};
const initialErrorState = {
  image: {
    message: 'Provide prompt to generate image by AI or select upload.',
    state: false,
  },
  content: {
    message: 'Provide prompt to generate content by AI or select write own.',
    state: false,
  },
  contentOnTable: {
    message: 'Content is required.',
    state: false,
  },
  title: {
    message: 'Title is required.',
    state: false,
  },
  startDateAt: {
    message: 'Required.',
    state: false,
  },
  endDateAt: {
    message: 'Required.',
    state: false,
  },
  startTimeAt: {
    message: 'Required.',
    state: false,
  },
  endTimeAt: {
    message: 'Required.',
    state: false,
  },
  callToActionUrl: {
    message: 'Required.',
    state: false,
  },
};

export const GenerateGbpPostsModal: React.FC<GenerateGbpPostsModalProps> = observer(({openGenerateGbpPostsModal, setOpenGenerateGbpPostsModal, deleteItem, editing, setEditing, standaloneTool}) => {
  const {ottoV2Store: {
    setGeneratedBulkPost,
    getGeneratedBulkPost,
    generatedBulkQA,
    getSelectedLocation,
    getOttoV2Project,
    setSelectedLocation,
    postBulkAiGenerate,
    ottoGBPUploadImage,
    getBulkPostSettings,
    getOttoGBPAiGenerateImage,
    postBulkCreate,
    loadIssueTableData,
    editOttoGBPPost,
    setBulkPostSettings,
    generateOttoGBPAiBulkImage,
    generatedBulkPost,
    getGBPLocations,
    gbpLocations,
    knowledgeGraph,
    getOttoGBPAiGenerateContent,
  }, settings: {
    customer: {getCustomerQuota},
  }} = useStore('');
  const [step1OptionStates, setStep1OptionStates] = useState(step1Options);
  const [aiImagePrompt, setAiImagePrompt] = useState('');
  const [aiContentPrompt, setAiContentPrompt] = useState('');
  const [quantity, setQuantity] = useState<number>(1);
  const [publishQuantity, setPublishQuantity] = useState<number>(1);
  const [daysQuantity, setDaysQuantity] = useState<number>(1);
  const [step, setStep] = useState<1 | 2>(1);
  const [date, setDate] = useState<any>(moment());
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const [selectedGbp, setSelectedGbp] = useState<any>({});
  const [multiSelectedGbp, setMultiSelectedGbp] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [generatingGbpPosts, setGeneratingGbpPosts] = useState(false);
  const [errorStates, setErrorStates] = useState(initialErrorState);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [uploadingImage, setUploadingImage] = useState(-1);
  const [generatingImages, setGeneratingImages] = useState<number[]>([]);
  const [viewPreview, setViewPreview] = useState(false);
  const [startImageGeneration, setStartImageGeneration] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [postType, setPostType] = useState('STANDARD');
  const [standaloneToolGBPData, setStandaloneToolGBPData] = useState(null);
  const [ignoreBusiness, setIgnoreBusiness] = useState(false);
  const uuid = getSingleUrlParam('uuid');
  const [generatingContent, setGeneratingContent] = useState([]);

  const updateRegeneratingIndexes = (index, toRemove) => {
    setGeneratingContent(prev => {
      if (toRemove) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const router = useRouter();

  useEffect(() => {
    if (!gbpLocations?.length) {
      getGBPLocations(false, standaloneTool);
    }
  }, []);

  useEffect(() => {
    if (standaloneTool && gbpLocations?.length) {
      setStandaloneToolGBPData(gbpLocations?.filter(item => item?.businessName));
    }
  }, [gbpLocations]);

  useEffect(() => {
    if (editing) {
      setStep(2);
    }
  }, [editing]);

  useEffect(() => {
    if (aiImagePrompt && startImageGeneration && getGeneratedBulkPost.length) {
      generateBulkImages();
      setStartImageGeneration(false);
    }
  }, [getGeneratedBulkPost]);

  useEffect(() => {
    if (!selectedGbp?.businessName || multiSelectedGbp.length == 0) {
      if (getSelectedLocation()['businessName']) {
        setSelectedGbp(getSelectedLocation());
        setMultiSelectedGbp([toJS(getSelectedLocation())]);
        setSelectedLocation(getSelectedLocation());
      } else if (getOttoV2Project?.connectedData?.gbpDetailsV2?.length) {
        setSelectedGbp(getOttoV2Project?.connectedData?.gbpDetailsV2[0]);
        setMultiSelectedGbp([toJS(getOttoV2Project?.connectedData?.gbpDetailsV2[0])]);
        setSelectedLocation(getOttoV2Project?.connectedData?.gbpDetailsV2[0]);
      }
    }
  }, [getOttoV2Project?.connectedData?.gbpDetailsV2, router.query?.subGroup]);

  useEffect(() => {
    if (selectedImage) {
      generateImageUrl();
    }
  }, [selectedImage]);

  const generateImageUrl = async () => {
    try {
      const formData = new FormData();
      formData.append('image', selectedImage);
      formData.append('format', 'JPEG');
      const response = await ottoGBPUploadImage(formData);
      editBulkPostData(selectedImageIndex, 'imageUrl', response);
      setUploadingImage(-1);
    } catch (error) {
      setUploadingImage(-1);
      const errorMessage = apiError(error, 'Failed to generate image url') as string;
      notification.error('', errorMessage);
    }
  };

  const onChangeQuantity = (value, setQuantity) => {
    if (value === 1) {
      setStep1OptionStates({...step1OptionStates, postOption: {...step1OptionStates['postOption'], option: 'all-together'}});
    }
    if (value > 0) {
      setQuantity(value);
    }
  };
  const handleImageOption = e => {
    setStep1OptionStates({...step1OptionStates, imageOption: {...step1OptionStates['imageOption'], option: e.target.value}});
    setErrorStates({...errorStates, image: {...errorStates['image'], state: false}});
    setAiImagePrompt('');
  };
  const handleContentOption = e => {
    setErrorStates({...errorStates, content: {...errorStates['content'], state: false}});
    setStep1OptionStates({...step1OptionStates, contentOption: {...step1OptionStates['contentOption'], option: e.target.value}});
    setAiContentPrompt('');
  };

  const handlePostOption = e => {
    if (quantity === 1 && e?.target?.value === 'separately') {
      return;
    }
    setStep1OptionStates({...step1OptionStates, postOption: {...step1OptionStates['postOption'], option: e.target.value}});
  };

  const onChangeImagePrompt = e => {
    if (e?.target?.value?.replace(/ /g, '')) {
      setErrorStates({...errorStates, image: {...errorStates['image'], state: false}});
    }
    setAiImagePrompt(e?.target?.value);
  };

  const onChangeContentPrompt = e => {
    if (e?.target?.value?.replace(/ /g, '')) {
      setErrorStates({...errorStates, content: {...errorStates['content'], state: false}});
    }
    setAiContentPrompt(e?.target?.value);
  };

  const generateBulkImages = async () => {
    const settings = {...getBulkPostSettings[0]};
    const locationsArray = multiSelectedGbp?.map(object => object.id);
    const postCount = locationsArray.length * quantity;
    setGeneratingImages(Array.from(Array(postCount).keys()));
    setBulkPostSettings([{...settings, image: {prompt: aiImagePrompt}}]);
    try {
      for (let i = 0; i < locationsArray.length; i++) {
        const locationId = locationsArray[i];
        const response = await generateOttoGBPAiBulkImage({prompt: aiImagePrompt, count: quantity}, locationId, standaloneTool);
        if (response && response.length) {
          const updatedPosts = generatedBulkPost.map((post, idx) => {
            if (post?.location === locationId) {
              setGeneratingImages(prevState => prevState?.filter(itm => itm !== idx));
              const updatedImageUrl = response.shift();
              return {
                ...post,
                imageUrl: updatedImageUrl ? updatedImageUrl : post?.imageUrl,
              };
            }
            return post;
          });
          setGeneratedBulkPost(updatedPosts);
        }
      }
    } catch (err) {
      setGeneratingImages([]);
      console.error('Error generating images:', err);
    } finally {
      setGeneratingImages([]);
    }
  };

  // eslint-disable-next-line
  const urlRegex = /^https?:\/\/([\w-]+\.)+[a-zA-Z]{2,63}(:\d+)?(\/[\w\-\\.~!$&'()*+,;=:@%]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[\w\-\\.~!$&'()*+,;=:@%]*)?$/;

  const onSubmit = async () => {
    try {
      if (step === 1) {
        if ((step1OptionStates['imageOption']?.option === 'ai-generate' && step1OptionStates['contentOption']?.option === 'write-with-ai') && (!aiImagePrompt.replace(/ /g, '') || (!aiContentPrompt.replace(/ /g, '') && ignoreBusiness))) {
          setErrorStates({...errorStates,
            image: {...errorStates['image'], state: !aiImagePrompt?.replace(/ /g, '') ? true : false},
            content: {...errorStates['content'], state: !aiContentPrompt?.replace(/ /g, '') && ignoreBusiness ? true : false},
          });
          return;
        } else if ((step1OptionStates['imageOption']?.option === 'ai-generate' && step1OptionStates['contentOption']?.option !== 'write-with-ai') && (!aiImagePrompt.replace(/ /g, ''))) {
          setErrorStates({...errorStates,
            image: {...errorStates['image'], state: true},
            content: {...errorStates['content'], state: false},
          });
          return;
        } else if ((step1OptionStates['imageOption']?.option !== 'ai-generate' && step1OptionStates['contentOption']?.option === 'write-with-ai') && (!aiContentPrompt.replace(/ /g, '') && ignoreBusiness)) {
          setErrorStates({...errorStates,
            image: {...errorStates['image'], state: false},
            content: {...errorStates['content'], state: true},
          });
          return;
        }
        setGeneratingGbpPosts(true);
        try {
          const payload = {
            location: selectedGbp?.id,
            count: quantity,
          };
          // if (aiImagePrompt) {
          //   payload['image'] = {
          //     'prompt': aiImagePrompt,
          //   };
          // }
          payload['content'] = step1OptionStates?.contentOption?.option === 'write-your-own' ? null : {
            'prompt': aiContentPrompt || null,
            'override': ignoreBusiness,
          };

          payload['override_fields'] = {
            'topic_type': postType,
          };
          if (step1OptionStates['postOption']?.option === 'separately') {
            payload['schedule'] = {
              'count_per_interval': publishQuantity,
              'days_interval': daysQuantity,
              'starting_on': moment(date).format('YYYY-MM-DD'),
            };
          } else {
            payload['override_fields'] = {
              ...payload['override_fields'],
              'schedule_publishing_at': moment(date).format('YYYY-MM-DD'),
            };
          }
          delete payload['location'];
          const newData = multiSelectedGbp.map(val=> (
            {...payload, location: val.id}
          ));
          const state = await postBulkAiGenerate({'settings': newData}, standaloneTool);
          if (state) {
            setStep(2);
            setStartImageGeneration(true);
          }
          setGeneratingGbpPosts(false);
        } catch (error) {
          setGeneratingGbpPosts(false);
          const errorMessage = apiError(error, 'Failed to generate post') as string;
          notification.error('', errorMessage);
        }
      } else if (step === 2 && getGeneratedBulkPost?.length) {
        const hasError = getGeneratedBulkPost?.some(item => !item?.content?.trim()) || getGeneratedBulkPost?.some(item => item?.content?.trim()?.length > 1500);
        let hasButtonLinkError = false;
        let hasTitleError = false; getGeneratedBulkPost?.some(item => !item?.title);
        let hasStartDateAtError = false;
        let hasEndDateAtError = false;
        let hasStartTimeAtError = false;
        let hasEndTimeAtError = false;
        const type = getGeneratedBulkPost?.map(item => item?.topicType);
        if (type?.includes('EVENT') || type?.includes('OFFER')) {
          hasTitleError = getGeneratedBulkPost?.some(item => !item?.title);
          hasStartDateAtError = getGeneratedBulkPost?.some(item => !item?.startDateAt);
          hasEndDateAtError = getGeneratedBulkPost?.some(item => !item?.endDateAt);
        }
        if (type?.includes('EVENT')) {
          hasStartTimeAtError = getGeneratedBulkPost?.some(item => !item?.startTimeAt);
          hasEndTimeAtError = getGeneratedBulkPost?.some(item => !item?.endTimeAt);

          if (!hasStartTimeAtError) {
            hasStartTimeAtError = getGeneratedBulkPost?.some(item => {
              const areDatesSame = moment(item?.startDateAt).isSame(item?.endDateAt);
              return areDatesSame && moment(item?.startTimeAt, 'hh:mm').valueOf() >= moment(item?.endTimeAt, 'hh:mm').valueOf();
            });
          }
          if (!hasEndTimeAtError) {
            hasEndTimeAtError = getGeneratedBulkPost?.some(item => {
              const areDatesSame = moment(item?.startDateAt).isSame(item?.endDateAt);
              return areDatesSame && moment(item?.endTimeAt, 'hh:mm').valueOf() <= moment(item?.startTimeAt, 'hh:mm').valueOf();
            });
          }
        }
        if (type?.includes('STANDARD') || type?.includes('EVENT')) {
          hasButtonLinkError = getGeneratedBulkPost?.filter(item => ![null, 'CALL'].includes(item?.callToActionType))?.some(item => !item?.callToActionUrl || !urlRegex.test(item?.callToActionUrl));
        }
        if (hasError || hasTitleError || hasStartDateAtError || hasEndDateAtError || hasStartTimeAtError || hasEndTimeAtError || hasButtonLinkError) {
          setErrorStates({...errorStates,
            contentOnTable: {...errorStates['contentOnTable'], state: hasError},
            title: {...errorStates['title'], state: hasTitleError},
            startDateAt: {...errorStates['startDateAt'], state: hasStartDateAtError},
            endDateAt: {...errorStates['endDateAt'], state: hasEndDateAtError},
            startTimeAt: {...errorStates['startTimeAt'], state: hasStartTimeAtError},
            endTimeAt: {...errorStates['endTimeAt'], state: hasEndTimeAtError},
            callToActionUrl: {...errorStates['callToActionUrl'], state: hasButtonLinkError},
          });
          return;
        } else {
          setErrorStates({...errorStates,
            contentOnTable: {...errorStates['contentOnTable'], state: hasError},
            title: {...errorStates['title'], state: hasTitleError},
            startDateAt: {...errorStates['startDateAt'], state: hasStartDateAtError},
            endDateAt: {...errorStates['endDateAt'], state: hasEndDateAtError},
            startTimeAt: {...errorStates['startTimeAt'], state: hasStartTimeAtError},
            endTimeAt: {...errorStates['endTimeAt'], state: hasEndTimeAtError},
            callToActionUrl: {...errorStates['callToActionUrl'], state: hasButtonLinkError},
          });
        }
        try {
          setGeneratingGbpPosts(true);
          const payload = convertArrayObjectKeysToSnakeCase(getGeneratedBulkPost)?.map(item => {
            const timezone = standaloneTool ? selectedGbp?.['timezone'] : knowledgeGraph?.['timezone'];
            return {
              ...item,
              image_url: item?.image_url || null,
              ...(type?.includes('OFFER') ? {call_to_action_type: null} : {}),
              ...(editing && {status: 'Pending Review'}),
              ...(![null, 'CALL'].includes(item['call_to_action_type']) ? {call_to_action_url: item['call_to_action_url']}: {call_to_action_url: null}),
              ...(item['schedule_publishing_at'] && {schedule_publishing_at: timezone ?
                moment(item['schedule_publishing_at']).tz(timezone).format():
                moment(item['schedule_publishing_at']).format('YYYY-MM-DD'),
              }),
            };
          });
          if (editing) {
            await editOttoGBPPost(payload[0]?.id, payload[0], standaloneTool);
            notification.success('Success', `Post updated successfully.`);
          } else {
            await postBulkCreate(payload, standaloneTool);
            notification.success('Success', `Post${getGeneratedBulkPost?.length>1 ? 's' : ''} created successfully.`);
          }
          setOpenGenerateGbpPostsModal(false);
          setEditing(false);
          setGeneratingGbpPosts(false);
          const params = {
            uuid,
            page_size: getOttoTablePageSize('gbp_posts'),
            issue_type: 'gbp_posts',
            page: 1,
            is_loading: false,
            ...(standaloneTool && {isStandAlone: standaloneTool}),
          };
          await loadIssueTableData(params, false);
        } catch (error) {
          setGeneratingGbpPosts(false);
          // eslint-disable-next-line no-console
          console.log('error', error);
          // const errorMessage = apiError(error, 'Failed to approve image') as string;
          // notification.error('', errorMessage);
        }
      }
      if (standaloneTool) {
        getCustomerQuota();
      }
    } catch (error) {
      setGeneratingGbpPosts(false);
      setOpenGenerateGbpPostsModal(false);
      setEditing(false);
      setStep(1);
    }
  };

  const handleSelectedLocation = e => {
    setSelectedGbp(e);
    if (!multiSelectedGbp.some(val => val?.id == e?.id)) {
      setMultiSelectedGbp([...multiSelectedGbp, e]);
    } else {
      if (multiSelectedGbp.length > 1) {
        setMultiSelectedGbp(multiSelectedGbp.filter(val => val.id != e.id));
      }
    }
  };


  const imageGenerate = async (imagePrompt, index) => {
    setGeneratingImages(indexes => [...indexes, index]);
    try {
      const response = await getOttoGBPAiGenerateImage({prompt: imagePrompt}, null, standaloneTool);
      editBulkPostData(index, 'imageUrl', response);
      getCustomerQuota();
      setGeneratingImages(indexes => indexes.filter(id => id !== index));
    } catch (err) {
      setGeneratingImages(indexes => indexes.filter(id => id !== index));
      const errorMessage = apiError(err, 'Failed to generate image') as string;
      notification.error('', errorMessage);
    }
  };

  const onImagePreview = image => {
    setPreviewImage(image);
    setViewPreview(true);
  };

  const filteredLocations = standaloneTool ?
    getStandAloneLocations(standaloneToolGBPData, searchValue) :
    getOttoV2Project?.connectedData?.gbpDetailsV2?.filter(d => searchValue ? d.businessName.toLowerCase().includes(searchValue.toLowerCase()) : true);

  const locationList = (
    <DropdownContainer>
      <GbpSearchInput onChange={e => setSearchValue(e.target.value)} placeholder='Search...'
        prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
      />
      <DropDownInfoInnerContainer>
        <FontAwesomeIcon icon={faCircleInfoReg} color='#2D6CCA' fontSize={16} style={{marginRight: 5}}/>
        <div style={{fontSize: '13px'}}>Add a company background information for each business to get better written and personalized AI content.</div>
      </DropDownInfoInnerContainer>
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
          <span style={{fontWeight: 'bold', fontSize: '12px', marginLeft: '8px', marginBottom: '10px'}}>Business</span>
        </div>
        <MultiDiv>
          {filteredLocations?.length ? filteredLocations?.map(business => (
            <div key={business?.locationId} style={{display: 'flex'}}>
              <Checkbox key={business?.locationId} style={{width: '100%', marginLeft: '8px'}} checked={multiSelectedGbp.some(value => value?.id == business?.id)} onChange={e=> handleSelectedLocation(e.target.value)} value={business}>
                <div>
                  <div style={{fontWeight: 'bold', fontSize: '12px'}}>{business?.businessName}</div>
                  <p style={{color: '#4E5156', fontSize: '12px', fontFamily: 'Inter', fontWeight: '400'}}>{business?.businessAddress}</p>
                </div>
              </Checkbox>
            </div>
          )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </MultiDiv>
      </div>
    </DropdownContainer>
  );

  const typeIconMapping = {
    EVENT: faCalendarReg,
    STANDARD: faNewspaper,
    OFFER: faCartShopping,
  };

  const contentGenerate = async (id, prompt, override, locationId, key) => {
    updateRegeneratingIndexes(key === 'title' ? `${id}-${key}`: id, false);
    try {
      const payload = {override};
      if (prompt) {
        payload['prompt'] = prompt;
      }
      const response = await getOttoGBPAiGenerateContent(payload, locationId, false, key);
      editBulkPostData(id, key, response);
      getCustomerQuota();
      updateRegeneratingIndexes(key === 'title' ? `${id}-${key}`: id, true);
    } catch (err) {
      updateRegeneratingIndexes(key === 'title' ? `${id}-${key}`: id, true);
    }
  };

  const handleImageUpload = (data, index) => {
    if (
      data?.file.type === 'image/png' ||
        data?.file.type === 'image/jpg' ||
        data?.file.type === 'image/jpeg'
    ) {
      if (data?.file?.size >= 10000 && data?.file?.size <= 5000000) {
        setSelectedImage(data?.file);
        setUploadingImage(index);
        setSelectedImageIndex(index);
      } else {
        let errorMessage = '';
        if (data?.file?.size < 10000) {
          errorMessage = 'Image size need to be greater than 10kb';
        } else if (data?.file?.size > 5000000) {
          errorMessage = 'Image size need to be under than 5mb';
        }
        notification.error('', errorMessage);
      }
    } else {
      notification.error('Acceptable file formats: JPEG, PNG and JPG. Please try again.', '');
    }
  };

  const columns = [
    {
      title: <div className='column-title'>TYPE</div>,
      dataIndex: 'type',
      key: 'type',
      width: '240px',
      render: (_, record) => {
        return (<TypeWrapper>
          <FontAwesomeIcon
            icon={typeIconMapping[record?.topicType]}
            color='rgba(0, 0, 0, 0.85)'
            fontSize={14}
          />{items?.find(item => item?.key === record?.topicType)?.label}
        </TypeWrapper>);
      },
    },
    {
      title: (<div className='column-title'>
        <span>IMAGE</span>
      </div>),
      dataIndex: 'image',
      key: 'image',
      width: '125px',
      render: (_, record, index) => {
        const prompt = getBulkPostSettings?.length > 0 ? getBulkPostSettings[0]?.image?.prompt : '';
        return (
          <ImageWrapper style={{padding: '8px 10px'}}>
            {
              record?.imageUrl ?
                <div className='container'>
                  <img src={record?.imageUrl} className='image'/>
                  <div className='hover-wrapper'>
                    <Tooltip title='View Image'>
                      <FontAwesomeIcon
                        style={{cursor: generatingImages.includes(index) ? 'not-allowed': 'pointer'}}
                        icon={faEye}
                        color='#f0f2f5'
                        fontSize={14}
                        onClick={()=> !generatingImages.includes(index) && onImagePreview(record?.imageUrl)}
                      />
                    </Tooltip>
                    {
                      prompt ?
                        <Tooltip title={generatingImages.includes(index) ? 'Generating image' : 'Regenerate Image'}>
                          <FontAwesomeIcon
                            style={{cursor: generatingImages.includes(index) ? 'not-allowed': 'pointer'}}
                            icon={faRegRotate}
                            color='#f0f2f5'
                            fontSize={14}
                            className={generatingImages.includes(index) ? 'fa-spin' : ''}
                            onClick={()=> !generatingImages.includes(index) && imageGenerate(prompt, index)}
                          />
                        </Tooltip>:<></>
                    }
                    <Tooltip title='Delete Image'>
                      <FontAwesomeIcon
                        style={{cursor: generatingImages.includes(index) ? 'not-allowed': 'pointer'}}
                        icon={faTrash}
                        color='#f0f2f5'
                        fontSize={14}
                        onClick={()=> !generatingImages.includes(index) && editBulkPostData(index, 'imageUrl', '')}
                      />
                    </Tooltip>
                  </div>
                </div> :
                <>
                  {
                    uploadingImage === index || generatingImages.includes(index) ?
                      <LoadingImage>
                        <StyledSpin color='#abaaaa' fontSize={24}/>
                      </LoadingImage>:
                      prompt ? <ImageContainer>
                        <Tooltip title='Upload Image' >
                          <StyledDragger
                            multiple={false}
                            showUploadList={false}
                            className='border-less'
                            accept='image/png, image/jpeg'
                            customRequest={(data:any) => {
                              handleImageUpload(data, index);
                            }}
                          >
                            <img style={{margin: 'auto', width: '25px'}} src='/icons/drop-upload.svg'/>
                          </StyledDragger>
                        </Tooltip>
                        <Tooltip title='Generate Image'>
                          <button className='upload-container'>
                            <FontAwesomeIcon icon={faRegRotate} color='grey' fontSize={14}
                              className={generatingImages.includes(index) ? 'fa-spin' : ''}
                              onClick={()=> !generatingImages.includes(index) && imageGenerate(prompt, index)}
                            />
                          </button>
                        </Tooltip>
                      </ImageContainer>:
                        <StyledDragger
                          multiple={false}
                          showUploadList={false}
                          accept='image/png, image/jpeg'
                          customRequest={(data:any) => {
                            handleImageUpload(data, index);
                          }}
                        >
                          <DragContainer>
                            <div className='contain'>
                              <img className='img' src='/icons/drop-upload.svg'/>
                              <TitleStyled>
                                Upload Image
                              </TitleStyled>
                            </div>
                          </DragContainer>
                        </StyledDragger>
                  }
                </>
            }
          </ImageWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>POST CONTENT</div>,
      dataIndex: 'content',
      key: 'content',
      width: '100%',
      render: (_, record, index) => {
        const callToActionOption = optionsCTA.find(item => item.value === record?.callToActionType)?.label;
        const postLocation = multiSelectedGbp?.find(loc => loc?.id === record?.location);

        const startDisabledDate: RangePickerProps['disabledDate'] = current => current && (current > moment(record?.endDateAt) || current <= moment().subtract(1, 'days'));

        const isStartTimeGreaterThanEndTime = moment(record?.startTimeAt, 'hh:mm').valueOf() >= moment(record?.endTimeAt, 'hh:mm').valueOf();
        const isEndTimeLesserThanStartTime = moment(record?.endTimeAt, 'hh:mm').valueOf() <= moment(record?.startTimeAt, 'hh:mm').valueOf();
        const contentOptions = record?.extras?.generationSettings?.content;
        const prompt = contentOptions?.prompt || getBulkPostSettings?.[0]?.content?.prompt;
        const override = contentOptions?.override || getBulkPostSettings?.[0]?.content?.override;

        return (<ImageWrapper>
          {record?.topicType === 'STANDARD' ?
            <div style={{width: '100%'}}>
              <FieldContainer style={{alignItems: 'normal'}}>
                <div className='label' style={{marginTop: '6px', display: 'flex', gap: 2, alignItems: 'flex-start'}}>
                    Description
                  <RegenerateButton disabled={generatingContent.includes(index)}>
                    <Tooltip title='Generate AI Description'>
                      <FontAwesomeIcon
                        icon={faRegRotate}
                        style={{cursor: generatingGbpPosts || generatingContent.includes(index) ? 'not-allowed' : 'pointer'}}
                        className={generatingContent.includes(index) ? 'fa-spin' : ''}
                        onClick={() => (!generatingGbpPosts && !generatingContent.includes(index)) && contentGenerate(index, prompt, override, record?.location, 'content')}
                      />
                    </Tooltip>
                  </RegenerateButton>
                </div>
                <DescriptionWrapper style={{width: '794px'}}>
                  <TextArea value={record?.content} onChange={e=>editBulkPostData(index, 'content', e.target.value )} rows={5} style={{borderRadius: '8px', padding: '0px 10px', borderColor: (errorStates['contentOnTable'].state && !record.content?.trim()) && '#ff4d4f', resize: 'none'}} />
                  {(errorStates['contentOnTable'].state && !record.content?.trim()) ? <Error>{errorStates['contentOnTable'].message}</Error> : <></>}
                  {(errorStates['contentOnTable'].state && record.content?.trim()?.length > 1500) ? <Error>Description content should be under 1500 characters</Error> : <></>}
                </DescriptionWrapper>
              </FieldContainer>
              <div style={{display: 'flex', gap: 30}}>
                <FieldContainer>
                  <div className='label'>
                      Button
                  </div>
                  <Dropdown overlay={
                    <Menu style={{borderRadius: 8}}>
                      {
                        optionsCTA.map(item => (
                          <Menu.Item key={item.value} style={{textAlign: 'center'}} onClick={()=>editBulkPostData(index, 'callToActionType', item.value)}>{item.label}</Menu.Item>
                        ))
                      }
                    </Menu>
                  } trigger={['click']}>
                    <DropdownWrapper style={{width: 130, margin: 0}}>
                      <div style={{padding: '5px 0px'}}>{
                        callToActionOption ? callToActionOption : 'None'
                      }</div>
                      <CaretDownOutlined style={{color: '#A3A4A4'}} />
                    </DropdownWrapper>
                  </Dropdown>
                </FieldContainer>
                {callToActionOption && !['None'].includes(callToActionOption) ?
                  <FieldContainer>
                    <div className='label'>
                      {record?.callToActionType == 'CALL' ? 'Phone number' : 'Button link'}
                    </div>
                    <div>
                      <Input disabled={['none', 'CALL'].includes(record?.callToActionType)} value={record?.callToActionType=='CALL' ? gbpLocations?.find(location => location?.id == record?.location)?.phoneNumbers?.length ? gbpLocations?.find(location => location?.id == record?.location)?.phoneNumbers[0] : 'No phone number available' :
                        record?.callToActionUrl} className='input' style={{width: 554}} onChange={e=>editBulkPostData(index, 'callToActionUrl', e?.target?.value)}/>
                      {errorStates['callToActionUrl'].state && record?.callToActionUrl && !urlRegex.test(record?.callToActionUrl) && <Error>Please provide a valid url</Error>}
                      {(errorStates['callToActionUrl'].state && !record?.callToActionUrl && record?.callToActionType !== 'CALL') && <Error>This field is required</Error>}
                    </div>
                  </FieldContainer>: <></>}
              </div>
              <FieldContainer>
                <div className='label'>Business</div>
                <LocationWrapper>{postLocation?.businessName}</LocationWrapper>
              </FieldContainer>
            </div> : record?.topicType === 'EVENT' ?
              <div style={{width: '100%'}}>
                <FieldContainer>
                  <div className='label' style={{display: 'flex', gap: 2, alignItems: 'flex-start'}}>
                    Title
                    <RegenerateButton disabled={generatingContent.includes(`${index}-title`)}>
                      <Tooltip title='Generate AI Title'>
                        <FontAwesomeIcon
                          icon={faRegRotate}
                          style={{cursor: generatingGbpPosts || generatingContent.includes(`${index}-title`) ? 'not-allowed' : 'pointer'}}
                          className={generatingContent.includes(`${index}-title`) ? 'fa-spin' : ''}
                          onClick={() => (!generatingGbpPosts && !generatingContent.includes(`${index}-title`)) && contentGenerate(index, prompt, override, record?.location, 'title')}
                        />
                      </Tooltip>
                    </RegenerateButton>
                  </div>
                  <div>
                    <Input value={record?.title} className='input' style={{width: 814}} onChange={e=>editBulkPostData(index, 'title', e.target.value)}/>
                    {errorStates['title'].state && !record.title?.trim()? <Error>{errorStates['title'].message}</Error> : <></>}
                  </div>
                </FieldContainer>
                <FieldContainer style={{alignItems: 'normal'}}>
                  <div className='label' style={{marginTop: '6px', display: 'flex', gap: 2, alignItems: 'flex-start'}}>
                      Description
                    <RegenerateButton disabled={generatingContent.includes(index)}>
                      <Tooltip title='Generate AI Description'>
                        <FontAwesomeIcon
                          icon={faRegRotate}
                          style={{cursor: generatingGbpPosts || generatingContent.includes(index) ? 'not-allowed' : 'pointer'}}
                          className={generatingContent.includes(index) ? 'fa-spin' : ''}
                          onClick={() => (!generatingGbpPosts && !generatingContent.includes(index)) && contentGenerate(index, prompt, override, record?.location, 'content')}
                        />
                      </Tooltip>
                    </RegenerateButton>
                  </div>
                  <DescriptionWrapper style={{width: '794px'}}>
                    <TextArea value={record?.content} onChange={e=>editBulkPostData(index, 'content', e.target.value )} rows={3} style={{borderRadius: '8px', padding: '0px 10px', borderColor: (errorStates['contentOnTable'].state && !record.content?.trim()) && '#ff4d4f', resize: 'none'}} />
                    {(errorStates['contentOnTable'].state && !record.content?.trim()) ? <Error>{errorStates['contentOnTable'].message}</Error> : <></>}
                    {(errorStates['contentOnTable'].state && record.content?.trim()?.length > 1500) ? <Error>Description content should be under 1500 characters</Error> : <></>}
                  </DescriptionWrapper>
                </FieldContainer>
                <div style={{display: 'flex', gap: 40}}>
                  <FieldContainer className='max-with-33'>
                    <div className='label'>
                        Start Date
                    </div>
                    <ScheduleDatePicker suffixIcon={<CaretDownOutlined />} size={'small'} format={'D MMM, YYYY'}
                      className='date-input'
                      style={{width: 140, borderColor: (errorStates['startDateAt'].state && !record.startDateAt?.trim()) && '#ff4d4f'}}
                      getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
                      showToday={false}
                      // status={'error'}
                      placeholder='DD/MM/YYYY'
                      disabledDate={startDisabledDate}
                      value={record?.startDateAt && moment(record?.startDateAt)}
                      onChange={(e: any) => editBulkPostData(index, 'startDateAt', moment(e).format('YYYY-MM-DD'))}
                      allowClear={false}
                    />
                  </FieldContainer>
                  <FieldContainer className='max-with-33'>
                    <div className='label'>
                      Start Time
                    </div>
                    <div>
                      <TimePicker
                        value={record?.startTimeAt && moment(record?.startTimeAt, 'HH:mm')}
                        // status={'error'}
                        use12Hours={false}
                        allowClear={false}
                        placeholder='Required'
                        style={{border: '1px solid #D8D8D8', borderRadius: 6, width: 140, borderColor: (errorStates['startTimeAt'].state && !record.startTimeAt?.trim()) && '#ff4d4f'}}
                        format='h:mm A'
                        suffixIcon={<CaretDownOutlined/>}
                        onChange={(e: any) => editBulkPostData(index, 'startTimeAt', moment(e, 'hh:mm A').format('HH:mm'))}
                      />
                      {(errorStates['startTimeAt'].state && isStartTimeGreaterThanEndTime) ? <Error>Start time should be less than End time.</Error> : <></>}
                    </div>
                  </FieldContainer>
                  <FieldContainer className='max-with-33'>
                    <div className='label'>
                        Button
                    </div>
                    <Dropdown overlay={
                      <Menu style={{borderRadius: 8}}>
                        {
                          optionsCTA.map(item => (
                            <Menu.Item key={item.value} style={{textAlign: 'center'}} onClick={()=>editBulkPostData(index, 'callToActionType', item.value)}>{item.label}</Menu.Item>
                          ))
                        }
                      </Menu>
                    } trigger={['click']}>
                      <DropdownWrapper style={{width: 130, margin: 0}}>
                        <div style={{padding: '5px 0px'}}>{
                          callToActionOption ? callToActionOption : 'None'
                        }</div>
                        <CaretDownOutlined style={{color: '#A3A4A4'}} />
                      </DropdownWrapper>
                    </Dropdown>
                  </FieldContainer>
                </div>
                <div style={{display: 'flex', gap: 40}}>
                  <FieldContainer className='max-with-33'>
                    <div className='label'>
                        End Date
                    </div>
                    <ScheduleDatePicker placeholder='DD/MM/YYYY' suffixIcon={<CaretDownOutlined />} size={'small'} format={'D MMM, YYYY'}
                      className='date-input'
                      getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
                      disabled={record?.startDateAt ? false : true}
                      style={{width: 140, borderColor: (errorStates['endDateAt'].state && !record.endDateAt?.trim()) && '#ff4d4f'}}
                      showToday={false}
                      disabledDate={currentDate => {
                        const isBeforeStartDate = record?.startDateAt && currentDate.isBefore(record?.startDateAt, 'day');
                        return currentDate <= moment().subtract(1, 'days') || isBeforeStartDate;
                      }}
                      value={record?.endDateAt && moment(record?.endDateAt)}
                      onChange={(e: any) => editBulkPostData(index, 'endDateAt', moment(e).format('YYYY-MM-DD'))}
                      allowClear={false} />
                  </FieldContainer>
                  <FieldContainer className='max-with-33'>
                    <div className='label'>
                      End Time
                    </div>
                    <div>
                      <TimePicker
                        value={record?.endTimeAt && moment(record?.endTimeAt, 'HH:mm')}
                        // status={'error'}
                        use12Hours={false}
                        allowClear={false}
                        placeholder='Required'
                        style={{border: '1px solid #D8D8D8', borderRadius: 6, width: 140, borderColor: (errorStates['endTimeAt'].state && !record.endTimeAt?.trim()) && '#ff4d4f'}}
                        format='h:mm A'
                        suffixIcon={<CaretDownOutlined/>}
                        onChange={(e: any) => editBulkPostData(index, 'endTimeAt', moment(e, 'hh:mm A').format('HH:mm'))}
                      />
                      {(errorStates['endTimeAt'].state && isEndTimeLesserThanStartTime) ? <Error>End time should be greater than Start time.</Error> : <></>}
                    </div>
                  </FieldContainer>
                  {
                    callToActionOption && !['None'].includes(callToActionOption) ?
                      <FieldContainer style={{maxWidth: '-webkit-fill-available'}}>
                        <div className='label'>
                          {record?.callToActionType == 'CALL' ? 'Phone number' : 'Button link'}
                        </div>
                        <div style={{width: '310px'}}>
                          <Input disabled={['none', 'CALL'].includes(record?.callToActionType)} value={record?.callToActionType=='CALL' ? gbpLocations?.find(location => location?.id == record?.location)?.phoneNumbers?.length ? gbpLocations?.find(location => location?.id == record?.location)?.phoneNumbers[0] : 'No phone number available' :
                            record?.callToActionUrl} className='input' style={{width: '100%'}} onChange={e=>editBulkPostData(index, 'callToActionUrl', e?.target?.value)}/>
                          {errorStates['callToActionUrl'].state && record?.callToActionUrl && !urlRegex.test(record?.callToActionUrl) && <Error>Please provide a valid url</Error>}
                          {errorStates['callToActionUrl'].state && !record?.callToActionUrl && <Error>This field is required</Error>}
                        </div>
                      </FieldContainer>: <></>
                  }
                </div>
                <FieldContainer>
                  <div className='label'>Business</div>
                  <LocationWrapper>{postLocation?.businessName}</LocationWrapper>
                </FieldContainer>
              </div> : record?.topicType === 'OFFER' ?
                <div style={{width: '100%'}}>
                  <FieldContainer>
                    <div className='label' style={{display: 'flex', gap: 2, alignItems: 'flex-start'}}>
                      Title
                      <RegenerateButton disabled={generatingContent.includes(`${index}-title`)}>
                        <Tooltip title='Generate AI Title'>
                          <FontAwesomeIcon
                            icon={faRegRotate}
                            style={{cursor: generatingGbpPosts || generatingContent.includes(`${index}-title`) ? 'not-allowed' : 'pointer'}}
                            className={generatingContent.includes(`${index}-title`) ? 'fa-spin' : ''}
                            onClick={() => (!generatingGbpPosts && !generatingContent.includes(`${index}-title`)) && contentGenerate(index, prompt, override, record?.location, 'title')}
                          />
                        </Tooltip>
                      </RegenerateButton>
                    </div>
                    <div>
                      <Input value={record?.title} className='input' style={{width: 814}} onChange={e=>editBulkPostData(index, 'title', e.target.value)}/>
                      {errorStates['title'].state && !record.title?.trim()? <Error>{errorStates['title'].message}</Error> : <></>}
                    </div>
                  </FieldContainer>
                  <FieldContainer style={{alignItems: 'normal'}}>
                    <div className='label' style={{display: 'flex', gap: 2, alignItems: 'flex-start'}}>
                        Description
                      <RegenerateButton disabled={generatingContent.includes(index)}>
                        <Tooltip title='Generate AI Description'>
                          <FontAwesomeIcon
                            icon={faRegRotate}
                            style={{cursor: generatingGbpPosts || generatingContent.includes(index) ? 'not-allowed' : 'pointer'}}
                            className={generatingContent.includes(index) ? 'fa-spin' : ''}
                            onClick={() => (!generatingGbpPosts && !generatingContent.includes(index)) && contentGenerate(index, prompt, override, record?.location, 'content')}
                          />
                        </Tooltip>
                      </RegenerateButton>
                    </div>
                    <DescriptionWrapper style={{width: '794px'}}>
                      <TextArea value={record?.content} onChange={e=>editBulkPostData(index, 'content', e.target.value )} rows={3} style={{borderRadius: '8px', padding: '0px 10px', borderColor: (errorStates['contentOnTable'].state && !record.content?.trim()) && '#ff4d4f', resize: 'none'}} />
                      {(errorStates['contentOnTable'].state && !record.content?.trim()) ? <Error>{errorStates['contentOnTable'].message}</Error> : <></>}
                      {(errorStates['contentOnTable'].state && record.content?.trim()?.length > 1500) ? <Error>Description content should be under 1500 characters</Error> : <></>}
                    </DescriptionWrapper>
                  </FieldContainer>
                  <div style={{display: 'flex', gap: 40}}>
                    <div>
                      <div style={{display: 'flex', gap: 40}}>
                        <FieldContainer>
                          <div className='label'>
                              Start Date
                          </div>
                          <div>
                            <ScheduleDatePicker suffixIcon={<CaretDownOutlined />} size={'small'} format={'D MMM, YYYY'}
                              className='date-input'
                              getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
                              style={{width: 140, borderColor: (errorStates['startDateAt'].state && !record.startDateAt?.trim()) && '#ff4d4f'}}
                              showToday={false}
                              // status={'error'}
                              placeholder='DD/MM/YYYY'
                              disabledDate={startDisabledDate}
                              value={record?.startDateAt && moment(record?.startDateAt)}
                              onChange={(e: any) => editBulkPostData(index, 'startDateAt', moment(e).format('YYYY-MM-DD'))}
                              allowClear={false} />
                          </div>
                        </FieldContainer>
                        <FieldContainer>
                          <div className='label' style={{width: 96}}>
                              Voucher code
                          </div>
                          <Input value={record?.couponCode} className='input' style={{width: 140}} placeholder='Optional' onChange={e=>editBulkPostData(index, 'couponCode', e?.target?.value)}/>
                        </FieldContainer>
                      </div>
                      <div style={{display: 'flex', gap: 40}}>
                        <FieldContainer>
                          <div className='label'>
                              End Date
                          </div>
                          <ScheduleDatePicker placeholder='DD/MM/YYYY' suffixIcon={<CaretDownOutlined />} size={'small'} format={'D MMM, YYYY'}
                            className='date-input'
                            getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
                            disabled={record?.startDateAt ? false : true}
                            style={{width: 140, borderColor: (errorStates['endDateAt'].state && !record.endDateAt?.trim()) && '#ff4d4f'}}
                            showToday={false}
                            disabledDate={currentDate => {
                              const isBeforeStartDate = record?.startDateAt && currentDate.isBefore(record?.startDateAt, 'day');
                              return currentDate <= moment().subtract(1, 'days') || isBeforeStartDate;
                            }}
                            value={record?.endDateAt && moment(record?.endDateAt)}
                            onChange={(e: any) => editBulkPostData(index, 'endDateAt', moment(e).format('YYYY-MM-DD'))}
                            allowClear={false} />
                        </FieldContainer>
                        <FieldContainer>
                          <div className='label' style={{width: 96}}>
                              Offer link
                          </div>
                          <Input value={record?.redeemUrl} className='input' style={{width: 140}} placeholder='Optional' onChange={e=>editBulkPostData(index, 'redeemUrl', e?.target?.value)}/>
                        </FieldContainer>
                      </div>
                    </div>
                    <FieldContainer style={{alignItems: 'normal'}}>
                      <div className='label' style={{marginTop: '6px'}}>
                          Terms & Conditions
                      </div>
                      <DescriptionWrapper style={{width: '260px'}}>
                        <TextArea value={record?.termsAndConditions} onChange={e=>editBulkPostData(index, 'termsAndConditions', e.target.value )} rows={4} style={{borderRadius: '8px', padding: '0px 10px', resize: 'none'}} />
                      </DescriptionWrapper>
                    </FieldContainer>
                  </div>
                  <FieldContainer>
                    <div className='label'>Business</div>
                    <LocationWrapper>{postLocation?.businessName}</LocationWrapper>
                  </FieldContainer>
                </div> : <></>}
        </ImageWrapper>);
      },
    },
    {
      title: <div className='column-title' style={{whiteSpace: 'nowrap'}}>SCHEDULED DATE</div>,
      dataIndex: 'schedule_publishing_at',
      key: 'schedule_publishing_at',
      render: (_, record, index) => {
        const timezone = standaloneTool ? selectedGbp?.['timezone'] : knowledgeGraph?.['timezone'];
        return <>
          <div style={{width: '130px', position: 'relative', padding: '8px 0px'}}>
            <Tooltip title={`${timezone ? 'Timezone: ' + timezone + ' ' : ''}Date: ${moment(record?.schedulePublishingAt).format('MMM D, YYYY')}`}>
              <ScheduleDatePicker placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'MMM D, YYYY'}
                className='date-input'
                showToday={false}
                disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                value={moment(record?.schedulePublishingAt)}
                onChange={(e: any) => editBulkPostData(index, 'schedulePublishingAt', moment(e).format('YYYY-MM-DD'))}
                allowClear={false}
                getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
              />
            </Tooltip>
          </div>
        </>;
      },
    },
    {
      title: '',
      dataIndex: 'trashIcon',
      key: 'trashIcon',
      render: (_, record, index) => {
        return (
          <Tooltip title={'Delete'}>
            {
              deleteItem === record?.itemIndex ?
                <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4', padding: '18px 0px'}} spin />}/>:
                <StyledTrashIcon
                  icon={faTrash}
                  style={{cursor: generatingImages.includes(index) || generatingGbpPosts || record?.createdBy === 'User' ? 'not-allowed': 'pointer', padding: '18px 0px'}}
                  onClick={()=> {
                    if (!generatingGbpPosts && !generatingImages.includes(index)) {
                      if (getGeneratedBulkPost?.length === 1) {
                        setStep(1);
                      } else {
                        removePostFromBulkData(index);
                      }
                    }
                  }}
                  fontSize={14}
                  color='#A3A4A4'/>
            }
          </Tooltip>
        );
      },
    },
  ];

  const editBulkPostData = (index, updatingKey, updatingValue) => {
    const data = [...getGeneratedBulkPost];
    data[index][updatingKey] = updatingValue;
    setGeneratedBulkPost(data);
  };

  const removePostFromBulkData = index => {
    if (multiSelectedGbp.length === 1) {
      setQuantity(quantity - 1);
    } else {
      const totalPosts = multiSelectedGbp.length * quantity;
      const newQuantity = totalPosts > 0 ? totalPosts - 1 : 0;
      setQuantity(newQuantity / multiSelectedGbp.length);
    }
    setGeneratingImages(generatingImages.slice(0, -1));
    const data = [...getGeneratedBulkPost];
    data.splice(index, 1);
    setGeneratedBulkPost(data);
  };

  const items = [
    {
      label: 'Update',
      key: 'STANDARD',
    },
    {
      label: 'Offer',
      key: 'OFFER',
    },
    {
      label: 'Event',
      key: 'EVENT',
    },
  ];

  const menu = (
    <Menu style={{borderRadius: 8}}>
      {
        items.map(item => (
          <Menu.Item key={item.key} style={{textAlign: 'center'}} onClick={()=>setPostType(item.key)}>{item.label}</Menu.Item>
        ))
      }
    </Menu>
  );
  const currentTimezone = standaloneTool ? selectedGbp?.['timezone'] : knowledgeGraph?.['timezone'];

  return (
    <>
      <StyledGenerateQAModal
        maskClosable={generatingGbpPosts ? false : true}
        width={step === 1 ? '650px' : '1505px'}
        closable={true}
        onClose={() => {
          setOpenGenerateGbpPostsModal(false);
          setEditing(false);
        }}
        visible={openGenerateGbpPostsModal}
        zIndex={100}
      >
        <StyledModalHeader>
          {
            editing ?
              <div className='modal-heading'>Edit GBP Post</div> :
              <>
                <div className='steps'>Step {step} of 2</div>
                <div className='modal-heading'>{step === 1 ? 'Generate' : 'Preview'} GBP Post(s)</div>
              </>
          }
        </StyledModalHeader>
        <ModalContent>
          {step === 1 ? (
            <>
              <FieldWrapper style={{alignItems: 'flex-start'}}>
                <div className='label' style={{width: 106}}>
                  <FontAwesomeIcon icon={faLocationDot} color='#2D6CCA' fontSize={22}/> Business
                </div>
                {(getOttoV2Project?.connectedData?.gbpDetailsV2?.length > 0 || (standaloneTool && standaloneToolGBPData?.length)) &&
                  <Dropdown getPopupContainer={triggerNode => triggerNode} trigger={['click']} overlay={locationList} visible={showGbpDropdown} onVisibleChange={e => setShowGbpDropdown(e)} disabled={generatingGbpPosts ? true : false}>
                    <DropdownWrapper style={{cursor: generatingGbpPosts ? 'not-allowed': 'pointer'}}>
                      <div style={{padding: '5px 0px'}}>
                        <div>{multiSelectedGbp?.length} Selected</div>
                      </div>
                      <FontAwesomeIcon icon={faCaretDown} fontSize={14}/>
                    </DropdownWrapper>
                  </Dropdown>}
              </FieldWrapper>
              <div style={{display: 'flex', gap: 100}}>
                <FieldWrapper>
                  <div className='label' style={{marginTop: 12, marginBottom: 10, width: 106}}>
                    <FontAwesomeIcon icon={faLayerGroup} color='#2D6CCA' fontSize={22}/> Quantity
                  </div>
                  <div className='field-container'>
                    <div className='body' style={{padding: 0}}>
                      <div className='domain-input-wrapper'>
                        <StyledNumberInput
                          upHandler={<FontAwesomeIcon icon={faSortUp} color='#4E5156' fontSize={14} className='up-icon'/>}
                          downHandler={<FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} className='down-icon'/>}
                          value={quantity}
                          max={10}
                          onChange={value => onChangeQuantity(value, setQuantity)}
                          // formatter={formatter}
                          bordered={false}
                          showAfter={false}
                          disabled={generatingGbpPosts ? true : false}
                          onKeyDown={e => e.preventDefault()}
                          type='number'
                        />
                      </div>
                    </div>
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className='label' style={{marginTop: 12, marginBottom: 10, width: 50}}>
                    Type
                  </div>
                  <Dropdown overlay={menu} trigger={['click']} getPopupContainer={node => node} disabled={generatingGbpPosts ? true : false}>
                    <DropdownWrapper style={{width: 100, cursor: generatingGbpPosts ? 'not-allowed': 'pointer'}}>
                      <div style={{padding: '5px 0px'}}>{items.find(item => item.key === postType)?.label}</div>
                      <FontAwesomeIcon icon={faCaretDown} fontSize={14}/>
                    </DropdownWrapper>
                  </Dropdown>
                </FieldWrapper>
              </div>
              <FieldWrapper style={{alignItems: 'flex-start'}}>
                <div className='label' style={{marginTop: 18}}>
                  <FontAwesomeIcon icon={faCalendar} color='#2D6CCA' fontSize={22}/> Post on
                </div>
                <div className='field-container'>
                  <div className='body' style={{padding: 0}}>
                    <div className='domain-input-wrapper' style={{display: 'block'}}>
                      <StyledRadioGroup onChange={handlePostOption} value={step1OptionStates['postOption']?.option} disabled={generatingGbpPosts ? true : false}>
                        <StyledRadio value='all-together'>All together</StyledRadio>
                        <Tooltip
                          title={quantity === 1 ? 'More than one post is required to enable' : ''}
                          placement='topLeft'
                          overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px'}}
                        >
                          <StyledRadio disabled={quantity === 1 ? true : false} value='separately'>Separately</StyledRadio>
                        </Tooltip>
                      </StyledRadioGroup>
                      {step1OptionStates['postOption']?.option === 'separately' ? (
                        <StyledPostContainer>
                          <div className='single-input'>
                            Publish
                            <StyledNumberInput
                              style={{padding: '0px 6px'}}
                              width='56px'
                              upHandler={<FontAwesomeIcon icon={faSortUp} color='#4E5156' fontSize={14} className='up-icon'/>}
                              downHandler={<FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} className='down-icon'/>}
                              value={publishQuantity}
                              max={quantity}
                              onChange={value => onChangeQuantity(value, setPublishQuantity)}
                              bordered={true}
                              showAfter={false}
                              onKeyDown={e => e.preventDefault()}
                              disabled={generatingGbpPosts ? true : false}
                              type='number'
                            />
                          </div>
                          <div className='single-input'>
                            post{publishQuantity>1? 's': ''}, after every
                            <StyledNumberInput
                              style={{padding: '0px 6px'}}
                              width='56px'
                              upHandler={<FontAwesomeIcon icon={faSortUp} color='#4E5156' fontSize={14} className='up-icon'/>}
                              downHandler={<FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} className='down-icon'/>}
                              value={daysQuantity}
                              max={10}
                              onChange={value => onChangeQuantity(value, setDaysQuantity)}
                              bordered={true}
                              showAfter={false}
                              onKeyDown={e => e.preventDefault()}
                              disabled={generatingGbpPosts ? true : false}
                              type='number'
                            />
                          </div>
                          <div className='single-input'>
                            day{daysQuantity>1? 's': ''}
                          </div>
                          <div className='single-input'>
                            starting on
                            <Tooltip title={`${currentTimezone ? 'Timezone: ' + currentTimezone + ' ' : ''}Date: ${date.format('MMM D, YYYY')}`}>
                              <DatePicker placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'MMM D, YYYY'}
                                className='date-input'
                                showToday={false}
                                disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                                value={date}
                                onChange={(e: any) => setDate(e)}
                                allowClear={false}
                                disabled={generatingGbpPosts ? true : false}
                                getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
                              />
                            </Tooltip>
                          </div>
                        </StyledPostContainer>
                      ) : (
                        <StyledPostContainer>
                          <div className='all-together-wrapper'>
                            <Tooltip title={`${currentTimezone ? 'Timezone: ' + currentTimezone + ' ' : ''}Date: ${date ? date.format('MMM D, YYYY') : moment().format('MMM D, YYYY')}`}>
                              <DatePicker placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'MMM D, YYYY'}
                                className='date-input w-125'
                                showToday={false}
                                disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                                value={date ? date : moment()}
                                onChange={(e: any) => setDate(e)}
                                allowClear={false}
                                disabled={generatingGbpPosts ? true : false}
                                getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
                              />
                            </Tooltip>
                          </div>
                        </StyledPostContainer>
                      )}
                    </div>
                  </div>
                </div>
              </FieldWrapper>
              <FieldWrapper style={{alignItems: 'flex-start'}}>
                <div className='label' style={{marginTop: 18}}>
                  <FontAwesomeIcon icon={faImage} color='#2D6CCA' fontSize={22}/> Image
                </div>
                <div className='field-container'>
                  <div className='body' style={{padding: 0}}>
                    <div className='domain-input-wrapper' style={{display: 'block'}}>
                      <StyledRadioGroup onChange={handleImageOption} value={step1OptionStates['imageOption']?.option} disabled={generatingGbpPosts ? true : false}>
                        <StyledRadio value='ai-generate'>AI Generate</StyledRadio>
                        <StyledRadio value='upload'>Upload</StyledRadio>
                      </StyledRadioGroup>
                      {step1OptionStates['imageOption']?.option === 'ai-generate' ? (
                        <StyledPostContainer>
                          <TextArea
                            style={{resize: 'none', height: '60px', padding: '0px'}}
                            bordered={false}
                            value={aiImagePrompt}
                            onChange={e => onChangeImagePrompt(e)}
                            disabled={generatingGbpPosts ? true : false}
                            placeholder={`Describe your image to the AI e.g A group of happy people in a park...`}
                          />
                        </StyledPostContainer>
                      ) : null}
                    </div>
                  </div>
                  {step1OptionStates['imageOption']?.option === 'upload' && <div style={{width: 480}}>You can upload individual images in the next step. AI Help will also be available.</div>}
                  {errorStates['image'].state ? <Error>{errorStates['image'].message}</Error> : <></>}
                </div>
              </FieldWrapper>
              <FieldWrapper style={{alignItems: 'flex-start'}}>
                <div className='label' style={{marginTop: 18}}>
                  <FontAwesomeIcon icon={faBars} color='#2D6CCA' fontSize={22}/> Content
                </div>
                <div className='field-container'>
                  <div className='body' style={{padding: 0}}>
                    <div className='domain-input-wrapper' style={{display: 'block'}}>
                      <StyledRadioGroup onChange={handleContentOption} value={step1OptionStates['contentOption']?.option} disabled={generatingGbpPosts ? true : false}>
                        <StyledRadio value='write-with-ai'>Write with AI</StyledRadio>
                        <StyledRadio value='write-your-own'>Write your own</StyledRadio>
                      </StyledRadioGroup>
                      {step1OptionStates['contentOption']?.option === 'write-with-ai' ? (
                        <StyledPostContainer>
                          <TextArea
                            style={{resize: 'none', height: '110px', padding: '0px'}}
                            bordered={false}
                            value={aiContentPrompt}
                            onChange={e => onChangeContentPrompt(e)}
                            disabled={generatingGbpPosts ? true : false}
                            placeholder={`What should the post be about? e.g ABC Corp is organizing a meeting in the Central Park...`}
                          />
                        </StyledPostContainer>
                      ) : null}
                    </div>
                  </div>
                  {errorStates['content'].state ? <Error>{errorStates['content'].message}</Error> : <></>}
                  {step1OptionStates['contentOption']?.option !== 'write-your-own' && <Tooltip
                    overlayInnerStyle={{width: '390px', borderRadius: '8px', backgroundColor: '#24262A', color: '#FFFFFF', fontFamily: 'Inter, sans-serif', fontWeight: 400, fontSize: '12px'}}
                    title={`The AI-generated post content will remain uninfluenced by the company's description information.`}
                    placement='bottomLeft'>
                    <ContentCheckbox>
                      <Checkbox disabled={generatingGbpPosts} checked={ignoreBusiness} onChange={e => {
                        setIgnoreBusiness(e?.target?.checked);
                        if (!e?.target?.checked) setErrorStates({...errorStates, content: {...errorStates['content'], state: false}});
                      }}/>Ignore business background info
                    </ContentCheckbox>
                  </Tooltip>}
                  {step1OptionStates['contentOption']?.option === 'write-your-own' && <div style={{width: 480}}>You can write the post content in the next step.</div>}
                </div>
              </FieldWrapper>
              <InfoContainer>
                <FontAwesomeIcon icon={faCircleInfo} color='#2D6CCA' fontSize={16} style={{marginRight: 5}}/>Posts, schedule date, image and content can be modified in the next step.
              </InfoContainer>
            </>
          ) : (
            <StyledStepTwoWrapper style={{backgroundColor: 'white'}}>
              <StyledTable
                columns={columns}
                dataSource={getGeneratedBulkPost}
                pagination={false}
              />
            </StyledStepTwoWrapper>
          )}
          <StyledModalFooter>
            <div className='cancel-btn' onClick={() => {
              setOpenGenerateGbpPostsModal(false);
              setEditing(false);
            }}>
              Cancel
            </div>
            <Tooltip
              placement='topRight'
              overlayInnerStyle={{backgroundColor: '#000000', borderRadius: '8px', padding: '7px 10px'}}
              title={generatingGbpPosts ? step === 1 ? `Generating Post${(quantity * multiSelectedGbp.length)>1 ? 's': ''}` : `Creating Post${(quantity * multiSelectedGbp.length)>1 ? 's': ''}` : multiSelectedGbp.length > 4 ? 'Maximum 4 businesses can be selected' : ''}
            >
              <div>
                <ButtonComponent
                  color='blue'
                  style='solid'
                  type='button'
                  className='update-button'
                  onClick={onSubmit}
                  disabled={(!getGeneratedBulkPost?.length && step == 2) || generatingImages.length || generatingGbpPosts || multiSelectedGbp.length > 4 || generatingContent?.length ? true : false}
                >
                  {
                    editing ? (<>
                      <FontAwesomeIconWrapper icon={faCircleCheck} fontSize={14} color='#fff'/> {generatingGbpPosts ? 'Updating' : 'Update'} {generatedBulkQA?.length > 0 && generatedBulkQA?.length} Post{quantity>1 ? 's': ''}
                      {generatingGbpPosts ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: 'white', marginLeft: 5}} spin />} /> : <></>}
                    </>
                    ) : <>
                      {step === 1 ? (<>
                        Preview {multiSelectedGbp.length * quantity} Post{(multiSelectedGbp.length * quantity)>1 ? 's': ''} {!generatingGbpPosts && <FontAwesomeIcon icon={faArrowRight} color='#fff' fontSize={14}/>}
                      </>) : (<>
                        <FontAwesomeIconWrapper icon={faCircleCheck} fontSize={14} color='#fff'/> {generatingGbpPosts ? 'Creating' : 'Create'} {multiSelectedGbp.length * quantity} Post{quantity>1 ? 's': ''}
                      </>
                      )} {generatingGbpPosts ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: 'white', marginLeft: 5}} spin />} /> : <></>}
                    </>
                  }
                </ButtonComponent>
              </div>
            </Tooltip>
          </StyledModalFooter>
        </ModalContent>
      </StyledGenerateQAModal>
      <StyledGenerateQAModal
        width={'850px'}
        closable={true}
        onClose={() => {
          setPreviewImage('');
          setViewPreview(false);
        }}
        closeIcon={<div style={{position: 'absolute', left: '7px', top: '-10px'}}><FontAwesomeIcon color={'black'} fontSize={22} icon={faXmark}/></div>}
        visible={viewPreview}
        zIndex={999}
      >
        <img src={previewImage} style={{width: '100%'}}/>
      </StyledGenerateQAModal>
    </>
  );
});

const FontAwesomeIconWrapper = styled(FontAwesomeIcon)`
  padding-right: 3px;
`;
const RegenerateButton = styled.button`
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
`;
const StyledTable = styled(StyledIssuesTable)`
  .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table {
    .ant-table-thead tr th, .ant-table-tbody tr td {
      padding: 6px 14px !important;
    }
  }
`;
const StyledGenerateQAModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    // right: -34px;
    // top: 0px;
    font-weight: 700;
    // color: #fff !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .steps {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
  }
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 8px;
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const ModalContent = styled.div``;
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .label {
    display: flex;
    width: 110px;
    align-items: center;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #121212;
    margin-top: 8px;
  }
  .field-container {
    .header {
      display: flex;
      justify-content: space-between;
      .right-section {
        align-items: center;
        display: flex;
        gap: 10px;
        .button {
          align-items: center;
          display: flex;
          gap: 10px;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 400;
          color:#2D6CCA;
          cursor: pointer;
        }
      }
    }
    .body {
      overflow: hidden;
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      padding: 10px;
      width: 100%;
      margin: 5px 0;
      background-color: #ffffff;

      .date {
        width: 105px;
      }
      .time {
        width: 85px;
      }
      .ant-picker-small {
        padding: 0px 0px 0px !important;
      }
      .replace-image {
        padding: 6px;
        background: #4E5156;
        border-radius: 6px;
        position: absolute;
        right: 33px;
        top: 131px;
      }

      .domain-input-wrapper {
        display: flex;
        align-items: center;
        .domain {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #A3A4A4;
          padding-left: 10px;
        }
        .slug {
          padding: 5px 0px;
        }
      }
      .keywords-input {
        resize: none;
        height: 78px;
      }
    }
    .error-message {
      color: red;
    }

  }
`;
const StyledModalFooter = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;
const StyledPostContainer = styled.div`
  padding: 10px 14px;
  width: 480px; 
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  .single-input {
    display: flex;
    align-items: center;
    gap: 6px;
    .date-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 130px !important;
      .ant-picker-input {
        padding: 4px 10px !important;
        pointer-events: none;
      }
    }
  }
  .all-together-wrapper {
    display: flex;
    gap: 7px;
    .date-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 145px !important;
      .ant-picker-input {
        padding: 4px 10px !important;
        pointer-events: none;
      }
    }
    .w-125 {
      width: 125px !important;
    }
    .time-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 130px !important;
    }
  }
`;
const StyledRadioGroup = styled(Radio.Group)`
  padding: 10px 14px;
  border-bottom: 1px solid #D8D8D8;
  display: block;
  width: 480px;
  .ant-radio-button-wrapper {
    padding: 0px 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #121212;
    &.ant-radio-button-wrapper-checked {
      background: #2D6CCA26;
      :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
    }
    :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 8px 8px 0px;
  }
`;
const StyledNumberInput = styled(InputNumber)<{width?: string; showAfter?: boolean}>`
  width: ${p => p?.width ? p.width : '90px'} !important;
  border: 1px solid #D8D8D8 !important;
  border-radius: 8px;
  .ant-input-number-handler-wrap {
    opacity: 1 !important;
    background: transparent;
  }
  &.ant-input-number-borderless {
    box-shadow: none !important;
    border: none !important;
  }
  .ant-input-number-input-wrap {
    .ant-input-number-input {
      padding: ${p => p?.showAfter ? '0px 58px 0px 11px': '0px 11px'} !important;
    }
  }
  .ant-input-number-handler-wrap {
    .ant-input-number-handler {
      border-left: none !important;
      border-top: none !important;
      &:active {
        background: transparent;
      }
      .up-icon {
        position: absolute;
        top: 7px;
        right: 8px;
      }
      .down-icon {
        position: absolute;
        bottom: 7px;
        right: 8px;
      }
    }
    &:hover .ant-input-number-handler {
      height: 50% !important;
    }
  }
  ${p => p?.showAfter ? `
  &::after {
    content: 'Q&As';
    position: absolute;
    top: 5px;
    right: 20px;
    color: #121212;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    z-index: 1;
  }` : ''};
`;
const StyledStepTwoWrapper = styled.div`
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  padding: 4px 10px;
`;

const ScheduleDatePicker = styled(DatePicker)`
  border: 1px solid #D8D8D8;
  border-radius: 8px 8px 8px 8px;
  .ant-picker-input {
    pointer-events: none;
    input {
      height: 32px;
      width: 94px;
    }
  }
`;

const StyledRadio = styled(Radio)`
  .ant-radio {
    .ant-radio-inner{
      background-color: #fff;
    &::after {
      background-color: #FFFFFF;
    }
  }
  &.ant-radio-checked .ant-radio-inner {
    border-color: #2D6CCA;
    background-color: #2D6CCA;
  }
}
`;

const DropdownWrapper = styled.div`
  padding: 0 11px;
  width: 480px;
  font-size: 14px;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  color: #121212;
  display: flex;
  align-items: center;  
  gap: 9px;
  cursor: pointer;
  background: #fff;
  justify-content: space-between;
  margin: 5px 0 10px 0;
`;

export const DropdownContainer = styled.div`
  width: 480px;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const GbpSearchInput = styled(Input)`
  width: 455px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;

const StyledDragger = styled(Dragger)`
  border-radius: 8px !important;
  padding: 0px 10px;
  margin: 0px;
  border: 2px dashed #d9d9d9 !important;
  .ant-upload {
    padding: 2px 0 !important;
  }
  .ant-upload-drag {
    height: 64px !important;
  }
  &.border-less {
    border: none !important;
    background-color: #F2F2F5;
  }
`;

const DragContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 103px;
  .contain {
    width: 103;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .img {
      margin: auto;
      width: 25px;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 2px 10px;
  border-radius: 8px;
  border: 2px dashed #d9d9d9;
  background-color: #F2F2F5;
  width: 126px;
  height: 68px;
  .upload-container {
    padding: 2px 10px;
    border: none;
    background-color: #F2F2F5;
    cursor: pointer;
  }
`;

const TitleStyled = styled.div`
  margin: auto;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #4E5156;
  margin-top: 5px;
`;

const LoadingImage = styled.div`
  width: 126px;
  height: 68px;
  border-radius: 8px;
  background-color: #F2F2F5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #2d6cca4b;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: black;
`;

const DropDownInfoInnerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #2d8bca4b;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: black;
`;

const Error = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-left: 4px;
  color: #ff4d4f;
`;

const MultiDiv = styled.div`
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
        
&::-webkit-scrollbar-thumb {
  background: rgba(138,138,138,0.32);
}
        
&::-webkit-scrollbar-track {
  background: rgba(138,138,138,0.12);
}
`;
const LocationWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  color: 121212;
`;
const ContentCheckbox = styled.div`
  width: 208px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #121212;
  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 5px !important;
        overflow: hidden;
      }
      &.ant-checkbox-checked::after {
        border-radius: 5px !important;
      }
    }
  }
`;
