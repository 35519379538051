import {observer} from 'mobx-react';
import React from 'react';
import {COLORS} from '@/components/common-components/v2/colors';
import {formatNumber, getPercentage, numberWithCommas} from '@/utils/number';
import {faMoneySimpleFromBracket, faSquareQuestion} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip, Progress, Divider, Button} from 'antd';
import {TooltipPlacement} from 'antd/lib/tooltip';
// import styles from './styles.module.scss';
import styled from 'styled-components';
import {ButtonColor} from '../../common-components/v2/Button';
import {getInversePercentageColor} from '@/utils/colors';
import {PurchasedQuota} from '../components/badge';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {openLiveChat} from '@/utils/intercom';

interface Guide {
  title: string;
  value: number | string;
}

interface ForHeatmapGuide {
  title: string;
  value: number | string;
}

interface GridShape {
  circle: ForHeatmapGuide[];
  rectangle: ForHeatmapGuide[];
}


interface Props {
  consumedData: number;
  totalData: number;
  heading?: string;
  description?: string;
  isCenter?: boolean;
  showCta?: boolean;
  showGuide?: boolean;
  showHeatmapGuide?: boolean;
  customCta?: any;
  customTopUpOnClick?: any;
  ctaColor?: ButtonColor;
  ctaLabel?: string;
  guideData?: Guide[];
  heatmapGuideData?: GridShape;
  isConsemed?:string;
  isRemaining?:string;
  isTotal?:string;
  isNoProgressBar?:boolean;
  isKrPublic?:boolean;
  isCampaignEmailQuota?: boolean;
  hideTopup?: boolean;
  showPurchasedQuota?: any;
  forHeatmap?: boolean;
  fontSize?: number;
  ctaIcon?: any;
  varient?: string;
  purchasedQuotaTooltipTxt?: string;
  showTopup?: boolean;
  showTopupAfterDesc?: boolean;
  topupCallback?: any;
  tooltipOnVisibleChange?: any;
  visible?: any;
  infoDescriptionWidth?: number | string;
  infoDescriptionIcon?: IconProp;
  infoDescription?: string;
  tooltipWidth?: number | string;
  dontShowGuideData?: boolean;
  tooltipPlacement?: TooltipPlacement;
  disableCtaButton?: boolean;
}

export const QuotaInfoDetail: React.FC<Props> = observer(({
  consumedData,
  totalData,
  heading,
  isCenter,
  description,
  showGuide,
  showHeatmapGuide,
  isConsemed,
  isRemaining,
  isTotal,
  guideData,
  showCta,
  customCta,
  customTopUpOnClick,
  ctaColor,
  ctaLabel,
  isNoProgressBar,
  isKrPublic,
  heatmapGuideData,
  isCampaignEmailQuota,
  hideTopup = false,
  showPurchasedQuota,
  forHeatmap = false,
  fontSize = 14,
  ctaIcon,
  varient= 'white',
  purchasedQuotaTooltipTxt,
  showTopup = false,
  showTopupAfterDesc = false,
  topupCallback,
  tooltipOnVisibleChange,
  visible,
  infoDescriptionWidth,
  infoDescriptionIcon,
  infoDescription,
  tooltipWidth,
  dontShowGuideData,
  tooltipPlacement = 'bottom',
  disableCtaButton= false,
}) => {
  const difference = totalData - consumedData;

  const {width} = useWindowSize();

  const alignConfig = {
    points: ['tr', 'br'],
    offset: [-50, 4],
  };

  return (
    <div>
      <QuotaContainer isCenter={isCenter}><QuotaDivSA fontSize={fontSize}>
        {isCampaignEmailQuota ? <>
          {/* <span style={{display: 'flex'}} >{heading}:<QuotaValues style={{marginRight: '5px'}}>{consumedData ? `${formatNumber(consumedData, 1)}`.replace('.0', '') : 0}</QuotaValues>of<QuotaSubValue style={{marginLeft: '5px'}}>{totalData ? `${formatNumber(totalData, 1)}`.replace('.0', '') : '-'}</QuotaSubValue></span> */}
          {!isNoProgressBar && <ProgressStyled
            trailColor={'#4E5156'}
            strokeColor={getInversePercentageColor(getPercentage(Number(consumedData), 0, Number(totalData)))}
            percent={getPercentage(Number(consumedData), 0, Number(totalData))}
            showInfo={false} /> }
        </> :
          isKrPublic ? <div>Quota: 10 requests per day</div> :<>
            <span style={{display: 'flex', color: forHeatmap ? '#121212' : ''}} >
              {heading}:<QuotaValues>{consumedData ? `${formatNumber(consumedData, 1)}`.replace('.0', '') : 0} </QuotaValues>
              <QuotaSubValue>/{totalData ? `${formatNumber(totalData, 1)}`.replace('.0', '') : 0}</QuotaSubValue>
              {showPurchasedQuota ? <PurchasedQuota total={`+${showPurchasedQuota}`} purchasedQuotaTooltipTxt={purchasedQuotaTooltipTxt} /> : ''}
              <Tooltip
                trigger={'hover'}
                align={dontShowGuideData ? alignConfig : {}}
                overlayInnerStyle={{
                  width: showGuide ? 200 : showHeatmapGuide ? width > 650 ? tooltipWidth : 'auto' : 157,
                  padding: showHeatmapGuide ? '15px' : '10px',
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
                  boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
                  borderRadius: 8,
                }}
                placement={tooltipPlacement}
                onVisibleChange={tooltipOnVisibleChange && tooltipOnVisibleChange}
                visible={visible}
                title={<>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <TooltipTitle showHeatmapGuide={showHeatmapGuide} showGuide={showGuide}>{description}</TooltipTitle>
                    {showTopup && <span style={{display: 'flex'}}> <TopupButton onClick={topupCallback}>
                      <FontAwesomeIcon icon={faMoneySimpleFromBracket} color='#fff' /> Top up
                    </TopupButton></span>}
                  </div>
                  {!showGuide ? showHeatmapGuide && forHeatmap ? null : <Divider style={{background: '#4E5156', margin: '10px 0px'}}/> : null}
                  {!showHeatmapGuide && !forHeatmap ? <><div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>{isConsemed ?? 'Used'}:</span><span>{numberWithCommas(consumedData)}</span></>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>{isRemaining ?? 'Remaining'}:</span><span style={{color: difference > 0 ? '#15ac47' : '#f44343'}}>{difference > 0 ? numberWithCommas(difference) : 0}</span></>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span> {isTotal ?? 'Total'}:</span><span>{totalData ? numberWithCommas(totalData) : 0}</span></>
                  </div></> : <div style={{width: '120px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                      <span style={{width: '105px', color: '#fff', fontSize: 12, fontWeight: 600}}>{isConsemed ?? 'Used'}</span>
                      <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
                        <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{numberWithCommas(consumedData)}</span>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                      <span style={{width: '105px', color: '#fff', fontSize: 12, fontWeight: 600}}>{isRemaining ?? 'Remaining'}</span>
                      <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
                        <span style={{color: difference > 0 ? '#2AC155' : '#f44343', fontSize: 12}}>&nbsp;{difference > 0 ? numberWithCommas(difference) : 0}</span>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                      <span style={{width: '105px', color: '#fff', fontSize: 12, fontWeight: 600}}> {isTotal ?? 'Total'}</span>
                      <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
                        <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{totalData ? numberWithCommas(totalData) : '-'}</span>
                      </div>
                    </div>
                  </div>}
                  {showHeatmapGuide && forHeatmap ? <>
                    <GuideInfoWrapper width={infoDescriptionWidth}><FontAwesomeIcon icon={infoDescriptionIcon} color={'#FFFFFF'} /><div>{infoDescription}</div></GuideInfoWrapper>
                    {showTopupAfterDesc && <span style={{display: 'flex', marginTop: '20px'}}> <TopupButton onClick={topupCallback}>
                      <FontAwesomeIcon icon={faMoneySimpleFromBracket} color='#fff' /> Top up
                    </TopupButton></span>}
                    {!dontShowGuideData && <DataWrapper style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>
                        <div style={{fontWeight: 600}}>Circle</div>
                        {heatmapGuideData?.circle?.map((item: Guide, key: number) => (
                          <div key={key} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{width: '55px', color: '#fff', fontSize: 12, fontWeight: 600}}>{`${item.title}`}</span>
                            <div style={{width: '80px', display: 'flex', alignItems: 'left'}}>
                              <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{item.value == '...' ? '' : numberWithCommas(item.value as number)}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        <div style={{fontWeight: 600}}>Grid</div>
                        {heatmapGuideData?.rectangle?.map((item: Guide, key: number) => (
                          <div key={key} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{width: '73px', color: '#fff', fontSize: 12, fontWeight: 600}}>{`${item.title}`}</span>
                            <div style={{width: '70px', display: 'flex', alignItems: 'left'}}>
                              <span style={{color: '#E8E8E8', fontSize: 12}}>&nbsp;{item.value == '...' ? '' : numberWithCommas(item.value as number)}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </DataWrapper>}
                  </> : null}
                  {showGuide ? <>
                    <Divider style={{background: '#4E5156', margin: '10px 0px'}} />
                    <TooltipTitle showGuide={showGuide}>Quota consumption guide</TooltipTitle>
                    {guideData.map((item: Guide, key: number) => (
                      <div key={key} style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                        <> <span>{`${item.title}:`}</span><span>{numberWithCommas(item.value as number)}</span></>
                      </div>
                    ))}
                  </> : null
                  }
                  {showCta ? <>
                    <Divider style={{background: '#4E5156', margin: '10px 0px'}}/>
                    <CtaButton disabled={disableCtaButton} onClick={() => {
                      if (customCta && typeof customCta === 'function' && !disableCtaButton) {
                        customCta();
                      }
                    }}
                    color={ctaColor ?? 'green'}
                    varient={varient}
                    style={{marginTop: '10px', marginLeft: 0}}
                    >
                      {ctaIcon? ctaIcon : ''}
                      {ctaLabel ?? ''}
                    </CtaButton>
                  </> : null}

                </>} >
                <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={14} style={{marginLeft: 6, marginTop: 4, cursor: 'pointer'}} />
              </Tooltip>
              {(!hideTopup && consumedData >= totalData) && consumedData >= totalData && <TopupText onClick={() => {
                if (customTopUpOnClick && typeof customTopUpOnClick === 'function') {
                  customTopUpOnClick();
                } else {
                  openLiveChat();
                }
              }}>Top Up</TopupText>}
            </span>
            {!isNoProgressBar && <ProgressStyled
              trailColor={forHeatmap ? '#bdbdbd' :'#4E5156'}
              strokeColor={getInversePercentageColor(getPercentage(Number(consumedData), 0, Number(totalData)))}
              percent={getPercentage(Number(consumedData), 0, Number(totalData))}
              showInfo={false} /> }
          </>}
      </QuotaDivSA> </QuotaContainer>
    </div>
  );
});

const DataWrapper = styled.div`
  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

const QuotaValues = styled.div`
  margin-left:5px;
`;
const ProgressStyled = styled(Progress)`
margin-top:-7px;
  .ant-progress-inner {
    height: 3px;
  }
`;
const QuotaSubValue = styled.div`
  font-size:12px;
  display: flex;
  align-items: center;
`;
const QuotaDivSA = styled.div<{fontSize?: number}>`
  display:flex;
  flex-direction:column;
  font-size: 14px;
  font-weight:400;
  color: ${COLORS.gray};
  @media screen and (max-width: 425px){
    font-size: ${p => p.fontSize !== null ? 12 : 14}px;
  }
  `;
const QuotaContainer = styled.div<{isCenter}>`
display:flex;
gap: 25px;
justify-content:${p=>p.isCenter ? '' : 'center'};
`;
const TooltipTitle = styled.div<{showHeatmapGuide?: boolean; showGuide?: boolean; calledFrom?:string}>`
font-family: 'Inter';
font-style: normal;
font-weight: ${p => p.showGuide || p.showHeatmapGuide ? 600 : 400};
font-size: ${p => p.showGuide ? 13 : '12px'};
line-height: ${p => p.showGuide ? 15.73 : 14}px;
color: '#FFFFFF';
margin-bottom: ${p => p.showGuide || p.showHeatmapGuide ? 10 : 0}px;
`;

const TopupText = styled.p`
color: #2D6CCA;
margin-left: 10px;
margin-top: 0;
margin-bottom: 0;
cursor: pointer;
`;

const GuideInfoWrapper = styled.div<{width?: number | string}>`
  width: ${p => typeof p?.width == 'string' ? p?.width : (typeof p?.width == 'number' ? `${p?.width}px` : '310px')};
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  color: #FFFFFF;
  margin: 10px 0;
  height: auto;
  padding: 3px 10px 3px 8px;
  border-radius: 21px;
  background: #4E5156;
  @media screen and (max-width: 650px) {
    width: auto;
  }
`;

const CtaButton = styled(Button)<{varient?: string; disabled?: boolean}>`
  ${p => p.varient === 'green' ? `
      background-color: rgba(42, 193, 85, 0.15) !important;
      width: 96px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 5px;
      border: none;
      margin-top: 12px;
      cursor: pointer;
      color: #fff;
      &:hover{
        color: #fff;
      }
  ` : ''}
  ${p => p.disabled ? `
  cursor: not-allowed;
  opacity: 0.4;
  
  ` : 'cursor: pointer'}
`;

const TopupButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4.5px;
  width: 96px;
  height: 32px;
  border-radius: 6px;
  background-color: #2AC155 !important;
  color: #fff;
  border: 0px;
  &:hover {
    color: #fff;
  }
  &:focus {
    color: #fff;
  }
`;
