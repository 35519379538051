import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {COLORS} from './colors';

export type ButtonColor = 'purple' | 'white' | 'tableGray' | 'textGrayLight' | 'textGrayDark' | 'green' | 'paleGreen' | 'blue' | 'black' | 'red' | 'pink' | 'orange' | 'neonBlue' | 'blueLight';
export type ButtonVariant = 'solid' | 'ghost';
export type ButtonMode = 'light' | 'dark';
export interface ButtonProps extends AntdButtonProps {
  color?: ButtonColor;
  variant?: ButtonVariant;
  mode?: ButtonMode;
  background?: string;
  textColor?: string;
  margin?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}


// Figma:
// https://www.figma.com/file/tdvnkn6V30KvcYXqs0lJ6Y/Tools-Dashboard?node-id=7575%3A2564

export const Button = ({color = 'purple', variant = 'solid', mode='light', background = '', textColor = '', onClick, ...props}: ButtonProps) => {
  return (
    <ButtonStyled onClick={onClick} color={color} variant={variant} background={background} mode={mode} textColor={textColor} {...props}></ButtonStyled>
  );
};

const ButtonStyled = styled(AntdButton)<ButtonProps>`
  background-color: ${p => p.background ? `${p.background}` : p.variant === 'ghost' ? `${COLORS[p.color]}1C` : `${COLORS[p.color]}` };
  border-radius: 5px;
  border: ${p => p.mode === 'dark' ? `1px solid ${COLORS[p.color]}` : '1px solid transparent'};
  padding: 7px 20px;
  color: ${p => p.textColor ? `${p.textColor}` : p.mode === 'dark' || p.variant === 'solid' ? '#fff' : COLORS[p.color]};
  font-size: 14px;
  width:auto;
  height: auto;
  margin: ${p => p.margin ? p.margin : '0px 10px'};
  display:flex ;
  align-items:center;
  :hover {
    background: ${p => p.variant === 'solid' && `linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)),${p.background ? `${p.background}` : COLORS[p.color]} !important`};
    background-color: ${p => `${COLORS[p.color]}2E`};
    border: ${p => p.mode === 'dark' ? `1px solid ${COLORS[p.color]}` : '1px solid transparent'};
    color: ${p => p.mode === 'dark' || p.variant === 'solid' ? '#fff' : p.textColor ? `${p.textColor}` : COLORS[p.color]};
  }

  :active {
    background: ${p => p.variant === 'solid' && `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),${p.background ? `${p.background}` : COLORS[p.color]} !important`};
    background-color: ${p => p.background ? `${p.background}` : p.variant === 'ghost' ? `${COLORS[p.color]}40` : COLORS[p.color] };
    border: ${p => p.mode === 'dark' ? `1px solid ${COLORS[p.color]}` : '1px solid transparent'};
    color: ${p => p.mode === 'dark' || p.variant === 'solid' ? '#fff' : p.textColor ? `${p.textColor}` : COLORS[p.color]};
  }
  :disabled{
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), ${p => p.background ? `${p.background}` : COLORS[p.color]};
      background-color: ${p => p.background ? `${p.background}` : p.variant === 'ghost' ? `${COLORS[p.color]}2E` : COLORS[p.color] };
      border-color: ${p => p.background ? `${p.background}` : COLORS[p.color]};
      color: ${p => p.mode === 'dark' || p.variant === 'solid' ? '#fff' : p.textColor ? `${p.textColor}` : COLORS[p.color]};
    opacity: 50%;
    :hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), ${p => p.background ? `${p.background}` : COLORS[p.color]};
      background-color: ${p => p.background ? `${p.background}` : p.variant === 'ghost' ? `${COLORS[p.color]}2E` : COLORS[p.color] };
      border-color: ${p => p.background ? `${p.background}` : COLORS[p.color]};
        color: ${p => p.mode === 'dark' || p.variant === 'solid' ? '#fff' : p.textColor ? `${p.textColor}` : COLORS[p.color]};
    }
  }

  :focus {
    background: ${p => p.variant === 'solid' && `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),${p.background ? `${p.background}` : COLORS[p.color]} !important`};
    background-color: ${p => p.background ? `${p.background}` : p.variant === 'ghost' ? `${COLORS[p.color]}40` : COLORS[p.color] };
    border: ${p => p.mode === 'dark' ? `1px solid ${COLORS[p.color]}` : '1px solid transparent'};
    color: ${p => p.mode === 'dark' || p.variant === 'solid' ? '#fff' : p.textColor ? `${p.textColor}` : COLORS[p.color]};
  }
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
`;


interface GrayButtonProps extends AntdButtonProps {
  color?: 'white' | 'transparent' | 'gray';
  variant?: 'border' | 'noBorder';
  mode?: 'light' | 'dark';
}

/*
* white button will only notice your border variations and will only work in light mode.
* gray button will react to border variations and will work in dark and white variation
* transparent button will react to border, and will only work in dark mode.
*/

export const GrayButton = ({color = 'white', variant = 'border', mode='light', ...props}: GrayButtonProps) => {
  return (
    <GrayButtonStyled color={color} variant={variant} mode={mode} {...props}></GrayButtonStyled>
  );
};

const GrayButtonStyled = styled(AntdButton)<GrayButtonProps>`
  background-color: ${p => (p.color === 'white' && p.mode === 'light') ? `#fff` : p.mode === 'dark' ? (p.color === 'transparent' && p.variant === 'noBorder') ? 'transparent' : `#54565A1C` : `#E8E8E81C` };
  border-radius: 5px;
  border: ${p => p.variant === 'border' ? (p.color === 'white' && p.mode === 'light') ? '1px solid #E8E8E8' : p.mode === 'dark' ? '1px solid #54565A' : '1px solid #E8E8E8' : '1px solid transparent'};
  padding: 7px 20px;
  color: ${p => (p.color === 'white' && p.mode === 'light') ? '#4E5156' : p.mode === 'dark' ? '#fff' : '#4E5156'};
  font-size: 14px;
  width:auto;
  height: auto;
  margin:0px 10px;

  :hover {
    background: ${p => p.mode === 'dark' ? 'rgba(84, 86, 90, 0.18)' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),#E8E8E8 !important'}; 
    background-color:${p => p.mode === 'dark' ? '#54565A2E' : '#E8E8E82E'};
    border: ${p => p.variant === 'border' ? (p.color === 'white' && p.mode === 'light') ? '1px solid #E8E8E8' : p.mode === 'dark' ? '1px solid #54565A' : '1px solid #E8E8E8' : '1px solid transparent'};
    color: ${p => (p.color === 'white' && p.mode === 'light') ? '#4E5156' : p.mode === 'dark' ? '#fff' : '#4E5156'};
  }

  :active {
    background: ${p => p.mode === 'dark' ? 'rgba(84, 86, 90, 0.18)' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),#E8E8E8 !important'}; 
    background-color: ${p => p.mode === 'dark' ? '#54565A40' : '#E8E8E840'};
    border: ${p => p.variant === 'border' ? (p.color === 'white' && p.mode === 'light') ? '1px solid #E8E8E8' : p.mode === 'dark' ? '1px solid #54565A' : '1px solid #E8E8E8' : '1px solid transparent'};
    color: ${p => (p.color === 'white' && p.mode === 'light') ? '#4E5156' : p.mode === 'dark' ? '#fff' : '#4E5156'};
  }
  :disabled{
    background-color: ${p => (p.color === 'white' && p.mode === 'light') ? `#fff`: p.mode === 'dark' ? `#54565A1C` : `#E8E8E81C` };
    color: ${p => (p.color === 'white' && p.mode === 'light') ? '#4E5156' : p.mode === 'dark' ? '#fff' : '#4E5156'};
    opacity: 50%;
    :hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),#E8E8E8 !important;
      background-color:${p => p.mode === 'dark' ? '#54565A2E' : '#E8E8E82E'};
      border: ${p => p.variant === 'border' ? (p.color === 'white' && p.mode === 'light') ? '1px solid #E8E8E8' : p.mode === 'dark' ? '1px solid #54565A' : '1px solid #E8E8E8' : '1px solid transparent'};
      color: ${p => (p.color === 'white' && p.mode === 'light') ? '#4E5156' : p.mode === 'dark' ? '#fff' : '#4E5156'};
    }
  }

  :focus {
    background: ${p => p.mode === 'dark' ? 'rgba(84, 86, 90, 0.18)' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),#E8E8E8 !important'}; 
    background-color:${p => p.mode === 'dark' ? '#54565A40' : '#E8E8E840'};
    border: ${p => p.variant === 'border' ? (p.color === 'white' && p.mode === 'light') ? '1px solid #E8E8E8' : p.mode === 'dark' ? '1px solid #54565A' : '1px solid #E8E8E8' : '1px solid transparent'};
    color: ${p => (p.color === 'white' && p.mode === 'light') ? '#4E5156' : p.mode === 'dark' ? '#fff' : '#4E5156'};
  }
`;

