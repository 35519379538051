import {getApiUrl, getAuthorizationHeader, getMessagesFromErrorResponse, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {notification} from '@/utils/notifications';
import {ottoWhitelabelName} from '@/utils/functions';
import {apiError} from '@/utils/api';

type InviteMemberDTO = {
  emails: string;
  message: string;
  role?: string;
  customer?: string;
};

type AddMemberDTO = {
  email_address: string;
  role: string;
};

class ProfileApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer');
  private static readonly ssoBaseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/sso');
  private static readonly keywordBaseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');

  public async getProfile() {
    const headers = {};
    headers['Authorization'] = getAuthorizationHeader();
    try {
      const response = await this.axios.get(`${ProfileApi.baseUrl}/account/user/`, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getNotifications(params: { page: number; page_size: number }) {
    const headers = {};
    const token = getTokenFromCookies();

    if (token ) {
      headers['Authorization'] = getAuthorizationHeader();
      try {
        const response = await this.axios.get(`${ProfileApi.baseUrl}/whatsnew/`, {
          params,
          headers: headers,
          cancelToken: this.cancelToken,
        });
        return response.data;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }

  public async activateProfile(activationCode: string | null) {
    try {
      const response = await this.axios.post(
        `${ProfileApi.baseUrl}/account/verify/`,
        {
          activation_code: activationCode,
        },
        {cancelToken: this.cancelToken},
      );
      if (response.data) {
        const urlName = localStorage.getItem('urlName');
        const publicHash = localStorage.getItem('publicHash');
        const domain = localStorage.getItem('domain');
        const id = localStorage.getItem('id');
        const step = localStorage.getItem('step');
        if (urlName === 'domainAnalyzeUrl') {
          window.location.replace(`/research/competitor-researcher-public/${id}?public_hash=${publicHash}&domain_analyzer=${domain}&step=${step}`);
        } else if (urlName === 'domainAnalyzeUrlNew') {
          window.location.replace(`/go/url/?step=${step}&create_article=false`);
        } else {
          window.location.replace('/onboarding');
        }
      }

      return response.data;
    } catch (error) {
      error?.response?.data && notification.error(error?.response?.data?.nonFieldErrors?.length ? error?.response?.data?.nonFieldErrors[0] : '');
      return {
        success: false,
      };
    }
  }

  public async updatePassword(oldPassword: string, newPassword: string) {
    try {
      const response = await this.axios.patch(
        `${ProfileApi.baseUrl}/account/user/change/password`,
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response;
    } catch (error) {
      const errorMessage = apiError(error, '', true) as string;
      error?.response?.data && notification.error(error?.response?.data?.nonFieldErrors?.length ? error?.response?.data?.nonFieldErrors[0] : errorMessage || '');
    }
  }

  public async updateProfile(
    phoneNumber: string,
    fullName: string,
    industry: string,
    logo?: any,
    selectedSignInImage?: any,
    selectedSignUpImage?: any,
    selectedFaviconImage?: any,
    starterPlanPrice?: number | string,
    growthPlanPrice?: number | string,
    proPlanPrice?: number | string,
    showOrdersPage?: boolean,
    showBillingPage?: boolean,
    showPricingPage?: boolean,
    whitelabelOtto?: string,
    shouldSendEmailsToAgencyCustomers?: boolean,
    defaultLocation?: string,
    brandColor?: any,
  ) {
    const formData = new FormData();
    if (logo) formData.append('logo', logo);
    if (selectedSignInImage) formData.append('signin_logo', selectedSignInImage);
    if (selectedSignUpImage) formData.append('signup_logo', selectedSignUpImage);
    if (selectedFaviconImage) formData.append('favicon', selectedFaviconImage);
    if (starterPlanPrice) formData.append('price_starter_plan', `${starterPlanPrice}`);
    if (growthPlanPrice) formData.append('price_growth_plan', `${growthPlanPrice}`);
    if (proPlanPrice) formData.append('price_pro_plan', `${proPlanPrice}`);
    formData.append('should_show_orders_page', JSON.stringify(showOrdersPage));
    formData.append('should_show_billing_page', JSON.stringify(showBillingPage));
    formData.append('should_show_pricing_page', JSON.stringify(showPricingPage));
    if (shouldSendEmailsToAgencyCustomers) formData.append('should_children_receive_emails', JSON.stringify(shouldSendEmailsToAgencyCustomers));
    if (phoneNumber) formData.append('phone_number', `+${phoneNumber}`);
    if (fullName) formData.append('contact_name', fullName);
    if (whitelabelOtto) formData.append('whitelabel_otto', whitelabelOtto);
    if (industry) formData.append('industry', industry);
    if (defaultLocation) formData.append('default_location', defaultLocation);
    if (brandColor) formData.append('brand_color', brandColor);
    try {
      const response = await this.axios.patch(
        `${ProfileApi.baseUrl}/account/user/profile/update`,
        formData,
        {
          headers: {
            'Authorization': getAuthorizationHeader(),
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: this.cancelToken,
        },
      );
      notification.success('Settings Updated Successfully');
      localStorage.setItem('whitelabelOtto', ottoWhitelabelName(whitelabelOtto) );
      return response;
    } catch (error) {
      error?.response?.data && notification.error(error?.response?.data?.nonFieldErrors?.length ? error?.response?.data?.nonFieldErrors[0] : Object.values(error?.response?.data || {}));
    }
  }

  public async forgotPassword(
    email: string,
    captcha: string,
  ): Promise<{success: boolean; response: any}> {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/user/forgot/password/`, {
        email, ...(captcha && {captcha: captcha}),
        cancelToken: this.cancelToken,
      });
      return {success: true, response};
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async updateRegisterEmail(
    oldEmail?: string,
    newEmail?: string,
  ): Promise<{success: boolean; response: any}> {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/update-email-address/`,
        {
          old_email: oldEmail,
          new_email: newEmail,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return {success: true, response};
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      // notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async resendVerificationEmail(
    email?: string,
  ): Promise<{success: boolean; response: any}> {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/resend-verification-code/`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return {success: true, response};
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      // notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async verifyVerificationEmail(
    email?: string,
    code?: string,
  ): Promise<{success: boolean; response: any}> {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/verify-verification-code/`,
        {
          email: email,
          verification_code: code,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return {success: true, response};
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      // notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async deactivateSubscription(userExperience, encounteredIssues, additionFeedback) {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/subscription/deactivate/`,
        {
          user_experience: userExperience,
          encountered_issues: encounteredIssues,
          addition_feedback: additionFeedback,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async getCustomerQuota(customerId: string) {
    try {
      const response = await this.axios.get(`${ProfileApi.baseUrl}/account/extended-details/${customerId}/`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /* POST ONBOARDING API START */
  public async getCompetitorKeywordSuggestions(url: string) {
    try {
      const response = await this.axios.get(`${ProfileApi.keywordBaseUrl}/v1/competitor-keyword-suggestions`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          params: {
            url: url,
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async validatePostOnboardingUrl(url: string) {
    try {
      const response = await this.axios.get(`${ProfileApi.keywordBaseUrl}/v1/validate-url`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          params: {
            url: url,
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async submitPostOnboardingData(domainUrl: string, keywords: string[], competitors: string[], countryCode: string) {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/projects/projects/`,
        {
          'domain_url': domainUrl,
          'keywords': keywords,
          'competitors': competitors,
          'country_code': countryCode,
        },
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async updateProjectCompetitor(projectId: Number, payload) {
    try {
      const response = await this.axios.patch(`${ProfileApi.baseUrl}/projects/projects/${projectId}`, payload, {
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getStatusForHomePage(id?: any) {
    try {
      const response = await this.axios.get(`${ProfileApi.baseUrl}/projects/projects/${id}/status/`,
        {headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getStripeKeys() {
    try {
      const response = await this.axios.get(`${ProfileApi.baseUrl}/white-stripe-credentials/`,
        {headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /* POST ONBOARDING API END */

  public async getInvitations() {
    const response = await this.axios.get(`${ProfileApi.baseUrl}/account/team/invitations/`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getTeamMembers() {
    const response = await this.axios.get(`${ProfileApi.baseUrl}/account/team/team-members/`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getAllTeamMembers() {
    const response = await this.axios.get(`${ProfileApi.baseUrl}/account/team/active-members/`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async removeInvitation() {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/team/remove-invitation/`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async removeTeamInvitation(email: string) {
    try {
      const response = await this.axios.delete(`${ProfileApi.baseUrl}/account/team/active-members/`,
        {
          params: {email},
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async inviteMember(payload: InviteMemberDTO) {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/team/v2/invite-member/`,
        payload,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async addTeamMember(payload: AddMemberDTO) {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/add-team-member/`,
        payload,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async removeMember() {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/team/remove-member/`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async updateMember(id: number, newRole?: string, newStatus?: string) {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/account/team/update-member/`,
        {
          id,
          new_role: newRole,
          new_status: newStatus,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async topUpAiQuota() {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/ai-quota-top-up/`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async verifyCustomerAccount(payload) {
    try {
      const response = await this.axios.post(`${ProfileApi.baseUrl}/verify-customer-user-account-v1/?invitation_code=${payload?.invitation_code}&inviter_email=${encodeURIComponent(payload?.inviter_email)}`);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSsoToken(accessToken: string | null) {
    try {
      const response = await this.axios.post(
        `${ProfileApi.ssoBaseUrl}/v1/authenticate/`,
        {
          access_token: accessToken,
        },
      );
      return response.data;
    } catch (error) {
      error?.response?.data && notification.error(error?.response?.data?.nonFieldErrors?.length ? error?.response?.data?.nonFieldErrors[0] : '');
      return {
        success: false,
      };
    }
  }

  public async getDomainLogos() {
    try {
      const response = await this.axios.get(`${ProfileApi.baseUrl}/account/domain-logo/`);
      return response.data;
    } catch (e) {
      // eslint-disable-next-line
      console.warn('Error loading logos', e);
      return null;
    }
  }
  public async postOnboardingData(id?: any, data?: any) {
    try {
      const response = await this.axios.patch(`${ProfileApi.baseUrl}/customer-onboard/${id}/`, data, {
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadWLChildrenAccessPages() {
    const response = await this.axios.get(`${ProfileApi.baseUrl}/account/agency-settings/`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async updateWLChildrenAccessPages(id: number, data: any) {
    const response = await this.axios.patch(`${ProfileApi.baseUrl}/account/agency-settings/${id}/`, data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getExternalSettings() {
    const response = await this.axios.get(`${ProfileApi.baseUrl}/external-settings/`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getAnnouncements() {
    const response = await this.axios.get(`${ProfileApi.baseUrl}/announcements/`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getOutage() {
    const result = await this.axios.get('https://api.status.searchatlas.com/v1/health');
    return result;
  }
}

export default ProfileApi;
