import {
  Menu,
} from 'antd';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClockRotateLeft} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle,
  faTrashXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
// import {notification} from '@/utils/notifications';
import {notification as notificationV2} from '@/utils/notification-v2';
import {AddPostModal} from '../Modals/addPostModal';
import {AddPageModal} from '../Modals/addPageModal';
import {AddQAModal} from './GBP/addQuestionAndAnswer';
import {GenerateQAModal} from '../Modals/generateQaModal';
import {Modal} from '@/components/common-components/components';
import {WpAddSiteModal} from '../../landing-page-optimizer/page-editor/LeftSide/TopSection/title-editor/OneClickPublisher/WpAddSiteModal';
import EditTargetKeywordsModal from '../Modals/editTargetKeywordsModal';
import {Images} from './TechnicalFixes/Images/Images';
import _ from 'lodash';
import {gbpList, ValidateFunctions} from '../Constants';

import {BackButton, DeleteButton,
  ModalWrapper,
  StatusMenu, Wrapper, Section} from '../style';
import {PageTitle} from './TechnicalFixes/PageTitle/PageTitle';
import {TopicalMapsAndSupplementalContent} from './TopicMap';
import {MetaDescription} from './TechnicalFixes/MetaDescription/MetaDescription';
import {MissingHeadings} from './TechnicalFixes/Headings/MissingHeadings';
import {MetaKeywords} from './TechnicalFixes/MetaKeywords/MetaKeywords';
import {H1Under20Over70} from './TechnicalFixes/Headings/H1Under20Over70';
import {H2Under20Over70} from './TechnicalFixes/Headings/H2Under20Over70';
import {OgDescription} from './TechnicalFixes/OgMeta/OgDescription';
import {OgTitle} from './TechnicalFixes/OgMeta/OgTitle';
import {OgUrl} from './TechnicalFixes/OgMeta/OgUrl';
import {TwitterDescription} from './TechnicalFixes/TwitterMeta/TwitterDescription';
import {TwitterTitle} from './TechnicalFixes/TwitterMeta/TwitterTitle';
import {TwitterSiteProperty} from './TechnicalFixes/TwitterMeta/TwitterSiteProperty';
import {TwitterCard} from './TechnicalFixes/TwitterMeta/TwitterCard';
import {GbpPosts} from './GbpAudit/GbpPosts/GbpPosts';
import {CloudStacks} from './AuthorityBuilding/CloudStacks';
import {Links} from './TechnicalFixes/Links/Links';
import {GbpQAndAnswers} from './GbpAudit/QAndASuggestions/QAndASuggestions';
import {InternalLinkingOpportunities} from './ContentOptimizations/InternalLinks/InternalLinkSuggestions';
import {CustomContent} from './NewContent/CustomContent/CustomContent';
import {NlpTerms} from './ContentOptimizations/SemanticAnalysis/NlpTerms';
import {OrganizationSchema} from './SchemaMarkup/SchemaMarkup/OrganizationSchema';
import {NlpFaq} from './ContentOptimizations/KnowledgeBasedTrust/NlpFaq';
import {MissingKeywords} from './ContentOptimizations/MissingKeywords/MissingKeywords';
import {UnansweredReviews} from './GbpAudit/UnansweredReviews/UnansweredReviews';
import {HtmlLangMissing} from './TechnicalFixes/misc/HtmlLangMissing';
import {CanonicalLink} from './TechnicalFixes/CanonicalLink/CanonicalLink';
import {PressRelease} from './AuthorityBuilding/pressRelease';
import {GenerateGbpPostsModal} from './GbpAudit/GbpPosts/GbpPosts/generateGbpPostsModal';
import {timeNotification} from '@/utils/notification-timer';
import {DynamicIndexing} from './Indexing/dynamicIndexing';
import {CustomUrlBasedIndexing} from './Indexing/gscIndexing/customUrlBasedIndexing';
import {SiteMapIndexing} from './Indexing/gscIndexing/siteMapIndexing';
import {BulkPagesModal} from '../Modals/addBulkPagesModal';
import {StyledSpin} from '../Utils/styledSpin';
import {AggregatorNetwork} from './GbpAudit/AggregatorNetwork/AggregatorNetwork';
import {PageContent} from '../../campaigns/page-content';
import {ManageFoldersModal} from '../../campaigns/manageFoldersModal';
import {CampaignsDetail} from '../../campaigns/detail-page';
import {InboxTable} from '../../campaigns/inbox/sections/inboxTable';
import {EmailConversationModal} from '../../campaigns/emailConversationModal';
import {GbpOptimization} from './GbpAudit/GbpOptimization';
import {ListingSection} from '../../campaigns/templates-page/sections/listingSection';
import {CreateTemplateModal} from '../../campaigns/createTemplateModal';
import {LandingPageGenerator} from './GbpAudit/LandingPageGenerator';
import {SiteMapIndexationModal} from '../Modals/siteMapIndexationModal';
import {CreateTopicalMapModal} from '../../topical-maps-page/sections/createTopicalMapModal';
import {MyTasks} from './GbpAudit/MyTasks';
import AutomateQandASettingsModal from './automateQandASettingsModal/automateQandASettingsModal';
import {WILDFIREComponent} from './AuthorityBuilding/WILDFIRE';

export const getDeployStatus = key => {
  switch (key) {
    case 'deployed':
      return true;
    default:
      return false;
  }
};

export const getDeployStatusForGbp = (key, issueType) => {
  if (['gbp_posts', 'qanda_suggestions'].includes(issueType)) {
    switch (key) {
      case 'deployed':
        return 'Published';
      default:
        return 'Pending Review, Scheduled, Publishing, Rejected, Unpublished';
    }
  } else if (issueType === 'unanswered_reviews') {
    switch (key) {
      case 'deployed':
        return 'true';
      default:
        return 'false';
    }
  }
};

interface TableIssuesCollapseProps {
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
  openGenerateGbpPostsModal: boolean;
  setOpenGenerateGbpPostsModal: (value: boolean) => void;
  showGenerateBulkHeader?: boolean;
  setShowGenerateBulkHeader?: Dispatch<SetStateAction<boolean>>;
}

export const TableIssuesCollapse: React.FC<TableIssuesCollapseProps> = observer(({
  campaignsId,
  setCampaignsId,
  openGenerateGbpPostsModal,
  setOpenGenerateGbpPostsModal,
  showGenerateBulkHeader,
  setShowGenerateBulkHeader,
}) => {
  const {ottoV2Store: {
    getOttoUrls,
    loadingDetail,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    selectedIssue,
    selectedCategory,
    getOttoV2ProjectsList,
    setDefaultParams,
    loadOttoV2Project,
    setIsDeploying,
    deployImageAltText,
    setCloudStackProjectShouldRepoll,
    setIssueTypeSelected,
    addSourcePage,
    setTopicalMapsShouldRepoll,
    deleteUrlIssue,
    deleteQASuggestion,
    deleteGbpPost,
    searchText,
    deleteLandingPage,
    openGenerateQAModal,
    setOpenGenerateQAModal,
    setSelectedReviewFilters,
    deleteIndexingSitemap,
    deleteCustomUrl,
  },
  } = useStore('');
  const [editDescription, setEditDescription] = useState<any>(-1);
  const uuid = getSingleUrlParam('uuid');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [issueTable, setIssueTable] = useState('');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [proposedFix, setProposedFix] = useState('');
  const [openAddPostModal, setOpenAddPostModal] = useState(false);
  const [openAddPageModal, setOpenAddPageModal] = useState(false);
  const [openBulkPagesModal, setOpenBulkPagesModal] = useState(false);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [addRecord, setAddRecord] = useState(false);
  // eslint-disable-next-line
  const [pageChanged, setPageChanged] = useState(false);
  const [ottoProject, setOttoProject] = useState(-1);

  const [editingPost, setEditingPost] = useState(false);
  const [recordEditing, setRecordEditing] = useState<Record<string, any>>();
  const [openQAModal, setOpenQAModal] = useState(false);
  const [openCreateTopicalMap, setOpenCreateTopicalMap] = useState(false);
  const [openCreateNewTopicalMapModal, setOpenCreateNewTopicalMapModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>(-1);
  const [deleteIssueIds, setDeleteIssueIds] = useState<any>({parent: null, child: null});
  const [currentIssueType, setCurrentIssueType] = useState('');
  const [addPageLoader, setAddPageloader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [currentRecordSubItem, setCurrentRecordSubItem] = useState<any>({});
  const [addBulkPagesFrom, setAddBulkPagesFrom] = useState('');
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [openSiteMapIndexationModal, setOpenSiteMapIndexationModal] = useState(false);
  const [modalConf, setModalConf] = useState({
    visible: false,
    keywords: [],
    path: '',
    id: null,
    issueType: '',
  });
  const [openAutomateSettingsModal, setOpenAutomateSettingsModal] = useState(false);

  useEffect(()=> {
    if (selectedCategory !== 'unanswered_reviews') {
      setSelectedReviewFilters({
        star_rating__in: null,
        is_replied: null,
        reply__status__in: null,
      });
    }
  }, [selectedCategory]);

  const copyIcons = document?.getElementsByClassName('copy-to-clipboard-container');
  _.map(copyIcons, function(e) {
    const data = e as unknown as HTMLElement;
    // eslint-disable-next-line
    data.addEventListener('click', function(){
      data.setAttribute('title', 'Copied to clipboard');
      setTimeout(function() {
        data.setAttribute('title', 'Copy to clipboard');
      }, 5000);
    });
  });

  useEffect(() => {
    setCloudStackProjectShouldRepoll(true);
    setTopicalMapsShouldRepoll(true);
    if (!getOttoV2ProjectsList?.results?.length) {
      setDefaultParams({search: '', pageSize: 20, page: 1});
    }
    return () => {
      setCloudStackProjectShouldRepoll(false);
      setTopicalMapsShouldRepoll(false);
    };
  }, []);

  useEffect(() => {
    if (selectedCategory === 'unanswered_reviews') {
      setSelectedRatings([]);
    }
  }, [selectedIssue, selectedCategory]);


  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
      setIssueTable('');
    }
  }, [getOttoUrls]);


  const onDeleteQASuggestion = async record => {
    try {
      setDeleteItem(record.id);
      const onDelayEnd = async () => {
        await deleteQASuggestion(record?.id);
        setDeleteItem(-1);
        const filter = ottoUrls.filter(d => d.issueType === 'qanda_suggestions');
        loadIssueTableData({
          uuid: getOttoV2Project?.uuid,
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          issue_type: 'qanda_suggestions',
          otto_project: getOttoV2Project?.id,
          search: searchText,
        });
      };
      const handleUndo = () => {
        setDeleteItem(-1);
      };
      timeNotification('#FF0000', faTrashXmark, 4, `Item deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
      setDeleteItem(-1);
    } catch (error) {
      setDeleteItem(-1);
      return Promise.reject(error);
    }
  };

  const deleteIssue = async (id, itemIndex, issueType) => {
    if (deleteItem === -1) {
      setDeleteItem(`${itemIndex}${id}`);
      setDeleteIssueIds({parent: id, child: itemIndex});
      try {
        const onDelayEnd = async () => {
          setEditDescription(-1);
          setProposedFix('');
          const payload = {
            'uuid': uuid,
            'issue_type': issueType,
            'item_index': itemIndex,
            'to_delete': true,
          };
          await deleteUrlIssue(id, uuid, payload);
          // notification.success(`${issueType == 'missing_keywords' ? 'Keyword' : issueType == 'internal_link_suggestions' ? 'Source Page' : 'Issue'} deleted successfully`, '', 2);
          setPageChanged(true);
          setIssueTypeSelected(issueType);
          setIsDeploying(true);
          setDeleteItem(-1);
          if (uuid) {
            loadOttoV2Project(uuid, true);
          }
        };
        const handleUndo = () => {
          setDeleteItem(-1);
          setDeleteIssueIds({parent: null, child: null});
        };
        timeNotification('#FF0000', faTrashXmark, 4, `${issueType == 'missing_keywords' ? 'Keyword' : issueType == 'internal_link_suggestions' ? 'Source Page' : 'Issue'} deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
        setDeleteItem(-1);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error: ', error);
        setDeleteItem(-1);
      }
    }
  };

  const onDeleteItem = async (id, issueType) => {
    if (deleteItem === -1) {
      setDeleteItem(id);
      try {
        const onDelayEnd = async () => {
          if (issueType === 'images' || issueType === 'dynamic_indexing') {
            await deployImageAltText({id, issueType, proposedFix: '', uuid, toDelete: true}, false);
            const filter = ottoUrls.filter(d => d.issueType === issueType);
            setPageChanged(true);
            setIssueTypeSelected(issueType);
            setIsDeploying(true);
            await loadIssueTableData({
              uuid: getOttoV2Project?.uuid,
              page_size: filter?.length ? filter[0].pageSize : 10,
              page: filter?.length ? filter[0].page : 1,
              issue_type: issueType,
              otto_project: getOttoV2Project?.id,
              search: searchText,
            }, true);
          } else if (issueType === 'sitemap_indexing' || issueType === 'custom_url_based_indexing') {
            if (issueType === 'sitemap_indexing') {
              await deleteIndexingSitemap([id]);
            } else {
              await deleteCustomUrl(Number(id));
            }
            const filter = ottoUrls.filter(d => d.issueType === issueType);
            setPageChanged(true);
            setIssueTypeSelected(issueType);
            setIsDeploying(true);
            await loadIssueTableData({
              uuid: getOttoV2Project?.uuid,
              page_size: filter?.length ? filter[0].pageSize : 10,
              page: filter?.length ? filter[0].page : 1,
              issue_type: issueType,
              otto_project: getOttoV2Project?.id,
              search: searchText,
            }, true);
          }
          setDeleteItem(-1);
          if (uuid) {
            loadOttoV2Project(uuid, true);
          }
        };

        const handleUndo = () => {
          setDeleteItem(-1);
        };
        timeNotification('#FF0000', faTrashXmark, 4, `${issueType === 'images' ? 'Missing Image Alt Text' : 'Record'} deleted successfully.`, ()=> handleUndo(), '#FF0000', onDelayEnd);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error: ', error);
        setDeleteItem(-1);
      }
    }
  };

  const onAddSourcePage = async (record, addingTargetPage = false) => {
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
    const payload = {
      issue_type: record?.issueType,
      otto_url_id: record?.id,
      object_value: editDescription == -1 ? proposedFix : `https://${domainName}/${proposedFix}${proposedFix.endsWith('/') ? '' : '/'}`,
      object_key: `https://${domainName}${domainName.endsWith('/') ? '' : '/'}`,
    };
    try {
      await addSourcePage(uuid, editDescription == -1 ? record : payload);
      notificationV2.success('Success', `${addingTargetPage ? 'Target' : 'Source'} Page added successfully`);
      await loadIssueTableData({
        uuid: getOttoV2Project?.uuid,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        issue_type: ottoUrls[0]?.issueType,
        otto_project: getOttoV2Project?.id,
      });
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      setOpenAddPageModal(false);
    } catch (error) {
      setEditDescription(-1);
    } finally {
      setAddPageloader(true);
      setEditDescription(-1);
      setProposedFix('');
    }
  };

  const showConfirmationPopup = (record, subItem={}) => {
    setCurrentRecord(record);
    record?.values && setCurrentRecordSubItem(subItem);
    setOpenModal(true);
  };


  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];

  const onDelete = async () => {
    try {
      if (currentRecord?.issueType == 'qanda_suggestions') {
        await onDeleteQASuggestion(currentRecord);
        // notificationV2.success('Success', 'Record deleted successfully.', 'Close');
      } else if (['nlp_terms', 'nlp_faq'].includes(currentRecord?.issueType)) {
        await deleteIssue(currentRecord?.id, currentRecord?.itemIndex, currentRecord?.issueType);
      } else if (currentRecord?.issueType === 'ai_landing_page_builder') {
        setDeleteItem(currentRecord?.uuid);
        const onDelayEnd = async () => {
          await deleteLandingPage(currentRecord?.uuid);
          const params = {
            uuid,
            issue_type: 'ai_landing_page_builder',
            page_size: 10,
            page: 1,
            is_loading: false,
          };
          await loadIssueTableData(params, false);
          setDeleteItem(-1);
        };
        const handleUndo = () => {
          setDeleteItem(-1);
        };
        timeNotification('#FF0000', faTrashXmark, 4, `Deleted successfully.`, ()=>handleUndo(), '#FF0000', onDelayEnd);
      } else if (currentRecord?.values) {
        deleteIssue(currentRecord?.id, currentRecordSubItem?.itemIndex, currentRecord?.issueType);
      } else if (currentRecord?.issueType === 'gbp_posts') {
        setDeleteItem(currentRecord?.id);
        const onDelayEnd = async () => {
          const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
          await deleteGbpPost(currentRecord?.id);
          const params = {
            uuid,
            issue_type: 'gbp_posts',
            page_size: filter?.length ? filter[0].pageSize : 10,
            page: filter?.length ? filter[0].page : 1,
            is_loading: false,
          };
          await loadIssueTableData(params, false);
          setDeleteItem(-1);
        };
        const handleUndo = () => {
          setDeleteItem(-1);
        };
        timeNotification('#FF0000', faTrashXmark, 4, `Deleted successfully.`, ()=>handleUndo(), '#FF0000', onDelayEnd);
      } else if (currentRecord?.issueType === 'sitemap_indexing' || currentRecord?.issueType === 'custom_url_based_indexing') {
        onDeleteItem(currentRecord?.id, currentRecord?.issueType);
      } else {
        onDeleteItem(currentRecord?.itemIndex, currentRecord?.issueType);
      }
      setOpenModal(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setDeleteItem(-1);
      console.error('Error: ', error);
    }
  };

  const ottoTableMapping = {
    'my_tasks': <MyTasks/>,
    'profile_optimizations': <GbpOptimization/>,
    'unanswered_reviews': <UnansweredReviews
      setPageChanged={setPageChanged}
      setIssueTable={setIssueTable}
      selectedRatings={selectedRatings}
      componentIssueType={'unanswered_reviews'}
      issueTable={issueTable}
    />,
    'topical_maps_and_supplemental_content': <TopicalMapsAndSupplementalContent
      openCreateNewTopicalMapModal={openCreateNewTopicalMapModal}
      setOpenCreateNewTopicalMapModal={setOpenCreateNewTopicalMapModal}
      setOpenCreateTopicalMap={setOpenCreateTopicalMap}
      componentIssueType={'topical_maps_and_supplemental_content'}
      issueTable={issueTable}
      setIssueTable={setIssueTable}
      setPageChanged={setPageChanged}
      showGenerateBulkHeader={showGenerateBulkHeader}
      setShowGenerateBulkHeader={setShowGenerateBulkHeader}
    />,
    'images': <Images
      setIssueTable={setIssueTable}
      setPageChanged={setPageChanged}
      deleteItem={deleteItem}
      showConfirmationPopup={showConfirmationPopup}
      componentIssueType={'images'}
      issueTable={issueTable}
    />,
    'internal_link_suggestions': <InternalLinkingOpportunities
      setIssueTable={setIssueTable}
      setPageChanged={setPageChanged}
      deleteItem={deleteItem}
      showConfirmationPopup={showConfirmationPopup}
      componentIssueType={'internal_link_suggestions'}
      setAddPageloader={setAddPageloader}
      setOttoProject={setOttoProject}
      setOpenAddPageModal={setOpenAddPageModal}
      setCurrentIssueType={setCurrentIssueType}
      issueTable={issueTable}
      deleteIssueIds={deleteIssueIds}
    />,
    'page_title': <PageTitle
      setPageChanged={setPageChanged}
      componentIssueType={'page_title'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'meta_description': <MetaDescription
      setPageChanged={setPageChanged}
      componentIssueType={'meta_description'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'aggregator_network': <AggregatorNetwork
      componentIssueType={'aggregator_network'}
    />,
    'meta_keywords': <MetaKeywords
      setPageChanged={setPageChanged}
      componentIssueType={'meta_keywords'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'missing_headings': <MissingHeadings
      setPageChanged={setPageChanged}
      componentIssueType={'missing_headings'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'h1_under_20_over_70': <H1Under20Over70
      setPageChanged={setPageChanged}
      componentIssueType={'h1_under_20_over_70'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'h2_under_20_over_70': <H2Under20Over70
      setPageChanged={setPageChanged}
      componentIssueType={'h2_under_20_over_70'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'og_description': <OgDescription
      setPageChanged={setPageChanged}
      componentIssueType={'og_description'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'og_title': <OgTitle
      setPageChanged={setPageChanged}
      componentIssueType={'og_title'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'og_url': <OgUrl
      setPageChanged={setPageChanged}
      componentIssueType={'og_url'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'twitter_description': <TwitterDescription
      setPageChanged={setPageChanged}
      componentIssueType={'twitter_description'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'twitter_title': <TwitterTitle
      setPageChanged={setPageChanged}
      componentIssueType={'twitter_title'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'twitter_site_property': <TwitterSiteProperty
      setPageChanged={setPageChanged}
      componentIssueType={'twitter_site_property'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'twitter_card': <TwitterCard
      setPageChanged={setPageChanged}
      componentIssueType={'twitter_card'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'gbp_posts': <GbpPosts
      setPageChanged={setPageChanged}
      componentIssueType={'gbp_posts'}
      setIssueTable={setIssueTable}
      showConfirmationPopup={showConfirmationPopup}
      setRecordEditing={setRecordEditing}
      setEditingPost={setEditingPost}
      deleteItem={deleteItem}
      setOpenGenerateGbpPostsModal={setOpenGenerateGbpPostsModal}
      issueTable={issueTable}
    />,
    'qanda_suggestions': <GbpQAndAnswers
      setPageChanged={setPageChanged}
      componentIssueType={'qanda_suggestions'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
      setOpenQAModal={setOpenQAModal}
      setOpenGenerateQAModal={setOpenGenerateQAModal}
      setOpenAutomateSettingsModal={setOpenAutomateSettingsModal}
    />,

    'cloud_stacks': <CloudStacks
      setPageChanged={setPageChanged}
      componentIssueType={'cloud_stacks'}
      setIssueTable={setIssueTable}
      setAddRecord={setAddRecord}
      addRecord={addRecord}
      issueTable={issueTable}
    />,
    'wildfire': <WILDFIREComponent/>,
    'links': <Links
      setPageChanged={setPageChanged}
      componentIssueType={'links'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'nlp_terms': <NlpTerms
      setPageChanged={setPageChanged}
      componentIssueType={'nlp_terms'}
      setIssueTable={setIssueTable}
      setOttoProject={setOttoProject}
      issueTable={issueTable}
      setCurrentIssueType={setCurrentIssueType}
      setOpenBulkPagesModal={setOpenBulkPagesModal}
      setAddBulkPagesFrom={setAddBulkPagesFrom}
    />,
    'custom_content': <CustomContent
      setPageChanged={setPageChanged}
      componentIssueType={'custom_content'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'organization_schema': <OrganizationSchema
      setPageChanged={setPageChanged}
      componentIssueType={'organization_schema'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'missing_keywords': <MissingKeywords
      setPageChanged={setPageChanged}
      componentIssueType={'missing_keywords'}
      setIssueTable={setIssueTable}
      showConfirmationPopup={showConfirmationPopup}
      deleteItem={deleteItem}
      issueTable={issueTable}
      deleteIssueIds={deleteIssueIds}
      setDeleteIssueIds={setDeleteIssueIds}
    />,
    'nlp_faq': <NlpFaq
      setPageChanged={setPageChanged}
      componentIssueType={'nlp_faq'}
      setIssueTable={setIssueTable}
      setOttoProject={setOttoProject}
      issueTable={issueTable}
      setCurrentIssueType={setCurrentIssueType}
      setOpenBulkPagesModal={setOpenBulkPagesModal}
      setAddBulkPagesFrom={setAddBulkPagesFrom}
    />,
    'html_lang_missing': <HtmlLangMissing
      setPageChanged={setPageChanged}
      componentIssueType={'html_lang_missing'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'canonical_link': <CanonicalLink
      setPageChanged={setPageChanged}
      componentIssueType={'canonical_link'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'press_release_distribution': <PressRelease
      setPageChanged={setPageChanged}
      componentIssueType={'press_release_distribution'}
      setIssueTable={setIssueTable}
      issueTable={issueTable}
    />,
    'dynamic_indexing': <DynamicIndexing
      setPageChanged={setPageChanged}
      componentIssueType={'dynamic_indexing'}
      setIssueTable={setIssueTable}
      setAddRecord={setAddRecord}
      showConfirmationPopup={showConfirmationPopup}
      addRecord={addRecord}
      issueTable={issueTable}
      deleteItem={deleteItem}
    />,
    'sitemap_indexing': <SiteMapIndexing
      setPageChanged={setPageChanged}
      componentIssueType={'sitemap_indexing'}
      setIssueTable={setIssueTable}
      showConfirmationPopup={showConfirmationPopup}
      issueTable={issueTable}
      setOpenSiteMapIndexationModal={setOpenSiteMapIndexationModal}
      setSitemapUrl={setSitemapUrl}
      deleteItem={deleteItem}
    />,
    'custom_url_based_indexing': <CustomUrlBasedIndexing
      setPageChanged={setPageChanged}
      componentIssueType={'custom_url_based_indexing'}
      setIssueTable={setIssueTable}
      showConfirmationPopup={showConfirmationPopup}
      issueTable={issueTable}
      deleteItem={deleteItem}
    />,
    'campaigns': <>
      {
        campaignsId ? <CampaignsDetail isInOTTO campaignsId={campaignsId} setCampaignsId={setCampaignsId}
        />:
          <PageContent componentIssueType='campaigns' isInOTTO campaignsId={campaignsId} setCampaignsId={setCampaignsId} tableHeaderStyles={{flexWrap: 'wrap'}} />
      }
    </>,
    'inbox': <InboxTable isInOTTO/>,
    'templates': <ListingSection isInOTTO/>,
    'ai_landing_page_builder': <LandingPageGenerator
      setPageChanged={setPageChanged}
      componentIssueType={'ai_landing_page_builder'}
      setIssueTable={setIssueTable}
      setAddRecord={setAddRecord}
      showConfirmationPopup={showConfirmationPopup}
      addRecord={addRecord}
      issueTable={issueTable}
      deleteItem={deleteItem}
    />,
  };

  return (
    <>
      <>
        {
          selectedIssue == 'digital_outreach' ? <div>
            {ottoTableMapping[selectedCategory]}
          </div> :
            (filteredOttoUrls?.length || !loadingDetail ) ? filteredOttoUrls?.length ? filteredOttoUrls?.map((url, index) => {
              return (
                <Section addCssProperties={url?.issueType === 'sitemap_indexing' || url?.issueType === 'custom_url_based_indexing'} key={index}>
                  {ottoTableMapping[url?.issueType]}
                </Section>
              );
            }) : selectedCategory=='profile_optimizations' ? <GbpOptimization/> : selectedCategory == 'my_tasks' ? <MyTasks/> : selectedCategory == 'wildfire' ? <WILDFIREComponent /> : '' :
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '385px'}}>
                <StyledSpin fontSize={100} color='#2D6CCA'/>
              </div>
        }
        {
          (loadingDetail && filteredOttoUrls?.length && selectedCategory!='profile_optimizations') ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px', margin: '30px'}}>
              <StyledSpin fontSize={40} color='#2D6CCA'/>
            </div> : <></>
        }
      </>
      {
        openAddPostModal && <AddPostModal
          openAddPostModal={openAddPostModal}
          setOpenAddPostModal={setOpenAddPostModal}
          editing={editingPost}
          setEditing={setEditingPost}
          data={recordEditing}
        />
      }
      {
        openGenerateGbpPostsModal && <GenerateGbpPostsModal
          openGenerateGbpPostsModal={openGenerateGbpPostsModal}
          setOpenGenerateGbpPostsModal={setOpenGenerateGbpPostsModal}
          editing={editingPost}
          setEditing={setEditingPost}
          showConfirmationPopup={showConfirmationPopup}
          deleteItem={deleteItem}
        />
      }
      {
        openAddPageModal && <AddPageModal
          openAddPageModal={openAddPageModal}
          setOpenAddPageModal={setOpenAddPageModal}
          ottoProject={ottoProject}
          setOttoProject={setOttoProject}
          currentIssueType={currentIssueType}
          setCurrentIssueType={setCurrentIssueType}
          onAddSourcePage={onAddSourcePage}
          addPageLoader={addPageLoader}
          setAddPageloader={setAddPageloader}
        />
      }
      {
        openBulkPagesModal && <BulkPagesModal
          openBulkPagesModal={openBulkPagesModal}
          setOpenBulkPagesModal={setOpenBulkPagesModal}
          addBulkPagesFrom={addBulkPagesFrom}
          currentIssueType={currentIssueType}
        />
      }
      {
        openQAModal && <AddQAModal
          openModal={openQAModal}
          setOpenModal={setOpenQAModal}
        />
      }
      {
        openCreateTopicalMap && <CreateTopicalMapModal openModal={openCreateTopicalMap} setOpenModal={setOpenCreateTopicalMap} isUsingInOttoTopicMaps/>
      }
      {
        openGenerateQAModal && <GenerateQAModal openGenerateQAModal={openGenerateQAModal} setOpenGenerateQAModal={setOpenGenerateQAModal}/>
      }
      {openSiteMapIndexationModal && <SiteMapIndexationModal openSiteMapIndexationModal ={openSiteMapIndexationModal} setOpenSiteMapIndexationModal={setOpenSiteMapIndexationModal} sitemapUrl={sitemapUrl}/>}
      {openModal && <Wrapper>
        <Modal
          onClose={() => {
            setOpenModal(false); setDeleteItem(-1);
          }}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this record? </p>
            <p className={'warningText'}>This action cannot be undone.</p>

            <DeleteButton
              buttonType='transparent' size='lg'
              loading={deleteItem !== -1}
              onClick={onDelete}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => {
              setOpenModal(false); setDeleteItem(-1);
            }} buttonType='transparent' size='lg'>
            Go Back
            </BackButton>

          </ModalWrapper>
        </Modal>
      </Wrapper>}
      <WpAddSiteModal />
      <AutomateQandASettingsModal
        visible={openAutomateSettingsModal}
        setVisible={setOpenAutomateSettingsModal}
      />
      <EditTargetKeywordsModal modalConf={modalConf} setModalConf={setModalConf} uuid={uuid} />
      <ManageFoldersModal isInOTTO/>
      {selectedIssue == 'digital_outreach' && <EmailConversationModal />}
      <CreateTemplateModal/>
    </>
  );
});

export const StyledMenu = ({selectedIssueType, deployOttoSection, currentProject, disabled = false}) => (<StatusMenu>
  <Menu.Item disabled={gbpList.includes(selectedIssueType) || disabled} onClick={()=>deployOttoSection(true)}>
    <FontAwesomeIcon icon={faCheckCircle} fontSize={16} color={disabled ? 'lightgrey' : '#4E5156'}/>&nbsp;
    <span>{currentProject?.pixelTagState == 'installed' && currentProject.isEngaged ? 'Deploy' : 'Enable'} all in this section</span>
  </Menu.Item>
  <Menu.Item disabled={gbpList.includes(selectedIssueType) || disabled} onClick={()=>deployOttoSection(false)}>
    <FontAwesomeIcon icon={faClockRotateLeft} fontSize={16} color={disabled ? 'lightgrey' : '#4E5156'}/>&nbsp;
    <span>Roll back all deploys in this section</span>
  </Menu.Item>
</StatusMenu>
);

export const canDeploy = (record, issueType) => {
  const validate = ValidateFunctions[issueType];
  return typeof validate === 'function' ? validate(record) : true;
};

export const getFilteredDataForOtto = (data, issueType, addRecord) => {
  let updatedData = data?.map(item => ({
    ...item,
    issueType,
    // status: 'Published' // quick update
  }));
  if (addRecord) {
    updatedData = [{
      url: '',
      targetKeyword: '',
      cloudsStacksContent: [],
      generatingCloudsStacksContent: false,
      cloudsSites: 0,
      indexation: 0,
      status: '',
      isFirstObject: true,
    },
    ...updatedData,
    ];
  }
  return updatedData;
};
