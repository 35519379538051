import React, {useState} from 'react';
import {BoldTitle, DeepFreezeSiteModal, PointLabel, PriceSection, SubPoints, Footer, DeepFreezeButton} from './styleComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';
import {faCheck, faClockRotateLeft, faClockSeven, faCloudArrowUp, faHandshakeSlash} from '@fortawesome/pro-regular-svg-icons';
import {faPause, faPenSlash, faPlayPause} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {getSingleUrlParam} from '@/utils/url';
import {Tooltip} from 'antd';
import {trialExpiresAtDateCheck} from '@/utils/moment';

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const DeepFreezeModal = observer(({visible, setVisible}: Props) => {
  const onClose = () => {
    setVisible(false);
  };
  const [loading, setLoading] = useState(false);
  const {ottoV2Store: {
    getOttoV2Project,
    freezeOttoProject,
    getBlockedQuotas,
  },
  settings: {customer: {profile: {whitelabelOtto, trialExpiresAt}}},
  } = useStore('');

  const points = [
    {
      label: 'When should I Deep Freeze my site?',
      subPoints: [
        {
          icon: faHandshakeSlash,
          label: 'Client Relationship Ended - Preserve all progress when discontinuing work with a client.',
        },
        {
          icon: faPause,
          label: 'Client Put Project on Hold - Securely maintain the current state while awaiting client’s return.',
        },
        {
          icon: faCheck,
          label: 'Completed Site Optimization - Save the optimized state and reduce costs by freezing the site.',
        },
      ],
    },
    {
      label: 'What are the advantages of Freezing vs keeping the active subscription?',
      subPoints: [
        {
          icon: faClockSeven,
          label: `Preserve 98 days and 38 hours of ${whitelabelOtto}’s work.`,
        },
        {
          icon: faCloudArrowUp,
          label: `Retain all ${whitelabelOtto} modifications and enhancements.`,
        },
        {
          icon: faPenSlash,
          label: 'Dynamic SEO and AI content generation will be disabled.',
        },
        {
          icon: faClockRotateLeft,
          label: 'Deployment and rollback are restricted while frozen.',
        },
        {
          icon: faPlayPause,
          label: `By deep freezing this project, its quota will remain blocked for 14 days after freezing. You won't be able to use this quota for new projects during this period.`,
        },
      ],
    },
  ];

  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');

  const freezeProject = async () => {
    setLoading(true);
    await freezeOttoProject(getOttoV2Project?.uuid);
    getBlockedQuotas();
    onClose();
    setLoading(false);
  };

  return (
    <DeepFreezeSiteModal
      visible={visible}
      onCancel={onClose}
      footer={false}
      closable={false}
      width={700}
    >
      <div style={{display: 'flex', alignItems: 'center'}}>
        <FontAwesomeIcon icon={faBoxArchive} color='#2D6CCA' fontSize={34} />
      </div>
      <BoldTitle>Proceed with Freezing {domainName}?</BoldTitle>
      <PriceSection>
        <div className='price'>$0</div>
        {/* <div>
          <div className='currency'>USD</div>
          <div className='desc'>
            <div>/ one time hosting fee</div>
            <div className='discount'><FontAwesomeIcon icon={faPercent} /> Save $1,188 per year</div>
          </div>
        </div> */}
      </PriceSection>

      {points.map((item, index) => (
        <div key={index}>
          <PointLabel>{item.label}</PointLabel>
          {item.subPoints.map((subPoint, idx) => (
            <SubPoints key={idx}>
              <div className='icon'>
                <FontAwesomeIcon icon={subPoint.icon} color='#4E5156' />
              </div>
              <div className='label'>
                {subPoint.label}
              </div>
            </SubPoints>
          ))}
        </div>
      ))}
      <Footer>
        <Tooltip title={trialExpiresAtDateCheck(trialExpiresAt) ? '' : 'Only available to paid plans'}>
          <DeepFreezeButton loading={loading} onClick={freezeProject} disabled={!trialExpiresAtDateCheck(trialExpiresAt)}>Deep Freeze for FREE!</DeepFreezeButton>
        </Tooltip>
        <button onClick={onClose} className='cancel-button'>Cancel</button>
      </Footer>
    </DeepFreezeSiteModal>
  );
});

export default DeepFreezeModal;
