import {observer} from 'mobx-react';
import {
  BodyWrapper,
  ButtonStyled,
  Description,
  EmailPresetFieldsWrapper,
  FooterWrapper,
  Heading,
  MenuStyled,
  SelectStyled,
  SenderWrappper,
  StyledInput,
  // MultipleSelectStyled,
  SubjectWrapper} from '../styledComponents';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {faEye, faPaperPlane} from '@fortawesome/pro-regular-svg-icons';
import {Divider, Dropdown, Menu, Popover, Select} from 'antd';
import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import EditorComponentV2 from '@/components/common-components/components/outreach/editor/templateEditor';

interface Props {
  rowSelect?: any;
  setVisible?: any;
  showEmail?: any;
  visibleBulk?: boolean;
  selectedOpportunities?: any;
  sendSingleMail?: boolean;
  editorStateV2?: string;
  setEditorStateV2?: any;
  setSelectedOpportunity?: any;
  setSelectedOpportunityForEmail?: any;
  setDataMail: any;
  data: any;
  campaignsId?: any;
  isScheduled?: string;
}

export const EmailSendModal = observer(({setVisible, showEmail, visibleBulk, selectedOpportunities, sendSingleMail, editorStateV2, setEditorStateV2, setSelectedOpportunity, setSelectedOpportunityForEmail, setDataMail, data, campaignsId, isScheduled}: Props) => {
  const {Option}= Select;
  const {campaigns: {
    existingTemplates,
    sendEmailContact,
    getExistingTemplates,
    campaignsCustomerEmails,
    // getOpportunityContact,
    getCampaignsOpportunity,
    getCampaignsContact,
    chartData,
    sendEmailInBulk}, settings: {customer: {getCustomerQuota}}} = useStore('');
  const {inbox: {
    contactId,
    sendSingleOutreachEmail, opportunityId, getUnreadEmailCount}} = useStore('');

  const router = useRouter();
  const [showError, setShowError] = useState(false);

  const onEditorChange = value => {
    setEditorStateV2(value);
  };

  useEffect(()=>{
    getExistingTemplates();
  }, []);

  useEffect(()=>{
    setDataMail({...data, body: editorStateV2, linked_email_id: isSenderInCampaignEmails ? chartData?.linkedEmailId : campaignsCustomerEmails?.results?.length ? campaignsCustomerEmails?.results[0]?.id : null});
  }, [editorStateV2]);

  const onChange = value => {
    setDataMail({...data, linked_email_id: value});
  };

  const handleUseTemplateBtnClick = (body, subject) => {
    setDataMail({...data, body: body, subject: subject});
    setEditorStateV2(body);
  };

  const id = router?.query?.id ? router?.query?.id?.[0] : campaignsId ?? null;
  const sendEmail = async () => {
    if (!data?.linked_email_id || !data?.subject) {
      setShowError(true);
      return;
    }
    if (visibleBulk) {
      await sendEmailInBulk({...data, opportunities: selectedOpportunities}, router.query?.id?.[0] || id);
    } else {
      if (sendSingleMail) {
        const payload = {
          linked_email_id: data?.linked_email_id,
          subject: data?.subject,
          body: data?.body,
        };
        await sendSingleOutreachEmail(contactId, opportunityId, payload);
      } else {
        await sendEmailContact(data, opportunityId, router.query?.id[0]);
      }
      showEmail(false);
    }
    await getUnreadEmailCount();
    if (opportunityId) await getCampaignsContact(opportunityId);
    if (id) await getCampaignsOpportunity(id, isScheduled);
    await getCustomerQuota();
    setDataMail({linked_email_id: null, subject: '', body: ''});
    setEditorStateV2('');
    if (visibleBulk) {
      setVisible(false);
      setSelectedOpportunity([]);
      setSelectedOpportunityForEmail([]);
    }
  };
  const isSenderInCampaignEmails = chartData?.linkedEmailId && campaignsCustomerEmails?.results?.length && campaignsCustomerEmails?.results.find(email => email.id === chartData?.linkedEmailId);

  return (
    <div style={{paddingBottom: '20px'}}>
      <Heading>{visibleBulk ? 'Contact opportunities in bulk' : 'Compose the email'}<span></span></Heading>
      {visibleBulk && <Description>{selectedOpportunities?.length} opportunities selected to get contacted in bulk</Description>}
      <EmailPresetFieldsWrapper>
        <SenderWrappper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '35px', marginBottom: showError && !data?.linked_email_id ? '25px' : ''}}>Sender</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <SelectStyled
              isError={showError && !data?.linked_email_id}
              value={isSenderInCampaignEmails ? chartData?.linkedEmailId : campaignsCustomerEmails?.results?.length ? campaignsCustomerEmails?.results[0]?.id : 'Select Sender'}
              placeholder='Select a sender'
              suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000'/>}
              onChange={onChange}>
              {campaignsCustomerEmails?.results?.map((item, idx) => <Option key={idx} value={item?.id}>
                {item?.emailAddress}
              </Option>)}
            </SelectStyled>
            {showError && !data?.linked_email_id && <div style={{color: 'red'}}>Please select an email</div>}
          </div>
        </SenderWrappper>
        {/* <SenderWrappper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '61px'}}>To</div>
          <MultipleSelectStyled
            maxTagCount='responsive'
            mode='multiple'
            placeholder='Select send mail to...'
            suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000'/>}
            onChange={() => {}}>
            {getOpportunityContact && getOpportunityContact?.map((item, idx) => <Option key={idx} value={item?.id}>
              {item?.emailAddress}
            </Option>)}
          </MultipleSelectStyled>
        </SenderWrappper> */}
        {/* {false ? <> */}
        <SubjectWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '31px', marginBottom: showError && !data?.subject ? '25px' : ''}}>Subject</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <StyledInput
              value={data?.subject}
              placeholder='Email subject'
              width='100%'
              height='38px'
              isError={showError && !data?.subject}
              borderRadius='8px'
              onChange={e => setDataMail({...data, subject: e.target?.value})}
            />
            {showError && !data?.subject && <div style={{color: 'red'}}>Please enter a subject</div>}
          </div>
        </SubjectWrapper>
        <BodyWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '45px'}}>Body</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '89%'}}>
            <EditorComponentV2 value={editorStateV2} onChange={onEditorChange} style={{width: '100%', height: '150px'}} />
          </div>
        </BodyWrapper>
        <FooterWrapper useTemplateChecked={false}>
          <div>
            <Dropdown
              trigger={['hover']}
              placement='bottomLeft'
              overlay={
                <MenuStyled>
                  {existingTemplates?.results?.map((item, idx) => <>
                    <Popover
                      overlayStyle={{maxWidth: '600px', borderRadius: 40}}
                      overlayInnerStyle={{padding: 10}}
                      zIndex={21}
                      trigger='hover'
                      placement='rightTop'
                      content={
                        <div>
                          <div style={{fontWeight: '600', marginBottom: 5}}>{item?.name}</div>
                          <div style={{marginBottom: 5}}>{item?.subject}</div>
                          <div dangerouslySetInnerHTML={{__html: item?.body}}></div>
                          <ButtonStyled
                            style={{
                              width: 137,
                              height: 28,
                              borderRadius: 6,
                              display: 'flex',
                              justifyContent: 'center'}}
                            onClick={() => handleUseTemplateBtnClick(item?.body, item?.subject)}>
                            Use this template
                          </ButtonStyled>
                        </div>
                      }>
                      <Menu.Item key={idx} style={{backgroundColor: 'white'}}>
                        {item?.name}&nbsp;<FontAwesomeIcon icon={faEye}/>
                      </Menu.Item>
                    </Popover>
                    <Divider style={{margin: 0}} /></>)}
                </MenuStyled>
              }>
              <div style={{color: '#2D6CCA', cursor: 'pointer'}}>View existing templates&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown}/></div>
            </Dropdown>

          </div>
          <ButtonStyled onClick={()=> sendEmail()} style={{marginTop: '20px', width: '150px'}} type='primary'>
            <FontAwesomeIcon icon={faPaperPlane} />
          Send email

          </ButtonStyled>
        </FooterWrapper>
      </EmailPresetFieldsWrapper>
    </div>
  );
});
