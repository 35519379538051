import {Collapse, Dropdown, Spin, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {addProtocolToDomainHttps, getDomain, getSingleUrlParam, removeProtocolAndPrefix} from '@/utils/url';
import {StatusWrapper, StyledButton, StyledCloudStackInput, StyledIssuesCollapse, StyledIssuesTable, StyledTrashIcon, UrlWrapper, StyledMenu, MenuItem} from '../../../style';
import {getFilteredDataForOtto} from '../../tableIssuesCollapse';
// import {debounce} from 'lodash';
import {TableTopBar} from '../../tableTopBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {openUrl} from '@/utils/router';
import {notification} from '@/utils/notifications';
import {newNotification} from '@/utils/notification-v3';
import {notification as notificationV2} from '@/utils/notification-v2';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../freezTooltip';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  setAddRecord: (value: any) => void;
  showConfirmationPopup: (value: any) => void;
  addRecord: any;
  deleteItem: any;
  issueTable: string;
  maxHeight?: string;
  removeTopBar?: boolean;
}

const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
export const DynamicIndexing = observer(({componentIssueType, setPageChanged, setIssueTable, setAddRecord, addRecord, issueTable, showConfirmationPopup, deleteItem, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    ottoUrlLoader,
    ottoIssueType,
    issueTypeArray,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    addSourcePage,
    ottoSearchTerm,
    getOttoV2Project,
    loadIssueTableData,
  }} = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [urlId, setUrlId] = useState(-1);
  const [value, setValue] = useState(`${addProtocolToDomainHttps(getDomain(domainName))}/` || '');
  const [addingPage, setAddingPage] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({page: 1, pageSize: getOttoTablePageSize(componentIssueType)});
  const [addRecordError, setAddRecordError] = useState('');
  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
  const filteredFormattedData = ottoSearchTerm.trim() ? filteredData?.filter(it => it?.recommendedValue?.includes(ottoSearchTerm)) : filteredData;
  const filteredDataV2 = Array.isArray(filteredFormattedData) && addRecord ? [...filteredFormattedData.slice(0, (pagination.page - 1) * pagination.pageSize), {isFirstObject: true}, ...filteredFormattedData.slice((pagination.page - 1) * pagination.pageSize)] : filteredFormattedData;

  useEffect(() => {
    if (filteredDataV2 && deleteItem == -1) {
      setTableData(filteredDataV2);
    } else {
      const newData = filteredDataV2.filter(val=> val.itemIndex !== deleteItem);
      setTableData(newData);
    }
  }, [deleteItem]);


  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
    const data = url?.issueTable?.results ?? [];
    const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
    const filteredFormattedData = ottoSearchTerm.trim() ? filteredData?.filter(it => it?.recommendedValue?.includes(ottoSearchTerm)) : filteredData;
    const filteredDataV2 = Array.isArray(filteredFormattedData) && addRecord ? [...filteredFormattedData.slice(0, (pagination.page - 1) * pagination.pageSize), {isFirstObject: true}, ...filteredFormattedData.slice((pagination.page - 1) * pagination.pageSize)] : filteredFormattedData;
    setTableData(filteredDataV2);
  }, [ottoUrls, ottoSearchTerm, addRecord, pagination.page, pagination.pageSize]);

  useEffect(() => {
    return () => resetInput();
  }, []);

  const deployOttoUrl = async (id, toDeploy, issueType) => {
    setUrlId(id);
    try {
      const data = {toDeploy, issueType, uuid};
      data['itemIndex'] = id;
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change Rolled Back', 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      notification.error('Something went wrong', '', 2);
      setUrlId(-1);
    }
  };

  const resetInput = () => {
    setValue(`${addProtocolToDomainHttps(getDomain(domainName))}/` || '');
    setAddRecord(false);
    setAddRecordError('');
  };

  const onAddPage = async () => {
    const filtered = filteredData?.some(it => {
      const value1 = it?.recommendedValue?.endsWith('/') ? it?.recommendedValue?.slice(0, -1) : it?.recommendedValue;
      const value2 = value?.endsWith('/') ? value?.slice(0, -1) : value;
      return removeProtocolAndPrefix(value1) === removeProtocolAndPrefix(value2);
    });
    const domain = getDomain(domainName);
    if (getDomain(value) === getDomain(domainName) && !filtered) {
      try {
        setAddingPage(true);
        const payload = {issue_type: componentIssueType, object_key: value};
        await addSourcePage(uuid, payload);
        setPageChanged(true);
        setIssueTypeSelected(componentIssueType);
        setIsDeploying(true);
        if (uuid) {
          loadOttoV2Project(uuid, true);
        }
        notificationV2.success('Success', 'Page added successfully');
        resetInput();
        setAddingPage(false);
        loadIssueTableData({uuid: getOttoV2Project?.uuid, page: 1, page_size: 10, issue_type: componentIssueType, otto_project: getOttoV2Project?.id}, true);
      } catch (error) {
        setAddingPage(false);
      }
    } else {
      if (filtered) {
        setAddRecordError('This URL already exists.');
      } else setAddRecordError(`Page url must contain the selected domain${domainName ? ': ' + addProtocolToDomainHttps(domain) : ''}`);
    }
  };

  const menu = () => {
    const topPages = Array.isArray(getOttoV2Project?.topPages) ? getOttoV2Project?.topPages : [];
    const data = value.trim() ? topPages?.filter(it => {
      const value1 = it?.url?.endsWith('/') ? it?.url?.slice(0, -1) : it?.url;
      const value2 = value?.endsWith('/') ? value?.slice(0, -1) : value;
      return value1?.includes(value2);
    }) : filteredData;
    return value?.length ? (
      <StyledMenu>
        <div style={{fontSize: 12, padding: '13px 14px 8px'}}>{data?.length || 0} matches found in Google Search Console</div>
        <div style={{height: 1, background: '#D9D9D9', marginInline: 14}}></div>
        <div className='results-container' style={{marginTop: 4, marginBottom: 13}}>
          {data.map((it, idx) => (
            <MenuItem onClick={() => {
              setDropdownVisible(false);
              setValue(it?.url);
            }} key={idx}><UrlWrapper style={{maxWidth: '100%', fontSize: 14, color: '#2D6CCA'}}>{it?.url}</UrlWrapper></MenuItem>
          ))}
        </div>
      </StyledMenu>
    ) : <></>;
  };

  const columns = [
    {
      title: <div className='column-title'>STATUS</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: 125,
      render: (_, record) => {
        return (
          record?.isFirstObject ? (
            <div style={{color: '#2AC155'}}>-</div>
          ) : (
            <FreezeWrapper removeTooltip={record?.isApproved}>
              <StatusWrapper
                status={record?.isApproved}
                style={{cursor: ((urlId != -1 && urlId != record?.itemIndex)) && 'not-allowed'}}
                onClick={() => (urlId == -1) && deployOttoUrl(record?.itemIndex, !record?.isApproved, record?.issueType)}
              >
                {
                  urlId == record?.itemIndex ? <Spin indicator={antUrlIcon} /> :
                    <>
                      <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.isApproved ? `#2AC155` : '#A3A4A4'} />
                      <span>{record?.isApproved ? 'Deployed': 'Deploy'}</span>
                    </>
                }
              </StatusWrapper>
            </FreezeWrapper>
          )
        );
      },
    },
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      onCell: record => record?.isFirstObject ? {colSpan: 2} : {},
      width: 650,
      className: 'no-padding-right',
      render: (_, record) => {
        return (
          <div style={{width: '100%'}}>
            {record?.isFirstObject ? (
              <Dropdown
                trigger={['click']}
                onVisibleChange={val => setDropdownVisible(val)}
                visible={dropdownVisible}
                overlay={menu}
                overlayStyle={{maxWidth: '100%'}}
              >
                <div>
                  <StyledCloudStackInput value={value} onChange={e => {
                    addRecordError && setAddRecordError('');
                    setDropdownVisible(true);
                    setValue(e.target.value);
                  }} style={{width: '100%'}} placeholder='Enter the Page url' />
                  {addRecordError && <div style={{color: 'red', fontSize: '11px'}}>{addRecordError}</div>}
                </div>
              </Dropdown>
            ) : <><UrlWrapper style={{maxWidth: '100%'}} onClick={() => openUrl(`${addProtocolToDomainHttps(record?.recommendedValue)}`, '_blank')}>{record?.recommendedValue}</UrlWrapper></>
            }
          </div>
        );
      },
    },
    {
      title: <div className='column-title'>REASON</div>,
      dataIndex: 'status',
      key: 'status',
      width: 150,
      onCell: record => record?.isFirstObject ? {rowSpan: 0, colSpan: 0} : {},
      render: () => {
        return (
          <div style={{fontSize: 13, color: '#121212', whiteSpace: 'nowrap'}}>No Impressions</div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'trashIcon',
      key: 'trashIcon',
      className: addingPage ? 'max-width-150' : 'max-width-120',
      width: 120,
      render: (_, record) => {
        return record?.isFirstObject ? (
          <div style={{display: 'flex', alignItems: 'center', gap: '6px', justifyContent: 'flex-end', width: 'fit-content', maxWidth: 170}}>
            <StyledButton loading={addingPage} disabled={addingPage} onClick={onAddPage} color='green'>Save page</StyledButton>
            <div onClick={() => resetInput()} style={{padding: '5px 6px', backgroundColor: '#4E5156', borderRadius: '4px', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
              <FontAwesomeIcon icon={faXmark} fontSize={14} color='#fff'/>
            </div>
            {addingPage && <Spin indicator={<LoadingOutlined style={{fontSize: 12, color: '#A3A4A4'}} spin />}/>}
          </div>
        ) : (
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <FreezeWrapper placement='topRight' removeTooltip={record?.isApproved}>
              <Tooltip title={deleteItem === record?.itemIndex ? 'Deleting': 'Delete'}>
                {
                  deleteItem === record?.itemIndex ?
                    <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                    <StyledTrashIcon icon={faTrash} onClick={()=> showConfirmationPopup(record)} fontSize={14} color='#A3A4A4'/>
                }
              </Tooltip>
            </FreezeWrapper>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        {!removeTopBar && <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setAddRecord={setAddRecord}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter} />}
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns}
            dataSource={tableData}
            scroll={removeTopBar ? {x: 'auto', y: 'auto'} : {x: 850}}
            maxHeight={maxHeight}
            pagination={tableData.length ?
              {
                pageSize: pagination.pageSize,
                showSizeChanger: true,
                showPrevNextJumpers: true,
                pageSizeOptions: ['5', '10', '20', '50', '100'],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} results shown`,
                showLessItems: true,
              } : false}
            onChange={pagination => {
              setPagination({page: pagination.current, pageSize: pagination.pageSize});
              saveOttoTablePageSize(componentIssueType, pagination.pageSize);
              resetInput();
            }}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>
    </>
  );
});
