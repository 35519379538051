import React, {useEffect} from 'react';
import styles from '@/components/dashboard/billing/pricing-sidebar/style.module.scss';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {Drawer, Switch} from 'antd';
import {Button, ButtonLink} from '@/components/common-components';
import {useTranslation} from 'next-i18next';
import {getPricingSidebarArray} from '@/store/pricing/data';
import {routes} from '@/utils/const';
import {getGrowBusinessLink} from '@/utils/legacy';
import {CloseOutlined} from '@ant-design/icons';
import {useRouter} from 'next/router';
import {CA_BASIC_PLAN_ID} from '@/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
// import {faBadgeCheck} from '@fortawesome/pro-duotone-svg-icons';
import {getLocation} from '@/utils/string';
import {NextImg} from '@/utils/nextImg';

import PricingPlan from '@/components/common-components/v2/pricingPlan';


export interface CheckoutFormProp {
  setStripe: any;
  setElements: any;
  setSelectedPlan: any;
}

export const PricingSidebar: React.FC = observer(() => {
  const {t: tPricingPlans} = useTranslation('common');
  const {t: tCommon} = useTranslation('common');
  const router = useRouter();
  const {plans: {getPlansAndPrices, loadPlans}} = useStore('');
  const {settings: {customer: {profile: {isWhitelabel, isViewer, isParentAccount, whitelabelOtto}}}, plans: {handlePayAnnual, payAnnualValue}} = useStore('');

  const plansList = JSON.parse(JSON.stringify(getPricingSidebarArray(tPricingPlans, isWhitelabel)));

  useEffect(() => {
    if (!getPlansAndPrices?.length) {
      loadPlans(tCommon, isWhitelabel, whitelabelOtto);
    }
  }, [getPlansAndPrices?.length]);

  const {
    plans: {
      getSidebarPayment,
      hideSidebarPaymentDrawer,
      sideBarPaymentContent,
    }, settings: {customer: {profile}},
  } = useStore('');


  useEffect(() => {
    const planObj = {};
    plansList.forEach((item, index) => {
      planObj[item.id] = index;
    });
  }, []);


  const onClose = () => {
    hideSidebarPaymentDrawer();
  };

  const subscribePlan = () => {
    router.push(`/${routes.checkout}?plan_id=${CA_BASIC_PLAN_ID}${payAnnualValue ? '&pay_annually=1' : ''}`);
    hideSidebarPaymentDrawer();
  };
  const onPricingBtnClick = planId => {
    if (!(isWhitelabel && !isParentAccount)) {
      router.push(`/${routes.checkout}?plan_id=${planId}${payAnnualValue ? '&pay_annually=1' : ''}`);
    }

    hideSidebarPaymentDrawer();
  };

  const redirectToPlanComparison = () => {
    router.push({pathname: `/${routes.pricing}`, query: {plan_comparison: true}});
    hideSidebarPaymentDrawer();
  };


  return (
    <Drawer
      // style={{overflow: 'auto'}}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={getSidebarPayment && !isViewer}
      width={1100}
      headerStyle={{background: '#fff'}}
      className={styles.upgradeDrawer + ' drawerCloseIcon'}
      closeIcon={<CloseOutlined className={'closeIcon'} style={getSidebarPayment ? {display: 'block'} : {display: 'none'}}/>}
      bodyStyle={{backgroundColor: '#F2F2F5', paddingTop: '11px'}}
    >
      <>
        <div style={{paddingTop: '20px', textAlign: 'center'}} >
          <div style={{alignItems: 'center'}}>
            {!isWhitelabel && <div><Switch
              checked={payAnnualValue}
              style={{background: payAnnualValue ? '#F1AA3E' : ''}}
              onChange={value => handlePayAnnual(value)}
            />&nbsp;&nbsp;Pay annually and <OfferText>GET 20% OFF
              <NextImg src={'/icons/annual-offer.svg'} width={99} height={9} />
            </OfferText></div>}
            <h2 style={{display: 'flex', justifyContent: 'center', paddingTop: '13px'}}>{sideBarPaymentContent?.title || 'Multiple cutting-edge tools. One simple price.'}</h2>
            <Paragraph >{!isWhitelabel && sideBarPaymentContent?.subtitle.includes('SearchAtlas') ? sideBarPaymentContent?.subtitle?.replace('SearchAtlas', getLocation(location)) :
              sideBarPaymentContent?.subtitle || `We’ve been developing the most advanced enterprise SEO software platform to give digital marketers every tool they need to execute SEO campaigns at scale. Get access to our full suite of tools with a ${!isWhitelabel ? 'SearchAtlas' : getLocation(location)} subscription.`}</Paragraph>
          </div>
          <p style={{display: 'flex', justifyContent: 'center', paddingTop: '9px'}}>{sideBarPaymentContent?.text}</p>
        </div>
        {/* <GuaranteeBanner>
          <FontAwesomeIcon icon={faBadgeCheck} style={{fontSize: 20, color: '#1FAC47', marginRight: 10}}/>
          <div>100% Money Back Guarantee if you’re not satisfied. No questions asked</div>
        </GuaranteeBanner> */}
        <div className={styles.content}>

          <Wrapper>
            <PricingPlan cardBackgroudStyle = {{
              backgroundColor: 'transparent',
              backgroundImage: 'none',
              border: '1px solid #ffffff2b',
              borderTop: '2px solid #815aa8',
            }}
            darkMode={false}
            planNameStyle = {{
              color: 'black',
            }}
            subHeadingStyle = {{
              color: 'black',
            }}
            priceStyle = {{
              color: 'black',
            }}
            ctaButtonStyle = {{
              color: 'black',
            }}
            planDetailStyle ={{
              color: 'black',
            }}
            handleSelection={({id}) => id !== profile.plan && onPricingBtnClick(id)}
            responsive
            isSidebar={true}
            />
          </Wrapper>


          <div className={styles.additionalOption}>
            <div className={styles.heading}>
              <h2>Additional options</h2>
              {!isWhitelabel && <span>See <a onClick={() => {
                redirectToPlanComparison();
              }}>full plan comparison</a> or request a <ButtonLink href={getGrowBusinessLink()} target='_blank'>Customized plan</ButtonLink></span>}
            </div>
            <div className={styles.footerSection}>
              <div className={styles.sectionBox1}>
                <h2>{tCommon('seo-content-assistant-plan')}</h2>
                <p style={{marginBottom: 0}}>{tCommon('only-need-access-to-writing-tools')}</p>
                <p>{tCommon('our-sca-only-plan-includes')}</p>
                <div style={{display: 'flex'}}>
                  <div className={styles.scaPlanIconArea}>
                    <span style={{marginRight: '5px'}}>
                      <FontAwesomeIcon icon={faCheck} style={{color: '#1FAC47'}}/>
                    </span>
                    <span className={styles.scaPlanDescriptionText}>
                      {tCommon('sca-only-pages-articles')}
                    </span>
                  </div>
                  <div className={styles.scaPlanIconArea} style={{marginLeft: 32}}>
                    <span style={{marginRight: '5px'}}>
                      <FontAwesomeIcon icon={faCheck} style={{color: '#1FAC47'}}/>
                    </span>
                    <span className={styles.scaPlanDescriptionText}>
                      {tCommon('sca-only-ai-quota')}
                    </span>
                  </div>
                </div>
                <div className={styles.scaPlanIconArea} style={{marginBottom: 10}}>
                  <span style={{marginRight: '5px'}}>
                    <FontAwesomeIcon icon={faCheck} style={{color: '#1FAC47'}}/>
                  </span>
                  <span className={styles.scaPlanDescriptionText}>
                    {tCommon('sca-only-content-assistant-keywords')}
                  </span>
                </div>
                {!isWhitelabel &&
                  <Button
                    onClick={() => subscribePlan()}
                  >
                    {tCommon('subscribe-for-49-month')}
                  </Button>}
                <NextImg src='/img/dashboard/landing-page-optimizer/writing.svg'/>
              </div>
              <div className={styles.sectionBox2}>
                <h2>{tCommon('schedule-a-demo-with-us')}</h2>
                <p>{tCommon('not-sure-where-to-start')}</p>
                {!isWhitelabel && <ButtonLink href={getGrowBusinessLink()}>{tCommon('schedule-a-demo')}</ButtonLink>}
                <NextImg src='/img/dashboard/gsc/insights-tool.svg'/>
              </div>
            </div>
          </div>
        </div>
      </>
    </Drawer>
  );
});

const Wrapper = styled.div`
    display: flex;
    overflow: auto;
    text-align: start;
    position: relative;
`;

const Paragraph = styled.p`
  display: flex;
  text-align: center;
  margin-right: 35px;
  margin-left: 35px;
  justify-content: center;
`;

// const GuaranteeBanner = styled.div`
//   width: 473px;
//   height: 32px;
//   background: rgba(31, 172, 71, 0.04);
//   border: 1px solid rgba(31, 172, 71, 0.5);
//   box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
//   border-radius: 21px;
//   margin: auto;
//   margin-top: 10px;
//   margin-bottom: 18px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-weight: 400;
//   font-size: 12px;
//   color: #121212;
//   `;
const OfferText = styled.span`
color: #F1AA3E;
font-weight: 500;
position: relative;
img {
  position: absolute;
  bottom: -12px;
  left: 0;
}
`;
