/* eslint-disable prefer-promise-reject-errors */
import React, {useEffect, useState} from 'react';
import {
  Col,
  Form,
  Row,
  Input,
  Select,
  DatePicker,
  Spin,
  Dropdown,
  Menu,
  Checkbox,
} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {Modal} from '@/components/common-components';
import {faCheck} from '@fortawesome/pro-regular-svg-icons';
import {Button} from '@/components/common-components/v2';

import {faCaretDown, faXmark, faPlus} from '@fortawesome/pro-solid-svg-icons';
import TextArea from 'antd/lib/input/TextArea';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import {notification} from '@/utils/notification-v2';
import moment from 'moment';
import {LOCAL_SEO_SCAN_API} from '@/api/local-seo';
import {apiError} from '@/utils/api';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import PhoneInput from 'react-phone-input-2';
import {LoadingOutlined} from '@ant-design/icons';
import {urlValidationRegex, urlWithHttp} from '@/constants/regex';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import CommonButton from '@/components/dashboard/pages/local-seo-scan/local-citations/CommonButton';
import SelectDate from '@/components/dashboard/pages/local-seo-scan/local-citations/SelectDate';
import styles from '@/components/dashboard/pages/local-seo-scan/local-citations/style.module.scss';
import {toJS} from 'mobx';
import ImagesForm from '../../../imagesForm';
import {weekDaysInitialData} from '@/components/dashboard/pages/local-seo-scan/local-citations/BusinessInfoModal';
import {startCase} from 'lodash';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';
import {faCircleInfo as faCircleInfoSolid} from '@fortawesome/pro-solid-svg-icons';

export const convertObjToErrorString = (obj, baseKey = '') => {
  let errorString = '';
  function traverse(obj, baseKey = '', parentKey = '') {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        traverse(obj[key], baseKey || key, key);
      } else if (obj[key] && Array.isArray(obj[key]) && typeof obj[key][0] === 'string') {
        errorString += `${startCase(baseKey)}: ${parentKey && parentKey !== baseKey ? parentKey + ' - ' : ''}${obj[key][0]}\n`;
      } else if (obj[key] && typeof obj[key] === 'string') {
        errorString += `${startCase(baseKey || key)}: ${parentKey && parentKey !== baseKey ? parentKey + ' - ' : ''}${obj[key]}\n`;
      }
    }
  }
  try {
    if (typeof obj === 'object' && obj) traverse(obj, baseKey);
    return errorString?.trim() || 'Something went wrong';
  } catch (error) {
    return 'Something went wrong';
  }
};

const tabsTitle = [
  'Select Business',
  'Location',
  'Media',
  'Contact Info',
  'About',
  'Social Profiles',
];

const daysName = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const aggregatorsAvailable = ['foursquare', 'gpsnetwork'];

const countries = [
  {countryCode: 'USA', label: 'United States', code: 'US', aggregatorsAvailable: [
    'dataaxle',
    'neustar',
    'foursquare',
    'gpsnetwork',
    'ypnetwork',
  ]},
  {countryCode: 'AUS', label: 'Australia', code: 'AU', aggregatorsAvailable: [
    'foursquare',
    'gpsnetwork',
  ]},
  {countryCode: 'AUT', label: 'Austria', code: 'AT', aggregatorsAvailable},
  {countryCode: 'CAN', label: 'Canada', code: 'CA', aggregatorsAvailable: [
    'dataaxle',
    'foursquare',
    'gpsnetwork',
  ]},
  {countryCode: 'CZE', label: 'Czechia', code: 'CZ', aggregatorsAvailable},
  {countryCode: 'DNK', label: 'Denmark', code: 'DK', aggregatorsAvailable},
  {countryCode: 'FRA', label: 'France', code: 'FR', aggregatorsAvailable},
  {countryCode: 'DEU', label: 'Germany', code: 'DE', aggregatorsAvailable},
  {countryCode: 'HKG', label: 'Hong Kong', code: 'HK', aggregatorsAvailable},
  {countryCode: 'IRL', label: 'Ireland', code: 'IE', aggregatorsAvailable},
  {countryCode: 'ITA', label: 'Italy', code: 'IT', aggregatorsAvailable},
  {countryCode: 'LUX', label: 'Luxembourg', code: 'LU', aggregatorsAvailable},
  {countryCode: 'NLD', label: 'Netherlands', code: 'NL', aggregatorsAvailable},
  {countryCode: 'NZL', label: 'New Zealand', code: 'NZ', aggregatorsAvailable},
  {countryCode: 'NOR', label: 'Norway', code: 'NO', aggregatorsAvailable},
  {countryCode: 'ESP', label: 'Spain', code: 'ES', aggregatorsAvailable},
  {countryCode: 'SWE', label: 'Sweden', code: 'SE', aggregatorsAvailable},
  {countryCode: 'CHE', label: 'Switzerland', code: 'CH', aggregatorsAvailable},
  {countryCode: 'PHL', label: 'Philippines', code: 'PH', aggregatorsAvailable},
  {countryCode: 'POL', label: 'Poland', code: 'PL', aggregatorsAvailable},
  {countryCode: 'PRT', label: 'Portugal', code: 'PT', aggregatorsAvailable},
  {countryCode: 'TWN', label: 'Taiwan', code: 'TW', aggregatorsAvailable},
  {countryCode: 'GBR', label: 'United Kingdom', code: 'GB', aggregatorsAvailable},
  {countryCode: 'UMI', label: 'United States Minor Outlying Islands', code: 'UM', aggregatorsAvailable},
];


const sociaLinks = [
  {key: 'facebook', title: 'Facebook URL (optional)'},
  {key: 'twitter', title: 'X (Twitter) URL (optional)'},
  {key: 'linkedin', title: 'Linkedin URL (optional)'},
  {key: 'instagram', title: 'Instagram URL (optional)'},
  {key: 'pinterest', title: 'Pinterest URL (optional)'},
];

const keysMapping = {
  foursquare: 'Foursquare',
  dataaxle: 'Data Axle',
  neustar: 'Neustar',
  ypnetwork: 'YP Network',
  gpsnetwork: 'GPS Network',
};

const data = [
  {
    key: 'foursquare',
    companyName: keysMapping['foursquare'],
    img: '/img/foursquare.png',
    price: '$25',
    supportedLocation: 'US, CA, AU',
    allCountries: true,
  },
  {
    key: 'dataaxle',
    companyName: keysMapping['dataaxle'],
    img: '/img/data-axle.png',
    price: '$25',
    supportedLocation: 'US, CA',
  },
  {
    key: 'neustar',
    companyName: keysMapping['neustar'],
    img: '/img/neustar.png',
    price: '$25',
    supportedLocation: 'US',
  },
  {
    key: 'ypnetwork',
    companyName: keysMapping['ypnetwork'],
    img: '/img/yp-network.png',
    price: '$25',
    supportedLocation: 'US',
  },
  {
    key: 'gpsnetwork',
    companyName: keysMapping['gpsnetwork'],
    img: '/img/gps-network.png',
    price: '$25',
    supportedLocation: 'US, CA, AU',
    allCountries: true,
  },
];

interface completionProps {
  visible: boolean;
  data: any;
}

interface Props {
  visible: boolean;
  setVisible: (boolean) => void;
  setOpenCompletionModal: (value: completionProps) => void;
  setSubmittedBusinessData: (any) => void;
}

export const BusinessInfoModal = observer((props: Props) => {
  const {visible, setVisible, setOpenCompletionModal, setSubmittedBusinessData} = props;

  const {
    localSeoScan: {
      addingAggregatorNetwork,
      addAggregatorNetwork,
    },
    ottoV2Store: {
      getOttoV2Project,
      getSelectedLocation,
      loadIssueTableData,
    },
    countries: {countriesData},
  } = useStore('');

  const uuid = getSingleUrlParam('uuid');

  const [save, setSave] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [maxAccessTab, setMaxAccessTab] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const [form] = Form.useForm();
  const [addAdditionalCategory, setAddAdditionalCategory] = useState([]);
  const [businesses, setBusinesses] = useState<any>([]);
  const [loadingBusinesses, setLoadingBusinesses] = useState(false);
  const [websiteAddress, setWebsiteAddress] = useState('');
  const [discount, setDiscount] = useState(0);
  const [selectedValue, setSelectedValue] = useState('1');
  const [selectedBusiness, setSelectedBusiness] = useState<any>({});
  const [businessName, setBusinessName] = useState('');
  const [check, setCheck] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [total, setTotal] = useState(0);
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState(null);
  const calculatedTotal = total - (discount / 100) * total || 0;
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    if (visible) {
      setActiveTab(0);
      setMaxAccessTab(0);
    }
    !businesses?.length && loadBusiness();
  }, [visible]);

  useEffect(() => {
    const filteredCountriesData = countries.map(i => {
      const matchedCountry = toJS(countriesData).find(country => i?.code === country?.code);

      return {
        ...i,
        flag: matchedCountry ? matchedCountry.emoji : undefined,
      };
    });
    setCountriesList(filteredCountriesData);
  }, [countriesData]);

  useEffect(() => {
    const length = selectedRowKeys.length;
    length == 5 ? setCheck(true) : length < 5 ? setCheck(false) : '';
    const value =
      length > 0 ? length == 1 || length == 2 ? 5 : length == 3 ? 15 : length == 4 ? 20 : length == 5 ? 20 : 0 : 0;
    setSave(value);
    setTotal(length * 25);
    const discount =
      length > 1 ? length == 2 ? 5 : length == 3 ? 10 : length == 4 ? 15 : length == 5 ? 20 : 0 : 0;
    setDiscount(discount);
  }, [selectedRowKeys]);

  useEffect(() => {
    if (Object.keys(getOttoV2Project.aggregatorNetworkAutofillData)?.length && getOttoV2Project.aggregatorNetworkAutofillData[selectedValue]) {
      setSelectedBusiness(getOttoV2Project.aggregatorNetworkAutofillData[selectedValue]);
    }
  }, [getOttoV2Project.aggregatorNetworkAutofillData, selectedValue]);

  const setForm = () => {
    const weekDaysV2 = {};
    const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const businessCategoryId = businesses.find(business => business?.name === selectedBusiness?.businessCategory);
    const campaignCountry = renderFilteredCountries().find(country => country?.label === selectedBusiness?.country);
    selectedBusiness?.workingHours?.periods?.forEach(item => {
      weekDaysV2[`${convertToShortName(item?.openDay)?.toLowerCase()}_start`] = [moment(Object.keys(item?.openTime)?.length ? convertTimeForRange(item?.openTime?.hours) : '09:00', 'hh:mm'), moment(Object.keys(item?.closeTime)?.length ? convertTimeForRange(item?.closeTime?.hours) : '09:00', 'hh:mm')];
    });
    daysOfWeek.forEach(day => {
      if (!weekDaysV2[`${day}_start`]) {
        weekDaysV2[day] = 'closed';
        weekDaysV2[`${day}_start`] = [moment('09:00', 'hh:mm'), moment('09:00', 'hh:mm')];
      }
    });

    const fullFormData = {
      campaign_name: selectedBusiness?.businessName,
      website_address: selectedBusiness?.businessWebsiteUrl,
      ...(campaignCountry?.value && {campaign_country: campaignCountry?.value}),
      campaign_city: selectedBusiness?.townCity,
      postcode: selectedBusiness?.zipPostcode,
      address1: selectedBusiness?.addressLine,
      contact_firstname: selectedBusiness?.contactFirstName,
      contact_lastname: selectedBusiness?.contactLastName,
      contact_email: selectedBusiness?.contactEmail,
      contact_telephone: selectedBusiness?.contactPhoneNumber,
      fax_number: selectedBusiness?.faxNumber,
      ...(selectedBusiness?.openingDate && {formation_date: moment(selectedBusiness?.openingDate)}),
      ...(businessCategoryId?.id && {business_category_id: businessCategoryId?.id}),
      employees_number: selectedBusiness?.numberOfEmployees,
      full_description: selectedBusiness?.fullBusinessDescription,
      brief_description: selectedBusiness?.briefBusinessDescription,
      facebook: selectedBusiness?.facebookUrl,
      twitter: selectedBusiness?.twitterUrl,
      linkedin: selectedBusiness?.linkedinUrl,
      instagram: selectedBusiness?.instagramUrl,
      pinterest: selectedBusiness?.pinterestUrl,
      ...weekDaysV2,
    };
    setWebsiteAddress(selectedBusiness?.businessWebsiteUrl);
    form.setFieldsValue(fullFormData);
    setFormData(fullFormData);
  };
  useEffect(() => {
    setForm();
  }, [selectedBusiness, businesses]);

  const loadBusiness = async () => {
    setLoadingBusinesses(true);
    try {
      const business = await LOCAL_SEO_SCAN_API.getBusinessCategories();
      setBusinesses(business);
    } catch (error) {
      setBusinesses([]);
      notification.error('Failed', 'Failed to load business categories');
    } finally {
      setLoadingBusinesses(false);
    }
  };

  useEffect(() => {
    if (!businessName) {
      if (getSelectedLocation()['businessName']) {
        handleMenuClick(getSelectedLocation());
      } else if (getOttoV2Project?.connectedData?.gbpDetailsV2?.length) {
        handleMenuClick(toJS(getOttoV2Project?.connectedData?.gbpDetailsV2[0]));
      }
    }
    if (businessName && getOttoV2Project?.connectedData?.gbpDetailsV2?.length) {
      const hasRemoved = businessName !== getOttoV2Project?.connectedData?.gbpDetailsV2[0]?.businessName;
      if (hasRemoved) {
        handleMenuClick(toJS(getOttoV2Project?.connectedData?.gbpDetailsV2[0]));
      }
    }
  }, [getOttoV2Project?.connectedData?.gbpDetailsV2]);

  const nextStep = async (values?: any) => {
    try {
      setFormData(prev => ({...prev, ...values}));
      setMaxAccessTab(activeTab + 1);
      setActiveTab(activeTab + 1);
    } catch (error) {
      notification.error('Invalid', 'Invalid form field values');
    }
  };

  const validateName = (_, value) => {
    if (/^[a-zA-Z\s'-]+$/.test(value) || !value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid name'));
  };

  const validateFaxNumber = (_, value) => {
    if (/^[\d+\-()]+$/.test(value) || !value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid fax number'));
  };

  const handleKeyDown = e => {
    if (e.key === '-') e.preventDefault();
  };

  const onChangeForm = () => maxAccessTab !== activeTab && setMaxAccessTab(activeTab);

  const handleComfirmAndPay = async () => {
    try {
      const allKeys = Object.keys(formData);
      const payload = {
        aggregators: selectedRowKeys,
        business_name: businessName,
        campaign_name: formData.campaign_name,
        website_address: websiteAddress,
        campaign_country: formData.campaign_country,
        campaign_state: formData.campaign_state,
        campaign_city: formData.campaign_city,
        region: formData.campaign_state,
        city: formData.campaign_city,
        postcode: formData.postcode,
        address1: formData.address1,
        ...(formData.address2 && {address2: formData.address2}),
        contact_firstname: formData.contact_firstname,
        contact_lastname: formData.contact_lastname,
        contact_name: `${formData.contact_firstname || ''} ${formData.contact_lastname || ''}`,
        ...(formData.contact_email && {contact_email: formData.contact_email}),
        contact_telephone: formData.contact_telephone,
        ...(formData.fax_number && {fax_number: formData.fax_number}),
        ...(formData.formation_date && {formation_date: moment(formData.formation_date).format('MM-YYYY')}),
        business_category_id: formData.business_category_id,
        employees_number: formData.employees_number,
        full_description: formData.full_description,
        brief_description: formData.brief_description,
        gb_location_id: selectedValue,
      };
      [1, 2, 3, 4, 5].forEach(value => {
        if (formData[`service_name_${value}`]) payload[`service_name_${value}`] = formData[`service_name_${value}`];
      });
      if (formData?.additional_business_category_0) {
        payload['extra_business_categories_ids'] = [];
        allKeys.forEach(key => {
          if (key.includes('additional_business')) payload['extra_business_categories_ids'].push(formData[key]);
        });
      }
      const regular = {};
      daysName.forEach(day => {
        const detailObject = {status: 'open', hours: []};
        allKeys.forEach(key => {
          if (key.includes(day) && formData[key]) {
            if ((key === `${day}_start`) || (key === `${day}_end`)) {
              formData[key] && detailObject.hours.push({
                start: formData[key][0] ? moment(formData[key][0])?.format('HH:mm') : '',
                end: formData[key][1] ? moment(formData[key][1])?.format('HH:mm') : '',
              });
            }
            if (key === day) {
              detailObject.status = formData[key];
            }
          }
        });
        if (!detailObject.hours?.length) {
          detailObject.status = 'closed';
        }
        if (['24hrs', 'closed'].includes(detailObject.status)) detailObject['hours'] = [];
        regular[day] = detailObject;
      });
      payload['opening_hours'] = {regular};

      const links = {};
      sociaLinks.forEach(({key}) => {
        if (formData[key]) links[key] = formData[key];
      });
      payload['social_profile_links'] = links;
      const data = new FormData();
      Object.entries(payload || {})?.forEach(([key, value]) => {
        if ((Array.isArray(value) || typeof value === 'object')) {
          data.append(key, JSON.stringify(value));
        } else {
          data.append(key, value);
        }
      });
      if (logo) {
        data.append('logo', logo);
      }
      images?.slice(0, 3)?.forEach((image, idx) => {
        data.append(`image${idx + 1}`, image?.url);
      });

      const response = await addAggregatorNetwork(data);

      setSubmittedBusinessData({
        aggregators: selectedRowKeys,
        total: calculatedTotal,
      });

      setOpenCompletionModal({
        visible: true,
        data: response,
      });
      setFormData({});
      setAddAdditionalCategory([]);
      setVisible(false);
      setBusinessName('');
      const params = {
        uuid,
        issue_type: 'aggregator_network',
        page_size: 10,
        page: 1,
        is_loading: false,
      };
      await loadIssueTableData(params, false);
    } catch (e) {
      if (e?.response?.data?.payment) {
        const errorMessage = apiError(e) as string;
        notification.error(errorMessage, 'Can not proceed at the moment, Please try again later', false, 'OK');
      } else if (e?.response?.data?.openingHours) {
        const errorMessage = convertObjToErrorString(e?.response?.data);
        notification.error('', typeof errorMessage === 'string' ? errorMessage : 'Something went wrong.', false, 'OK');
      } else {
        const errorMessage = apiError(e, '', true);
        notification.error('', typeof errorMessage === 'string' ? errorMessage : 'Something went wrong.', false, 'OK');
      }
      setVisible(true);
    }
  };

  const renderFilteredCountries = () => {
    const filteredCountry = countries.filter(country => selectedRowKeys?.every(aggr => country.aggregatorsAvailable.includes(aggr))) || [];
    return filteredCountry?.map(country => ({
      value: country.countryCode, label: country.label,
    }));
  };

  const convertToShortName = fullName => {
    const weekdays = {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    };

    return weekdays[fullName?.toLowerCase()] || fullName;
  };

  const handleRowClick = (record: any) => {
    const newSelectedRowKeys = [...selectedRowKeys];
    if (newSelectedRowKeys.includes(record.key)) {
      setSelectedRowKeys(newSelectedRowKeys.filter(key => key !== record.key));
    } else {
      setSelectedRowKeys([...newSelectedRowKeys, record.key]);
    }
    setForm();
  };

  const convertTimeForRange = time => {
    const timeString = time;
    const timeFormat = 'h A';
    const timeMoment = moment(timeString, timeFormat);

    return timeMoment.format('HH:mm');
  };

  const handleBannerCheckboxChange = (event:any) => {
    setCheck(event.target.checked);
    const newSelectedRowKeys:any = event.target.checked ? data.map(item => item.key) : [];
    setSelectedRowKeys(newSelectedRowKeys);
    setForm();
  };

  const UrlError = ({value}) => {
    return <GrayError>Please enter a valid domain name like
      <span className='url-btn' onClick={() => {
        setWebsiteAddress(addProtocolToDomainHttps(value));
        form.setFields([{name: 'website_address', touched: false, validating: false, errors: []}]);
      }}>{addProtocolToDomainHttps(value)}</span></GrayError>;
  };

  const urlValidator = (_, _2, callback) => {
    const value = websiteAddress;
    if (value?.match(urlValidationRegex) && value?.match(urlWithHttp) || !value) {
      callback();
    } else if (addProtocolToDomainHttps(value)?.match(urlWithHttp)) {
      callback(<UrlError value={value} />);
    } else {
      callback(<ErrorMessage text='Please enter a valid URL' />);
    }
  };

  const ErrorMessage = ({text}) => <StylesError>{text}</StylesError>;

  const weekDays = (selectedBusiness?.businessMetadata?.openingHours?.perDay || weekDaysInitialData) ?.map(item => ({
    id: item?.dayNumber,
    label: convertToShortName(item?.name),
    name: convertToShortName(item?.name)?.toLowerCase(),
    type: item?.value === 'Closed' && !formData[`${item?.name[0]?.toLowerCase() + item?.name?.slice(1)}_start`] ? 'closed' : 'open',
    from: item?.parsed?.length ? convertTimeForRange(item?.parsed[0]?.open) : '09:00',
    to: item?.parsed?.length ? convertTimeForRange(item?.parsed[0]?.close) : '09:00',
    row: (item?.name === 'Saturday' || item?.name === 'Sunday') ? 2 : 1,
  }))?.sort((a, b) => {
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return daysOfWeek.indexOf(a.label) - daysOfWeek.indexOf(b.label);
  });

  const handleMenuClick = (business: any) => {
    setSelectedValue(business?.locationId);
    setBusinessName(business?.businessName);
  };

  const handleBackClick = () => {
    setMaxAccessTab(activeTab - 1);
    setActiveTab(activeTab - 1);
  };

  // const handleBannerClick = () => {
  //   const newSelectedRowKeys = !check ? data.map(item => item.key) : [];
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  const tags = {
    tag1: <div style={{display: 'flex', justifyItems: 'center', padding: '2px, 5px, 2px, 5px'}}><FontAwesomeIcon style={{marginTop: '2px', marginRight: '5px'}} color='#2AC155' icon={faCheck}/><div style={{color: '#2AC155'}}>Added</div></div>,
    tag2: (
      <div
        style={{
          width: '132px',
          height: '22px',
          borderRadius: '45px',
          padding: '3px, 8px, 4px, 8px',
          textAlign: 'center',
          color: '#2D6CCA',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(45, 108, 202, 0.1)',
        }}
      ><FontAwesomeIcon style={{marginRight: '4px'}} color='#2D6CCA' icon={faPlus}/>{`Add and save ${save}%`}</div>
    ),
  };

  const filteredLocations = getOttoV2Project?.connectedData?.gbpDetailsV2;

  const menu = (
    <StyledMenu
      style={{position: 'absolute'}}
      selectedKeys={[selectedValue]}
      defaultValue={selectedValue}
    >
      {/* <TableTopBar
        placeholder='Search Projects'
        onSearch={value => setSearchText(value)}
      /> */}
      {filteredLocations?.map(project => (
        <StyledMenuItem key={project?.id} onClick={() => {
          handleMenuClick(project);
        }}>
          <div style={{fontSize: 14, fontWeight: 500, color: '#121212'}}>{project?.businessName}</div>
          <div style={{fontSize: 12, fontWeight: 400, color: '#4E5156'}}>{project?.businessAddress}</div>
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );

  const LocationMenu = ({codeList, allCountries}) => (
    <LocationContainer>
      {
        (allCountries ? countriesList : countriesList.filter(location => codeList.split(', ').includes(location?.code))).map(item => (<div key={item.label} style={{display: 'flex', alignItems: 'center', gap: 10}}>
          <img src={`${item.flag}`} style={{height: '12px', borderRadius: '2px'}}/>
          <RowText style={{color: 'black'}}>{item.label}</RowText></div>
        ))
      }
    </LocationContainer>
  );

  const steps = [
    <div key='step1'>
      <DropdownLabel style={{marginTop: 0}}>Business</DropdownLabel>
      <StyledDropdown getPopupContainer={node => node} trigger={['click']} overlay={menu}>
        <div>
          <div>
            <SelectedMenuItem style={{fontWeight: '400'}}>
              {businessName != '' ? businessName : getSelectedLocation()['businessName']}
            </SelectedMenuItem>
          </div>
          <DropdownIcon>
            <FontAwesomeIcon icon={faCaretDown} />
          </DropdownIcon>
        </div>
      </StyledDropdown>
      <PaymentWrapper>
        <Header>Data Aggregator & Pricing</Header>
        <div
          style={{
            border: '1px solid #D2D2D2',
            borderRadius: '8px',
            padding: '4px',
          }}
        >
          <BannerContainer >
            <StyledCheckbox
              checked={check}
              onChange={handleBannerCheckboxChange}
            />
            <BannerText>
              Select all and{' '}
              <a style={{textDecoration: 'underline'}}>save 20%</a> on your
              submission
            </BannerText>
          </BannerContainer>
          {data.map(row => (
            <Container style={{height: '100%'}} key={row.key} onClick={() => handleRowClick(row)}>
              <Column isFirst={true}>
                <StyledCheckbox
                  checked={selectedRowKeys.includes(row.key)}
                  style={{marginRight: 9}}
                />
                <ImageDiv>
                  <img src={`${row.img}`} />
                </ImageDiv>
                <RowText>{row.companyName}</RowText>
              </Column>
              <Column style={{width: '20%'}}>
                <div onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                  <LocationDropdown trigger={['click']} overlay={<LocationMenu codeList={row?.supportedLocation} allCountries={row?.allCountries} />} placement='bottomLeft'>
                    <div>
                      <RowText>
                        {row?.supportedLocation}{row?.allCountries ? '...' : ''}
                      </RowText>
                      <FontAwesomeIcon icon={faCaretDown} color='#121212' />
                    </div>
                  </LocationDropdown>
                </div>
              </Column>
              <Column>
                <RowText>{row.price}</RowText>
                {selectedRowKeys?.length ? (
                  <Tag>
                    {selectedRowKeys.includes(row.key) ? tags.tag1 : tags.tag2}
                  </Tag>
                ) : (
                  <></>
                )}
              </Column>
            </Container>
          ))}
        </div>
      </PaymentWrapper>
      <div
        style={{
          color: 'white',
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          marginTop: 10,
        }}
      >
        <StyledDiscount>
          Discount:{' '}
          <span
            style={{color: '#121212', fontWeight: '600', fontSize: '18px'}}
          >
            {discount}%
          </span>
        </StyledDiscount>
        <StyledDiscount>
          Total:{' '}
          <span
            style={{color: '#121212', fontWeight: '600', fontSize: '18px'}}
          >
            ${calculatedTotal}
          </span>
        </StyledDiscount>
      </div>
      <ButtonWrapper>
        <CommonButton
          text='Next Step'
          small
          disabled={!businessName || !selectedRowKeys.length}
          action={()=>{
            setMaxAccessTab(activeTab + 1);
            setActiveTab(activeTab + 1);
            form.validateFields(['mobile_number']);
          }}
        />
      </ButtonWrapper>
    </div>,
    <Form
      key='step2'
      layout='vertical'
      autoComplete='off'
      name='step2'
      onFinish={nextStep}
      form={form}
      onChange={onChangeForm}
    >
      <Row gutter={[20, 5]}>
        <Col xs={12}>
          <Form.Item
            name='campaign_name'
            label={<span className='label'>Business Name</span>}
            rules={[
              {
                required: true,
                whitespace: true,
                message: <ErrorMessage text='Please enter business name' />,
              },
            ]}
          >
            <CustomInput>
              <Input
                defaultValue={formData.campaign_name}
                bordered={false}
              />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='website_address'
            label={<span className='label'>Business Website URL</span>}
            rules={[
              {
                required: true,
                message: <ErrorMessage text='Please enter website URL' />,
              },
              {validator: urlValidator},
            ]}
          >
            <CustomInput>
              <Input
                value={websiteAddress}
                onChange={e => setWebsiteAddress(e.target.value)}
                bordered={false}
              />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='campaign_country'
            label={<span className='label'>Country</span>}
            rules={[
              {
                required: true,
                message: <ErrorMessage text='Please select your country' />,
              },
            ]}
            className='countryDropdown'
          >
            <Select
              placeholder='Select Country'
              defaultValue={
                formData.campaign_country
              }
              options={renderFilteredCountries()}
              suffixIcon={
                <FontAwesomeIcon icon={faCaretDown} color='#000000' />
              }
              onChange={() => {
                // reset postcode, city and state on changing country
                const obj = {campaign_city: '', postcode: '', campaign_state: ''};
                form.setFieldsValue(obj);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <StyledFormItem
            name='campaign_city'
            label={<span className='label'>Town / City</span>}
            rules={[
              {
                required: true,
                whitespace: true,
                message: <ErrorMessage text='Please enter your city' />,
              },
            ]}
          >
            <Input
              defaultValue={formData.campaign_city}
              bordered={false}
            />
          </StyledFormItem>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='campaign_state'
            label={<span className='label'>State / County / Region</span>}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <ErrorMessage text='Please enter your State / County / Region' />
                ),
              },
            ]}
          >
            <CustomInput>
              <Input
                defaultValue={formData?.campaign_state}
                bordered={false}
              />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <StyledFormItem
            name='postcode'
            label={<span className='label'>Zip / PostCode</span>}
            rules={[
              {
                required: true,
                message: (
                  <ErrorMessage text='Please enter ZipCode / PostCode!' />
                ),
              },
              {
                pattern: /^[a-zA-Z0-9\s-]+$/,
                message: (
                  <ErrorMessage text='Please enter a valid zip code' />
                ),
              },
            ]}
          >
            <Input
              defaultValue={
                formData?.postcode
              }
              bordered={false}
            />
          </StyledFormItem>
        </Col>
        <Col span={24}>
          <Form.Item
            name='address1'
            label={<span className='label'>Address Line</span>}
            className='addressItem'
            rules={[
              {
                required: true,
                whitespace: true,
                message: <ErrorMessage text='Please enter your address' />,
              },
              {
                max: 100,
                message: (
                  <ErrorMessage text='Address must be less than 100 characters' />
                ),
              },
            ]}
            extra={(
              <span className='addressNote'>
                <FontAwesomeIcon icon={faCircleInfo} color='#1890ff' fontSize={14} />
                Do not include <span style={{fontWeight: 700}}>PO BOX</span> in the address because it {`won't`} be accepted by the Aggregators.
              </span>
            )}
          >
            <CustomInput>
              <Input
                defaultValue={
                  formData.address1
                }
                bordered={false}
              />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='address2'
            label={<span className='label'>Address Line 2 (Optional)</span>}
            rules={[
              {
                max: 100,
                message: (
                  <ErrorMessage text='Address must be less than 100 characters' />
                ),
              },
            ]}
          >
            <CustomInput>
              <Input defaultValue={formData.address2} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
      </Row>
      <ButtonSection>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </Form>,
    <div key='step3'>
      <ImagesForm
        images={images}
        setImages={setImages}
        logo={logo}
        setLogo={setLogo}
        maxLimit={3}
        showUrlSection={false}
        photosSectionTitle='Business Photos'
        logoPlaceholder='Format: JPG, PNG'
      />
      <ButtonSection style={{marginTop: 160}}>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton action={() => nextStep()} text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </div>,
    <Form
      key='step4'
      layout='vertical'
      autoComplete='off'
      name='step4'
      onFinish={nextStep}
      initialValues={formData}
      onChange={onChangeForm}
    >
      <Row gutter={[20, 5]}>
        <Col xs={12}>
          <Form.Item
            name='contact_firstname'
            label={<span className='label'>Contact First Name</span>}
            rules={[
              {whitespace: true, message: <ErrorMessage text='Please enter first name' />},
              {required: true, message: <ErrorMessage text='Please enter first name' />},
              {validator: validateName},
            ]}
          >
            <CustomInput>
              <Input defaultValue={formData.contact_firstname} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='contact_lastname'
            label={<span className='label'>Contact Last Name</span>}
            rules={[
              {whitespace: true, message: <ErrorMessage text='Please enter last name' />},
              {required: true, message: <ErrorMessage text='Please enter last name' />},
              {validator: validateName},
            ]}
          >
            <CustomInput>
              <Input defaultValue={formData.contact_lastname} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='contact_email'
            label={<span className='label'>Contact Email (Optional)</span>}
            rules={[{type: 'email', message: <ErrorMessage text='Please enter a valid email' />}]}
          >
            <CustomInput>
              <Input defaultValue={formData.contact_email} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='contact_telephone'
            label={<span className='label'>Contact Phone Number</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please enter phone number' />},
            ]}
          >
            <StyledPhoneInput
              inputStyle={{width: '100%'}}
              country='us'
              placeholder='Phone Number'
              value={formData.contact_telephone}
              onChange={e => {
                form.setFieldsValue({contact_telephone: e});
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='fax_number'
            label={<span className='label'>Fax number (Optional)</span>}
            rules={[{validator: validateFaxNumber}]}
          >
            <CustomInput>
              <Input defaultValue={formData.fax_number} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='formation_date'
            label={<span className='label'>Opening Date</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please select an opening date' />},
            ]}
          >
            <DatePicker
              picker='month'
              format='MM-YYYY'
            />
          </Form.Item>
        </Col>
      </Row>
      <ButtonSection>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </Form>,
    <Form
      key='step5'
      layout='vertical'
      autoComplete='off'
      name='step5'
      onFinish={nextStep}
      initialValues={formData}
      onChange={onChangeForm}
    >
      <Row gutter={[20, 5]}>
        <Col xs={16}>
          <Form.Item
            name='business_category_id'
            label={<span className='label'>Business Category</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please select a business category' />},
            ]}
          >
            <Select
              showSearch
              placeholder='Select Category'
              defaultValue={formData.business_category_id}
              filterOption={(input, option) => {
                try {
                  return String(option.label)?.toLowerCase()?.includes(input.toLowerCase());
                } catch (error) {
                  return true;
                }
              }}
              options={businesses?.map(business => ({value: business.id, label: business.name})) || []}
              suffixIcon={loadingBusinesses ? <Spin indicator={<LoadingOutlined style={{fontSize: 20}} spin />} /> : <FontAwesomeIcon icon={faCaretDown} color='#000000' />}
              disabled={loadingBusinesses}
            />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name='employees_number'
            label={<span className='label'>Number of Employees</span>}
            rules={[{required: true, message: <ErrorMessage text='Please enter number of employees' />}]}
          >
            <CustomInput>
              <StyledInput
                defaultValue={formData.employees_number}
                bordered={false}
                min={0}
                type='number'
                onKeyDown={handleKeyDown}
              />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='full_description'
            label={<span className='label'>Full Business Description</span>}
            rules={[
              {required: true, whitespace: true, message: <ErrorMessage text='Please enter full description' />},
              {
                max: 500,
                message: (
                  <ErrorMessage text='Full business description must be less than 500 characters' />
                ),
              },
            ]}
          >
            <CustomInput>
              <StyledTextArea defaultValue={formData?.full_description} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='brief_description'
            label={<span className='label'>Brief Business Description</span>}
            rules={[
              {required: true, whitespace: true, message: <ErrorMessage text='Please enter brief description' />},
              {
                max: 200,
                message: (
                  <ErrorMessage text='Brief business description must be less than 200 characters' />
                ),
              },
            ]}
          >
            <CustomInput>
              <StyledTextArea defaultValue={formData.brief_description} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
      </Row>
      {addAdditionalCategory.length ?
        <Row>
          <AdditonalFields>
            <Labels>Additional Business Category (optional)</Labels>
            <Labels style={{marginLeft: '61px'}}>List of Services/Products (optional)</Labels>
          </AdditonalFields>
        </Row> :
        <></>
      }
      <StyledRow>
        {addAdditionalCategory.length ?
          addAdditionalCategory.map((i, idx) => {
            return (
              <AddAdditionalCategorySection key={idx}>
                <Index>#{idx + 1}</Index>
                <Form.Item key={idx} name={`additional_business_category_${idx}`}>
                  <Select
                    placeholder='Select Category'
                    style={{width: '236px'}}
                    defaultValue={formData[`additional_business_category_${idx}`]}
                    options={businesses?.map(business => ({value: business.id, label: business.name})) || []}
                    suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
                  />
                </Form.Item>
                <Form.Item
                  style={{marginLeft: '16px'}}
                  name={`service_name_${idx + 1}`}
                >
                  <CustomInput>
                    <Input style={{width: '250px', height: '33px'}} defaultValue={formData[`service_name_${idx + 1}`]} bordered={false} />
                  </CustomInput>
                </Form.Item>
                <StyledFontAwesomeIcon icon={faTrash} color='#A3A4A4' fontSize={18} onClick={() => setAddAdditionalCategory(addAdditionalCategory.filter(value => value !== i))} />
              </AddAdditionalCategorySection>
            );
          }) : <></>
        }
      </StyledRow>
      <Row>
        <AddAdditionalCategory isDisabled={addAdditionalCategory.length >= 5} onClick={() => addAdditionalCategory.length < 5 && setAddAdditionalCategory([...addAdditionalCategory, addAdditionalCategory.length + 1])}>+ Add additional category</AddAdditionalCategory>
      </Row>
      <Row gutter={[20, 7]} className={styles.workingHours}>
        <Col span={24}>
          <div className={styles.label}>Working Hours</div>
        </Col>
        <DateWrapper>
          <div className='wrapper'>
            {weekDays?.filter((item, index) => index<5).map((item, id) => <SelectDate key={id} item={item} formData={formData}/>)}
          </div>
          <div className='wrapper'>
            {weekDays?.filter((item, index) => index>=5).map((item, id) => <SelectDate key={id} item={item} formData={formData}/>)}
          </div>
        </DateWrapper>
      </Row>
      <ButtonSection>
        <ButtonWrapper>
          {/* <CommonButton text='Back' small /> */}
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </Form>,
    <Form
      key='step6'
      layout='vertical'
      autoComplete='off'
      name='step6'
      onFinish={handleComfirmAndPay}
      initialValues={formData}
    >
      <Row gutter={[20, 5]}>
        {sociaLinks.map((item, i) => (<Col key={i} xs={12}>
          <Form.Item
            name={item.key}
            label={<span className='label'>{item.title}</span>}
            rules={[{type: 'url', message: <ErrorMessage text='Please enter a valid URL' />}]}
          >
            <CustomInput>
              <Input defaultValue={formData[item.key]} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>))}
      </Row>
      <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
        <div className={styles.amountWrapper} style={{paddingRight: 0}}>
          Discount: <span className={styles.total}>{discount || 0}%</span>
        </div>
        <div className={styles.amountWrapper} style={{paddingRight: 0, marginTop: 10}}>
          Total amount: <span className={styles.total}>${calculatedTotal || 0}</span>
        </div>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton
            text='Confirm and Pay'
            loading={addingAggregatorNetwork}
            disabled={addingAggregatorNetwork}
            isLock
          />
        </ButtonWrapper>
      </div>
    </Form>,
  ];

  return (
    <ModalStyled
      visible={visible}
      destroyOnClose={true}
      onClose={() => {
        setVisible(false);
        setBusinessName('');
      }}
      title={
        <BannerStyled>
          <FontAwesomeIcon width={18} height={18} color='#34AEF3' icon={faCircleInfoSolid}/>
          <div>
            <h1>Verify your business information to prevent potential issues.</h1>
            <h1 style={{fontSize: 12, fontWeight: 400}}>Before submitting, please carefully review all details and verify that all data is 100% correct</h1>
          </div>
        </BannerStyled>
      }
      closable={false}
      isLast={activeTab === tabsTitle.length - 1}
    >
      <div className='header'>
        <Title>Submit Business Info</Title>
        <span onClick={() => {
          setVisible(false);
          setBusinessName('');
        }}>
          <FontAwesomeIcon className='close-icon' icon={faXmark} />
        </span>
      </div>
      <div className='steps-wrapper'>
        <div className='steps'>
          {tabsTitle.map((_, index) => (
            <div
              key={index}
              className={`section ${
                index <= activeTab ? 'active-section ' : ''
              } ${index <= maxAccessTab ? '' : ' restricted'}`}
              onClick={() => {
                if (index <= maxAccessTab) {
                  setActiveTab(index);
                }
              }
              }
            >
              <div className='step'>{`Step ${index + 1}`}</div>
              <div className={`step ${index <= activeTab ? 'active' : ''}`}>
                {tabsTitle[index]}
              </div>
            </div>
          ))}
        </div>
        <div className='border-line' />
      </div>
      <div className={`${styles.formwrapper} checkout-scroll`}>
        {steps[activeTab]}
      </div>
    </ModalStyled>
  );
});

const StyledTextArea = styled(TextArea)`
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138, 138, 138, 0.12);
  }
`;

const BannerStyled = styled.div`
   display: flex;
   gap: 10px;
   align-items: center;

   h1 {
    color: #fff;
    margin-bottom: 0px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
   }
`;

const ModalStyled = styled(Modal)<{isLast: boolean}>`
  max-width: 740px;
  width: 100%;
  padding-bottom: 0;
  min-height: ${p=> p.isLast ? '400px' : '680px'};

  .rc-dialog-content {
    min-height: 100%;
    width: 100%;
    padding: 0;
    background: none;
  }

  .rc-dialog-header{
    background: aliceblue;
    margin-bottom: 11px;
    border-radius: 12px;
    padding: 11.5px 17px;
    backdrop-filter: blur(24px);
    background: #34AEF340;
    
  }

  .checkout-scroll {
    ${p => p.isLast ? `
      @media screen and (max-width: 650px) {
      max-height: calc(100vh - 200px);
      overflow-y: scroll;
      overflow-x: hidden;
      min-height: 400px;
    }
    ` : ''}
  }

  .rc-dialog-body {
    padding: 25px 25px;
    min-height: ${p=> p.isLast ? '400px' : '680px'};
    background: white;
    border-radius: 12px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;

    span {
      cursor: pointer;
    }
  }

  .countryDropdown {
    .ant-select-selector {
      border-radius: 8px !important;
      max-height: 32px !important;
      min-height: 32px !important;
    }
  }

  .steps-wrapper {
    position: relative;

    .steps {
      display: flex;
    }

    .border-line {
      bottom: 3px;
      position: relative;
      width: 100%;
      height: 2px;
      border-bottom: 2px solid #e8e8e8 !important;
    }
    .section {
      flex-grow: 1;
      cursor: pointer;

      &.active-section {
        z-index: 1;
        padding-bottom: 8px;
        border-bottom: 4px solid #1fac47;
      }
      &.restricted {
        cursor: default;
      }
      .step {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: #4e5156;

        &.active {
          font-weight: 500;
          color: #121212;
        }
      }
    }
  }

  .close-icon {
    color: #a3a4a4;
    font-size: 28px;
  }

  .addressItem {
    .addressNote {
      color: #4e5156;
      font-size: 12px;
      padding-top: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  p {
    margin-bottom: 0;
  }
}
`;


const GrayError = styled.p`
  color: #4e5156;
  line-height: 1;
  font-weight: 400;
  margin-top: 6px;
  font-size: 10px;
  .url-btn {
    color: #2d6cca;
    cursor: pointer;
    word-break: break-all;
    margin-left: 6px;
  }
`;

const ButtonSection = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

const CancelButton = styled(Button)`
  margin: 10px !important;
  padding: 0px 5px !important;
  color: #4E5156;

  &:hover {
    background-color: transparent !important;
  }
`;

const CustomInput = styled.div`
  padding: 0px 0px;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 3px 2px 0px #00000005;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-control-input {
    min-height: 30px;
    padding: 0px 0px;
    border-radius: 8px;
    border: 1px solid #d2d2d2;
    box-shadow: 0px 3px 2px 0px #00000005;
  }
`;

const AddAdditionalCategory = styled.span<{ isDisabled: boolean }>`
  margin-top: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #2d6cca;
  cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};
`;

const AdditonalFields = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 8px;
`;

const Labels = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4e5156;
`;

const AddAdditionalCategorySection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .ant-select-selection-item {
    max-width: 90% !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 16px;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

const BannerText = styled.div`
  font-size: 12px;
  font-weight: 500px;
  color: #121212;
  line-height: 10px;
`;

const Index = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4e5156;
  width: 30px;
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    height: 32px !important;
    border-radius: 8px !important;
  }
  .flag-dropdown {
    border-radius: 8px 0 0 8px !important;
  }
  .flag-dropdown.open .selected-flag {
    border-radius: 8px 0 0 8px !important;
  }
  .selected-flag:hover {
    border-radius: 8px 0 0 8px !important;
  }
`;

const StyledInput = styled(Input)`
  height: 33px;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .wrapper {
    width: 50%;
  }
  .ant-picker-range {
    padding-inline: 4px !important;
  }
  .ant-picker-clear {
    right: 3px !important;
  }
  .ant-picker-input {
    max-height: 22px !important;
    &:first-child {
      padding-left: 2px !important;
    }
    &:last-child {
      padding-right: 2px !important;
    }
  }
`;

const StyledRow = styled(Row)`
  max-height: 190px;
  overflow-y: auto;
  min-width: 585px;
`;

const StylesError = styled.p`
  font-size: 10px;
  margin-bottom: 0;
`;

const DropdownLabel = styled.div`
  color: #4e5156;
  margin-top: 19px;
  font-size: 12px;
`;

const DropdownIcon = styled.div`
  position: absolute;
  color: #121212;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledDropdown = styled(Dropdown)`
  background-color: #fff;
  height: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
  padding: 0 16px;
  color: #121212;
  cursor: pointer;
  position: relative;
  margin-top: 7px;
  margin-bottom: 24px;
  @media screen and (max-width: 820px) {
    width: auto;
    max-width: 380px;
  }
  &:hover {
    background-color: rgb(237, 237, 241);
  }
  &.selected {
    background-color: rgb(237, 237, 241);
  }
`;

const StyledMenu = styled(Menu)`
  background-color: #fff;
  color: #e8e8e8;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(237, 237, 241);
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: rgba(78, 81, 86, 0.5);
    border-radius: 99px;
  }
  &::-webkit-scrollbar-track {
    width: 8px;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  background-color: #fff;
  font-weight: 500;
  &:hover {
    background-color: rgb(237, 237, 241);
  }
  &.selected {
    background-color: rgb(237, 237, 241);
  }
`;

const SelectedMenuItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #121212;
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2ac155;
    border-color: #2ac155;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }
`;

const PaymentWrapper = styled.div`
  @media screen and (max-width: 600px) {
    overflow-x: scroll;
    max-width: 100%;
  }
`;

const BannerContainer = styled.div`
  background-color: #2d6cca26;
  color: #121212;
  height: 28px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    width: auto;
  }
`;

const StyledDiscount = styled.div`
  font-size: 14px;
  color: #4e5156;
  line-height: 16px;
  font-weight: 400;
  margin-left: 8px;
  margin-top: 10px;

  span {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const Header = styled.div`
  font-size: 12px;
  font-weight: 400;
  height: 28px;
  color: #4e5156;
  padding-left: 10px;
  @media screen and (max-width: 570px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 7px;
  padding: 8px 0;
  padding-left: 8px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  &:nth-last-child(1) {
    border-bottom: none;
    margin-bottom: 0;
  }
  @media screen and (max-width: 570px) {
    width: 100%;
  }
  &:hover {
    background-color: #e8e8e8;
  }
`;

const Column = styled.div<{isFirst?: boolean}>`
  width: 50%; 
  display: flex;
  align-items: center;
  @media screen and (max-width: 570px) {
    ${p => p?.isFirst ? 'width: 60% !important; min-width: 60% !important' : 'width: auto !important;'}
  }
`;

const ImageDiv = styled.div`
  height: 28px;
  margin-right: 9px;
`;

const RowText = styled.div`
  color: #121212;
  font-size: 14px;
  font-weight: 500px;
`;

const Tag = styled.div`
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 500px;
  color: #ffffff;
  margin-left: 10px;
  align-items: center;
`;
const LocationDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const LocationContainer = styled.div`
  background-color: #fff;
  color: #E8E8E8;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.15);
  max-height: 100px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.8);
      border-radius: 12px;
    }
            
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
`;
