import escapeRegExp from 'lodash/escapeRegExp';


const punctuation = '!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~';

export const specialCharactersRegex = /[^a-zA-Z0-9 _\s]/; // matches letters, numbers, space and enter

export const findKeywordRegex = /[\n,]/;

export const tagsRegex = new RegExp('(https://|http://)', 'g');

export const keywordNewLineReg = /\n/g;

export const emailNewLineReg = /\n/g;

export const emailReg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const emailRegexWithoutCommma = /^[^\s@,]+@[^\s@,]+\.[^\s@,]+$/;
export const emailRegexwithPlus = /^[\w-.]+[\w-+]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const emailRegShare = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


export const psiRegex = /\n/g;

export const sanitizeRegex = new RegExp(`^(?:https?://)?(?:www.)?`, 'g');

export const cleanUrlRegex = new RegExp(`^(?:https?://|http://)?(?:www.)?`, 'g');

export const punctuationRegex = new RegExp(`[${escapeRegExp(punctuation)}]`, 'g');

export const urlRegexV2 = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:\d+)?(\/[^\s]*)?(\?[a-zA-Z0-9&=]*)?$/; // Url Regex in which protocol is must and it can accept domain and subdomain and also params
export const urlValidationRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/ig;
export const urlValidationRegex2 = /^([a-z][a-z0-9*\-.]*):\/\/(?:(?:(?:[\w.\-+!$&'()*+,;=]|%[0-9a-f]{2})+:)*(?:[\w.\-+%!$&'()*+,;=]|%[0-9a-f]{2})+@)?(?:(?:[a-z0-9\-.]|%[0-9a-f]{2})+|(?:\[(?:[0-9a-f]{0,4}:)*(?:[0-9a-f]{0,4})\]))(?::[0-9]+)?(?:[/|?](?:[\w#!:.?+=&@!$'~*,;/()[\]-]|%[0-9a-f]{2})*)?$/ig;
// eslint-disable-next-line
export const urlValidationRegexMultiple=/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ig;
// eslint-disable-next-line
export const urlValidationRegexSingle=/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
// eslint-disable-next-line max-len
export const urlWithHttp = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/ig;
export const urlDomainRegex = /^(?:http(s)?:\/\/)?(www.)?([\w-]+\.)[\w-]+(\/?)+(\.?)$/ig;
export const urlSubDomainRegex = /^(?:http(s)?:\/\/)?([\w-]+\.){2,}[\w-]+$/ig;
export const targetPageRegex = /^(?:http(s)?:\/\/)?([\w-]+\.)[\w-]+[\w\-._~:/?#[\]@!$&'%^(){}"<>*+|,;=]+$/ig;
export const targetPageRegexV2 = /^(?:http(s)?:\/\/)?(?:([\w-]+)\.)?([\w-]+\.[\w-]+)(?:\/[\w\-._~]*)?\/?$/i;
export const targetPageRegexV3 = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(?:\/[a-zA-Z0-9-_.~!*'();:@&=+$,/?#[\]]+)?$/;
export const subFolderRegex = /^(?:http(s)?:\/\/)?([\w-]+\.)[\w-]+[\w\-._~:/?#[\]@!$&'%^(){}"<>*+|,;=]+$/ig;
export const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const phoneValidationRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export const phoneValidationRegex2 = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const removeNotePadTags = /(\r|\n|\r\n|\n\r)?$/g;

export const multiWordBoundaryRegex =normalizedTerms=>{
  return new RegExp(String.raw`\b(?:${normalizedTerms})\b`, 'g');
};

export const googleSheetUrlValidationRegex = new RegExp('(docs.google.com|(http|https))(://[A-Za-z]+-my.sharepoint.com)?', 'i');

export const multiWordRegex =normalizedTerms=>{
  return new RegExp(`(^|[^\\w])(${normalizedTerms})($|[^\\w])`, 'g');
};

export const highlightKeywordRegex =keyword=>{
  const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(`(^|[^\\w])(${escapedKeyword})($|[^\\w])`, 'g');
};

export const containLetterAndSpace = /^[a-zA-Z0-9\s]*$/;
export const urlValidationRegexV3 = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;
export const urlWithProtocolRegex = /^https?:\/\/(www\.)?[a-zA-Z0-9-_]+\.(?:[a-zA-Z]{2,}|[a-zA-Z]{2}\.[a-zA-Z]{2,})(\/[a-zA-Z0-9-_]*)*\/?$/;

// eslint-disable-next-line no-useless-escape
export const domainValidationRegex = /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)(\/(?!\/)[\da-z\-_\.?&=#]*)*[\/\?]?$/i;

export const googleMapsUrlRegex = /^https:\/\/(?:www\.)?google\.[a-z]{2,3}(?:\.[a-z]{2})?\/maps\/place\/([^?]+)?(?:\?.*)?$/;
