import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Spin, Tooltip} from 'antd';
import {Button} from '@/components/common-components/v2/Button';
import {faArrowRight, faCircleInfo, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {notification} from '@/utils/notifications';
import {notification as notificationV2} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {StyledWidgetModal} from '.';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react';
import {StepOne} from './Steps/stepOne';
import {StepTwo} from './Steps/stepTwo';
import {StepThree} from './Steps/stepThree';
import {apiError} from '@/utils/api';
import {LoadingOutlined} from '@ant-design/icons';
import {addProtocolToDomainHttps} from '@/utils/url';
import {formatCrawlRules} from '../site-audit-v2/audited-sites/siteCard';
import {PRO_PLAN_ID} from '@/constants';

interface Props {
  isWidgetModalVisible: boolean;
  setIsWidgetModalVisible: Dispatch<SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  uuid: string;
  setUuid: Dispatch<SetStateAction<string>>;
}

const WidgetModal: React.FC<Props> = observer(({isWidgetModalVisible, setIsWidgetModalVisible, activeTab, setActiveTab, uuid, setUuid}) => {
  const {
    ottoStore: {
      seoBtnLoaing,
      setDomainLoading,
    },
    ottoV2Store: {
      createOttoProject,
      creatingOttoProject,
      updateOttoV2Project,
      setDefaultParams,
      defaultParams,
      ottoKnowledgeGraphFieldsMeta,
      getKnowledgeGraphFieldsMeta,
      loadOttoV2Project,
      resetOttoV2Project,
    },
    siteAuditorV2: {
      auditedSitesList,
      setCrawelSettingDetail,
      postSiteAudit,
      setLoadProjectDetails,
    },
    settings: {customer: {getCustomerQuota, profile: {isWhitelabel, whitelabelOtto, plan}}},
    gsc: {googleAnalytics: {gaDataModal}},
  } = useStore('');
  const tabsTitle = [
    'Select a website',
    'Setting up',
    `Install ${whitelabelOtto} Pixel`,
  ];
  const [newSiteLoading, setNewSiteLoading] = useState(false);
  const [userAgent, setUserAgent] = useState<string>('searchatlas');
  const [selectedSite, setSelectedSite] = useState(-1);
  const [crawlSpeedVal, setCrawlSpeedVal] = useState(5);
  const [crawlBudgetVal, setCrawlBudgetVal] = useState<number>(100);
  const router = useRouter();
  const isPublicPage = router?.pathname?.includes('public');
  const [existSiteAudit, setExistSiteAudit] = useState<string>('existing-site');
  const [widgetStatus, setWidgetStatus] = useState(-1);
  const [crawlSelectOption, setCrawlSelectOption] = useState(isPublicPage ? 'daily' : 'weekly');
  const [selectedAiModel, setSelectedAiModel] = useState('openai');
  const [crawlFrequency, setCrawlFrequency] = useState('1');
  const [pagesToCrawlError, setPagesToCrawlError] = useState(false);
  const [crawlSpeedError, setCrawlSpeedError] = useState(false);
  const [frequencyError, setFrequencyError] = useState(false);
  const [inputVal, setInputVal]= useState<string>('');
  const [inputValue, setInputValue] = useState('');
  const [maxAccessTab, setMaxAccessTab] = useState(0);
  const [knowledgeGraphSections, setKnowledgeGraphSections] = useState([]);
  const [siteProperty, setSiteProperty] = useState('');
  const [gmbSelected, setGmbSelected] = useState(null);
  const [serpLocation, setSerpLocation] = useState();
  const [gbpIdsArray, setGbpIdsArray] = useState([]);
  const [country, setCountry] = useState(gaDataModal?.countryCode);
  const [crawlCriteria, setCrawlCriteria] = useState<boolean>(false);
  const [language, setLanguage] = useState<{language: string; code: string}>({language: 'Automatic Detection', code: 'None'});
  const [domainError, setDomainError] = useState({
    message: '',
    errorState: false,
  });
  const [conditions, setConditions] = useState([{action: 'Exclude', rule: 'Contain', value: ''}]);

  const [form] = Form.useForm();

  const isCrawlFrequencyError = () => {
    return plan !== PRO_PLAN_ID && crawlFrequency && Number(crawlFrequency) > 0 && Number(crawlFrequency) < 7;
  };

  const onClose = () => {
    setIsWidgetModalVisible(false);
    setWidgetStatus(-1);
    setInputVal('');
    setExistSiteAudit('existing-site');
    setInputValue('');
    setActiveTab(0);
    setMaxAccessTab(0);
    !router?.pathname.includes('task') && resetOttoV2Project(null);
    form.setFieldsValue({
      siteproperty: '',
    });
    form.resetFields();
    setMaxAccessTab(0);
    setSelectedSite(-1);
    setGbpIdsArray([]);
    setConditions([{action: 'Exclude', rule: 'Contain', value: ''}]);
  };

  useEffect(() => {
    if (crawlSelectOption) {
      switch (crawlSelectOption) {
        case 'daily':
          setCrawlFrequency('1');
          break;
        case 'weekly':
          setCrawlFrequency('7');
          break;
        case 'monthly':
          setCrawlFrequency('30');
          break;
        case 'never':
          setCrawlFrequency('0');
          break;
        default:
          break;
      }
    }
    if (crawlSelectOption == 'Custom' && (crawlFrequency == '' || crawlFrequency == '0')) {
      setFrequencyError(true);
    } else {
      setFrequencyError(false);
    }
  }, [crawlSelectOption]);

  useEffect(() => {
    if (inputValue || inputVal) {
      setWidgetStatus(-1);
    }
  }, [inputValue, inputVal]);


  const onCrawlSpeedChange = (value: any) => {
    setCrawlSpeedVal(value);
  };

  const auditedSite = auditedSitesList?.find(site => site.id === selectedSite);

  const createProject = async (id:number) => {
    const res = await createOttoProject({id, readyForProcessing: false});
    if (res && res?.uuid) {
      setUuid(res.uuid);
    }
    setDefaultParams({search: '', pageSize: defaultParams.pageSize, page: 1});
  };

  // const checkUrl = async url => {
  //   const checkProtocol = url.match(urlValidationRegex2);
  //   const addProtocol = (!/^(https?:)?\/\//i.test(url)) ? 'https://' + url : url;
  //   try {
  //     const checkTargetPage = new URL(addProtocol);
  //     if (!checkProtocol || checkTargetPage?.pathname != '/') {
  //       setErrorAndUpdateUrl(`Please enter a valid domain name like =${checkTargetPage?.protocol + checkTargetPage?.pathname}`);
  //     } else {
  //       setNewSiteLoading(true);
  //       const dataToPatch = {
  //         selected_user_agent: userAgent,
  //         crawl_concurrency: crawlSpeedVal,
  //       };
  //       if (!isPublicPage) {
  //         dataToPatch['crawl_budget'] = crawlBudgetVal;
  //         dataToPatch['next_processing_delta_days'] = crawlFrequency;
  //         dataToPatch['crawl_select_option'] = crawlSelectOption;
  //       }
  //       setCrawelSettingDetail(dataToPatch);
  //       let newSite;
  //       if (url?.length > 0 ) {
  //         newSite = await postSiteAudit(url?.replace('sc-domain:', 'https://'));
  //         setLoadProjectDetails(true);
  //         if (crawlBudgetError?.length==0) {
  //           onClose();
  //         }
  //         router?.pathname == '/otto-page' && notification.success('New tasks will get populated soon.');
  //         if (newSite?.id) {
  //           await createProject(newSite?.id);
  //         }
  //         setNewSiteLoading(false);
  //         setIsWidgetModalVisible(false);
  //         setStatusState(-1);
  //         setWidgetStatus(-1);
  //         setInputVal('');
  //         if (!isPublicPage) {
  //           await getCustomerQuota();
  //         }
  //         await loadAuditedSitesList();
  //         onClose();
  //       }
  //     }
  //   } catch (e) {
  //     const errorMessage = apiError(e) as string;
  //     notification.error(errorMessage);
  //     setNewSiteLoading(false);
  //   }
  // };


  useEffect(() => {
    const topPages = {name: 'top_pages', label: 'Top pages', value: []};
    const sections = toJS(ottoKnowledgeGraphFieldsMeta?.value);
    const alreadyExist = sections?.find(item => item.name === 'top_pages');
    if (!alreadyExist) {
      sections?.splice(1, 0, topPages);
    }
    setKnowledgeGraphSections(sections);
  }, [ottoKnowledgeGraphFieldsMeta]);
  useEffect(() => {
    getKnowledgeGraphFieldsMeta();
  }, []);

  const stepOne = async () => {
    if (existSiteAudit === 'existing-site') {
      createProject(auditedSite?.id);
      // if (statusState === -1) {
      //   embedWidget();
      // } else {
      //   setIsWidgetModalVisible(false);
      //   setStatusState(-1);
      //   setWidgetStatus(-1);
      //   setInputVal('');
      //   setInputValue('');
      //   setExistSiteAudit('existing-site');
      //   setErrorMsg('');
      // }
    } else {
      if (domainError.errorState) {
        return;
      } else {
        setNewSiteLoading(true);
        const dataToPatch = {
          selected_user_agent: userAgent,
          crawl_concurrency: crawlSpeedVal,
          should_respect_robots_txt: crawlCriteria,
          crawl_rules: formatCrawlRules(conditions),
        };
        if (!isPublicPage) {
          dataToPatch['crawl_budget'] = crawlBudgetVal;
          dataToPatch['next_processing_delta_days'] = crawlFrequency;
          dataToPatch['crawl_select_option'] = crawlSelectOption;
        }
        setCrawelSettingDetail(dataToPatch);
        let newSite;
        if (inputValue?.length > 0 ) {
          newSite = await postSiteAudit(addProtocolToDomainHttps(inputValue?.replace('sc-domain:', 'https://')));
          setLoadProjectDetails(true);
          router?.pathname == '/otto-page' && notification.success('New tasks will get populated soon.');
          if (newSite?.id) {
            await createProject(newSite?.id);
          }
          setNewSiteLoading(false);
          setWidgetStatus(-1);
        }
      }
    }
    setMaxAccessTab(activeTab + 1);
    setActiveTab(activeTab + 1);
  };
  const openInOtto = item => {
    onClose();
    setDomainLoading();
    router?.push(`/otto-page-v2/tasks?uuid=${item?.ottoProjectUuid}`);
  };
  const stepTwo = async values => {
    const payload={
      'background_info': values?.business_description,
      'llm_provider': selectedAiModel,
      'knowledge_graph': values?.knowledgeGraph,
      'ready_for_processing': true,
      'language_code': language.code === 'None' ? null : language.code,
      ...((gbpIdsArray.length || siteProperty.length) ? {google_integrations: []}: {}),
    };
    if (siteProperty.length) {
      payload['google_integrations'].push({
        service: 'gsc',
        property_url: siteProperty,
        country_code: country ? country : '',
      });
    }
    if (gbpIdsArray.length) {
      payload['google_integrations'].push({
        service: 'gbp',
        location_ids: gbpIdsArray,
      });
    }
    setNewSiteLoading(true);
    await updateOttoV2Project(uuid, payload);
    await loadOttoV2Project(uuid, true);
    setDefaultParams({search: '', pageSize: defaultParams.pageSize, page: 1});
    setNewSiteLoading(false);
    setMaxAccessTab(activeTab + 1);
    setActiveTab(activeTab + 1);
  };

  const nextStep = async values => {
    try {
      if (activeTab == 0) {
        await stepOne();
      } else if (activeTab === 1) {
        await stepTwo(values);
      } else {
        setMaxAccessTab(0);
        setActiveTab(0);
        onClose();
        form.resetFields();
        await getCustomerQuota();
        setIsWidgetModalVisible(false);
      }
    } catch (e) {
      const errorMessage = apiError(e, '', true) as string;
      if (errorMessage === `Non Field Errors : Invalid URL ${inputValue}: Status code -> 401`) {
        notificationV2.error('Error', `Unauthorized Access: Our crawler was blocked from auditing ${inputValue} due to a 401 error (Authentication needed).`, false, 'OK');
      } else {
        notificationV2.error('Error', errorMessage, false, 'OK');
      }
      setNewSiteLoading(false);
    }
  };
  const steps = [
    <StepOne
      key={'step-1'}
      existSiteAudit={existSiteAudit}
      setExistSiteAudit={setExistSiteAudit}
      setInputVal={setInputVal}
      form={form}
      userAgent={userAgent}
      crawlBudgetVal={crawlBudgetVal}
      pagesToCrawlError={pagesToCrawlError}
      crawlSpeedVal={crawlSpeedVal}
      onCrawlSpeedChange={onCrawlSpeedChange}
      crawlSpeedError={crawlSpeedError}
      crawlSelectOption={crawlSelectOption}
      setCrawlSelectOption={setCrawlSelectOption}
      crawlFrequency={crawlFrequency}
      inputValue={inputValue}
      setInputValue={setInputValue}
      setWidgetStatus={setWidgetStatus}
      selectedSite={selectedSite}
      setSelectedSite={setSelectedSite}
      setUserAgent={setUserAgent}
      setCrawlBudgetVal={setCrawlBudgetVal}
      setPagesToCrawlError={setPagesToCrawlError}
      setCrawlSpeedVal={setCrawlSpeedVal}
      setCrawlSpeedError={setCrawlSpeedError}
      setCrawlFrequency={setCrawlFrequency}
      setFrequencyError={setFrequencyError}
      domainError={domainError}
      setDomainError={setDomainError}
      openInOtto={openInOtto}
      conditions={conditions}
      setConditions={setConditions}
      customCrawlError={isCrawlFrequencyError()}
      setCrawlCriteria={setCrawlCriteria}
      crawlCriteria={crawlCriteria}
    />,
    <StepTwo
      key={'step-2'}
      selectedAiModel={selectedAiModel}
      setSelectedAiModel={setSelectedAiModel}
      knowledgeGraphSections={knowledgeGraphSections}
      form={form}
      serpLocation={serpLocation}
      setSerpLocation={setSerpLocation}
      siteProperty={siteProperty}
      setSiteProperty={setSiteProperty}
      gmbSelected={gmbSelected}
      setGmbSelected={setGmbSelected}
      gbpIdsArray={gbpIdsArray}
      setGbpIdsArray={setGbpIdsArray}
      country={country}
      setCountry={setCountry}
      language={language}
      setLanguage={setLanguage}
    />,
    <StepThree
      key={'step-3'}
      widgetStatus={widgetStatus}
      setWidgetStatus={setWidgetStatus}
      existSiteAudit={existSiteAudit}
      inputVal={inputVal}
      inputValue={inputValue}
    />,
  ];
  return (
    <StyledWidgetModal
      width={'550px'}
      closable={true}
      onClose={onClose}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/></div>}
      visible={isWidgetModalVisible}
      style={{top: '50%', transform: 'translateY(-50%)', marginTop: '0px', marginBottom: '0px', maxHeight: '96vh'}}
      zIndex={10}
    >
      <div className={'content-wrapper'}>
        <div className={'body-heading'}>{`Add ${whitelabelOtto} Site`}</div>
        {/* <div className={'site'}>Site Audit</div> */}
        <div className='steps-wrapper'>
          <div className='steps'>
            {tabsTitle.map((_, index) => (
              <div
                key={index}
                className={`section ${
                  index <= activeTab ? 'active-section ' : ''} ${
                  index <= maxAccessTab ? '' : ' restricted'
                }`}
                // onClick={() => index <= maxAccessTab && setActiveTab(index)}
              >
                <div className='step'>{`Step ${index + 1}`}</div>
                <div className={`step ${index <= activeTab ? 'active' : ''}`}>
                  {tabsTitle[index]}
                </div>
              </div>
            ))}
          </div>
          <div className='border-line' />
        </div>
        {!isWhitelabel ? <div className='banner' style={{display: 'flex', alignItems: 'center'}}>
          {activeTab === 0 ? (<>
            <FontAwesomeIcon icon={faCircleInfo} color='#2D6CCA' fontSize={14}/>
            <span style={{lineHeight: 'normal'}}>{`${whitelabelOtto} can be activated exclusively on sites that have undergone a site audit.`}</span>
          </>) : activeTab === 1 ? (<>
            <Spin indicator={<LoadingOutlined style={{fontSize: 18, color: '#2D6CCA'}} spin />}/>
            <span style={{lineHeight: 'normal'}}>{`${whitelabelOtto} Started crawling your site. The fields below can be modified anytime.`}</span>
          </>) : (<>
            <FontAwesomeIcon icon={faCircleInfo} color='#2D6CCA' fontSize={14}/>
            <span>{`Below is a short guide on how to install and activate the ${whitelabelOtto} Pixel effectively.`}</span>
          </>)}
        </div> : ''}
        <Form
          form={form}
          onFinish={nextStep}
        >
          {steps[activeTab]}
          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 25}}>
            <Button background='transparent' textColor='#4E5156' style={{color: 'black'}} onClick={onClose}>Cancel</Button>
            <Tooltip title={(activeTab === 0 && selectedSite == -1 && existSiteAudit !== 'new-site') ? 'Please select a site from the list' : ''}>
              <div>
                <Button
                  background='#2D6CCA'
                  style={{marginRight: 0}}
                  textColor='#FFFFFF'
                  loading={seoBtnLoaing || creatingOttoProject || newSiteLoading}
                  disabled={seoBtnLoaing || creatingOttoProject || pagesToCrawlError || crawlSpeedError || frequencyError || (activeTab === 0 && selectedSite == -1 && existSiteAudit !== 'new-site') || (activeTab == 0 && existSiteAudit == 'new-site' && isCrawlFrequencyError())}
                  htmlType={'submit'}
                  onClick={() => nextStep}>
                Continue <FontAwesomeIcon icon={faArrowRight} fontSize={12} style={{marginLeft: '15px'}} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </Form>
      </div>
    </StyledWidgetModal>
  );
});

export default WidgetModal;

