import moment from 'moment';

export const removeOccurences = function(str: string, occurences: Array<string>) {
  let string = str;
  occurences.forEach(element => {
    string = string.replace(element, '');
  });
  return string;
};

export const truncate = function(str: string, length: number=16, ending: string='...') {
  if (str?.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const kFormatter = function(num: number) {
  return (num >= 1e3) ? +(num / 1e3).toFixed(1) + 'k' : num;
};

export const trim = (text: string, size: number):string => {
  return text && text.length > size ? `${text.slice(0, size)}...` : text;
};

export const domain = (property: string) => {
  return property ? (property.startsWith('https') ? property.split('https')[1] : property.startsWith('http') ? property.split('http')[1] : property) : '';
};

export const titleCase = s =>
  s?.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase());

export const capitalizeFirstLetter = string => {
  if (string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  } else return '';
};

export const toSnakeCase = (text: string) => {
  return text?.replace(/\W+/g, ' ')
    ?.split(/ |\B(?=[A-Z])/)
    ?.map(word => word.toLowerCase())
    ?.join('_');
};

export const humanize = str => {
  let i; const frags = str?.split(str?.includes('_') ? '_' : '-');
  for (i = 0; i < frags?.length || 0; i++) {
    frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
  }
  return frags?.join(' ');
};

export const camelCaseToHumanize = str => {
  return str?.replace(/([A-Z])/g, ' $1')?.trim();
};

export const createDynamicFontAwsomeIcons = str => {
  if (!str) {
    return ['', ''];
  }

  const fontAwsomeIconType = str?.split('-', 1)[0];
  let fontAwsomeIconName = str?.substring(str?.indexOf('-')+1);
  fontAwsomeIconName = (fontAwsomeIconName.indexOf('fa') != 0) ? 'fa'+fontAwsomeIconName : fontAwsomeIconName;

  if (fontAwsomeIconName == 'faGlobal') {
    fontAwsomeIconName = fontAwsomeIconName?.replace('Global', 'Globe');
  }

  return [fontAwsomeIconType, fontAwsomeIconName];
};

export const getLocation = (location?: any)=>{
  if (location?.origin?.includes('dashboard.linkgraph') || location?.origin?.includes('localhost')) {
    return 'SearchAtlas';
  } else {
    return location?.host?.replace('dashboard.', '');
  }
};

export const dateFormatMonth = (str: string, dateTime: boolean = false) => {
  const date = new Date(str);
  // Define months and get month and day values
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  if (dateTime) {
    return `${month} ${day}, ${year} at ${hour}:${minutes}`;
  }
  return `${month} ${day}, ${year}`;
};

export const snakeToCamel = str =>
  str?.toLowerCase()?.replace(/([-_][a-z0-9])/g, group =>
    group
      ?.toUpperCase()
      ?.replace('-', '')
      ?.replace('_', ''),
  );

export const camelToSnakeCase = str => str?.replace(/[A-Z]/g, letter => `_${letter?.toLowerCase()}`);

export function checkPlaceID(input: string): boolean {
  if (!(input.length >= 12)) {
    return false;
  }

  for (let i = 0; i < input.length; i++) {
    if (input[i] === ' ') {
      return false;
    }
  }

  return true;
}

export function extractNumber(text: string): number | null {
  const regex = /(\d+)\s*(?:pts|[xX])\s*(\d+)|(\d+)/i;

  const match = regex.exec(text);

  return match ? parseInt(match[1] || match[3]) : null;
}

export function createArrayOfNumbersAsString(n: number) {
  const result = [];
  for (let i = 1; i <= n; i++) {
    result.push(i.toString());
  }
  return result;
}

export const snakeToCamelCase = (value: string) => {
  try {
    return value?.replace(/_([a-z])/g, (_, letter) => letter?.toUpperCase());
  } catch (error) {
    return value;
  }
};


export const convertKeysToSnakeCase = data => {
  if (Array.isArray(data)) {
    return data?.map(item => convertKeysToSnakeCase(item));
  } else if (typeof data === 'object' && data !== null) {
    const newData = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const newKey = toSnakeCase(key);
        newData[newKey] = convertKeysToSnakeCase(data[key]);
      }
    }
    return newData;
  } else {
    return data;
  }
};
export const convertToHyphenatedLowerCase = (str: string) => {
  // Convert the string to lowercase
  const lowerCaseStr = str.toLowerCase();
  // Replace spaces with hyphens
  const hyphenatedStr = lowerCaseStr.replace(/\s+/g, '-');
  return hyphenatedStr;
};

export const smallFirstLetter = val => {
  return String(val).charAt(0).toLocaleLowerCase() + String(val).slice(1);
};


export function getWeekLabel(date) {
  const currentWeek = moment(date).format('WW');

  function getSuffix(weekNumber) {
    if (weekNumber % 10 === 1 && weekNumber !== 11) {
      return 'st';
    } else if (weekNumber % 10 === 2 && weekNumber !== 12) {
      return 'nd';
    } else if (weekNumber % 10 === 3 && weekNumber !== 13) {
      return 'rd';
    } else {
      return 'th';
    }
  }

  const suffix = getSuffix(currentWeek);

  return currentWeek + suffix + ' week';
}
