import {Collapse, Input, Spin, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {useStore} from '@/store/root-store';
import {GrayViewButton, StyledButton, StyledIssuesCollapse, StyledIssuesTable, StyledTrashIcon, UrlWrapper} from '../../../../style';
// import {debounce} from 'lodash';
import {TableTopBar} from '../../../tableTopBar';
import AutoIndexingSwitch from './autoIndexingSwitch';
import {openUrl} from '@/utils/router';
import {numberWithCommas} from '@/utils/number';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import FreezeWrapper from '../../../freezTooltip';
import {useRouter} from 'next/router';
import {SlugInputWrapper} from '../customUrlBasedIndexing';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import {urlWithProtocolRegex} from '@/constants/regex';
import {notification} from '@/utils/notification-v2';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  showConfirmationPopup: (value: any) => void;
  issueTable: string;
  setOpenSiteMapIndexationModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSitemapUrl?: React.Dispatch<React.SetStateAction<string>>;
  deleteItem: any;
  maxHeight?: string;
  removeTopBar?: boolean;
}
const buttonStyles = {
  bgColor: '#F7F7F8',
  borderRadius: '4px',
  padding: '6px 14px',
  border: '1px solid #E8E8E8',
  color: '#121212',
  fontSize: '13px',
  width: '115px',
};
interface IndexationQuotaStateProps {
  googleConsumedDailyQuota: number;
  googleRemainingDailyQuota: number;
  total: number;
  loadingQuotaForIndexation: boolean;
}
const indexationQuotaInitialState = {
  googleConsumedDailyQuota: 0,
  googleRemainingDailyQuota: 0,
  total: 0,
  loadingQuotaForIndexation: false,
};

export const SiteMapIndexing = observer(({componentIssueType, setPageChanged, setIssueTable, showConfirmationPopup, issueTable, setOpenSiteMapIndexationModal, setSitemapUrl, deleteItem, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    ottoUrlLoader,
    ottoIssueType,
    getOttoUrls,
    getGscEmailStatus,
    getOttoV2Project,
    hasAccess,
    deletingSiteMap,
    loadIssueTableData,
    createIndexSitemap,
  }, settings: {customer: {profile: {customerId}}}} = useStore('');

  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [addRecord, setAddRecord] = useState(false);
  const data = getOttoUrls?.find(i => i?.issueType === componentIssueType)?.issueTable?.results;
  const [indexationQuota, setIndexationQuota] = useState<IndexationQuotaStateProps>(indexationQuotaInitialState);
  const [tableData, setTableData] = useState([]);
  const [addRecordError, setAddRecordError] = useState({
    message: 'Provide valid url',
    state: false,
  });
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [pageUrl, setPageUrl] = useState('');
  const [addingPage, setAddingPage] = useState(false);
  const router = useRouter();

  const filteredData = addRecord ?
    [{id: -1, isFirstObject: true}, ...(data || []).map(item => ({...item, issueType: componentIssueType}))] :
    (data || []).map(item => ({...item, issueType: componentIssueType}));

  useEffect(() => {
    if (filteredData && deleteItem == -1) {
      setTableData(filteredData);
    } else {
      const newData = filteredData?.filter(val=> val?.id !== deleteItem);
      setTableData(newData);
    }
  }, [deleteItem, addRecord, data]);

  const getIndexationQuota = async () => {
    try {
      setIndexationQuota({...indexationQuota, loadingQuotaForIndexation: true});
      const res = await getGscEmailStatus(customerId, getOttoV2Project?.connectedData?.gscDetails?.propertyUrl);
      if (res) {
        setIndexationQuota({...indexationQuota,
          googleConsumedDailyQuota: res?.googleConsumedDailyQuota,
          googleRemainingDailyQuota: res?.googleRemainingDailyQuota,
          total: res?.googleConsumedDailyQuota+res?.googleRemainingDailyQuota,
          loadingQuotaForIndexation: false,
        });
      } else {
        setIndexationQuota({...indexationQuota, loadingQuotaForIndexation: false});
      }
    } catch (err) {
      setIndexationQuota({...indexationQuota, loadingQuotaForIndexation: false});
    }
  };
  useEffect(() => {
    if (customerId) {
      getIndexationQuota();
    }
  }, [customerId]);

  const onChangePageUrl = (value: string) => {
    setPageUrl(value);
    let extractedUrl = null;
    if (value.endsWith('.xml')) {
      extractedUrl = value?.slice(0, -4);
    } else {
      extractedUrl = value;
    }
    const fullUrl = addProtocolToDomainHttps(`${domainName+'/'+extractedUrl}`);
    if (!urlWithProtocolRegex.test(fullUrl)) {
      setAddRecordError({...addRecordError, message: 'Provide valid url.', state: true});
    } else {
      setAddRecordError({...addRecordError, message: 'Provide valid url.', state: false});
    }
  };
  const onAddPage = async () => {
    let fullUrl = '';
    if (pageUrl.startsWith('/')) {
      fullUrl = addProtocolToDomainHttps(`${domainName+pageUrl}`);
    } else {
      fullUrl = addProtocolToDomainHttps(`${domainName+'/'+pageUrl}`);
    }
    if (fullUrl.endsWith('.xml')) {
      const urlWithoutXml = fullUrl.slice(0, -4);
      if (!urlWithProtocolRegex.test(urlWithoutXml)) {
        setAddRecordError({...addRecordError, message: 'Provide valid URL.', state: true});
        return;
      }
      fullUrl = `${urlWithoutXml}.xml`;
    } else {
      setAddRecordError({...addRecordError, message: 'Invalid sitemap please include .xml', state: true});
      return;
    }
    setAddingPage(true);
    const filter = getOttoUrls.filter(d => d.issueType === componentIssueType);
    await createIndexSitemap({sitemap_url: fullUrl});
    await await loadIssueTableData({
      uuid: getOttoV2Project?.uuid,
      page_size: filter?.length ? filter[0].pageSize : 10,
      page: filter?.length ? filter[0].page : 1,
      issue_type: componentIssueType,
      otto_project: getOttoV2Project?.id,
      search: searchText,
    }, true);
    setPageUrl('');
    setAddRecord(false);
    setAddingPage(false);
    notification.success('', `Sitemap added successfully`);
  };

  const columns = [
    {
      title: <div className='column-title'>AUTO INDEXING</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      onCell: record => record?.isFirstObject ? {colSpan: 4} : {},
      width: 125,
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <>
            <SlugInputWrapper style={{borderColor: addRecordError.state && '#ff4d4f'}}>
              <div className='domain'>{domainName}/</div><Input autoFocus className='slug' value={pageUrl} onChange={e => onChangePageUrl(e.target.value)} bordered={false}></Input>
            </SlugInputWrapper>
            {addRecordError.state && <div style={{color: 'red', fontSize: '11px'}}>{addRecordError.message}</div>}
          </>;
        } else {
          return <AutoIndexingSwitch key={record?.id} record={record} componentIssueType={componentIssueType} hasAccess={hasAccess} indexing={false}/>;
        }
      },
    },
    {
      title: <div className='column-title'>SITEMAP</div>,
      dataIndex: 'url',
      key: 'url',
      onCell: record => record?.isFirstObject ? {colSpan: 0} : {},
      width: 400,
      className: 'no-padding-right',
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <></>;
        } else {
          return <UrlWrapper onClick={() => openUrl(record?.url, '_blank')}>{record?.url}</UrlWrapper>;
        }
      },
    },
    {
      title: <div style={{width: '90px'}} className='column-title'>TOTAL PAGES</div>,
      dataIndex: 'status',
      key: 'status',
      onCell: record => record?.isFirstObject ? {colSpan: 0} : {},
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <></>;
        } else {
          return <div>{numberWithCommas(record?.urlCount) || '-'}</div>;
        }
      },
    },
    {
      title: <div style={{width: '150px'}} className='column-title'>AWAITING INDEXATION</div>,
      dataIndex: 'trashIcon',
      key: 'trashIcon',
      onCell: record => record?.isFirstObject ? {colSpan: 0} : {},
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <></>;
        } else {
          return <div>{numberWithCommas(record?.urlUnindexedCount) || '-'}</div>;
        }
      },
    },
    {
      title: '',
      dataIndex: 'viewSiteMap',
      key: 'viewSiteMap',
      className: '',
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
            <Tooltip title={!pageUrl?.trim()?.length ? 'Please enter a Page Url.' : ''}>
              <span>
                <StyledButton disabled={!pageUrl?.trim()?.length || addingPage} onClick={onAddPage} color='green'>Add Page</StyledButton>
              </span>
            </Tooltip>
            <div onClick={() => {
              setAddRecord(false);
              setPageUrl('');
            }}
            style={{padding: '5px 6px', backgroundColor: '#4E5156', borderRadius: '4px', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
              <FontAwesomeIcon icon={faXmark} fontSize={14} color='#fff'/>
            </div>
            {addingPage && <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>}
          </div>;
        } else {
          return (
            <GrayViewButton
              disabled={!record?.isSelected}
              styleObject={buttonStyles}
              buttonType='transparent'
              onClick={() => {
                if (!record?.isSelected) return;
                setOpenSiteMapIndexationModal(true);
                setSitemapUrl(record?.url);
              }}
            >
                View sitemap
            </GrayViewButton>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'trashIcon',
      className: 'text-align-middle',
      width: 40,
      render: (_, record) => {
        if (record?.isFirstObject) {
          return <>-</>;
        } else {
          return (
            <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
              <Tooltip title={'Delete'}>
                {(deleteItem === record?.id && deletingSiteMap) ?
                  <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                  <StyledTrashIcon
                    icon={faTrash}
                    fontSize={14}
                    color='#A3A4A4'
                    onClick={() => {
                      showConfirmationPopup(record);
                    }}
                  />}
              </Tooltip>
            </FreezeWrapper>
          );
        }
      },
    },
  ];

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        {!removeTopBar && <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setAddRecord={setAddRecord}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter}
          hasAccess={hasAccess}
          indexationQuota={indexationQuota}/>}
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns}
            dataSource={tableData}
            scroll={removeTopBar ? {x: 1100, y: 'auto'} : {x: 850}}
            pagination={false}
            maxHeight={maxHeight}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>
    </>
  );
});
