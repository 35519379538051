import {faCircleInfo, faSortDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dropdown, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';

interface NewQuotaDropDownProps {
  quotaTableArray?: {
    quotaTitle: string;
    available: number;
    used: number;
    total: number;
    purchasedQuota?: number | string;
    purchasedQuotaTooltipTxt?: string;
    topupConf?: any;
    quotaInfoTooltip?: React.ReactNode;
    quotaInfoTooltipWidth?: number | string;
  }[];
  placement?: 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'top' | 'bottom';
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  overlayMinWidth?: string;
  children?: React.ReactNode;
  setTopUpBulkVisibility?: (value: any) => void;
  dropDownOpen?: boolean;
  setDropDownOpen?: any;
}


export const NewQuotaDropDown:React.FC<NewQuotaDropDownProps> = observer(({quotaTableArray, setTopUpBulkVisibility, placement='bottomLeft', trigger=['click'], overlayMinWidth='462px', children, dropDownOpen, setDropDownOpen}) => {
  const {
    settings: {
      customer: {
        profile: {
          quotaUtilization,
          whitelabelOtto,
        },
      },
    },
  } = useStore('');

  // const [dropDownOpen, setDropDownOpen] = useState(false);

  const openLiveChat = item => {
    setTopUpBulkVisibility({
      ...item?.topupConf,
      visibility: true,
      consumed: item?.used,
      total: item?.total,
    });
  };
  return (
    <Dropdown
      // @ts-ignore
      open={dropDownOpen}
      onOpenChange={setDropDownOpen}
      overlayStyle={{minWidth: overlayMinWidth}}
      trigger={trigger}
      placement={placement}
      overlay={
        <QuotaOverlayWrapper>
          <div className='content'>
            <table className='quota-table'>
              <thead className='table-head'>
                <tr className='table-row'>
                  <th className='title text-left'>QUOTA</th>
                  <th className='title'>AVAILABLE</th>
                  <th className='title text-right'>USED</th>
                </tr>
              </thead>
              <tbody className='table-body'>
                {quotaTableArray?.map((item, index) => {
                  return (<tr className='table-row' key={index}>
                    <td style={item?.quotaInfoTooltip ? {display: 'flex', alignItems: 'center', gap: 6} : {}} className={`quota-name sans-serif font-14 font-600 color-white`}>{item?.quotaTitle} {item?.quotaInfoTooltip ? (
                      <Tooltip
                        overlayStyle={{maxWidth: 300}}
                        overlayInnerStyle={{background: '#000', borderRadius: 8, width: item?.quotaInfoTooltipWidth ? item?.quotaInfoTooltipWidth : 300}}
                        title={item?.quotaInfoTooltip}
                      >
                        <FontAwesomeIcon style={{cursor: 'pointer'}} size='sm' icon={faCircleInfo} color={'#FFFFFF'} />
                      </Tooltip>
                    ) : ''}</td>
                    <td className={`available-value sans-serif font-14 font-600 ${item?.topupConf?.quotaKey == 'allowed_ai_premium_content_generation' ?
                      ((item?.topupConf?.overAllQuota - (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed)) <= 0 ? 'color-red' : 'color-green') :
                      item?.topupConf?.quotaKey === 'quota_ca_allowed_otto_cloud_stacks' ?
                        ((item?.total + Number(item?.purchasedQuota || 0)) - (quotaUtilization?.ca?.allowedOttoCloudStacks?.consumed + quotaUtilization?.ca?.allowedOttoCloudStacksPurchased?.consumed) <= 0 ? 'color-red' : 'color-green') :
                        item?.available > 0 ? 'color-green' : 'color-red'} text-right`}>{
                        item?.topupConf?.quotaKey == 'allowed_ai_premium_content_generation' ?
                          (item?.total) :
                          item?.topupConf?.quotaKey == 'quota_ca_allowed_otto_cloud_stacks' ?
                            (item?.total) :
                            item?.available}
                    </td>
                    <td className={`used-value sans-serif font-14 font-400 color-gray text-right`}>{item?.topupConf?.quotaKey == 'allowed_ai_premium_content_generation' ? (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed):
                      item?.topupConf?.quotaKey == 'quota_ca_allowed_otto_cloud_stacks' ? (quotaUtilization?.ca?.allowedOttoCloudStacks?.consumed + quotaUtilization?.ca?.allowedOttoCloudStacksPurchased?.consumed) : item?.used}</td>
                    {item?.topupConf && <td className={`top-up-button sans-serif font-14 font-400 text-right`} onClick={() => {
                      openLiveChat(item);
                      typeof setDropDownOpen == 'function' && setDropDownOpen(!dropDownOpen);
                    }}>Top up</td>}
                  </tr>);
                })}
              </tbody>
            </table>
            <div className='banner'>
              <div className='icon'>
                <FontAwesomeIcon icon={faCircleInfo} color='#fff' fontSize={14}/>
              </div>
              <div className='banner-text'>
                <div>{`Individual AI Generation Quota is used to generate titles, descriptions, posts, etc and can be monitored inside each ${whitelabelOtto} Site.`}</div>
                <div>{`AI Premium Quota is used to generate content for the Topic Map and is shared between all ${whitelabelOtto} Sites.`}</div>
                <div>Topic Map will consume 20 AI Quota.</div>
              </div>
            </div>
          </div>
        </QuotaOverlayWrapper>
      }
    >
      {children ? children : <QuotaTag>{`View ${whitelabelOtto} Quotas`} <FontAwesomeIcon icon={faSortDown} color='#34AEF3' fontSize={14}/></QuotaTag>}
    </Dropdown>
  );
});

const QuotaTag = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #34AEF3;
  margin-bottom: 14px;
  cursor: pointer;
`;
const QuotaOverlayWrapper = styled.div`
  background-color: #121212;
  border-radius: 12px;
  width: 460px;
  padding: 10px 14px;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .quota-table {
      margin-bottom: 16px;
      width: 100%;
      .table-head {
        .table-row {
          .text-left {
            text-align: left;
          }
          .text-right {
            text-align: right;
          }
          .title {
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-weight: 600;
            color: #fff;
          }
        }
      }
      .table-body {
        .table-row {
          .sans-serif {
            font-family: 'Inter', sans-serif;
          }
          .font-14 {
            font-size: 14px;
          }
          .font-600 {
            font-weight: 600;
          }
          .font-400 {
            font-weight: 400;
          }
          .color-white {
            color: #fff;
          }
          .color-red {
            color: #F44343;
          }
          .color-green {
            color: #2AC155;
          }
          .color-gray {
            color: #A3A4A4;
          }
          .text-right {
            text-align: right;
          }

          .quota-name {
            width: 156px;
          }
          .available-value {
            width: 66px;
          }
          .used-value {
            width: 63px;
          }
          .total-value {
            width: 63px;
          }
          .top-up-button {
            color: #34AEF3;
            cursor: pointer;
            width: 72px;
          }
          .purchased-quota {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
    .banner {
      padding: 6px 10px;
      background:#4E5156;
      border-radius: 12px;
      display: flex;
      width: 433px;
      gap: 6px;
      .banner-text {
        div {
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: #FFFFFF;
        }
        div:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
