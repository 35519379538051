import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleExclamation, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {Tooltip} from 'antd';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import FreezeWrapper from '../../../freezTooltip';
import {PopupContainer} from '../styles';
import {SuggestionButton} from '../styles';
import moment from 'moment';

const PopupField = observer(({field, openModal, suggestion}) => {
  const [iconDetails, setIconDetails] = useState({
    color: '#F44343',
    icon: faCircleExclamation,
  });

  useEffect(() => {
    if (field?.value) {
      if (!suggestion || suggestion?.value?.length == 0 || compareSuggestions()) {
        setIconDetails({
          color: '#2AC155',
          icon: faCircleCheck,
        });
      } else {
        setIconDetails({
          color: '#FF8536',
          icon: faTriangleExclamation,
        });
      }
    } else {
      setIconDetails({
        color: '#F44343',
        icon: faCircleExclamation,
      });
    }
  }, [field?.value]);

  const extractValue = item => {
    if (item) {
      if (typeof(item) == 'string') {
        return item;
      } else {
        if (Object.keys(item)?.includes('label')) {
          return item.label;
        } else if (Object.keys(item)?.includes('displayName')) {
          return item.displayName;
        } else if (Object.keys(item)?.includes('serviceTypeId')) {
          return item.serviceTypeId?.label;
        } else if (Object.keys(item)?.includes('value')) {
          return item.value;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };

  const compareSuggestions = () => {
    switch (field.fieldName) {
      case 'categories':
        return suggestion?.value?.every(item => field?.value?.some(cat => cat?.key === item?.key)) ?? false;
      case 'services':
        // eslint-disable-next-line
        return !suggestion?.value?.some(item =>
          !field?.value?.some(val => {
            const displayNameMatch = item.displayName && item.displayName === val.displayName;
            const serviceTypeIdMatch = item.serviceTypeId && val.serviceTypeId && item.serviceTypeId.label === val.serviceTypeId.label;
            return displayNameMatch || serviceTypeIdMatch;
          })) || false;
      default:
        return suggestion?.value?.some(val => field?.value?.includes(val));
    }
  };

  const formatDate = dateObj => {
    if (dateObj) {
      const date = dateObj?.day === 0 ?
        moment(new Date(dateObj?.year, dateObj?.month-1)) :
        moment(new Date(dateObj?.year, dateObj?.month-1, dateObj?.day));
      return date.format('MMM DD, YYYY');
    }
  };

  const tooltipSuggestion = (
    <div>
      <div>You have a possible suggestion</div>
      <div style={{marginTop: 5}}>
        <FreezeWrapper>
          <SuggestionButton onClick={() => openModal(suggestion, field?.value)}>View OTTO Suggestion</SuggestionButton>
        </FreezeWrapper>
      </div>
    </div>
  );
  return (
    <>
      <Tooltip title={suggestion?.value?.length && !compareSuggestions() ? tooltipSuggestion : 'No possible suggestions'}
        zIndex={1045}
        overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: (suggestion && (suggestion !== field?.value) && !compareSuggestions()) && 391}}>
        <FontAwesomeIcon
          style={{marginRight: 6}}
          color={iconDetails.color}
          icon={iconDetails.icon}
        />
      </Tooltip>
      <PopupContainer style={{color: (!field?.value && !field?.value?.length) && '#2D6CCA', width: 'calc(100% - 60px)', cursor: (!field?.value && !field?.value?.length) && 'pointer'}}>
        <div onClick={() => (!field?.value && !field?.value?.length) && openModal(suggestion, field?.value)}>
          {(field?.value && (field?.fieldName == 'open_info')) ? !field?.value?.openingDate ? field?.value?.status=='OPEN' ? 'Open' : `Add ${field.label}` : formatDate(field?.value?.openingDate) : field?.value?.length ? (field.fieldName == 'categories' || field.fieldName == 'services') ? field?.value?.map(item => extractValue(item))?.join(', ') :
            field?.value?.map((item, idx) => (
              <div key={field.fieldName + idx}>{extractValue(item)}</div>
            )): `Add ${field.label}`}
        </div>
      </PopupContainer>
      <div className='edit-icon'>
        <FreezeWrapper>
          <Tooltip title='Edit'>
            <div onClick={() => openModal(suggestion, field?.value)}>
              <FontAwesomeIcon
                style={{marginRight: 6}}
                icon={faPen}
                color='#2D6CCA'
              />
          Edit
            </div>
          </Tooltip>
        </FreezeWrapper>
      </div>
    </>
  );
});

export default PopupField;
