import {Pagination, Table} from '@/components/common-components/components';
import {FiltersV2} from '@/components/common-components/v2/filtersV2';
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import styles from '../../style.module.scss';
import {faCircleArrowRight} from '@fortawesome/pro-duotone-svg-icons';
import {faUser} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {
  FlexWrapper,
  HeaderCheckboxContainer,
  HeadingWrapper,
  ImgUser,
  BodyWrapper,
  TextOverflow,
  FirstColumnWrapper,
  CampaignName,
  DateWrapper,
  ActionWrapper,
  ViewButton,
  TableHeaderSeparated,
  TableWrapper,
  PaginationWrapper,
  PaginationSpan,
  RepliedAt} from '../styledComponents';
import {getTimeAgo} from '@/utils/moment';
import {SortOrder} from 'antd/lib/table/interface';
import {useRouter} from 'next/router';
import {inboxTableFilter} from '@/store/inbox/data';
import {getFilterValues, getKey, getValue} from '@/components/dashboard/pages/site-explorer-page/detail-page/components/functions';
import {copyAndModifyQueryParams} from '@/utils/url';
import {faInboxOut, faInboxIn} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import moment from 'moment';
import {Dropdown, Menu} from 'antd';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import FreezeWrapper from '../../../otto-page-detail/Component/freezTooltip';

const statusesSetup = {
  EMAILED: {
    label: 'Emailed',
    icon: faInboxOut,
    iconColor: '#2AC155',
  },
  REPLIED: {
    label: 'Replied',
    icon: faInboxIn,
    iconColor: '#34AEF3',
  },
  lost: {
    label: 'Lost',
    icon: null,
    iconColor: '#F443431A',
  },
  won: {
    label: 'Won',
    icon: null,
    iconColor: '#2AC155',
  },
};

const milestones = [
  {
    label: 'Idle',
    value: 'idle',
  },
  {
    label: 'Got a reply',
    value: 'got_a_reply',
  },
  {
    label: 'Negotiation',
    value: 'negotiation',
  },
  {
    label: 'Lost',
    value: 'lost',
  },
  {
    label: 'Won',
    value: 'won',
  },
];

interface Props {
  isInOTTO?: boolean;
}

export const InboxTable = observer(({isInOTTO}: Props) => {
  const {inbox: {
    getEmailInboxData,
    resetEmailInboxData,
    getInboxTableFilter,
    emailInboxLoading,
    emailInboxData,
    getInboxEmailThread,
    setConversationModalVisible,
    setInboxOrdering,
    allInboxParams,
    setInboxPagination,
    updateInboxFilter,
    updateMilestone,
    getUnreadEmailCount,
  },
  campaigns: {campaignsList, getFullCampaignsList},
  settings: {customer: {profile: {quotaUtilization}}},
  ottoV2Store: {getOttoV2Project}} = useStore('');
  const [activeSort, setActiveSort] = useState('');
  const [campaignsId, setCampaignsId] = useState([]);
  const router = useRouter();
  // local state for storing read emails while api is pending
  const [readEmails, setReadEmails] = useState([]);


  useEffect(() => {
    if (campaignsList?.length>0 && campaignsId?.length<1) {
      setCampaignsId(campaignsList?.map(result => ({label: result?.name || '', value: `${result?.id}`})));
    }
  }, [campaignsList]);

  useEffect(() => {
    if (router?.query) {
      const isOtto = window.location.pathname.includes('otto-page');
      const newArr = [...inboxTableFilter(campaignsId).filter(z=>z?.customCategoryType !== 'status' || isOtto)];
      for (const [key, value] of Object.entries(router.query)) {
        const idx = newArr?.findIndex(item => [item?.name, item?.filterField, item?.customCategoryType].includes(key));
        if (newArr[idx] && value) {
          newArr[idx] = getFilterValues(newArr[idx], value) as any;
        }
      }
      if (isInOTTO) {
        updateInboxFilter(newArr, getOttoV2Project?.id);
      } else {
        updateInboxFilter(newArr);
      }
    }
  }, [router?.query, campaignsId]);

  useEffect(() => {
    getFullCampaignsList();
    if (isInOTTO) {
      getEmailInboxData(false, getOttoV2Project?.id);
    } else {
      getEmailInboxData();
    }
  }, []);

  useEffect(()=>{
    return () => resetEmailInboxData();
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortFieldName} = column || {};
    const sorterKey = order ? `${order == 'descend' ? '-' : ''}${sortFieldName}` : '';
    setActiveSort(sorterKey);
    setInboxOrdering({...allInboxParams, ordering: sorterKey});
  };

  const handleMilestoneChange = async (id, milestone) => {
    await updateMilestone({id: id, milestone: milestone});
    if (isInOTTO) {
      getEmailInboxData(false, getOttoV2Project?.id);
    } else {
      getEmailInboxData();
    }
  };

  const menuItems = id => {
    return (
      <StyledMenu>
        {milestones?.map((milestone, index) => (
          <>
            <Menu.Item key={index} onClick={() => handleMilestoneChange(id, milestone.value)}>
              {milestone.label}
            </Menu.Item>
            {index != milestones.length-1 && <div className='divider'/>}
          </>
        ))}
      </StyledMenu>
    );
  };

  const campaignColumns = [
    {
      title: <HeaderCheckboxContainer><HeadingWrapper>CONTACT</HeadingWrapper></HeaderCheckboxContainer>,
      dataIndex: 'contact',
      key: 'contact',
      sortFieldName: 'contact',
      sortOrder: (activeSort?.includes('contact') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      align: 'left' as 'left',
      fixed: 'left' as 'left',
      width: 120,
      onCell: record => ({
        onClick: () => {
          getInboxEmailThread(record?.id);
          setConversationModalVisible(true);
        },
        style: {cursor: 'pointer'},
      }),
      render: (_, record) => {
        return (
          <FlexWrapper>
            {record.photo_url? <ImgUser src={record.photo_url} alt='' /> : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 34, height: 34, borderRadius: 8, background: '#F2F2F5'}}><FontAwesomeIcon icon={faUser} color='#DBDBDB' fontSize={18}/></div>}
            <FirstColumnWrapper>
              <a onClick={() => {
                // setConversationModalVisible(true);
              }}>
                <CampaignName>
                  <span>{record.contactFirstName || record?.contactLastName ? `${record.contactFirstName} ${record?.contactLastName}` : '-'}</span> <FontAwesomeIcon icon={faCircleArrowRight} color='#2D6CCA'/>
                </CampaignName>
              </a>
              <DateWrapper>{record?.contactEmail}</DateWrapper>
            </FirstColumnWrapper>
          </FlexWrapper>
        );
      },
    },
    {
      title: <div style={{textAlign: 'left'}}>{('Subject').toUpperCase()}</div>,
      dataIndex: 'subject',
      key: 'subject',
      sortFieldName: 'subject',
      sortOrder: (activeSort?.includes('subject') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      align: 'left' as 'left',
      width: 120,
      render: (_, record) => {
        return (
          <div style={{marginLeft: '20px'}}>
            <div>
              <TextOverflow isBoldTxt={!record?.isRead && !readEmails.includes(record?.id)}>{record.subject}</TextOverflow>
              <BodyWrapper isUnread={!record?.isRead && !readEmails.includes(record?.id)} dangerouslySetInnerHTML={{__html: record.body}} />
            </div>
          </div>
        );
      },
    },
    {
      title: <div>LAST<br/>REPLY</div>,
      dataIndex: 'lastReply',
      key: 'lastReply',
      sortFieldName: 'replied_at',
      sortOrder: (activeSort?.includes('replied_at') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      align: 'left' as 'left',
      width: 80,
      render: (_, record) => {
        const parsedDate = moment(record?.repliedAt);
        const formattedDate = parsedDate.format('MMM DD [at] h:mmA');
        return (<>
          {record?.repliedAt ? <>
            <RepliedAt isBoldTxt={!record?.isRead && !readEmails.includes(record?.id)}>{getTimeAgo(record?.repliedAt)}</RepliedAt>
            <div style={{color: '#4E5156'}}>{formattedDate}</div>
          </> : <div>-</div>}
        </>
        );
      },
    },
    {
      title: <div>CAMPAIGN/<br/>OPPORTUNITY</div>,
      dataIndex: 'campaignOpportunity',
      key: 'campaignOpportunity',
      sortFieldName: 'campaign',
      sortOrder: (activeSort?.includes('campaign') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      align: 'left' as 'left',
      width: 80,
      render: (_, record) => {
        return (
          <>
            <TextOverflow>{record.campaignName}</TextOverflow>
            <a>
              <CampaignName>
                <span>{record.campaignUrl ? record.campaignUrl : ''}</span>
              </CampaignName>
            </a>
          </>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'left' as 'left',
      sorter: false,
      width: 80,
      render: (_, record) => {
        const styleConfig = statusesSetup[record?.isReplied ? 'REPLIED' : 'EMAILED'];
        return (
          <CurrentStatus milestone={false} color={styleConfig?.iconColor}>
            {styleConfig?.icon ? <FontAwesomeIcon icon={styleConfig?.icon} color={styleConfig?.iconColor} fontSize={14} /> : ''}
            <CurrentStatusLabel>{styleConfig?.label}</CurrentStatusLabel>
          </CurrentStatus>
        );
      },
    },
    {
      title: 'MILESTONE',
      dataIndex: 'Milestone',
      key: 'Milestone',
      align: 'left' as 'left',
      sortFieldName: 'milestone',
      sortOrder: (activeSort?.includes('milestone') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
      sorter: true,
      width: 80,
      render: (_, record) => {
        const styleConfig = statusesSetup[record?.milestone || ''];
        const milestone = milestones.find(m => m.value == record?.milestone)?.label;
        return (
          <Dropdown overlay={() => menuItems(record?.id)} trigger={['click']} placement='bottomLeft'>
            <div>
              <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
                <CurrentStatus milestone={true} color={styleConfig?.iconColor || '#F2F2F5'} noOpacity={!styleConfig}>
                  <CurrentStatusLabel>{milestone || '-'}</CurrentStatusLabel>
                  <FontAwesomeIcon icon={faCaretDown} fontSize={10} style={{marginLeft: '10px'}} />
                </CurrentStatus>
              </FreezeWrapper>
            </div>
          </Dropdown>
        );
      },
    },
    ...(!isInOTTO ? [{
      title: '',
      dataIndex: '',
      sorter: false,
      width: 80,
      skeletonAlign: 'flex-end',
      render: (_, record) => (
        <ActionWrapper>
          <ViewButton buttonType={'transparent'} size='sm' onClick={async () => {
            setConversationModalVisible(true);
            if (!record?.isRead) setReadEmails(prev => [...prev, record?.id]);
            await getInboxEmailThread(record?.id);
            getUnreadEmailCount();
            if (!record?.isRead) {
              if (isInOTTO) {
                await getEmailInboxData(true, getOttoV2Project?.id);
              } else {
                await getEmailInboxData(true);
              }
              setReadEmails(prev => prev.filter(id => id != record?.id));
            }
          }}>View</ViewButton>
          {/* <FontAwesomeIcon style={{marginLeft: '18px'}} icon={faEllipsisVertical} onClick={()=> {}}/> */}
        </ActionWrapper>
      ),
    }] : []),
  ];

  const handleFilterChange = filters => {
    const idx = filters?.findIndex(item=> item?.filterField == 'targetPage');
    filters[idx] = {
      ...filters[idx],
      type: 'targetPage',
    } as any;
    const filterParams = filters?.map(z => {
      return {
        key: getKey(z),
        value: getValue(z),
      };
    });
    router.push(`${copyAndModifyQueryParams(filterParams)}`, undefined, {shallow: true});
  };

  return (
    <div>
      <TableHeaderSeparated style={{flexWrap: 'wrap'}}>
        <FiltersV2
          onChange={handleFilterChange}
          width='fit-content'
          mainFilters={getInboxTableFilter}
          hideTopSection={true}
          activeSort={''}
        />
        {isInOTTO && <div style={{marginLeft: 'auto'}}><QuotaInfoDetail tooltipPlacement='bottomRight' consumedData={quotaUtilization?.ke?.allowedOutreachSentEmails?.consumed} totalData={quotaUtilization?.ke?.allowedOutreachSentEmails?.total} heading={'Emails sent'} description='Emails sent' /></div>}
      </TableHeaderSeparated>
      <TableWrapper>
        <Table
          className={styles.tableStyles}
          pagination={false}
          columns={campaignColumns}
          scroll={{x: 600, y: 600}}
          dataSource={emailInboxData?.results}
          loading={emailInboxLoading}
          loadingRows={10}
          onChange={handleTableChange}
        />
        <PaginationWrapper>
          <Pagination
            onChange={(page, pageSize) => setInboxPagination(page, pageSize)}
            total={emailInboxData?.count}
            pageSize={allInboxParams?.page_size}
            current={allInboxParams?.page}
            showSizeChanger
            showTotal= {(total, [from, to])=> {
              return (
                <PaginationSpan>
                  {from}-{to} {'of'} {total} {'results shown'}
                </PaginationSpan>
              );
            }}
            defaultPageSize={10}
            pageSizeOptions={['10', '20', '50', '100']}
            showQuickJumper={false}
            disabled={false}
          />
        </PaginationWrapper>
      </TableWrapper>
    </div>
  );
});

const CurrentStatus = styled.div<{color?: string; noOpacity?: boolean; milestone?:boolean}>`
  display: flex;
  align-items: center;
  background-color: ${p => p?.noOpacity ? p.color : `${p.color}12`};
  padding: 4px 12px;
  border-radius: 6px;
  width: fit-content;
  cursor: ${p => p?.milestone ? 'pointer' : 'arrow'}
`;
const CurrentStatusLabel = styled.div`
  margin-left: 5px;
`;

const StyledMenu = styled(Menu)`
  border-radius: 5px !important;
  .divider {
    width: 100%;
    height: 1px;
    background: #D9D9D9;
  }
`;
