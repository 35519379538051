import React, {useEffect, useState} from 'react';
import UserMenu from '@/components/layoutV2/user-menu';
import {observer} from 'mobx-react-lite';
import styles from './style.module.scss';
import classnames from 'classnames';
import {RootStoreType, useStore} from '@/store/root-store';
import {Popover, notification as antdNotification} from 'antd';
import {useRouter} from 'next/router';
import {
  getKnowledgeAcademyLink,
  getSearchAtlasKnowledgeAcademyLink,
} from '@/utils/legacy';
import {PendingInvoiceModal} from '@/components/dashboard/billing/modals/pending-invoices';
import {ReadOutlined} from '@ant-design/icons';
import {ProjectActiveModal} from '@/components/common-components/components/project-active-modal/project-active';
import {ConfettiAnimation} from '@/components/common-components/components/confetti-animation';
import firebase from 'firebase/app';
import 'firebase/database';
import {getSingleUrlParam} from '@/utils/url';
import {faGear} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MenuItemsBar} from '../menu/menuItems';
import {useLocalStorage} from '@/utils/hooks/useLocalStorage';
import styled from 'styled-components';
import {NoSsr} from '@/components/common-components';
import {getTokenFromCookies} from '@/api/common-utils';
import {routes} from '@/utils/const';
import {faBell, faMicrochipAi} from '@fortawesome/pro-duotone-svg-icons';
import Link from 'next/link';
import {ScaTopUpQuotaModal} from './headerComponents/scaTopUpQuotaModal';
import NotificationsPopup from './notifications-popup';
import WhatsNewModal from './whats-new-modal';
import moment from 'moment';
import {NextImg} from '@/utils/nextImg';
import {SmallBadge} from '@/components/common-components/components/badge';

type HeaderProps = {
  store: RootStoreType;
  handleSideBarItems?: (items) => void;
};

const config = {
  apiKey: 'AIzaSyAAnv2XuzvhHREv_x9tPOKgtEhCgCpkRzo',
  authDomain: 'organic-ruler-207123.firebaseapp.com',
  databaseURL: 'https://organic-ruler-207123-default-rtdb.firebaseio.com',
  projectId: 'organic-ruler-207123',
  storageBucket: 'organic-ruler-207123.appspot.com',
  messagingSenderId: '88261415951',
  appId: '1:88261415951:web:db784e7f23f543a1b3ff7a',
  measurementId: 'G-8CMC6QJNNS',
};

!firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

const Header = observer(({store, handleSideBarItems}: HeaderProps) => {
  const router = useRouter();
  const checkPublicPages = router.pathname?.includes('public');
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [highlightNotification, setHighlightNotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<
    number | null
  >(null);
  const customerIds = getSingleUrlParam('customerId');

  const {
    gsc: {setDrawerVisibility},
    notifications: {setNotifications, triggerNotSeen},
    settings: {
      customer: {
        setAuthModal,
        setShowScaTopUpModal,
        showTopUpConfirmationModal,
        loadProfile,
        loadCustomerNotifications,
        notificationsNextPage,
        customerNotifications: {
          results: {mostRecentlyUpdatedAt, data: notificationsData},
        },
        profile: {isVerified, isViewer, isParentAccount},
      },
    },
    contentOptimizer: {
      currentPage: {isEditorScrolled},
    },
    navBar: {
      setIsDockedSider,
      isDockedSider,
      loadSiteProperties,
      getSiteProperties,
    },
  } = useStore('');

  const setNotificationModalVisibility = (visibility: boolean) => {
    if (visibility) {
      if (unreadNotifications) {
        setHighlightNotification(true);
      }
      setUnreadNotifications(false);
      if (mostRecentlyUpdatedAt) {
        localStorage.setItem('notificationsUpdatedAt', mostRecentlyUpdatedAt);
      }
    } else {
      setHighlightNotification(false);
      setSelectedNotification(null);
    }
    setShowNotificationsModal(visibility);
  };

  const token = getTokenFromCookies();

  useEffect(() => {
    try {
      const showModal = JSON.parse(localStorage.getItem('showUpdates'));
      const notificationsLastUpdatedAt = localStorage.getItem(
        'notificationsUpdatedAt',
      );
      let hasUnreadNotifications = false;
      if (!notificationsLastUpdatedAt) {
        hasUnreadNotifications = true;
      }
      if (
        notificationsLastUpdatedAt?.length > 0 &&
        mostRecentlyUpdatedAt?.length > 0
      ) {
        const newerDate = moment(mostRecentlyUpdatedAt);
        const olderDate = moment(notificationsLastUpdatedAt);
        if (newerDate?.isAfter(olderDate)) {
          hasUnreadNotifications = true;
        }
      }
      if (!notificationsData?.length) {
        hasUnreadNotifications = false;
      }
      setUnreadNotifications(hasUnreadNotifications);
      if (
        showModal &&
        hasUnreadNotifications &&
        notificationsData?.length &&
        !isWhitelabel
      ) {
        setHighlightNotification(true);
        setNotificationModalVisibility(true);
      }
    } catch (error) {
      setNotificationModalVisibility(false);
    }
  }, [mostRecentlyUpdatedAt]);

  const allowedPagesForSitesAPI = ['gsc'];
  const currentPathname = window.location.pathname;
  const isAllowedPage = allowedPagesForSitesAPI.some(page =>
    currentPathname.includes(page),
  );

  useEffect(() => {
    notificationsNextPage === 1 && loadCustomerNotifications();
    if (token) {
      loadProfile();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      if (!getSiteProperties?.length && isAllowedPage) {
        loadSiteProperties();
      }
    }
  }, [token, currentPathname]);

  // Kill all notifications when changing page
  useEffect(() => {
    antdNotification.destroy();
  }, [router]);
  const [isDocked, setIsDocked] = useLocalStorage<boolean>('isDocked', true);

  useEffect(() => {
    setIsDockedSider(isDocked);
  }, [isDocked]);

  const {
    plans: {projectActivePopup},
    settings: {
      customer: {profile},
    },
  } = useStore('');
  const {
    settings: {
      customer: {
        profile: {
          logo,
          isWhitelabel,
          hasCompletedRegistration,
          showConfettiAnimation,
          subscription,
          isVendastaClient,
        },
      },
    },
  } = useStore('');
  const {isSubscriber, id, firebaseKey} = profile;
  const isBacklinkAnalyzer = router.pathname.match(/\/backlink.*/g);
  const showProjectsPicker =
    !store.backLinkAnalyzer.projectStore.loading &&
    store.backLinkAnalyzer.projectStore.currentProject;
  const [customerLogo, setCustomerLogo] = useState(logo);
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isSmallScreen = screenWidth <= 768;
  const isMediumScreen = screenWidth <= 980;

  useEffect(() => {
    setCustomerLogo(logo);
  }, [logo]);

  useEffect(() => {
    if (firebaseKey) {
      const dbRef = firebase.database().ref(firebaseKey).limitToLast(30);
      dbRef.on('value', snapshot => {
        if (snapshot.exists()) {
          const notifications = snapshot.val();
          setNotifications(snapshot.val());
          if (
            !Object.keys(notifications).every(notificationKey =>
              notifications[notificationKey]['was_seen_by_users']?.includes(id),
            )
          ) {
            triggerNotSeen();
          }
        }
      });
    }
  }, [firebaseKey, id, setNotifications, triggerNotSeen]);

  const getProjectFromUrl = () => {
    const backlinkProject = getSingleUrlParam('sp');
    if (backlinkProject) {
      store.backLinkAnalyzer.projectStore.setCurrentProject(
        backlinkProject,
        isSubscriber,
      );
      store.backLinkAnalyzer.refdomainStore.selectCurrentProject(
        backlinkProject,
      );
    }
  };

  const pendingInvoice = store.invoices.getPendingInvoices.filter(
    item =>
      ['pending', 'failed'].includes(item.status?.toLowerCase()) &&
      !['order', 'ai_quota_top_up'].includes(item.type),
  );
  if (pendingInvoice.length > 0) {
    store.invoices.openModal();
  }
  useEffect(() => {
    if (pendingInvoice?.length == 0) {
      store.invoices.closeModal();
    }
  }, []);

  useEffect(() => {
    if (showProjectsPicker && isBacklinkAnalyzer) {
      getProjectFromUrl();
    }
  }, [showProjectsPicker, isBacklinkAnalyzer]);

  // close sca top up modal on dismount
  useEffect(() => {
    return () => {
      setShowScaTopUpModal(false);
    };
  });

  // const addDefaultSrc = e => {
  //   e.target.src = '';
  // };

  return (
    <NoSsr>
      <WrapperMain
        id='HEADER_MAIN'
        isEditorScrolled={isEditorScrolled}
        style={{
          padding: isEditorScrolled ? '0' : '15px 15px 0 15px',
          background: '#24262a',
          height: isEditorScrolled ? '0' : 'auto',
          transition: 'all 0.2s ease',
        }}
      >
        <div
          className={styles.header}
          style={{display: isEditorScrolled ? 'none' : 'flex'}}
        >
          <SiderLogoContainer
            isDockedSider={isDockedSider}
            className={`${styles.logo} ${isDockedSider ? '' : styles.hideLogo}`}
          >
            <NextImg
              className={styles.imgStyle}
              src='/icons/collapsed.svg'
              height={18}
              width={18}
              onClick={() => setIsDocked(!isDocked)}
            />
            {/* We do not show anything if its WL without logo or if its WL with logo with generic logo */}
            {isWhitelabel &&
            (!customerLogo || customerLogo?.includes('/Logo_SVG.svg')) ? (
                <div
                  onClick={() => setDrawerVisibility(true)}
                  style={{
                    display: isDockedSider ? 'flex' : 'none',
                    fontSize: '20px',
                    color: '#E8E8E8',
                    marginLeft: '5px',
                    alignItems: 'center',
                    transition: 'all 0.2s ease',
                  }}
                ></div>
              ) : (
                <div className={styles.logoWrapper}>
                  <NextImg
                    className={`${styles.hideimg} logo-responsive`}
                    style={{
                      height: '100%',
                      padding: 5,
                      overflow: 'hidden',
                      marginLeft: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      transition: 'all 0.2s ease',
                      width: isWhitelabel ? '100%' : '160px',
                      objectFit: 'contain',
                    }}
                    onClick={
                      checkPublicPages && !isSubscriber ?
                        () => setAuthModal(true, true) :
                        () => router.push(routes?.home)
                    }
                    src={
                      isWhitelabel && customerLogo ?
                        customerLogo :
                        '/img/logo/searchAtlas_new_login.svg'
                    }
                    alt='logo'
                  // onError={e => addDefaultSrc(e)}
                  />
                </div>
              )}
          </SiderLogoContainer>
          <PendingInvoiceModal
            visible={
              store.invoices.visibleModal &&
              pendingInvoice.length > 0 &&
              !isViewer && !isVendastaClient
            }
            closable={true}
            content={[
              ...pendingInvoice.map(invoice => {
                return {
                  order: invoice['order'] ?? '',
                  status: invoice.status,
                  slug: invoice.slug,
                  createdAt: invoice.createdAt,
                  amount: invoice.amount,
                  type: invoice.type,
                };
              }),
            ]}
            account={store.settings.customer.profile}
            onClose={store.invoices.closeModal}
          />
          {showTopUpConfirmationModal && <ScaTopUpQuotaModal />}
          {projectActivePopup && (
            <ProjectActiveModal visible={projectActivePopup} closable={true} />
          )}

          {hasCompletedRegistration && showConfettiAnimation && (
            <ConfettiAnimation />
          )}

          <MenuItemsBar
            isSmallScreen={isSmallScreen}
            isMediumScreen={isMediumScreen}
            handleSideBarItems={handleSideBarItems}
          />
          <UserMenuRight
            style={{
              display: 'flex',
              flexGrow: 1,
              position: 'sticky',
              alignItems: 'center',
            }}
          >
            {!isMediumScreen &&
            !customerIds && subscription?.plan?.name != 'Agency Starter' && !isParentAccount ? (
                <Link legacyBehavior href={`/${routes.ottoPageV2}`} passHref>
                  <a style={{marginRight: '10px'}}>
                    <div
                      className={classnames(
                        styles.boxItemStyle,
                        router.pathname == '/otto-page-v2' ? styles.selected : '',
                      )}
                    >
                      <FontAwesomeIcon icon={faMicrochipAi} />
                      {`${profile.whitelabelOtto} SEO V2`}
                    </div>
                  </a>
                </Link>
              ) : (
                (subscription?.plan?.name != 'Agency Starter' || isParentAccount) &&
                  <Link legacyBehavior href={`/${routes.ottoPageV2}`} passHref>
                    <a style={{marginRight: '10px'}}>
                      <div
                        className={classnames(
                          styles.boxItemStyle,
                          router.pathname == '/otto-page-v2' ? styles.selected : '',
                        )}
                      >
                        <FontAwesomeIcon icon={faMicrochipAi} />
                        {`${profile.whitelabelOtto} SEO V2`}
                      </div>
                    </a>
                  </Link>
              )}
            {!checkPublicPages || isVerified ? (
              <>
                <div className={classnames(styles['header__buttons'])}>
                  <div className={styles.IconsHeader}>
                    {isWhitelabel &&
                    location?.origin?.includes('dashboard.searchatlas') ? (
                        <IconLink
                          href={getSearchAtlasKnowledgeAcademyLink()}
                          target='_blank'
                          rel='noreferrer noopener'
                        >
                          <ReadOutlined />
                        </IconLink>
                      ) : (profile.isWhitelabeled && profile.isParentAccount) ||
                      (!profile.isWhitelabeled && !profile.isParentAccount) ? (
                          <IconLink
                            disabled={isWhitelabel}
                            href={!isWhitelabel && getKnowledgeAcademyLink()}
                            target='_blank'
                            rel='noreferrer noopener'
                          >
                            <ReadOutlined />
                          </IconLink>
                        ) : (
                          ''
                        )}
                    <Popover
                      overlayClassName={`${styles.notificationPopover} hide-arrow`}
                      content={
                        <NotificationsPopup
                          setSelectedNotification={setSelectedNotification}
                          setNotificationModalVisibility={
                            setNotificationModalVisibility
                          }
                          visible={visible}
                        />
                      }
                      trigger={showNotificationsModal ? 'hover' : 'click'}
                      placement='bottomRight'
                      onOpenChange={e => setVisible(e)}
                      overlayInnerStyle={{
                        maxWidth: 420,
                        width: isSmallScreen ? 'auto' : 420,
                        maxHeight: 507,
                        padding: '0',
                        background: '#FFF',
                        boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.02)',
                        borderRadius: '12px',
                        border: '1px solid #E8E8E8',
                      }}
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0}
                    >
                      <div style={{position: 'relative'}}>
                        <StyledIcon icon={faBell} />
                        {unreadNotifications && <YellowDot></YellowDot>}
                      </div>
                    </Popover>
                    {/* <IconContainer onClick={() => setDrawerVisibility(true)}>
                      <FontAwesomeIcon icon={faGear} />
                    </IconContainer> */}
                    <IconContainer
                      onClick={() => {
                        const currentQuery = {...router.query};
                        if (!currentQuery['active_modal']) {
                          currentQuery['active_modal'] = 'settings';
                          currentQuery['active_section'] = 'profile';
                          router.replace({
                            pathname: router.pathname,
                            query: currentQuery,
                          }, undefined, {shallow: true});
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faGear} />
                      <SmallBadge beta customStyle={{position: 'absolute', top: '-10px', left: '12px'}}/>
                    </IconContainer>
                  </div>
                </div>
                <div className={styles['header__user-menu']}>
                  <UserMenu
                    name={store.settings.customer.profile.fullName}
                    email={store.settings.customer.profile.emailAddress}
                  />
                </div>
                {showNotificationsModal && !isWhitelabel && (
                  <WhatsNewModal
                    selectedNotificationId={selectedNotification}
                    highlightLatest={highlightNotification}
                    visible={showNotificationsModal}
                    setVisible={setNotificationModalVisibility}
                  />
                )}
              </>
            ) : (
              ''
            )}
          </UserMenuRight>
        </div>
      </WrapperMain>
    </NoSsr>
  );
});

export default Header;

const WrapperMain = styled.div<{ isEditorScrolled?: boolean }>`
  padding: ${p => (p.isEditorScrolled ? '0 !important' : '15px 15px 0 15px')};
  background: #24262a !important;
  @media screen and (max-width: 768px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 6px !important;
    .logo-responsive {
      display: none !important;
    }
  }
`;

const SiderLogoContainer = styled.div<{ isDockedSider: boolean }>`
  ${p =>
    p.isDockedSider ?
      `
    max-width: 197px;
    min-width: 197px;
  ` :
      ''}
  @media screen and (max-width: 768px) {
    min-width: 0;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  width: 46px;
  @media screen and (max-width: 768px) {
    width: 30px !important;
  }
`;

const YellowDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #f1aa3e;
  border-radius: 9999px;
  position: absolute;
  top: 1px;
  right: 13px;
`;

const UserMenuRight = styled.div`
  display: flex;
  position: sticky;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  @media screen and (max-width: 560px) {
    position: static;
  }
`;

const IconLink = styled.a<{ disabled?: boolean }>`
  color: unset;
  width: 34px;
  margin-right: 5px;
  text-align: center;
  &[disabled] {
    color: rgba(78, 81, 86, 1) !important;
  }
`;
// const InfoIcon = styled.div`
//   color: unset;
//   width: 20px;
//   height: 20px;
//   margin-right: 19px;
//   text-align: center;
//   border-radius: 34px;
//   box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
//   transform: scale(1);
//   animation: pulse 4s infinite;
//   display: flex;
//   margin-bottom: 2px;
//   cursor: pointer;

//   @keyframes pulse {
//     0% {
//       transform: scale(0.95);
//       box-shadow: 0 0 0 0 #C95B14;
//     }

//     70% {
//       transform: scale(1);
//       box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
//     }

//     100% {
//       transform: scale(0.95);
//       box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
//     }
//   }

// `;

const IconContainer = styled.div`
  color: #a3a4a4 !important;
  padding-left: 12px;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: 560px) {
    padding-left: 4px !important;
  }
`;
