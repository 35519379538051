import {Button, Checkbox, Dropdown, Empty, Input, Rate, Table, Tooltip} from 'antd';
import {Modal} from '@/components/common-components';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {faArrowLeft, faXmark, faCircleNotch, faMagnifyingGlass, faCheck, faSync, faCodePullRequest, faUndo, faEyeSlash, faEye} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState, useEffect} from 'react';
import {faCaretDown, faLocationDot, faPen} from '@fortawesome/pro-solid-svg-icons';
import {ApproveModal, buttonOptions} from './modals/ApproveModal';
import {EditPostModal} from './modals/EditPostModal';
import {useStore} from '@/store/root-store';
import {OTTO_V2_API} from '@/api/otto-v2';
import moment from 'moment';
import {notification} from '@/utils/notification-v2';
import {GBPMediaLibraryModal} from '../GbpPosts/GbpPosts/gbpMediaLibraryModal';
import {addStoreCode, getStandAloneLocations} from '@/utils/functions';
import {toJS} from 'mobx';
import {humanize, truncate} from '@/utils/string';
import {StyledSpin} from '../../../Utils/styledSpin';
import {ModalForms} from '../GbpOptimization/ModalForms';
import {ModalStyled as ModalStyled2} from '../GbpOptimization/styles';
import FreezeWrapper from '../../freezTooltip';
import {camelCase, snakeCase} from 'lodash';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';

export const MyTasks = observer(({isInGBPStandAlone=false, showBusinessDropdown = false, maxHeight = null}) => {
  const [selectedTitle, setSelectedTitle] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [loader, setLoader]= useState(null);
  const [openGbpLibraryModal, setOpenGbpLibraryModal] = useState(false);
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [edit, setEdit] = useState('');
  const [showMoreList, setShowMoreList] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [isCopied, setIsCopied]=useState(false);
  const {ottoV2Store: {getMyTasks, myTasksLocations, setMyTasksLocations, selectedCategory, getGBPLocations, gbpLocations, allGBPLocations, loadingAllGBPLocations, loadingTasks}} = useStore('');
  const activeArray = getStandAloneLocations(toJS(gbpLocations), searchValue)?.filter(location => myTasksLocations?.map(taskLocation => taskLocation?.id).includes(location?.id)) ?? [];
  const [pageNumber, setPageNumber] = useState(null);
  // for Location Modal
  const [selectedField, setSelectedField] = useState('');
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [selectedLocationCountryCode, setSelectedLocationCountryCode] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showImageType, setShowImageType] = useState();
  const [gbpData, setGBPData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [key, setKey] = useState(Math.random().toFixed(3));
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [pendingFields, setPendingFields] = useState([]);
  const [isDeploying, setIsDeploying] = useState(false);
  const pendingFieldsCount = pendingFields.reduce((sum, item) => sum + item.pendingTasks.length, 0);
  const labelsMapper = {
    'heatmap': 'Create a Local SEO Heatmap (Keyword Tracking)',
    'media_profile': 'Add Business Logo',
    'media_cover': 'Add a Cover Photo',
    'phone_number': 'Add a Phone Number',
    'address_lines': 'Add Business Address',
    'categories._secondary_category': 'Add Secondary Category',
    'categories._primary_category': 'Add Primary Category',
    'business_description': 'Add Business Description',
    'opening_date': 'Add Opening Date',
    'open_hours': 'Add Open Hours',
    'services': 'Add Services',
    'attributes': 'Add Attributes',
    'website_uri': 'Add a Website URL',
    'attributes._social_media_url': 'Add Social Media Profiles',
    'attributes._url_appointment': 'Add Booking URL',
    'services._description': 'Add Service Description',
    'open_info': 'Add Opening Date',
  };

  const socialAttributes = ['attributes/url_facebook', 'attributes/url_instagram', 'attributes/url_youtube', 'attributes/url_twitter', 'attributes/url_linkedin', 'attributes/url_pinterest', 'attributes/url_tiktok'];
  const socialMedia = ['attributesUrlInstagram', 'attributesUrlYoutube', 'attributesUrlLinkedin', 'attributesUrlFacebook', 'attributesUrlTwitter', 'attributesUrlTiktok', 'attributesUrlPinterest'];
  const [action, setAction] = useState(null);
  const [filterValue, setFilterValue] = useState(false);

  useEffect(() => {
    getData(false, true);
  }, [JSON.stringify(myTasksLocations)]);

  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  useEffect(() => {
    setSearchValue('');
  }, [selectedCategory]);

  useEffect(() => {
    getGBPLocations(false, isInGBPStandAlone);
  }, []);

  useEffect(() => {
    if (modalData?.callToActionType == 'CALL') {
      setPhoneNumbers(gbpLocations?.find(location => location?.id == modalData?.location)?.phoneNumbers);
    }
  }, [modalData]);

  useEffect(() => {
    if (!isModalOpen && modalType===null) {
      setModalData(null);
    }
  }, [isModalOpen]);

  useEffect(() => {
    getData();
  }, [filterValue]);

  const getData = async (loadMore=false, storeLoader=false) => {
    try {
      if (myTasksLocations?.length) {
        if (loadMore) {
          setLoader('data');
        } else {
          setLoader('loader');
        }
        const data = await getMyTasks(loadMore ? pageNumber : null, isInGBPStandAlone, storeLoader, filterValue);
        if (data?.data) {
          data.data?.forEach(task => {
            if (task.attributes.taskType !== 'LOCATION') {
              const taskData = data.meta[task.attributes.taskType.toLowerCase()+ 's']?.find(item => item.id == task.attributes?.objectId);
              task['data'] = taskData;
            }
          });
          if (loadMore) {
            // setTableData(prevState => ([...prevState, ...data.data]));
            setTableData(prevState => ([...prevState, ...data.data.filter(item => (['location', 'post', 'review', 'question'].some(val => item.attributes.locator.startsWith(val) && item.attributes.fieldType != 'services._description' && item.attributes.fieldType != 'pending_sync_fields')))]));
          } else {
            setTableData(data.data.filter(item => (['location', 'post', 'review', 'question', 'categories'].some(val => item.attributes.locator.startsWith(val) && item.attributes.fieldType != 'services._description' && item.attributes.fieldType != 'pending_sync_fields'))));
            // setTableData(data.data);
          }
          setPendingFields(data.meta.locations?.map(item => ({id: item.id, name: item.businessName, pendingTasks: item.pendingSyncFields?.filter(item => item !== 'attributes')})));
          if (data?.links?.next) {
            if (loadMore) {
              setPageNumber(prev => prev + 1);
            } else setPageNumber(2);
          } else {
            setPageNumber(null);
          }
        } else {
          setTableData([]);
        }
        setRefresh(false);
      } else {
        setPendingFields([]);
        setTableData([]);
        setPageNumber(null);
      }
    } catch {
      notification.error('Failed', 'Failed to load tasks data');
    } finally {
      await setLoader(null);
    }
  };

  const handleLocationTasks = async (task, type) => {
    setLoader(task.id);
    let gbpObj = gbpData?.find(item => item.data.id == task.relationships.location.data?.id);
    if (!gbpObj) {
      const response = await OTTO_V2_API.getOptimizationsV2(task.relationships.location.data?.id);
      setGBPData(prev => [...prev, response]);
      gbpObj = response;
    }
    const field = task.attributes.fieldType;
    if (field == 'services') {
      const catId = gbpObj?.data?.attributes?.categories?.[0];
      const category = gbpObj?.meta?.categories?.find(item => item.key == catId);
      setPrimaryCategory(category ?? null);
    }
    if (field?.startsWith('categories')) {
      setSelectedField('categories');
    } else {
      setSelectedField(field?.includes('attributes.') ? field?.split('._')[1] : field.includes('media') ? 'images' : field);
    }
    if (field.includes('media')) {
      setShowImageType(field.split('_')[1]);
    }
    getSelectedValue(field, (field.includes('categories') || field.includes('services')), gbpObj);
    setModalType(type);
    setIsModalOpen(true);
    setModalLoader(false);
    setLoader(null);
    const location = gbpLocations?.find(location => location?.id == task.relationships.location.data?.id);
    setSelectedLocationCountryCode(location?.addressRegionCode);
    setSelectedLocation(task.relationships.location.data.id);
    setKey(Math.random().toFixed(3));
  };

  const getSelectedValue = (fieldName, fromMeta, gbpObj) => {
    let value = null;
    if (fromMeta) {
      if (fieldName.includes('services')) {
        const services = gbpObj?.data.attributes.services;
        value = services.map(item => {
          let updatedValue = item;
          if (item.serviceTypeId) {
            updatedValue = gbpObj?.meta?.services?.find(ser => ser.key == item.serviceTypeId);
          }
          return updatedValue;
        });
      } else if (fieldName.includes('categories')) {
        const catIds = gbpObj?.data?.attributes?.categories;
        value = catIds?.map(id => gbpObj?.meta?.categories?.find(item => item.key == id)) ?? [];
      }
    } else {
      if (fieldName.includes('social')) {
        value = JSON.parse(JSON.stringify(Object.entries(gbpObj.data.attributes.attributes)?.map(item => ({key: item[0], value: item[1]}))?.filter(item => socialMedia.includes(item.key))));
      } else if (fieldName.includes('appointment')) {
        value = gbpObj.data.attributes?.attributes?.attributesUrlAppointment;
      } else if (fieldName == 'attributes') {
        value = JSON.parse(JSON.stringify(gbpObj.data.attributes?.attributes));
        [...socialMedia, 'attributesUrlAppointment']?.forEach(item => {
          delete value[item];
        });
        value = {value: value};
      } else if (fieldName == 'opening_date') {
        value = gbpObj.data.attributes?.openInfo;
      } else {
        value = gbpObj.data.attributes?.[camelCase(fieldName)];
      }
    }

    setSelectedValue(value);
  };

  const openModal = async (task, type) => {
    try {
      if (type == 'LOCATION') {
        handleLocationTasks(task, type);
      } else if (type !== 'edit') {
        setLoader(task.id);
        const response = await OTTO_V2_API.getModalDataByTypeAndId((type?.toLowerCase() + 's'), task?.attributes?.objectId ?? task?.id, isInGBPStandAlone);
        setModalData({...response, businessName: myTasksLocations?.find(item => item.id == response?.location)?.businessName ?? ''});
        setLoader(null);
        const title = type == 'POST' ? 'Approve Post' : type == 'QUESTION' ? 'Approve Q&A' : 'Approve Review Reply';
        if (type != 'edit') {
          const modalTitle = (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginLeft: 15}}>
              <div>
                <div style={{fontSize: 24}}>{title}</div>
                {response?.schedulePublishingAt && <div style={{fontSize: 14, fontWeight: 400, marginTop: 8}}>Scheduled for {moment(response?.schedulePublishingAt).format('MMM DD, YYYY')}</div>}
              </div>
              {type == 'POST' && <div style={{fontSize: 12, color: '#2D6CCA', fontWeight: 400, cursor: 'pointer'}} onClick={() => openModal(task, 'edit')}>
                <FontAwesomeIcon icon={faPen}/> Edit post
              </div>}
            </div>
          );
          setSelectedTitle(modalTitle);
        }
        setModalType(type);
        setIsModalOpen(true);
      } else if (type == 'edit') {
        const modalTitle = (
          <div style={{marginLeft: 15}}>
            <div style={{fontSize: 14, fontWeight: 400, color: '#4E5156', cursor: 'pointer'}} onClick={() => openModal(task, 'POST')}><FontAwesomeIcon icon={faArrowLeft}/> Back to Post Preview</div>
            <div style={{fontSize: 24, marginTop: 10, marginRight: 5}} onClick={() => openModal(task, 'edit')}>Edit Post</div>
          </div>
        );
        setSelectedTitle(modalTitle);
        setModalType(type);
        setIsModalOpen(true);
      }
    } catch {
      setLoader(null);
      notification.error('Failed', 'Failed to Fetch Data');
    }
  };
  const showMoreHandler = (id, type) => {
    setShowMoreList(prevState => {
      const isReviewExpanded = isShowMore(id, 'review');
      const isReviewAnswerExpanded = isShowMore(id, 'review-answer');
      if (type === 'review') {
        if (isReviewExpanded && isReviewAnswerExpanded) {
          return prevState.filter(item => item.id !== id);
        }
        if (!isReviewExpanded && !isReviewAnswerExpanded) {
          return [...prevState, {id, type: 'review'}, {id, type: 'review-answer'}];
        }
        if (!isReviewExpanded) {
          return [...prevState, {id, type: 'review'}];
        }
        return prevState.filter(item => item.id !== id);
      }
      if (type === 'review-answer') {
        if (isReviewAnswerExpanded) {
          return prevState.filter(item => item.id !== id || item.type !== 'review-answer');
        }
        return [...prevState, {id, type: 'review-answer'}];
      }
      const isItemExpanded = prevState.some(item => item.id === id && item.type === type);
      return isItemExpanded ?
        prevState.filter(item => item.id !== id || item.type !== type) :
        [...prevState, {id, type}];
    });
  };

  const isShowMore = (id, type) => {
    return showMoreList?.some(item => (item.id === id && item.type === type));
  };

  const checkPendingStatus = task => {
    const fieldName = task.attributes.fieldType;
    if (fieldName == 'status') {
      return false;
    }
    const pendingFieldsArray = pendingFields?.find(item => item.id == task.relationships.location.data.id)?.pendingTasks;
    if (!pendingFieldsArray?.length) {
      return false;
    } else {
      if (fieldName.includes('._')) {
        if (fieldName == 'attributes._social_media_url') {
          return ['facebook', 'instagram', 'youtube', 'twitter', 'linkedin', 'pinterest', 'tiktok']?.some(item => pendingFieldsArray.includes('attributes/url_' + item));
        } else if (fieldName == 'attributes._url_appointment') {
          return pendingFieldsArray.includes('attributes/url_appointment');
        } else if (fieldName.includes('attributes')) {
          return pendingFieldsArray.some(field => (field.includes('attributes') && ![...socialAttributes, 'attributes/url_appointment'].includes(field)));
        } else if (fieldName.includes('services')) {
          return pendingFieldsArray.includes('services');
        } else if (fieldName.includes('categories')) {
          return pendingFieldsArray.includes('categories');
        }
      } else if (fieldName == 'opening_date') {
        return pendingFieldsArray.includes('open_info');
      } else {
        return pendingFieldsArray.includes(fieldName);
      }
    }
  };

  const columns = [
    {
      title: 'BUSINESS',
      dataIndex: ['relationships', 'location', 'data', 'id'],
      key: 'name',
      width: '25%',
      render: locationId => (
        <div style={{marginRight: 20}}>
          <div style={{fontWeight: 600}}>{myTasksLocations?.find(item => item.id == locationId)?.businessName}</div>
          <p style={{marginBottom: 'unset', fontSize: 12, color: '#4E5156'}}>{myTasksLocations?.find(item => item.id == locationId)?.businessAddress}</p>
        </div>
      ),
    },
    {
      title: 'PREVIEW',
      dataIndex: 'data',
      key: 'data',
      render: (data, task) => (
        <>
          {data ? <>
            {task.attributes.taskType == 'POST' && <PreviewContainer>
              <div className='image-container'>
                {data?.imageUrl ?
                  <img referrerPolicy='no-referrer' key={data?.imageUrl} src={data?.imageUrl} className='image' style={{width: '-webkit-fill-available', objectFit: 'cover'}}/> :
                  <Tooltip title={'Image not available'}>
                    <img src='/img/icon/broken-image.svg' className='image' style={{width: 50, objectFit: 'cover'}}/>
                  </Tooltip>}
              </div>
              <div className='post'>
                {(data?.topicType !== 'STANDARD' && data?.title) && <div className='content-title'>{data?.title}</div>}
                {(data?.content?.length > 300 && !isShowMore(task.id, 'post')) ? data?.content?.slice(0, 300) : <>
                  {data?.content}
                  {((data?.callToActionType === 'CALL') || (data?.callToActionType !== 'CALL' && data?.callToActionUrl)) && (
                    <Tooltip
                      title={data?.callToActionType == 'CALL' && (gbpLocations?.find(location => location?.id == data?.location)?.phoneNumbers?.length ? isCopied ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}}/>{` Phone number copied`}</span> : 'Copy phone number' : 'No phone number available')}
                      placement='bottom'
                      overlayInnerStyle={{backgroundColor: 'black'}}
                      onOpenChange={visible => {
                        if (!visible) {
                          setTimeout(() => {
                            setIsCopied(false);
                          }, 300);
                        }
                      }}><div className='learn-more' style={{width: 'fit-content'}}>
                        <a
                          style={data?.callToActionType === 'CALL' && !gbpLocations?.find(location => location?.id == data?.location)?.phoneNumbers?.length ? {cursor: 'not-allowed'} : {}}
                          href={data?.callToActionType !== 'CALL' && data?.callToActionUrl}
                          onClick={() => {
                            if (data?.callToActionType === 'CALL') {
                              navigator.clipboard.writeText(gbpLocations?.find(location => location?.id == data?.location)?.phoneNumbers[0]);
                              setIsCopied(true);
                            }
                          }}
                          target={data?.callToActionType === 'CALL' ? '_self' : '_blank'}
                          rel='noreferrer'
                        >
                          {buttonOptions?.find(item => item.value === data?.callToActionType)?.label}
                        </a>
                      </div></Tooltip>
                  )}
                </>}
                {data?.content?.length > 300 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'post')}>{!isShowMore(task.id, 'post') ? 'Show More' : 'Show Less'}</div>}
              </div>
            </PreviewContainer>}
            {task.attributes.taskType == 'QUESTION' && <PreviewContainer style={{flexDirection: 'column'}}>
              <div className='question'>
                <div className='label'>Q:&nbsp;</div>
                <div className='question-content'>
                  {data?.content}
                </div>
              </div>
              <div className='qa-answer'>
                <div className='label'>A:&nbsp;&nbsp;&nbsp;</div>
                <div className='answer-content'>
                  {(data?.ownersAnswer?.content?.length > 250 && !isShowMore(task.id, 'qa-answer')) ? `${data?.ownersAnswer?.content?.slice(0, 250)}...` : data?.ownersAnswer?.content}
                  {data?.ownersAnswer?.content?.length > 250 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'qa-answer')}>{!isShowMore(task.id, 'qa-answer') ? 'Show More' : 'Show Less'}</div>}
                </div>
              </div>
            </PreviewContainer>}
            {task.attributes.taskType == 'REVIEW' && <PreviewContainer>
              <div className='review' style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  Rating: <Rate style={{fontSize: 12, marginRight: 'unset', marginLeft: 10, marginBottom: 3}} disabled value={data?.starRating} />
                </div>
                {(data?.content?.length > 150 && !isShowMore(task.id, 'review')) ? data?.content?.slice(0, 150) : data?.content}
                {data?.content?.length > 150 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'review')}>{!isShowMore(task.id, 'review') ? 'Show More' : 'Show Less'}</div>}
              </div>
              <div className='answer'>{(data?.reply?.content?.length > 150 && !isShowMore(task.id, 'review-answer')) ? data?.reply?.content?.slice(0, 150) : data?.reply?.content}
                {data?.reply?.content?.length > 150 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'review-answer')}>{!isShowMore(task.id, 'review-answer') ? 'Show More' : 'Show Less'}</div>}
              </div>
            </PreviewContainer>}
          </> : task.attributes.taskType == 'LOCATION' ? <div>
            {labelsMapper[task.attributes.fieldType]}
          </div> :
            <div>No Preview Available</div>}
        </>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150px',
      render: (_, task) => {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px'}}>{moment(task?.data?.createdAt).format('MMM DD, YYYY')}</div>;
      },
    },
    {
      title: 'ACTION',
      dataIndex: ['attributes', 'taskType'],
      key: 'Action',
      width: '130px',
      render: (text, task) => {
        return (
          <div style={{display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'space-between'}}>
            <Tooltip title={task?.attributes?.isDeleted ? 'This task is ignored.' : ''}>
              <div
                style={{
                  cursor: task?.attributes?.isDeleted ? 'not-allowed' : 'pointer',
                }}
              >
                <div
                  onClick={() => (loader !== task.id) && openModal(task, text)}
                  style={{
                    background: '#2D6CCA26',
                    width: 'fit-content',
                    padding: '7px 12px',
                    color: '#2D6CCA',
                    borderRadius: 8,
                    cursor: loader === task.id ? 'not-allowed' : 'pointer',
                    pointerEvents: task?.attributes?.isDeleted ? 'none' : 'auto',
                    opacity: task?.attributes?.isDeleted ? 0.5 : 1,
                  }}
                >
                  {action !== 'ignore' && (
                    <FontAwesomeIcon
                      style={{
                        marginRight: 2,
                        display: loader !== task.id ? 'none' : 'inline-block',
                      }}
                      spin={loader === task.id}
                      icon={faCircleNotch}
                    />
                  )}
                  {text === 'LOCATION' ? checkPendingStatus(task) ? 'Update' : 'Add' : 'Review'}
                </div>
              </div>
            </Tooltip>
            <Tooltip title={`${task?.attributes?.isDeleted ? 'Restore Task' : 'Ignore Task'}`}>
              <div
                onClick={() => {
                  updateTaskStatus(task);
                }}
                style={{marginRight: 8}}
              >
                <FontAwesomeIcon
                  style={{marginRight: 2, color: '#A3A4A4', cursor: `${loader === task.id ? 'not-allowed' : 'pointer'}`}}
                  spin={loader === task.id && action === 'ignore'}
                  icon={(loader === task.id && action === 'ignore') ? faCircleNotch : task?.attributes?.isDeleted ? faUndo : faEyeSlash}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const selectImageFromMediaLibrary = image => {
    setLoader('image-file');
    if (image?.url) {
      try {
        setModalData(prevState => {
          return ({...prevState, imageUrl: image?.url});
        });
        setOpenGbpLibraryModal(false);
        setIsModalOpen(true);
        setLoader(null);
      } catch (e) {
        setLoader(null);
        return Promise.reject(e);
      }
    } else {
      setLoader(null);
    }
  };

  useEffect(() => {
    if (!getStandAloneLocations(toJS(gbpLocations), '')?.length) {
      setMyTasksLocations([]);
    }
  }, [getStandAloneLocations(toJS(gbpLocations), '')]);

  const myTaskLocationHandler = (location, event) => {
    let updatedLocations = JSON.parse(JSON.stringify(myTasksLocations));
    if (location == 'all') {
      if (updatedLocations?.length == getStandAloneLocations(toJS(gbpLocations), searchValue)?.length) {
        updatedLocations = [];
      } else {
        updatedLocations = JSON.parse(JSON.stringify(getStandAloneLocations(toJS(gbpLocations), searchValue)));
      }
    } else {
      if (event.target.checked) {
        updatedLocations?.push(location);
      } else {
        updatedLocations = updatedLocations?.filter(item => item.id != location?.id);
      }
    }
    setMyTasksLocations(updatedLocations);
  };

  const myTasksLocationList = (
    <DropdownContainer>
      {loadingAllGBPLocations? <RingLoaderV2 height='225px' fontSize='40px' /> :
        <>
          <GbpSearchInput onChange={e => setSearchValue(e.target.value)} value={searchValue} placeholder='Search...'
            prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
          />
          <DropdownListContainer>
            {getStandAloneLocations(toJS(gbpLocations), searchValue)?.length > 1 && <Checkbox key='all' disabled={loadingTasks} checked={activeArray?.length == getStandAloneLocations(toJS(gbpLocations), searchValue)?.length} onClick={event => myTaskLocationHandler('all', event)}>All</Checkbox>}
            {getStandAloneLocations(toJS(gbpLocations), searchValue)?.length ? addStoreCode(toJS(gbpLocations), searchValue, toJS(allGBPLocations))?.map(business => (
              <Checkbox disabled={loadingTasks} key = {business?.id} style={{width: '100%'}} checked={myTasksLocations?.some(item => item.id == business?.id)} value={business} onClick={event => myTaskLocationHandler(business, event)}>
                <div>
                  <div style={{fontWeight: 'bold', fontSize: '12px'}}>{business?.businessName}</div>
                  <p style={{color: '#4E5156', fontSize: '12px', fontFamily: 'Inter', fontWeight: '400'}}>{business?.businessAddress ? business?.businessAddress : business?.storeCode ? `Store code: ${business?.storeCode}` : ''}</p>
                </div>
              </Checkbox>
            )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </DropdownListContainer>
        </>
      }
    </DropdownContainer>
  );

  const getLocationData = async id => {
    const response = await OTTO_V2_API.getOptimizationsV2(id);
    setGBPData(prevData => {
      const existing = prevData.some(item => item.data.id === response.data.id);
      if (existing) {
        return prevData.map(item =>
          item.data.id === response.data.id ? response : item);
      } else {
        return [...prevData, response];
      }
    });
    setGBPData(prev => [...prev, response]);
  };

  const updateData = async payload => {
    try {
      const updatePayload = [];
      payload.forEach(dataItem => {
        let value = dataItem.value;
        if (dataItem.fieldName == 'categories') {
          value = value.map(item => item.key);
        } else if (dataItem.fieldName == 'services') {
          value = value.map(item => {
            if (Object.keys(item).includes('categoryName')) {
              return {
                display_name: item.displayName,
                category_name: item.categoryName,
              };
            } else if (Object.keys(item).includes('serviceTypeId')) {
              return {service_type_id: item.serviceTypeId.key};
            }
          }).filter(item => item);
        } else if (dataItem.fieldName == 'attributes/url_appointment') {
          while (Array.isArray(value) && Array.isArray(value[0])) {
            value = value[0];
          }
          if (Array.isArray(value)) {
            value = value[0]?.startsWith('http') ? value : [`http://${value[0]}`];
          } else {
            value = value.startsWith('http') ? value : `http://${value}`;
          }
        }
        updatePayload.push({
          field_name: dataItem.fieldName,
          field_type: dataItem.fieldType,
          value: value,
        });
      });
      const v2Payload = {};
      const gbpObj = gbpData?.find(item => item.data.id == selectedLocation);
      if (updatePayload?.some(item => item.field_type == 'ATTRIBUTE')) {
        const previousAttributes = Object.entries(gbpObj?.data?.attributes?.attributes)
          .reduce((acc, [key, item]) => {
            const formattedKey = snakeCase(key).replace('attributes_', 'attributes/');
            if (Array.isArray(item)) {
              acc[formattedKey] = item;
            } else {
              acc[formattedKey] = item;
            }
            return acc;
          }, {});
        v2Payload['attributes'] = previousAttributes;
      }
      updatePayload?.forEach(item => {
        if (item.field_type == 'MEDIA') {
          v2Payload['media_' + (item.field_name == 'ADDITIONAL' ? 'items' : item.field_name.toLowerCase())] = item.value;
        } else if (item.field_type == 'ATTRIBUTE') {
          if (!v2Payload['attributes']) v2Payload['attributes'] = {};
          if (item.value) {
            v2Payload['attributes'][item.field_name] = item.value;
          } else {
            v2Payload['attributes'] = Object.fromEntries(
              Object.entries(v2Payload['attributes']).filter(attribute => attribute[0]!=item.field_name));
          }
        } else {
          v2Payload[item.field_name] = item.value;
        }
      });
      const data = {
        data: {
          type: 'locations',
          id: selectedLocation,
          attributes: v2Payload,
        },
      };
      await OTTO_V2_API.updateOptimizationsV2(selectedLocation, data);
      await getLocationData(selectedLocation);
      await getData();
      setIsModalOpen(false);
      notification.success('Update Successful', 'The field has been updated');
    } catch (error) {
      setLoader(false);
      const response = error?.response?.data?.errors;
      if (response) {
        const messagesArray = response?.map(item => item?.detail);
        if (messagesArray?.length) {
          messagesArray.forEach(item => {
            notification.error('Update Failed', item);
          });
        }
      } else {
        notification.error('Update Failed', 'Failed to Update the Current Field');
      }
      return null;
    } finally {
      setModalLoader(false);
    }
  };

  const getTooltipData = () => {
    const tooltipData = pendingFields.map(item => {
      const data = {
        name: item.name,
        tasks: item.pendingTasks?.map(task => humanize(task.replace('attributes/', '')).replace('Url', 'URL')),
      };
      return data;
    }).filter(item => item.tasks?.length);
    return <TooltipContainer>{tooltipData?.map(item => (<div key={item.name}>
      <div>{item.name}</div>
      <ul style={{paddingLeft: 30}}>
        {item.tasks.map(task => (<li key={task}>{task}</li>))}
      </ul>
    </div>))}</TooltipContainer>;
  };

  const updateTaskStatus = async task => {
    setLoader(task.id);
    setAction('ignore');
    try {
      const data = {
        data: {
          type: 'location_tasks',
          id: task?.id,
          attributes: {
            is_deleted: !task?.attributes?.isDeleted,
          },
        },
      };
      await OTTO_V2_API.updateMyTaskV2(task?.id, data, isInGBPStandAlone);
      await getLocationData(task?.relationships?.location?.data.id);
      await getData();
      notification.success(`Task ${task?.attributes?.isDeleted ? 'Restored' : 'Ignored'} Successfully`, `The task has been ${task?.attributes?.isDeleted ? 'restored' : 'ignored'} successfully.`);
    } catch (error) {
      setLoader(null);
      const response = error?.response?.data?.errors;
      if (response) {
        const messagesArray = response?.map(item => item?.detail);
        if (messagesArray?.length) {
          messagesArray.forEach(item => {
            notification.error('Update Failed', item);
          });
        }
      } else {
        notification.error('Something went wrong!', `Failed to ${task?.attributes?.isDeleted ? 'restore' : 'ignore'} task.`);
      }
      return null;
    } finally {
      setLoader(null);
      setAction(null);
    }
  };

  const refreshTasks = async () => {
    setRefreshLoader(true);
    const payload = {data: myTasksLocations?.map(item => ({type: 'locations', id: item.id}))};
    const response = await OTTO_V2_API.bulkRefreshTasks(payload, isInGBPStandAlone);
    localStorage.setItem('bulkRefreshTaskId', response.data.id);
    getStatus(response.data.id, 'refresh');
  };

  const getStatus = async (id, type) => {
    try {
      const response = await OTTO_V2_API.getTaskResult(id);
      if (response?.status == 'PENDING') {
        setTimeout(() => {
          getStatus(id, type);
        }, 3000);
      } else {
        if (response?.status == 'SUCCESS') {
          notification.success('Refresh Successful', 'Successfully refreshed the tasks.');
        } else {
          notification.error('Refresh Failed', response.errorMessage);
        }
        localStorage.removeItem('bulkRefreshTaskId');
        setRefreshLoader(false);
        await getData();
      }
    } catch (e) {
      Promise.reject(e);
    }
  };

  const getDeployStatus = async (taskId, first=false) => {
    try {
      const response = await OTTO_V2_API.taskResults(taskId);
      if (response.status == 'FAILURE') {
        notification.error('Deployment Failed', response.errorMessage);
        localStorage.setItem('deployStatusTaskId', null);
        setIsDeploying(false);
      } else if (response.status == 'SUCCESS') {
        notification.success('Deployment Successful', 'Deployment was successful');
        localStorage.setItem('deployStatusTaskId', null);
        setIsDeploying(false);
      } else {
        localStorage.setItem('deployStatusTaskId', JSON.stringify({taskId: taskId, locationId: selectedLocation?.id}));
        getDeployStatus(taskId);
      }
      if (response.status == 'SUCCESS' || response.status == 'FAILURE' || (response.status == 'PENDING' && first)) {
        await setLoader(true);
        await getData();
      }
    } catch {
      return Promise.reject;
    }
  };

  useEffect(() => {
    const id = localStorage.getItem('bulkRefreshTaskId');
    if (id) {
      getStatus(id, 'refresh');
      setRefreshLoader(true);
    }
  }, []);

  const bulkDeploy = async () => {
    try {
      const Ids = pendingFields?.filter(item => item.pendingTasks?.length)?.map(item => item.id);
      if (Ids?.length) {
        setIsDeploying(true);
        const data = [];
        Ids?.map(id => data?.push({
          'type': 'locations',
          'id': id,
        }));
        const payload = {
          'data': data,
          'meta': {
            'mode': 'PARTIAL',
          },
        };
        const response = await OTTO_V2_API.bulkDeployOptimizationsV2(payload, isInGBPStandAlone);
        notification.success('Deployment Triggered', 'It will take few minutes to deploy your changes');
        await getDeployStatus(response.data.id);
      } else {
        notification.info('No Pending Fields', 'No Pending Data found for deployment');
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return (
    <>
      <div>
        <div style={{display: 'flex', justifyContent: isInGBPStandAlone ? 'space-between' : 'flex-end', alignItems: 'center', margin: isInGBPStandAlone ? '10px 0' : '-10px 0 10px 0'}}>
          {isInGBPStandAlone && <Tooltip title={!myTasksLocations?.length ? 'No businesses available' : ''}><Dropdown disabled={loader || !myTasksLocations?.length} trigger={['click']} overlay={myTasksLocationList} open={showGbpDropdown} onOpenChange={e => setShowGbpDropdown(e)}>
            <DropdownWrapper locationsLength={myTasksLocations?.length} style={showBusinessDropdown ? {marginBottom: '10px'} : {}}>
              <FontAwesomeIcon icon={faLocationDot} fontSize={14}/>
                  Business: &nbsp;{activeArray?.length ? activeArray?.length == 1 ? truncate(activeArray?.[0]?.businessName, 30) : (+ activeArray?.length + ' selected'): 'Select'}
              <FontAwesomeIcon icon={faCaretDown} fontSize={14}/>
            </DropdownWrapper>
          </Dropdown></Tooltip>}
          <div style={{display: 'flex', alignItems: 'center', gap: 15, cursor: !myTasksLocations?.length ? 'not-allowed' : 'pointer'}}>
            <FreezeWrapper>
              <Tooltip title={!myTasksLocations?.length ? 'No business selected' : !filterValue ? 'Show Ignored Tasks' : 'Show Active Tasks'}>
                <div>
                  <FontAwesomeIcon icon={!filterValue ? faEyeSlash : faEye} onClick={() => setFilterValue(prev => !prev)} style={{cursor: 'pointer', pointerEvents: `${!myTasksLocations?.length ? 'none' : 'auto'}`}}/>
                </div>
              </Tooltip>
              <Tooltip title={!myTasksLocations?.length ? 'No business selected' : !isDeploying ? (pendingFieldsCount ? getTooltipData() : 'No Pending Tasks found for deployment') : 'Tasks are recently deployed. Wait for few minutes for next deployment'}>
                <ButtonStyled style={{cursor: (isDeploying || !pendingFieldsCount) ? 'not-allowed' : 'pointer'}}
                  onClick={() => ((!isDeploying && pendingFieldsCount) && bulkDeploy())} loading={isDeploying}>
                  <FontAwesomeIcon icon={faCodePullRequest} color={'#fff'} style={{marginRight: 10}}/>{'Deploy all changes ' + `${pendingFieldsCount ? '(' + pendingFieldsCount + ')' : ''}`}
                </ButtonStyled>
              </Tooltip>
              <Tooltip title={!myTasksLocations?.length ? 'No business selected' : ''}>
                <div style={{color: '#2D6CCA', cursor: (isDeploying || refreshLoader || !myTasksLocations?.length) ? 'not-allowed' : 'pointer'}} onClick={() => !refreshLoader && !isDeploying && myTasksLocations?.length && refreshTasks()}>
                  <FontAwesomeIcon icon={faSync} spin={refreshLoader}/> Refresh
                </div>
              </Tooltip>
            </FreezeWrapper>
          </div>
        </div>
        <StyledTable
          columns={columns}
          dataSource={tableData}
          loading={loader === 'loader'}
          pagination={false}
          scroll={showBusinessDropdown ? {x: 1400, y: 'auto'} : {x: 1400}}
          maxHeight={maxHeight}
        />
      </div>
      {loader === 'data' ?
        <div style={{textAlign: 'center', marginTop: 12, cursor: 'not-allowed', color: '#2d6cca', display: 'flex', justifyContent: 'center', gap: '10px'}}><StyledSpin fontSize={16} color='#2d6cca'/> Loading...</div> :
        pageNumber && <div onClick={() => getData(true)} style={{textAlign: 'center', marginTop: 12, cursor: 'pointer', color: '#2d6cca'}}>Load More</div>}
      {isModalOpen && modalType !== 'LOCATION' && <ModalStyled
        width={620}
        visible={isModalOpen}
        closable={true}
        onClose={() => {
          setIsModalOpen(false);
          setModalData(null);
          setModalType(null);
        }}
        closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
        mask={true}
        title={selectedTitle}
      >
        {modalType != 'edit' && <ApproveModal modalType={modalType} modalData={modalData} setIsModalOpen={setIsModalOpen} setRefresh={setRefresh} edit={edit} setEdit={setEdit} phoneNumbers={phoneNumbers} isInGBPStandAlone={isInGBPStandAlone} />}
        {modalType == 'edit' && <EditPostModal postData={modalData} setPostData={setModalData} setRefresh={setRefresh} setIsModalOpen={setIsModalOpen} setOpenGbpLibraryModal={setOpenGbpLibraryModal} openModal={openModal} phoneNumbers={phoneNumbers} isInGBPStandAlone={isInGBPStandAlone} />}
      </ModalStyled>}
      {openGbpLibraryModal && <GBPMediaLibraryModal
        openGbpLibraryModal={openGbpLibraryModal}
        setOpenGbpLibraryModal={setOpenGbpLibraryModal}
        setOpenGBPAutomationModal={setIsModalOpen}
        isLocalSEOMyTask={true}
        selectImageFromMediaLibrary={selectImageFromMediaLibrary}
        modalData={modalData}
        isInGBPStandAlone={isInGBPStandAlone}
      />
      }
      {
        modalType == 'LOCATION' && <ModalStyled2
          key={key}
          width={selectedField == 'open_hours' ? 800 : selectedField == 'images' ? 1280 : 560}
          closable={true}
          closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
          mask={true}
          title={selectedField == 'categories' ? 'Business Category' : selectedField == 'images' ? 'Media' : (selectedField == 'website_uri' ? 'Website URL' : selectedField == 'url_appointment' ? 'Appointment URL' : humanize(selectedField))} visible={isModalOpen} footer={false} onClose={() => setIsModalOpen(false)} style={{height: 100}}>
          {isModalOpen && <ModalForms
            updateData={payload => updateData(payload)}
            attributes={null}
            selectedAttributes={null}
            services={services}
            selectedField={selectedField}
            categories={categories}
            setIsModalOpen={setIsModalOpen}
            modalSuggestion={null}
            selectedValue={selectedValue}
            setModalLoader={setModalLoader}
            modalLoader={modalLoader}
            incidents={null}
            primaryCategory={primaryCategory}
            setCategories={setCategories}
            setServices={setServices}
            selectedLocation={selectedLocation}
            selectedLocationCountryCode={selectedLocationCountryCode}
            showImageType={showImageType}
            isInLocalSeo={isInGBPStandAlone}
            subGroup={'my_tasks'}
          />}
        </ModalStyled2>
      }
    </>
  );
});

const ButtonStyled = styled(Button)`
  background: #2ac155;
  color: white;
  border: unset;
  box-shadow: unset;
  border-radius: 5px;
  height: 40px;
  &:hover{
    background: #2ac155;
    color: white;
    border: unset;
    box-shadow: unset;
  }
  &:focus{
    background: #2ac155;
    color: white;
    border: unset;
    box-shadow: unset;
  }
  &:active{
    background: #2ac155;
    color: white;
    border: unset;
    box-shadow: unset;
  }

`;

const StyledTable = styled(Table)<{maxHeight?: string}>`
  .ant-table-cell {
    padding: 10px 8px 13px 8px !important;
    vertical-align: top;
  }
  .ant-table-body {
    ${p => p?.maxHeight ? `max-height: ${p?.maxHeight};` : ''}
  }
  th::before {
    content: null;
  }
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: #7a7a7a; 
  }
`;

const ModalStyled = styled(Modal)`
  .rc-dialog-content {
    padding: 10px;
  }
  .rc-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .rc-dialog-wrap {
    align-items: center !important;
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .rc-dialog-body {
    padding: 20px 30px 20px 30px !important;
    border-radius: 12px !important;
  }
  .rc-dialog-close {
    font-size: 28px !important;
    color: white !important;
    left: 102% !important;
    top: -3px !important;
    opacity: 1 !important;
  }
  .ant-btn-submit {
    background-color:  #2D6CCA !important;
    color: white;
    padding: 13px 25px 14px 25px;
    border-radius: 8px;
    height: 44px;
    border: none;
    margin-top: 15px;
    &:hover {
      background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
      background-color:  '#7f4ead2E';
      border: 1px solid transparent !important;
      color: #fff;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }

  .rc-dialog-header {
  background: var(--Table-row-stripe, #F9F9FB);
  padding: 19px 19px 2px 19px;
  }
  .rc-dialog-content {
  background: var(--Table-row-stripe, #F9F9FB);
  }
`;
const DropdownWrapper = styled.div<{locationsLength?:any}>`
  padding: 0 8px;
  font-size: 14px;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  color: #4E5156;
  display: flex;
  align-items: center;  
  gap: 9px;
  cursor: ${p => p.locationsLength ? 'pointer' : 'not-allowed'};
  background: #fff;
  width: fit-content;
  max-width: 500px;
  height: 40px;
  // margin-top: 10px;
`;
const DropdownContainer = styled.div`
  width: 400px;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
const GbpSearchInput = styled(Input)`
  width: 380px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;
const DropdownListContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
  .ant-checkbox-wrapper {
    margin-left: unset;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  .image-container {
    width: 55px;
    margin-right: 10px
  }
  .show-more {
    cursor: pointer;
    color: #2d6cca
  }
  .question {
    display: flex;
    .label {
      font-weight: 600;
      width: 22px;
    }
    .question-content {
      font-weight: 600;
      max-width: 91%;
    }
  }
  .qa-answer {
    display: flex;
    .label {
      font-weight: 600;
      width: 22px;
    }
    .answer-content {
      max-width: 91%;
    }
  }
  .answer {
    width: 50%;
  }
  .post {
    width: calc(100% - 55px);
  }
  .review {
    font-weight: 600;
    margin-right: 10px;
    width: 50%;
  }
  .learn-more > a {
    cursor: pointer;
    font-style: italic;
    color: #2D6CCA;
    }
`;

const TooltipContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
   &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: #7a7a7a; 
  }
`;
