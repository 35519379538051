import {Button} from '@/components/common-components/v2';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal} from 'antd';
import {Dispatch, SetStateAction, useEffect} from 'react';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {faCalendar} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import {NoSsr} from '@/components/common-components';
import {updateGTMDatalayer} from '@/utils/gtm';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
}

const OnboardingPromotionModal = observer(({visible, setVisible, loading}: Props) => {
  const {settings: {customer: {profile: {firstName, customerType, emailAddress}}}} = useStore('');

  const pointsList = [
    {heading: '⏩ Fast-Track Your Setup:', text: `Complete your account setup with ease, guided step-by-step.`},
    {heading: `❓ Get Your Questions Answered:`, text: 'Address any challenges or concerns as we walk through the process together.'},
    {heading: '⭐ Pro Tips for Success:', text: 'Learn insider tips to optimize workflows and achieve better rankings, faster.'},
    {heading: `🎯 Set Up Your First Project:`, text: 'Start your first OTTO project with guidance from our team.'},
  ];

  const saveLastShownDate = () => {
    const currentDateStr = moment().format('YYYY-MM-DD');
    localStorage.setItem('callModalShownDate', currentDateStr);
  };

  const onClose = (alreadyBooked?: boolean) => {
    if (alreadyBooked) localStorage.setItem('onboardingCallBooked', JSON.stringify(true));
    saveLastShownDate();
    setVisible(false);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    document.body.appendChild(script);


    function isCalendlyEvent(e) {
      return e.data.event &&
        e.data.event.indexOf('calendly') === 0 && e.data.event === 'calendly.event_scheduled';
    }

    window.addEventListener(
      'message',
      function(e) {
        if (isCalendlyEvent(e)) {
          localStorage.setItem('onboardingCallBooked', JSON.stringify(true));
          setVisible(false);
        }
      },
    );
  }, []);

  const specialistList = [
    {
      name: 'Jonathan',
      isOnline: true,
      imgSrc: '/img/onboarding_specialist_1.png',
    },
    {
      name: 'Laura',
      isOnline: true,
      imgSrc: '/img/onboarding_specialist_2.png',
    },
    {
      name: 'Luis',
      isOnline: true,
      imgSrc: '/img/onboarding_specialist_3.png',
    },
    {
      name: 'Paula and 7 more',
      isOnline: true,
      imgSrc: '/img/onboarding_specialist_4.png',
    },
  ];

  return (
    <NoSsr>
      <StyledModal
        open={visible}
        centered
        footer={null}
        closable={false}
      >
        <CloseIcon onClick={() => setVisible(false)}>
          <FontAwesomeIcon width={20} height={20} icon={faXmark} />
        </CloseIcon>
        <div className='containerWrapper'>
          <div className='headingWrapper'>
            <img src='/icons/handicon.svg' style={{width: 44, height: 44}} alt='handicon' />
            <p className='heading'>{`Let's`} Get You Onboarded Today, {firstName ?? ''}</p>
          </div>
          <div className='hyperDrive'>
            <div className='hyperDriveMain'>
              <h1>🎉 Book Your Onboarding & Grab <span style={{color: '#936BDA', marginInline: 4}}> 20 FREE </span> Hyperdrive Credits! 🚀</h1>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: 9, marginTop: 16, marginBottom: 20}}>
            <p className='desc'>During your group onboarding session, you’ll learn:</p>
            <div style={{display: 'flex', flexDirection: 'column', gap: 3}}>
              {pointsList?.map((item, index) => (
                <div key={index} style={{fontSize: 14}}>
                  <p><span style={{fontWeight: 700}}>{item?.heading}</span> <span style={{color: '#4E5156'}}>{item?.text}</span></p>
                </div>
              ))}
            </div>
          </div>
          <div className='specialistText'>11 Product specialists online and ready to help!</div>
          <SpecialistContainer>
            {specialistList?.slice(0, 4)?.map((item, idx) => {
              const isLastItem = specialistList?.slice(0, 4)?.length - 1 === idx;
              const containsExtraItems = specialistList?.length > specialistList?.slice(0, 4)?.length;
              const extraLength = specialistList?.slice(4)?.length;
              return (
                <div key={idx} className='container'>
                  <div style={{position: 'relative'}}>
                    <img src={item?.imgSrc} alt='specialist' width={36} height={36} />
                    <div className='green-indicator'></div>
                  </div>
                  <div>
                    <div className='name'>{item?.name}{(isLastItem && containsExtraItems && extraLength) ? ` and ${extraLength} more` : ''}</div>
                    <div className='status'>{item?.isOnline ? 'Online' : ''}</div>
                  </div>
                </div>
              );
            })}
          </SpecialistContainer>
          <div className='calendly-wrapper'>
            <div className='emptyDiv' />
            <div className='calendly-inner-wrapper'>
              <div
                className='calendly-inline-widget'
                data-url={customerType === 'IN' ? 'https://calendly.com/searchatlas-seo/brand-group-onboarding' : 'https://calendly.com/searchatlas-seo/agency-group-onboarding'}
                style={{minWidth: '100%', height: '378px', display: 'block', border: 'border: 3px solid', borderImageSource: 'linear-gradient(109.21deg, #874ACC 10.23%, #387CE1 60.42%)'}}
              ></div>
            </div>
          </div>
          <div className='buttonContainer'>
            <StyledButton bgColor='rgba(11, 32, 65, 0.12)' disabled={loading} loading={loading} onClick={() => onClose()}>
              <FontAwesomeIcon icon={faCalendar} color='#121212' />
                Remind me later
            </StyledButton>
            <StyledButton transparent style={{padding: 0, fontSize: 12}} txtColor='#4E5156' onClick={() => {
              updateGTMDatalayer({
                event: 'Already booked',
                email: emailAddress,
              });
              onClose(true);
            }}>Already booked</StyledButton>
          </div>
        </div>
      </StyledModal>
    </NoSsr>
  );
});

export default OnboardingPromotionModal;

const SpecialistContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  gap: 35px;
  align-items: center;
  .container {
    display: flex;
    gap: 8px;
    align-items: center;
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #121212;
    }
    .status {
      font-size: 12px;
      font-weight: 400;
      color: #4E5156;
    }
    .green-indicator {
      position: absolute;
      top: 0;
      right: 0;
      background: #2AC155;
      border-radius: 99px;
      width: 12px;
      height: 12px;
      border: 2px solid #fff;
    }
  }
`;

const StyledModal = styled(Modal)`
  margin-top: auto;
  margin-bottom: auto;
  min-height: 240px;
  width: 85vw !important;
  max-width: 832px !important;
  p {
    margin-bottom: 0 !important;
    color: #121212;
    line-height: 24px;
  }
  .rc-dialog-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0 !important;
    min-height: 240px;
    max-height: 95vh !important;
    overflow: hidden;
    .containerWrapper {
      padding: 32px 35px !important;
      max-height: 95vh !important;
      overflow-y: auto;
      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.82);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
    }
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 12px;
  }

  .headingWrapper{
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .heading {
    font-size: 28px;
    font-weight: 700;
    line-height: 39.76px;
  }

  .hyperDrive{
     margin: auto;
     margin-top: 12px;
     width: max-content;

    .hyperDriveMain{
      background: rgba(147, 107, 218, 0.16);
      border-radius: 8px;
      padding: 12px 25px 12px 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      
      h1{
        font-size: 20px;
        line-height: 22.9px;
        margin-bottom: 0;
        font-weight: 500;

        span{
          font-weight: 700;
        }
      }
  }
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #4E5156;
  }
  .specialistText {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #4E5156;
    line-height: 22px;
  }
  .calendly-wrapper {
    padding: 3px;
    border-radius: 12px;
    background-color: transparent;
    position: relative;
    .emptyDiv {
      background-image: linear-gradient(109.21deg, #874ACC 10.23%, #387CE1 60.42%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 12px;
    }
    .calendly-inner-wrapper {
      background: #fff;
      position: relative;
      z-index: 1;
      padding: 6px;
      border-radius: 12px;
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 19px;
  }
`;

const StyledButton = styled(Button)<{bgColor?: string; txtColor?: string; transparent?: boolean}>`
  height: 44px;
  margin: 0 !important;
  border-radius: 8px;
  padding: 13px 30px !important;
  background-color: ${props => props?.bgColor || '#fff'} !important;
  color: ${p => p?.txtColor ||'#121212'};
  border-color: transparent !important;
  font-size: 14px;
  ${p => p?.transparent ? 'padding: 0 !important;' : ''}
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover, &:focus {
    background: ${p => p?.bgColor || '#fff'} !important;
    color: ${p => p?.txtColor ||'#121212'} !important;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: -31px;
  top: 0;
  svg {
    height: 20px !important;
    color: #ffff;
    cursor: pointer;
  }
`;
