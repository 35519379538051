import {Modal} from '@/components/common-components';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {notification} from '@/utils/notification-v2';
import ImagePicker from '../../../imagePicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {PaginationStyled} from '../../../../style';

interface ParamsType {
  page: number;
  pageSize: number;
}
interface GBPMediaLibraryModalProps {
  openGbpLibraryModal: boolean;
  setOpenGbpLibraryModal: (value: boolean) => void;
  setOpenGBPAutomationModal: React.Dispatch<React.SetStateAction<boolean>>;
  isLocalSEOMyTask?: boolean;
  selectImageFromMediaLibrary?: (e: any) => void;
  modalData?: any;
  isInGBPStandAlone?: boolean;
}
function getNewPageValue(url) {
  const urlObj = new URL(url);
  const pageValue = urlObj.searchParams.get('page');
  if (pageValue !== null) {
    const newPageValue = parseInt(pageValue) - 1;
    return newPageValue;
  }
}

export const GBPMediaLibraryModal: React.FC<GBPMediaLibraryModalProps> = observer(({openGbpLibraryModal, setOpenGbpLibraryModal, setOpenGBPAutomationModal, isLocalSEOMyTask, selectImageFromMediaLibrary, modalData, isInGBPStandAlone = false}) => {
  const {ottoV2Store: {
    getGbpMediaLibraryImages,
    uploadImageToGbpMediaLibrary,
    ottoGBPUploadImage,
    deleteImageToGbpMediaLibrary,
    selectedLocation,
  }} = useStore('');
  const [images, setImages] = useState([]);
  const [gettingImages, setGettingImages] = useState(false);
  const [showImageActions, setShowImageActions] = useState(null);
  const [uploadingImagesNames, setUploadingImagesNames] = useState<string[]>([]);
  const [deletingImage, setDeletingImage] = useState<any>(-1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(7);
  const [count, setCount] = useState(0);

  const geImages = async (params: ParamsType, loading?: boolean) => {
    try {
      if (loading) {
        setGettingImages(true);
      }
      const resp = await getGbpMediaLibraryImages(params, false, modalData ? String(modalData?.location) : '', isInGBPStandAlone);
      if (resp) {
        setPage(resp?.next ? getNewPageValue(resp?.next) : resp?.totalPages);
        setPageSize(resp?.pageSize);
        setCount(resp?.count);
        const images = resp?.results?.map(obj => {
          return {id: obj?.id, type: 'photos', url: obj?.url};
        });
        setImages(images);
      }
      setGettingImages(false);
    } catch (error) {
      setGettingImages(false);
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (openGbpLibraryModal) {
      const params = {page: page, pageSize: pageSize};
      geImages(params, true);
    }
  }, [openGbpLibraryModal]);

  // const imageTypes = [
  //   {
  //     key: 'All images',
  //     length: 6,
  //   },
  //   {
  //     key: 'From reviews',
  //     length: 2,
  //   },
  //   {
  //     key: 'From profile',
  //     length: 3,
  //   },
  //   {
  //     key: 'Uploaded',
  //     length: 1,
  //   },
  // ];

  const onDeleteGbpGalleryImage = async id => {
    try {
      setDeletingImage(id);
      await deleteImageToGbpMediaLibrary(id, isInGBPStandAlone);
      notification.success('', 'Image deleted successfully');
      setDeletingImage(-1);
      const params = {page: Number(page), pageSize: Number(pageSize)};
      geImages(params);
    } catch (error) {
      setDeletingImage(-1);
      return Promise.reject(error);
    }
  };
  const selectAndUploadImage = async file => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('format', 'JPEG');
      const response = await ottoGBPUploadImage(formData);
      await uploadImageToGbpMediaLibrary({url: response}, isInGBPStandAlone);
      setUploadingImagesNames(prevState => {
        const uploadedImageName = prevState?.filter(name => name !== file?.name);
        return [...uploadedImageName];
      });
      notification.success('', 'Image uploaded successfully');
      const params = {page: Number(page), pageSize: Number(pageSize)};
      geImages(params);
    } catch (error) {
      setUploadingImagesNames(prevState => {
        const uploadedImageName = prevState?.filter(name => name !== file?.name);
        return [...uploadedImageName];
      });
      setImages(prev => {
        const removedUnUploadedImage = prev?.filter(item => item?.url !== file);
        return [...removedUnUploadedImage];
      });
      return Promise.reject(error);
    }
  };

  const handleImageUpload = file => {
    const MAX_SIZE_BYTES = 2097152;
    if (file.size > MAX_SIZE_BYTES) {
      return notification.error('Image(s) size must be upto 2MB', '');
    }

    const isImageInList = images?.some(image => image.url === file);
    const isImageAlreadyUploading = uploadingImagesNames?.some(imgName => imgName === file?.name);
    if (!isImageInList && !isImageAlreadyUploading) {
      setUploadingImagesNames(prevState => [...prevState, file?.name]);
      setImages(prev => {
        const newId = prev.length > 0 ? prev[prev.length - 1].id + 1 : 0;
        const newItem = {id: newId, url: file, type: 'photos'};
        return [newItem, ...prev];
      });
      selectAndUploadImage(file);
      setShowImageActions(false);
    }
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: 'image/png, image/jpg, image/jpeg',
    showUploadList: false,
    onDeleteGbpGalleryImage: onDeleteGbpGalleryImage,
    gbpGalleryDeletingImageId: deletingImage,
    customRequest: data => {
      if (
        data?.file &&
        data?.file?.type === 'image/png' ||
        data?.file?.type === 'image/jpg' ||
        data?.file?.type === 'image/jpeg'
      ) {
        handleImageUpload(data.file);
      } else {
        notification.error('Acceptable file formats: JPEG, PNG and JPG. Please try again.', '', false, 'OK', null, null, null, null, null, null, null, null, true);
      }
    },
    onDrop(e) {
      e.preventDefault();
      const file = e.dataTransfer.files?.length ? e.dataTransfer.files[0] : null;
      if (
        file?.type === 'image/png' ||
        file?.type === 'image/jpg' ||
        file?.type === 'image/jpeg'
      ) {
        handleImageUpload(file);
      } else {
        notification.error('Acceptable file formats: JPEG, PNG and JPG. Please try again.', '', false, 'OK', null, null, null, null, null, null, null, null, true);
      }
    },
  };

  return (
    <>
      <StyledGenerateQAModal
        maskClosable={true}
        width={'1240px'}
        closable={true}
        onClose={() => {
          setOpenGbpLibraryModal(false);
        }}
        visible={openGbpLibraryModal}
        zIndex={100}
      >
        <StyledModalHeader>
          <div onClick={()=> {
            setOpenGbpLibraryModal(false);
            setOpenGBPAutomationModal(true);
          }}
          style={{display: 'flex', alignItems: 'center', gap: '14px', marginBottom: '16px', cursor: 'pointer'}}
          >
            <FontAwesomeIcon icon={faArrowLeft} /><span>Back to Automate GBP Posting</span>
          </div>
          <div className='modal-heading'>Media Library for {modalData ? modalData?.businessName ?? '' : selectedLocation?.businessName ?? ''}</div>
          <div className='modal-description'>When you upload images to OTTO, it analyzes them,
            writes keyword-optimized descriptions, renames files with your keywords,
            and geotags them for your target area.
            OTTO then uploads these images to your Google Business Profile’s media section and uses them for posts.
            Ensure the images are new to your profile.</div>
        </StyledModalHeader>
        <ModalContent>
          {/* <DeployStatusWrapper>
            {
              imageTypes.map((image, index) => (
                <DeployStatus
                  key={index}
                  disabled={false}
                  onClick={() => {}}
                  isActive={index === 0}
                  borderRadius={index === 0 ? '6px 0px 0px 6px': index + 1 === imageTypes?.length ? '0px 6px 6px 0px' : '0'}
                >{`${image.key} (${image.length})`}</DeployStatus>
              ))
            }
          </DeployStatusWrapper> */}
          {gettingImages ?
            <StyledLoader>
              <RingLoaderV2 color='#D8D8D8' fontSize={100}/>
            </StyledLoader> :
            <>
              <ImagePicker
                wrapperStyle={{maxHeight: '600px', overflow: 'hidden', overflowY: 'auto'}}
                width={'280px'}
                setImages={setImages}
                images={images}
                type='photos'
                showImageActions={showImageActions}
                setShowImageActions={setShowImageActions}
                props={props}
                hideExtra={typeof pageSize === 'number' && (pageSize + 1) === images.length}
                variant='gbp-post'
                uploadingImagesNames={uploadingImagesNames}
                isLocalSEOMyTask={isLocalSEOMyTask}
                selectImageFromMediaLibrary={selectImageFromMediaLibrary}
              />
              <PaginationStyled
                disabled={((deletingImage !== -1) || (uploadingImagesNames?.length)) ? true : false}
                onChange={(page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                  const params = {page: page, pageSize: pageSize};
                  geImages(params, true);
                }}
                total={count}
                pageSize={pageSize}
                current={page}
                showSizeChanger
                pageSizeOptions={['7', '15', '23', '47', '100']}
              />
            </>}
        </ModalContent>
      </StyledGenerateQAModal>
    </>
  );
});

const StyledGenerateQAModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    // right: -34px;
    // top: 0px;
    font-weight: 700;
    // color: #fff !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    line-height: 29.05px;
    margin-bottom: 10px;
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
    line-height: 16.94px;
    margin-bottom: 15px;
  }
`;
const ModalContent = styled.div`

`;

// const DeployStatusWrapper = styled.div`
//   display: flex;
//   margin-bottom: 20px;
// `;

// const DeployStatus = styled.div<{borderRadius: string; isActive: boolean; disabled: boolean}>`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: fit-content;
//   padding: 3px 10px;
//   border-radius: ${p => p?.borderRadius};
//   border: 1px solid ${p => (p?.isActive ? '#B498D0' : '#CDCDCD')};
//   background: ${p => (p?.isActive ? 'rgba(127, 78, 173, 0.15)' : '#fff')};
//   cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
// `;
const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
`;
