import {
  Avatar,
  Dropdown,
  Menu,
  Button,
  Switch,
  Row,
} from 'antd';
import React, {Fragment, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {faVideo} from '@fortawesome/pro-solid-svg-icons';

import {clearHomeSortFromStorage, flushTokenFromCookies} from '@/api/common-utils';
import styles from './style.module.scss';
import {
  CaretDownOutlined,
} from '@ant-design/icons';
import {Modal} from '@/components/common-components';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {routes} from '@/utils/const';
import {useTranslation} from 'next-i18next';

import {MixPanel} from '@/utils/mixpanel';
import {HOME_FREE_CONSULTATION_CLICKED} from '@/constants/events';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileInvoiceDollar, faUserGroup, faCalendarDay, faArrowRightFromBracket, faCreditCard, faSliders, faCodeCompare, faCircleQuestion} from '@fortawesome/pro-regular-svg-icons';
import {faFlaskVial, faPlay, faUserPlus} from '@fortawesome/pro-duotone-svg-icons';
import {CreateUserModal} from './createUserModal';
import {CustomerSwitcherModal} from './customer-switcher/customerSwitcher';
import Link from 'next/link';

const UserMenu: React.FC<{name: string; email: string}> = observer(({name, email}) => {
  const router = useRouter();
  const [isVisibleRefferModal, setIsVisibleRefferModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const {t} = useTranslation('common');

  const {
    reportBuilder: {setreportBuilderIdAndDomain},
    gsc: {setDrawerVisibility, setIsTeamMember, criteria: {clearCriteria}},
    backLinkAnalyzer: {projectStore: {loading}},
    settings: {customer: {externalSettings, isInitialExtendedDetailsFetched, profile, profile: {
      isWhitelabel,
    }}},
  } = useStore('');

  const profileEmail = profile?.emailAddress?.split('@').reduce((acc, n)=>acc+n[0], '').toLocaleUpperCase();
  const avatarName = name.length ? name.split(' ').reduce((acc, n)=>acc+n[0], '').toLocaleUpperCase() : profileEmail;
  const isVendastaClient= profile?.isVendastaClient;

  useEffect(() => {
    const freeUiCheck = JSON.parse(localStorage.getItem('free-ui'));
    if (freeUiCheck) {
      profile.togglePlan(freeUiCheck);
    }
  }, []);

  const refferalIframe = (
    <iframe src='invite-afriend.com' id='refferFrame' className={styles.refferalIframe} />
  );


  const renderMenuContent = () => {
    const eleId = document.getElementById('customerToken') as any;
    const onLogout = () => {
      localStorage.removeItem('whitelabelOtto');
      if (!eleId?.value) {
        flushTokenFromCookies();
        localStorage.removeItem('currentCustomerId');
        setreportBuilderIdAndDomain('');
        localStorage.removeItem('reportBuilderIdAndDomain');
      }
      localStorage.removeItem('currentCustomerId');
      setreportBuilderIdAndDomain('');
      localStorage.removeItem('reportBuilderIdAndDomain');
      localStorage.removeItem('hideGscBanner');
      clearCriteria();
      localStorage.removeItem('onboardingData');
      clearHomeSortFromStorage();
      localStorage.removeItem('meetingBanner');
      MixPanel.reset();
      router.push('/login');
    };
    // const getColor = ()=>{
    //   switch (serviceTier?.toLowerCase()) {
    //     case 'silver':
    //       return '#FFFFFF';
    //     case 'gold':
    //       return '#CB9B54';
    //     case 'diamond':
    //       return '#9FF4FF';
    //     case 'bronze':
    //       return '#CB9B54';
    //     default:
    //       return '#E8E8E8';
    //   }
    // };

    const isAppEcoSystem = () => {
      if (!profile?.isWhitelabel || (profile?.isWhitelabel && profile?.isParentAccount)) {
        return true;
      } else {
        return false;
      }
    };


    return (
      <Menu>
        {/* <div className={styles.modifiedItem} key='service_tier'>
          <div className={styles.tierButton}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{marginRight: 11}}> <NextImg style={{width: '24px', height: '24px'}} src={serviceTier ? `/img/${serviceTier?.toLowerCase()}.png` : '/img/null-tier.png'} /></div>
              <div>
                <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`/${routes.billing}`} passHref>
                  <Tooltip title='Credits can be used to purchase products and services from the Order Builder.'>
                    <div style={{color: getColor()}} className={styles.tierText}>{serviceTier ? `${serviceTier} Tier` : 'Basic Tier'}</div>
                    <div>Order builder credits: {currentCredits}</div>
                  </Tooltip>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.divider} /> */}
        {!isInitialExtendedDetailsFetched || profile?.isShopifyCustomer || profile?.isViewer || (isWhitelabel && !profile.isParentAccount && !profile.shouldShowOrdersPage && !isVendastaClient) ? null : <Menu.Item key='my-order'>
          <Button type='link' className={styles.menuBtn}>
            <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`/${routes.orders.default}`} passHref >
              <a ><span className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faFileInvoiceDollar} style={{color: '#A3A4A4'}} />
              </span>
              <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'My Orders'}</span></a>

            </Link>
          </Button>
        </Menu.Item>}
        {profile?.isVendastaClient || !isInitialExtendedDetailsFetched || profile?.isViewer || (isWhitelabel && !profile.isParentAccount && !profile.shouldShowBillingPage) ? '' :
          <Menu.Item key='billing'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`/${routes.billing}`} passHref>
                <a>    <span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faCreditCard} style={{color: '#A3A4A4'}} />
                </span>
                <span style={{fontSize: '14px'}}>{'Billing'}</span></a>
              </Link>
            </Button>
          </Menu.Item>}
        {isVendastaClient ? '' : <Menu.Item key='team-members'>
          <Button type='link' className={styles.menuBtn} onClick={() => {
            router.push(`${location.pathname}?active_tab=team-members`);
            setIsTeamMember(true);
            setDrawerVisibility(true);
          }}>
            <a style={{display: 'flex', alignItems: 'baseline'}}>
              <span className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faUserGroup} style={{color: '#A3A4A4'}} />
              </span>
              <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Team Members'}</span>
            </a>
          </Button>
        </Menu.Item>}
        {isWhitelabel ? '' :
          <Menu.Item key='consultation'>
            <Button type='link' className={styles.menuBtn} href={'https://searchatlas.com/consultation'} target='_blank'
              onClick={() => {
                MixPanel.track(HOME_FREE_CONSULTATION_CLICKED);
              }}>
              <a style={{display: 'flex', alignItems: 'baseline'}}>
                <span className={styles.iconWrapper}>
                  <FontAwesomeIcon icon={faCalendarDay} style={{color: '#A3A4A4'}} />
                </span>
                <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Book a Demo'}</span>

              </a>
            </Button>
          </Menu.Item>}
        {isAppEcoSystem() ? <Menu.Item key='app-ecosystem'>
          <Button type='link' className={styles.menuBtn}>
            <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`/${routes.appsEcoSystem}`} passHref>
              <a><span className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faCodeCompare} style={{color: '#A3A4A4'}} />
              </span>
              <span style={{fontSize: '14px', paddingLeft: '5px', color: '#fff'}}>{'App Ecosystem'}</span></a>
            </Link>
          </Button>
        </Menu.Item> : ''}
        {!isVendastaClient && <Menu.Item key='logout'>
          <Button type='link' className={styles.menuBtn} onClick={onLogout}>
            <a style={{display: 'flex', alignItems: 'baseline'}}>
              <span className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} style={{color: '#A3A4A4'}} />
              </span>
              <span style={{fontSize: '14px', paddingLeft: '5px'}}>{eleId?.value ? 'Logout customer' : 'Logout'}</span>
            </a>
          </Button>
        </Menu.Item>}

        {profile?.isLinkgraph ? <Fragment>
          <div className={styles.divider} />
          <div className={styles.title}>Team features</div>
          <Menu.Item key='account'>
            <Button type='link' onClick={()=>setShowModal(true)} className={styles.menuBtn}>
              <span className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faUserPlus} style={{color: '#A3A4A4'}} />
              </span>
              <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Create user account'}</span>
            </Button>
          </Menu.Item>
          <Menu.Item key='test'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`/${routes.test}`} passHref >
                <a>
                  <span className={styles.iconWrapper}>
                    <FontAwesomeIcon icon={faFlaskVial} style={{color: '#A3A4A4'}} />
                  </span>
                  <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Testing page'}</span>
                </a>
              </Link>
            </Button>
          </Menu.Item>
          {isVendastaClient ? '' : profile.isLinkgraph &&
            <Menu.Item key='freePlan'>
              <Row justify='center'>
                <div style={{color: '#fff', marginRight: '15px'}}>{t('free-plan-ui')}</div>
                <Switch
                  style={{backgroundImage: 'linear-gradient(90deg,rgba(0,0,0,.25),rgba(0,0,0,.25)),linear-gradient(90deg,#fff,#fff)'}}
                  disabled={loading}
                  defaultChecked={!profile.plan}
                  onChange={(checked, e) => {
                    e.stopPropagation();
                    profile.togglePlan(checked);
                    localStorage.setItem('free-ui', JSON.stringify(checked));
                  }}
                />
              </Row>
            </Menu.Item>
          }
        </Fragment> : ''}
        {/* {profile?.isParentAccount ? <Fragment>
          <div className={styles.divider} />
          <div className={styles.title}>Agency features</div>
          <Menu.Item key='customer-switcher'>
            <Button type='link' onClick={()=>{
              setShowCustomerModal(true);
              getCustomersPlanOptions();
            }} className={styles.menuBtn}>
              <span className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faSearch} style={{color: '#A3A4A4'}} />
              </span>
              <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Customer switcher'}</span>
            </Button>
          </Menu.Item>
        </Fragment> : ''} */}
        <Fragment>
          <div className={styles.divider} />
          <Menu.Item key='startOnboarding'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`/${routes.startOnBoarding}?resetSetting=true`} passHref >
                <a>
                  <span className={styles.iconWrapper}>
                    <FontAwesomeIcon icon={faPlay} color='#A3A4A4'/>
                  </span>
                  <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Get Started Guide'}</span>
                </a>
              </Link>
            </Button>
          </Menu.Item>
          <Menu.Item key='agencySettings'>
            <Button type='link' className={styles.menuBtn}>
              <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`/${routes.agencySettings}`} passHref >
                <a>
                  <span className={styles.iconWrapper}>
                    <FontAwesomeIcon icon={faSliders} style={{color: '#A3A4A4'}} />
                  </span>
                  <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Agency settings'}</span>
                </a>
              </Link>
            </Button>
          </Menu.Item>
          {isWhitelabel ? '' :
            <Menu.Item key='helpCenter'>
              <Button type='link' className={styles.menuBtn}>
                <Link legacyBehavior style={{display: 'flex', alignItems: 'baseline'}} href={`https://help.searchatlas.com/en/`} passHref>
                  <a target='_blank' rel='noopener noreferrer'>
                    <span className={styles.iconWrapper}>
                      <FontAwesomeIcon icon={faCircleQuestion} style={{color: '#A3A4A4'}} />
                    </span>
                    <span style={{fontSize: '14px', paddingLeft: '5px'}}>{'Help Center'}</span>
                  </a>
                </Link>
              </Button>
            </Menu.Item>
          }
        </Fragment>
        {profile.isWhitelabeled || !profile?.isParentAccount ? null : <Fragment>
          {externalSettings?.showLink && (
            <>
              <div className={styles.divider} />
              <div className={styles.zoomTextWithLink}>
          Connect with our live support team, <br/> available Monday to Friday from 8AM to 6PM CT, at our virtual headquarters.
              </div>
              {
                <div className={styles.zoomBtnWrapper}>
                  <Button type='link' href={externalSettings?.zoomLink} target='_blank' className={styles.zoomBtn}>
                    <FontAwesomeIcon icon={faVideo} color='#ffffff' fontSize={16}/>
                    <span>Join meeting</span>
                  </Button>
                </div>}
            </>
          )}
        </Fragment>}
        <Menu.Item style={{height: 0, margin: 0, padding: 0}}>
          <CreateUserModal setShowModal={setShowModal} showModal={showModal}/>
          {showCustomerModal && <CustomerSwitcherModal setShowCustomerModal={setShowCustomerModal} showCustomerModal={showCustomerModal}/>}
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <>
      <Dropdown
        overlay={renderMenuContent}
        trigger={['click']}
        className={styles.dropdown}
        overlayClassName={`${styles.menu} ${styles.dropdownOffset}`}
        placement='bottomRight'
      >
        <div>
          <input type='hidden' name='logedInUserEmail' id='logedInUserEmail' value={email} />
          <input type='hidden' name='logedInUserFullName' id='logedInUserFullName' value={name} />
          <Avatar>{avatarName}</Avatar>{' '}
          <span className={styles['down-icon']}><CaretDownOutlined /></span>
          <Modal visible={isVisibleRefferModal} onClose={() => setIsVisibleRefferModal(false)}>
            {refferalIframe}
          </Modal>
        </div>
      </Dropdown>
    </>
  );
});

export default UserMenu;
