import {Tooltip, Spin, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {notification as notificationV2} from '@/utils/notification-v2';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomain} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {CloseButton, InputDomainWrapper, LinkDeployBtn, PaginationStyled, StyledCloudStackInput, StyledIssuesTable, StyledLink, UrlWrapper, UrlInternalLinkWrapper, StyledIssuesCollapse, PurpleCheckbox} from '../../../../style';
import {getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {getDeployStatus, showNotificationMess} from '../../../../Constants/functions';
import {debounce} from 'lodash';
import {Button} from '@/components/common-components';
import {faChevronLeft, faLink, faTimes, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {ItemWrapper, StyledItemData} from '../../../TopicMap';
import {faCirclePlus, faCircleCheck, faPen} from '@fortawesome/pro-solid-svg-icons';
import {TableTopBar} from '../../../tableTopBar';
import {newNotification} from '@/utils/notification-v3';
import {BulkActionBar} from '../../../bulkActionBar';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';

interface Props {
  setIssueTable: (value: string) => void;
  setPageChanged: (value: boolean) => void;
  setAddPageloader?: (value: boolean) => void;
  setOpenAddPageModal?: (value: boolean) => void;
  setOttoProject?: (value: number) => void;
  setCurrentIssueType?: (value: string) => void;
  deleteItem: any;
  showConfirmationPopup: (value: any, obj?: any) => void;
  componentIssueType: string;
  issueTable: string;
  deleteIssueIds: any;
  maxHeight?: string;
  removeTopBar?: boolean;
}
const extraItem = {
  anchorText: null,
  isApproved: false,
  isCreatedByUser: false,
  itemIndex: null,
  recommendedValue: '',
};

export const InternalLinkingOpportunities = observer(({componentIssueType, setPageChanged, setIssueTable, deleteItem, setAddPageloader, showConfirmationPopup, setOpenAddPageModal, setCurrentIssueType, issueTable, setOttoProject, deleteIssueIds, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    isActiveKeys,
    loadIssueTableData,
    issueTypeArray,
    selectedIssue,
    selectedCategory,
    setSelectedIssue,
    setSelectedCategory,
    ottoUrlLoader,
    ottoIssueType,
    deployOttoUrls,
    loadOttoV2Project,
    setIssueTypeSelected,
    setIsDeploying,
    setOttoSearchTerm,
    setLoadingDetail,
    deploySubOttoUrls,
    deployingProposedFix,
    addSourcePage,
    updateProposeFix,
  }} = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [errorInput, setErrorInput] = useState('');
  const [currentProject, setCurrentProject] = useState(null);
  const [urlId, setUrlId] = useState<any>(-1);
  const [editDescription, setEditDescription] = useState<any>(-1);
  const [proposedFix, setProposedFix] = useState('');
  const [anchorFix, setAnchorFix] = useState('');
  const [subUrlId, setSubUrlId] = useState<any>(-1);
  const [isEdit, setEdit] = useState(null);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [anchorTextError, setAnchorTextError] = useState('');


  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (filteredData?.length && selectAll) {
      const pageArray = filteredData.map(data => data?.id);
      setSelectedPages(pageArray);
    }
  }, [ottoUrls, selectAll]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const deployOttoUrl = async (id, toDeploy, issueType, indexesArray = []) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    setUrlId(`${id}unDeploy`);
    try {
      const data = {toDeploy, issueType, uuid};
      if (issueType === 'images') {
        data['itemIndex'] = id;
      } else {
        data['ottoUrls'] = [id];
      }
      if (['internal_link_suggestions'].includes(issueType)) {
        data['itemIndexes'] = indexesArray;
      }
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(`${indexesArray.length} Change${indexesArray.length > 1 ? 's' : ''} deployed`, 2, 'deploy');
      } else {
        newNotification(`${indexesArray.length} Change${indexesArray.length > 1 ? 's' : ''} Rolled Back`, 2, 'rollback');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
    setUrlId(-1);
  };

  const deploySubOttoUrl = async (id, index, toDeploy, issueType ) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    if (['internal_link_suggestions'].includes(issueType)) {
      setSubUrlId(`${index}${id}`);
    } else {
      setSubUrlId(index);
    }
    try {
      const data = {toDeploy, issueType, uuid};
      data['itemIndex'] = index;
      data['ottoUrls'] = [id];
      await deploySubOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(issueType == 'page_title' ? (currentProject?.isActive ? '1 Change deployed' : '1 Change enabled') : '1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change rolled back', 2, 'rollback');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
    setSubUrlId(-1);
  };

  const onAddSourcePage = async (record, addingTargetPage = false, itemIndex) => {
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls?.find(i => i?.issueType === componentIssueType)?.issueType);
    // updateProposeFix
    const upatePayload = {
      uuid: uuid,
      issueType: record?.issueType,
      id: record.id,
      recommendedValue: anchorFix,
      itemIndex: itemIndex,
    };
    const payload = {
      issue_type: record?.issueType,
      otto_url_id: record?.id,
      anchor_text: anchorFix,
      object_value: editDescription == -1 ? proposedFix : `https://${domainName}/${proposedFix?.startsWith('/') ? proposedFix?.slice(1) : proposedFix}${proposedFix.endsWith('/') ? '' : '/'}`,
      object_key: `https://${domainName}${domainName.endsWith('/') ? '' : '/'}`,
    };
    try {
      if (isEdit) {
        await updateProposeFix(upatePayload);
      } else {
        await addSourcePage(uuid, editDescription == -1 ? record : payload);
      }
      notificationV2.success('Success', `${addingTargetPage ? 'Target' : 'Source'} Page ${isEdit ? 'updated' : 'added'} successfully`);
      await loadIssueTableData({
        uuid: getOttoV2Project?.uuid,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        issue_type: ottoUrls?.find(i => i?.issueType === componentIssueType)?.issueType,
        otto_project: getOttoV2Project?.id,
      });
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
    } catch (error) {
      setEditDescription(-1);
    } finally {
      setAddPageloader && setAddPageloader(true);
      setOpenAddPageModal && setOpenAddPageModal(false);
      setEditDescription(-1);
      setProposedFix('');
      setCurrentIssueType && setCurrentIssueType('');
      setEdit(null);
      setAnchorFix(null);
      setProposedFix('');
    }
  };

  function hasValidSlashes(str) {
    const regex = /\/{2,}/;
    return !regex.test(str);
  }

  const inputDescriptionContainer = (record, link) => {
    return (
      <div style={{minWidth: 'auto'}}>
        <div style={{
          display: 'flex',
          gap: '10px',
          width: '100%',
          alignItems: 'center',
          flexWrap: 'wrap',
          minWidth: 150,
        }}>
          <InputDomainWrapper>
            <StyledCloudStackInput className='anchor' placeholder='Anchor text' style={{width: '100%'}} disabled={deployingProposedFix} value={anchorFix} onChange={e => {
              setAnchorFix(e.target.value);
              if (e?.target?.value) setAnchorTextError('');
            }}/>
          </InputDomainWrapper>
          {anchorTextError && <p style={{color: 'red', width: '100%', marginBottom: 0, lineHeight: '10px', marginTop: '-4px', fontSize: '12px'}}>{anchorTextError}</p>}
          <InputDomainWrapper>
            <div style={{cursor: isEdit ? 'not-allowed' : ''}} className='domain'>{domainName}/</div><StyledCloudStackInput disabled={isEdit || deployingProposedFix} style={{width: '100%'}} value={proposedFix} onChange={e => {
              setProposedFix(e.target.value);
              setErrorInput('');
            }}/>
          </InputDomainWrapper>
          {(errorInput) && <p style={{color: 'red', width: '100%', marginBottom: 0, lineHeight: '10px', marginTop: '-4px', fontSize: '12px'}}>{errorInput}</p>}
          <Button
            buttonType={['internal_link_suggestions'].includes(record?.issueType) ? 'blue-button' : 'green'}
            loading={deployingProposedFix}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '26px',
              fontSize: '14px',
              padding: '0px 10px',
            }}
            onClick={() => {
              let hasError = false;
              if (!isEdit && (proposedFix?.includes('//') || proposedFix?.includes(':') || proposedFix?.includes('www') || proposedFix?.includes('https') || proposedFix?.includes('.') || proposedFix?.includes('https://') || !proposedFix?.trim() || !hasValidSlashes(proposedFix))) {
                if (proposedFix?.includes('//')) {
                  setErrorInput(' Double slash (//) is not allowed in Slug');
                } else {
                  setErrorInput(!proposedFix?.trim() ? 'URL is required' : 'Please enter a valid URL!');
                }
                hasError = true;
              }
              if (anchorFix?.includes('|')) {
                setAnchorTextError('Anchor text should not contain "|"');
                hasError = true;
              } else if (!anchorFix?.trim()) {
                setAnchorTextError('Anchor text is required');
                hasError = true;
              }
              if (hasError) return;

              if (proposedFix === '/') {
                setErrorInput('Slug is required');
                hasError = true;
              } else if (proposedFix?.includes('/') && proposedFix.split('/').length === 2 && proposedFix.trim() === '/') {
                setErrorInput('Slug is required');
                hasError = true;
              } else if (proposedFix?.startsWith('/')) {
                setErrorInput('Slug should not start with a slash (/)');
                hasError = true;
              }

              if (!hasError) {
                setAnchorTextError('');
                setErrorInput('');
                onAddSourcePage(record, false, link?.itemIndex);
              }
            }}
          >
            {!deployingProposedFix ? isEdit ? 'Update' : 'Save' : ''}
          </Button>
          <CloseButton style={{
            height: '26px',
            width: '32px',
          }}>
            <FontAwesomeIcon icon={faTimes} fontSize={18} color='white' onClick={() => {
              setEditDescription(-1);
              setProposedFix('');
              setEdit(null);
              setAnchorFix(null);
              setProposedFix('');
              setAnchorTextError('');
              setErrorInput('');
            }} />
          </CloseButton>
        </div>
      </div>
    );
  };

  const descriptionContainer = index => {
    return (
      <FreezeWrapper>
        <StyledLink onClick={() => {
          setEditDescription(index);
          setEdit(null);
          setAnchorFix(null);
          setProposedFix('');
        }}
        >
          <FontAwesomeIcon
            icon={faCirclePlus}
            color={'#2D6CCA'}
            fontSize={14}
          />
          <UrlWrapper style={{cursor: 'pointer', maxWidth: '100%'}} >Add Source Page</UrlWrapper>
        </StyledLink>
      </FreezeWrapper>
    );
  };
  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };

  const columns = [
    {
      title: <div className='column-title'>TARGET PAGE</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: '320px',
      render: (_, record) => {
        return (<div style={{display: 'flex', alignItems: 'flex-start', gap: 10}}>
          {showBulkBanner && <PurpleCheckbox checked={selectedPages.includes(record?.id)} onClick={()=>selectUnselectPages(record?.id)}/>}
          <UrlWrapper
            onClick={() => openUrl(`${addProtocolToDomain(domainName)}${record?.path?.startsWith('/') ? '' : '/'}${record?.path}`, '_blank')}>
            {record?.path}
          </UrlWrapper>
        </div>);
      },
    },
    {
      title: <div className='column-title' style={{paddingLeft: '8px'}}>SOURCE PAGE</div>,
      dataIndex: 'sourcePage',
      key: 'sourcePage',
      className: 'greenish-column source-page',
      width: '60%',
      render: (_, record, index) => {
        const linksArray = record?.values;
        // Extra item, adding to the 0 and last index to the new array for deploy buttons and add source button
        const newArray = record?.values?.length ? !showBulkBanner ? [extraItem, ...linksArray, extraItem] : [extraItem, ...linksArray] : [];
        const deployedLinksCount = record?.values?.length ? record?.values?.filter(link => link?.isApproved) : [];
        return (
          newArray?.length && newArray?.map((link, idx) => (
            <ItemWrapper style={{marginLeft: '50px'}} key={idx} itemIndex={idx} totalItems={newArray?.length || 0}>
              <StyledItemData className={record?.id == deleteIssueIds?.parent && link.itemIndex == deleteIssueIds?.child ? 'deletedItem' : ''} style={{width: '100%'}} itemIndex={idx} totalItems={newArray?.length || 0}>
                {idx === 0 && <FontAwesomeIcon icon={faChevronLeft} fontSize={14} color='#4E5156' className='first-item-arrow'/>}
                {idx === 0 && <div className='link-wrapper'><FontAwesomeIcon icon={faLink} fontSize={12} color='#54565A' className='link-icon'/></div>}
                {idx === 0 ?
                // Add deploy buttons for fist duplicated item
                  !showBulkBanner ? <div style={{display: 'flex', alignItems: 'center', gap: '14px'}}>
                    <div>Deployed: {deployedLinksCount?.length} of {record?.values?.length}</div>
                    <FreezeWrapper>
                      <LinkDeployBtn color='#219843' onClick={() => deployOttoUrl(record?.id, true, record?.issueType, record?.values.map(value => value.itemIndex))}>
                        {currentProject?.isEngaged ? 'Deploy' : 'Enable'} all {urlId === record?.id ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, color: '#219843'}} spin />} /> : ''}
                      </LinkDeployBtn>
                    </FreezeWrapper>
                    <FreezeWrapper>
                      <LinkDeployBtn color='#F44343'onClick={() => deployOttoUrl(record?.id, false, record?.issueType, record?.values.map(value => value.itemIndex))}>
                        {currentProject?.isEngaged ? 'Undeploy' : 'Disable'} all {urlId === `${record?.id}unDeploy` ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, color: '#F44343'}} spin />} /> : ''}
                      </LinkDeployBtn>
                    </FreezeWrapper>
                  </div> : <div style={{height: '20px'}}></div> :
                // Add deploy buttons for last duplicated item
                  (!showBulkBanner && (idx === newArray?.length - 1)) ?
                    <div className='wrapper'>
                      <div style={{display: 'flex'}}>
                        {editDescription !== index ? descriptionContainer(index) : inputDescriptionContainer(record, link)}
                      </div>
                    </div> :
                    <div className='wrapper'>
                      <div style={{display: record?.id == deleteIssueIds?.parent && link.itemIndex == deleteIssueIds?.child ? 'none' : 'flex'}}>
                        {link.itemIndex == isEdit ? inputDescriptionContainer(record, link) :
                          <StyledLink style={record?.id == deleteIssueIds?.parent && link.itemIndex == deleteIssueIds?.child ? {display: 'none'} : {}}>
                            {showBulkBanner ? <></> : (subUrlId === `${link?.itemIndex}${record?.id}`) ?
                              <Spin indicator={<LoadingOutlined style={{fontSize: 10, color: '#219843'}} spin />} /> :
                              <FreezeWrapper removeTooltip={link?.isApproved}>
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  color={link?.isApproved ? '#2AC155' : '#A3A4A4'}
                                  fontSize={14}
                                  onClick={() => deploySubOttoUrl(record?.id, link?.itemIndex, link?.isApproved ? false : true, record?.issueType)}
                                />
                              </FreezeWrapper>}
                            <UrlInternalLinkWrapper style={{maxWidth: '100%'}} key={idx} onClick={() => openUrl(link.recommendedValue, '_blank')}><Tooltip title='Anchor text'><span style={{color: '#121212'}}>{link.anchorText ? `${link.anchorText?.replace('|', '')} - ` : ''}</span></Tooltip>
                              <Tooltip title='Source Page URL'><span className='link'>{link.recommendedValue}</span></Tooltip></UrlInternalLinkWrapper>
                            {!showBulkBanner && <>
                              <FreezeWrapper placement='topRight' removeTooltip={link?.isApproved}>
                                <Tooltip title={deleteItem === link?.itemIndex ? 'Editing':'Edit'}>
                                  {deleteItem === `${link?.itemIndex}${record?.id}` ?
                                    <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                                    <FontAwesomeIcon onClick={() => {
                                      setAnchorFix(link.anchorText?.replace('|', ''));
                                      setProposedFix(link.recommendedValue.split(`${domainName}${domainName.endsWith('/') ? '' : '/'}`)[1]);
                                      setEdit(link.itemIndex);
                                    }} className={'source-delete-icon'} icon={faPen} color='#4E5156' fontSize={14}/>}
                                </Tooltip>
                              </FreezeWrapper>
                              <FreezeWrapper placement='topRight' removeTooltip={link?.isApproved}>
                                <Tooltip title={deleteItem === link?.itemIndex ? 'Deleting':'Delete'}>
                                  {deleteItem === `${link?.itemIndex}${record?.id}` ?
                                    <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                                    <FontAwesomeIcon onClick={() => showConfirmationPopup(record, link)} className={'source-delete-icon'} icon={faTrash} color='#4E5156' fontSize={14}/>}
                                </Tooltip>
                              </FreezeWrapper>
                            </>}
                          </StyledLink>}
                      </div>
                    </div>}
              </StyledItemData>
            </ItemWrapper>
          ))
        );
      },
    },
  ];
  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    const categories = issue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : issue;
    const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === categories || issueObj.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === category);
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === issueType);
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (rating) {
          params['star_rating__in'] = rating;
        }
        if (searchText) {
          params['search'] = searchText;
          setOttoSearchTerm(searchText);
        } else {
          setOttoSearchTerm('');
        }
        if (searchText) {
          searchDebounce(params, itemObject?.isSitewide);
        } else {
          await loadIssueTableData(params, itemObject?.isSitewide);
        }
      }
    }
    setLoadingDetail(false);
  }, []);
  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, '', activeKey);
  };
  const selectAllOnPage = () => {
    setSelectedPages(filteredData.map(data => data?.id));
  };

  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      {!removeTopBar && <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setOpenAddPageModal={setOpenAddPageModal}
        setCurrentIssueType={setCurrentIssueType}
        setOttoProject={setOttoProject}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter}
        setShowBulkBanner={setShowBulkBanner}
      />}
      {showBulkBanner ? <BulkActionBar
        count={url?.issueTable?.count}
        setShowBulkBanner={setShowBulkBanner}
        selectedPages={selectedPages}
        setSelectedPages={setSelectedPages}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectAllOnPage={selectAllOnPage}
        issueType={url?.issueType}
        currentProject={currentProject}
        setPageChanged={setPageChanged}
      /> : <></>}
      <Collapse.Panel key='open' header={<></>}>
        <StyledIssuesTable
          loading={ottoUrlLoader && ottoIssueType === componentIssueType}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          scroll={{x: 850, y: 'auto'}}
          maxHeight={maxHeight}
        />
        <PaginationStyled
          onChange={(page, pageSize) => {
            setPageChanged(true);
            setIssueTable(url?.issueType);
            handlePaginationChange([url?.issueType], page, pageSize);
            saveOttoTablePageSize(componentIssueType, pageSize);
            setPageSize(pageSize);
          }}
          total={url?.issueTable?.count}
          pageSize={pageSize}
          current={url?.page ?? 1}
          showSizeChanger
          pageSizeOptions={['5', '10', '20', '50', '100']}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});
