import {StyledWidgetModal} from '@/components/dashboard/pages/otto-page-v2-main';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {notification} from '@/utils/notification-v2';
interface SettingsModalProps {
  visible: boolean;
  onClose: (value: any) => void;
}
import {
  ApiOutlined,
  MailOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Layout, Menu} from 'antd';
import {faAddressCard} from '@fortawesome/pro-light-svg-icons';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {ApiKey} from '@/components/dashboard/settings/settings/ApiKey';
import {GASettingsV2} from '@/components/dashboard/settings/settings/GaSettings/GaSettingsV2';
import TeamMembers from '@/components/dashboard/settings/settings/TeamMembers';
import {YourProfileV2} from '@/components/dashboard/settings/settings/YourProfile/yourProfileV2';
import {EmailSetting} from './emailsetting';
import {apiError} from '@/utils/api';
import {useRouter} from 'next/router';
import {updateQueryStringParameter} from '@/utils/url';

const {Content, Sider} = Layout;

const menuItems: MenuProps['items'] = [
  {
    key: 'user-account',
    label: 'USER ACCOUNT',
    type: 'group',
    children: [
      {
        key: 'profile',
        icon: <FontAwesomeIcon icon={faAddressCard} fontSize={22} />,
        label: 'Profile',
      },
      {key: 'email', icon: <MailOutlined />, label: 'Email Settings'},
      {key: 'team', icon: <TeamOutlined />, label: 'Team Members'},
      {key: 'integration', icon: <ApiOutlined />, label: 'Integrations'},
      {key: 'api', icon: <ApiOutlined />, label: 'API Keys'},
    ],
  },
  // {
  //   key: 'agency-account',
  //   label: 'AGENCY ACCOUNT',
  //   type: 'group',
  //   children: [
  //     {key: '6', icon: <DashboardOutlined />, label: 'Whitelabel Dashboard'},
  //     {key: '7', icon: <SettingOutlined />, label: 'Agency Settings'},
  //   ],
  // },
];
const SettingsModal: React.FC<SettingsModalProps> = observer(({visible, onClose}) => {
  const {settings,
    navBar: {getCustomerEmailSetting},
  } = useStore('');
  const router = useRouter();
  const {integration} = settings;
  const [selectedMenuKey, setSelectedMenuKey] = useState<string>('profile');
  const {teamMembers: {setFromResponse}, customer: {profile: {customerId, isParentAccount}}} = settings;

  const getEmailSetting = async () => {
    try {
      await getCustomerEmailSetting();
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage, false, 'OK');
    }
  };

  useEffect(() => {
    getEmailSetting();
    setFromResponse();
  }, []);

  useEffect(() => {
    if (visible && router.query['active_modal'] === 'settings') {
      if (router.query['active_section'] && (router.query['active_section'] !== 'email'|| isParentAccount)) {
        const tab = router.query['active_section'];
        setSelectedMenuKey(String(tab));
      } else {
        router.push(
          `${updateQueryStringParameter(location.href, 'active_section', 'profile')}`,
          undefined,
          {shallow: true},
        );
        setSelectedMenuKey('profile');
      }
    }
  }, [visible]);
  const formatAccountID = (accountID: number) => {
    const accountIDString = accountID?.toString();
    const formattedID = accountIDString.replace(/(\d{3})(?=\d)/g, '$1-');
    return formattedID;
  };

  const AccountId = (
    <div className={'accountId'}>
      Account ID:{' '}
      {customerId ?
        formatAccountID(customerId) :
        '-'}
    </div>
  );

  const handleMenuClick: MenuProps['onClick'] = e => {
    router.push(
      `${updateQueryStringParameter(location.href, 'active_section', e.key)}`,
      undefined,
      {shallow: true},
    );
    setSelectedMenuKey(e.key);
  };
  const renderContent = () => {
    switch (selectedMenuKey) {
      case 'profile':
        return <MainContainer>
          <div className='main-Heading'>Profile {AccountId}</div>
          <div className='container-wrapper profile-wrapper'>
            <YourProfileV2 />
          </div>
        </MainContainer>;
      case 'email':
        return <MainContainer>
          <div className='main-Heading'>Email Settings</div>
          <div className='container-wrapper'>
            <EmailSetting onClose={onClose}/>
          </div>
        </MainContainer>;
      case 'team':
        return <MainContainer>
          <div className='main-Heading'>Team Member {AccountId}</div>
          <div className='container-wrapper'>
            <TeamMembers />
          </div>
        </MainContainer>;
      case 'integration':
        return <GASettingsV2 profile={integration.profiles}/>;
      case 'api':
        return <MainContainer>
          <div className='main-Heading'>API Keys {AccountId}</div>
          <ApiKey inputStyles={{border: '1px solid #d9d9d9 !important;'}} />
        </MainContainer>;
      case '6':
        return <MainContainer>
          <div className='main-Heading'>Whitelabel Dashboard Content {AccountId}</div>
        </MainContainer>;
      case '7':
        return <MainContainer>
          <div className='main-Heading'>Agency Settings Content {AccountId}</div>
        </MainContainer>;
      default:
        return <div>Select an option from the menu</div>;
    }
  };
  const filteredMenuItems = menuItems.map(group => {
    if (group.key === 'user-account' && 'children' in group) {
      return {
        ...group,
        children: group.children.filter(item => !(item.key === 'email' && !isParentAccount)),
      };
    }
    return group;
  });
  return (
    <StyledModal
      width={'92vw'}
      closable={true}
      closeIcon={
        <div onClick={onClose}>
          <FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark} />
        </div>
      }
      visible={visible}
      style={{top: 10}}
      zIndex={10}
    >
      <Layout>
        <Content>
          <Layout>
            <Sider width={230}>
              <StyledMenu
                mode='inline'
                onClick={handleMenuClick}
                selectedKeys={[selectedMenuKey]}
                defaultSelectedKeys={['profile']}
                style={{
                  height: '100%',
                  padding: '10px',
                }}
                items={filteredMenuItems}
              />
            </Sider>
            <Content style={{padding: '0 24px', backgroundColor: 'white'}}>{renderContent()}</Content>
          </Layout>
        </Content>
      </Layout>
    </StyledModal>
  );
});

export default SettingsModal;

const StyledModal = styled(StyledWidgetModal)`
  .rc-dialog-content {
    height: 90vh;
    /* height: 200vh; */
    .rc-dialog-body {
      height: 100%;
      .ant-layout {
        height: 100%;
      }
    }
  }
`;

const StyledMenu = styled(Menu)`
background-color: #F9F9FB !important;
  .ant-menu-item {
    &:hover {
      background-color: #f5f5f5 !important;
      border-radius: 8px;
    }
    &:active {
      background-color: #f5f5f5 !important;
      border-radius: 8px;
    }
  }
  .ant-menu-item-selected::after {
    border-right: 0 !important;
    opacity: 0;
  }

  .ant-menu-item-selected {
    background-color: #d0ddf1 !important;
    width: 100%;
    border-radius: 8px;
    color: #2d6cca !important;

    .anticon {
      color: #2d6cca !important;
    }
    .ant-menu-item-group > .ant-menu-item-group-title {
      font-size: 14px !important;
      font-weight: bold !important;
      color: #595959 !important;
      margin-top: 16px !important;
    }
  }
`;
const MainContainer = styled.div`
height: 100%;
display: flex;
flex-direction: column;
  .main-Heading{
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    padding-top: 35px;
    padding-left: 1px;
    font-weight: 600;
    line-height: normal;
    color: #121212;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .accountId {
      color: #121212;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .container-wrapper {
    overflow: hidden;
    height: 90%;
    .email-updates-wrapper {
      margin-top: 15px;
      overflow: hidden;
      overflow-y: auto;
      height: 98%;
      .ant-collapse {
        margin-top: 0px !important;
      }
      .seo-task-collapse {
        margin-bottom: 0px;
      }
      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #4E515680;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #F9F9FB;
        border-radius: 10px;
      }
    }
    .team-members {
      height: 100%;
      overflow: hidden;
    }
    .team-members >.style-from-new-settings {
      overflow: hidden;
      min-height: unset !important;
      height: 100% !important;
      .table-wrapper {
        .ant-table-body {
          &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: #4E515680;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            background: #F9F9FB;
            border-radius: 10px;
          }
        }
        .bottomTable {
          display: none !important;
        }
      }
    }
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #F9F9FB;
      border-radius: 10px;
    }
}
.profile-wrapper {
  overflow-y: auto;
}
`;

