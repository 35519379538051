import {Tooltip, Spin, Dropdown, Collapse, Empty, Button} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faCopy, faTimes, faPen, faRotate} from '@fortawesome/pro-regular-svg-icons';
import {faSortDown, faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomain} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {CloseButton, ImagesDropdownWrapper, PaginationStyled, RedirectWrapper, StatusMenu, StatusWrapper, StyledInput, StyledIssuesCollapse, StyledIssuesTable, TableBar, UrlWrapper} from '../../../../style';
import {StyledMenu} from '../../../../Utils/styledMenu';
import {TableTopBar} from '../../../tableTopBar';
import {canDeploy, copyLink, getDeployStatus, getStatusText, showNotificationMess} from '../../../../Constants/functions';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {debounce} from 'lodash';
import {newNotification} from '@/utils/notification-v3';
import styled from 'styled-components';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: any) => void;
  issueTable: string;
  maxHeight?: string;
  removeTopBar?: boolean;
}

export const Links = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    loadIssueTableData,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    setOttoSearchTerm,
    isActiveKeys,
    selectedIssue,
    generatingAiProposedFix,
    selectedCategory,
    deployProposedFix,
    deployingProposedFix,
    generateAiProposedFix,
    setAIGenerateBtnClick,
  },
  settings: {customer: {getCustomerQuota, profile: {whitelabelOtto}}},
  } = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [currentProject, setCurrentProject] = useState(null);
  const [urlId, setUrlId] = useState<any>(-1);
  const [ottoUrls, setOttoUrls] = useState([]);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [proposedFix, setProposedFix] = useState('');
  const [proposedFixId, setProposedFixId] = useState([]);
  const [editDescription, setEditDescription] = useState<any>(-1);

  const {width} = useWindowSize();
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;
  const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [selectedBrokenLinkId, setSelectedBrokenLinkId] = useState(-1);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);
  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const deployOttoUrl = async (id, toDeploy, issueType, values) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    setUrlId(id);
    try {
      const data = {toDeploy, issueType, uuid, ottoUrls: [id]};
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        const length = values?.filter(value => !value?.isApproved)?.length;
        newNotification(`${length} Change${length>1 ? 's' : ''} deployed`, 2, 'deploy');
      } else {
        const length = values?.filter(value => value?.isApproved)?.length;
        newNotification(`${length > 0 ? length : ''} Change${length>1 ? 's' : ''} Rolled Back`, 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      setUrlId(-1);
      return Promise.reject(error);
    }
  };

  const deployOttoUrlLink = async (id, toDeploy, issueType, itemIndex) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    setUrlId(itemIndex);
    try {
      const data = {toDeploy, issueType, uuid, ottoUrls: [id], itemIndex};
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change Rolled Back', 2, 'rollback');
      }
      setUrlId(-1);
      setSelectedBrokenLinkId(-1);
    } catch (error) {
      setUrlId(-1);
      return Promise.reject(error);
    }
  };

  const deployOttoSection = async (toDeploy: boolean) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    try {
      await deployOttoUrls({toDeploy, issueType: url?.issueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(url?.issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('Changes Deployed Successfully', 2, 'deploy');
      } else {
        newNotification('Changes Rolled Back', 2, 'rollback');
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onChangeProposedFix = async (id, issueType, itemIndex) => {
    try {
      await deployProposedFix({id, issueType, proposedFix, uuid}, itemIndex);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setEditDescription(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
      setEditDescription(-1);
    }
  };

  const inputDescriptionContainer = (record, obj) => {
    return (
      <div style={{minWidth: 'auto', width: '203px'}}>
        <div style={{
          display: 'flex',
          gap: '10px',
          width: '100%',
          alignItems: 'center',
          flexWrap: 'wrap',
          minWidth: 200,
        }}>
          <StyledInput rows={2} value={proposedFix} onChange={e => setProposedFix(e.target.value)}/>
          <Button
            loading={deployingProposedFix}
            disabled={!proposedFix}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '22px',
              fontSize: '13px',
              padding: '0px 15px',
              backgroundColor: '#2ac155',
              color: 'white',
            }}
            onClick={() => onChangeProposedFix(record?.id, record?.issueType, obj.itemIndex)}
          >
            {'Save'}
          </Button>
          <CloseButton style={{height: '20px'}} onClick={() => {
            setEditDescription(-1);
            setProposedFix('');
          }} >
            <FontAwesomeIcon icon={faTimes} fontSize={14} color='white' />
          </CloseButton>
        </div>
      </div>
    );
  };

  const generateNewProposedFix = async (id, issueType, itemIndex) => {
    const fixId = `${id}-${itemIndex}`;
    try {
      setAIGenerateBtnClick(true);
      setProposedFixId(prev => [...prev, fixId]);
      await generateAiProposedFix({id, issueType, itemIndex, uuid});
      getCustomerQuota();
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setProposedFixId(prev => prev.filter(id => id != fixId));
    } catch (error) {
      setProposedFixId(prev => prev.filter(id => id != fixId));
      return Promise.reject(error);
    }
  };

  const dropdownOverlay = (record, columnKey) => {
    const linkKeys = ['has_internal_broken_links', 'has_external_broken_links'];
    const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
    const redirectItems = record?.values?.filter(item => item?.issueType === 'has_internal_links_that_redirect');
    const brokenItems = record?.values?.filter(item => linkKeys.includes(item?.issueType));
    return (
      <StatusMenu style={{padding: 0, width: '600px'}}>
        <ImagesDropdownWrapper>
          {columnKey === 'redirects' ? (<>
            <TableBar>
              <div style={{color: '#4E5156', fontSize: '12px', width: '112px'}}>STATUS</div>
              <div style={{width: '12px'}}></div>
              <div style={{color: '#4E5156', fontSize: '12px', width: '205px'}}>CURRENT VALUE</div>
              <div style={{width: '12px'}}></div>
              <div style={{color: '#4E5156', fontSize: '12px', width: '220px'}}>FIXED VALUE</div>
            </TableBar>
            <RedirectWrapper className='container'>
              {redirectItems?.length ? redirectItems?.map((obj, idx) => (
                <div className='link-item' key={idx}>
                  <FreezeWrapper removeTooltip={obj?.isApproved}>
                    <StatusWrapper status={obj?.isApproved} style={{cursor: 'pointer', width: '112px'}} onClick={()=> deployOttoUrlLink(record?.id, !obj?.isApproved, record?.issueType, obj?.itemIndex)}>
                      {urlId === obj?.itemIndex ? <Spin indicator={antUrlIcon} /> :
                        <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={obj?.isApproved ? `#2AC155` : '#A3A4A4'} />
                      }
                      <span>{getStatusText(obj?.isApproved, currentProject)}</span>
                    </StatusWrapper>
                  </FreezeWrapper>
                  <div style={{width: '212px'}}>
                    <a href={obj?.currentValue} target='_blank' rel='noreferrer' style={{color: '#A3A4A4', fontSize: '12px', textDecoration: 'line-through', wordBreak: 'break-all', whiteSpace: 'normal'}}>
                      {obj?.currentValue ?? '-'}
                    </a>
                  </div>
                  <FontAwesomeIcon icon={faArrowRight} color='#A3A4A4' fontSize={14}/>
                  <div style={{width: '212px', display: 'flex', alignItems: 'flex-start', marginLeft: 10}}>
                    <a href={obj?.recommendedValue} target='_blank' rel='noreferrer'style={{color: '#2D6CCA', fontSize: '12px', wordBreak: 'break-all', whiteSpace: 'normal'}}>
                      {obj?.recommendedValue ?? '-'}
                    </a>
                    <FontAwesomeIcon icon={faCopy} fontSize={14} color='#4E5156' style={{marginLeft: '8px', cursor: 'pointer'}} onClick={() => copyLink(obj?.recommendedValue)}/>
                  </div>
                </div>
              )) : <Empty/>}
            </RedirectWrapper>
          </>) : columnKey === 'brokenLinks' ? (<>
            <TableBar style={{justifyContent: 'normal'}}>
              <div style={{color: '#4E5156', fontSize: '12px', width: '112px'}}>STATUS</div>
              <div style={{color: '#4E5156', fontSize: '12px', width: '212px'}}>BROKEN LINKS</div>
              <div style={{color: '#4E5156', fontSize: '12px', width: '212px'}}>{`${whitelabelOtto} SUGGESTION`}</div>
            </TableBar>
            <RedirectWrapper className='container'>
              {brokenItems?.length ? brokenItems?.map((obj, idx) => {
                return (
                  <div className='link-item' style={{justifyContent: 'normal'}} key={idx}>
                    <FreezeWrapper removeTooltip={obj?.isApproved}>
                      <StatusWrapper
                        status={obj?.isApproved}
                        style={{cursor: 'pointer', width: '112px', marginRight: '10px'}}
                        onClick={()=> deployOttoUrlLink(record?.id, !obj?.isApproved, record?.issueType, obj?.itemIndex)}>
                        {urlId === obj?.id ? <Spin indicator={antUrlIcon} /> :
                          <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={obj?.isApproved ? `#2AC155` : '#A3A4A4'} />
                        }
                        <span>{getStatusText(obj?.isApproved, currentProject)}</span>
                      </StatusWrapper>
                    </FreezeWrapper>
                    <div style={{display: 'flex', alignItems: 'center', padding: '0 10px'}}>
                      <div>
                        <a href={obj?.currentValue} target='_blank' rel='noreferrer'style={{color: '#2D6CCA', fontSize: '12px', wordBreak: 'break-all', whiteSpace: 'normal', width: '180px'}}>
                          {obj?.currentValue ?? '-'}
                        </a>
                        <FontAwesomeIcon icon={faCopy} fontSize={14} color='#2D6CCA' style={{margin: '0 8px', cursor: 'pointer'}} onClick={() => copyLink(obj?.currentValue)}/>
                      </div>
                      <FontAwesomeIcon icon={faArrowRight} fontSize={14} color='#2D6CCA' style={{margin: '0 8px', cursor: 'pointer'}}/>
                    </div>
                    {/* {inputDescriptionContainer(record)} */}
                    {editDescription !== `${idx}-${obj?.issueType}` ? obj?.recommendedValue ? <>
                      <div style={{display: 'flex', alignItems: 'center', margin: '0 8px'}}>
                        <a href={obj?.recommendedValue} target='_blank' rel='noreferrer'style={{color: '#2D6CCA', fontSize: '12px', wordBreak: 'break-all', whiteSpace: 'normal', width: '173px'}}>
                          {obj?.recommendedValue ?? '-'}
                        </a>
                      </div>
                      <span className='icons-wrapper' key={idx} style={{width: '100px'}}>
                        <FreezeWrapper placement='topRight' removeTooltip={obj?.isApproved}>
                          <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Edit'}>
                            <FontAwesomeIcon style={{cursor: (urlId !== -1) ? 'not-allowed' : 'pointer', margin: '0 5px'}} icon={faPen} fontSize={12} color='#4E5156' onClick={() => {
                              if (urlId !== -1) {
                                return;
                              }
                              setEditDescription(`${idx}-${obj?.issueType}`);
                              setProposedFix(obj?.recommendedValue);
                            }}/>
                          </Tooltip>
                        </FreezeWrapper>
                        <FreezeWrapper placement='topRight'>
                          <Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                            <FontAwesomeIcon
                              icon={faRotate}
                              onClick={()=> generateNewProposedFix(record?.id, record?.issueType, obj.itemIndex)}
                              className={generatingAiProposedFix && proposedFixId.includes(`${record?.id}-${obj?.itemIndex}`) ? 'fa-spin' : ''}
                              fontSize={12}
                              color='#4E5156'
                              style={{cursor: 'pointer'}}/>
                          </Tooltip>
                        </FreezeWrapper>
                      </span></> : <span className='icons-wrapper' style={{display: 'flex', gap: 10}}>
                      <FreezeWrapper placement='topRight' removeTooltip={obj?.isApproved}>
                        <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Write'}>
                          <ContentWrappers style={{cursor: 'pointer'}} className='content-wrapper' onClick={() => {
                            setEditDescription(`${idx}-${obj?.issueType}`);
                            setProposedFix(obj?.recommendedValue);
                          }}>
                            <FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' />Write
                          </ContentWrappers>
                        </Tooltip>
                      </FreezeWrapper>
                      <FreezeWrapper placement='topRight'>
                        <Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                          <ContentWrappers className='content-wrapper' style={{width: '117px', cursor: 'pointer'}} onClick={() => {
                            if (!proposedFixId.includes(`${record?.id}-${obj?.itemIndex}`)) generateNewProposedFix(record?.id, record?.issueType, obj.itemIndex);
                          }}>
                            <FontAwesomeIcon icon={faRotate} fontSize={12} color='#4E5156' />AI Generate
                          </ContentWrappers>
                        </Tooltip>
                      </FreezeWrapper>
                    </span> : inputDescriptionContainer(record, obj)}
                  </div>
                );
              }) : <Empty/>}
            </RedirectWrapper>
          </>) : <></>}
        </ImagesDropdownWrapper>
      </StatusMenu>
    );
  };
  const toggleLinkDropdown = (visible, id) => {
    if (visible) {
      setSelectedBrokenLinkId(id);
    } else {
      setSelectedBrokenLinkId(-1);
    }
  };
  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
  const columns = [
    {
      title: (removeTopBar ? <div style={{cursor: 'default'}} className='column-title'>
        <div>
          <span>STATUS</span>
        </div>
      </div> : <div className='column-title'>
        {<Dropdown overlay={
          <StyledMenu disabled={filteredData?.length == 0} selectedIssueType={url?.issueType} deployOttoSection={deployOttoSection} currentProject={currentProject}/>
        } trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
          <div>
            <span>STATUS</span>
            <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={12}/>
          </div>
        </Dropdown>
        }
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      fixed: 'left' as 'left',
      render: (_, record) => {
        return (
          <FreezeWrapper removeTooltip={record?.isActive}>
            <Tooltip title={canDeploy(record, record?.issueType) || record?.isActive ? '' : 'Empty fields cannot be deployed.'}>
              <StatusWrapper
                status={record?.isActive}
                onClick={() => ((canDeploy(record, record?.issueType) || record?.isActive)) && deployOttoUrl(record?.id, !record?.isActive, record?.issueType, record?.values)}
                isDisabled={(!canDeploy(record, record?.issueType) && !record?.isActive)}
              >
                {urlId === record?.id ? <Spin indicator={antUrlIcon} /> :
                  <>
                    <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.isActive ? `#2AC155` : '#A3A4A4'} />
                    <span>{getStatusText(record?.isActive, currentProject)}</span>
                  </>
                }
              </StatusWrapper>
            </Tooltip>
          </FreezeWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      width: 280,
      render: (_, record) => {
        const path = (record?.path?.length && record?.path[0] === '/') ? record?.path.slice(1) : record?.path;
        return <UrlWrapper style={{maxWidth: '420px'}} onClick={() => openUrl(`${addProtocolToDomain(domainName)}/${path}`, '_blank')}>{record?.path}</UrlWrapper>;
      },
    },
    {
      title: <div className='column-title'>{('Redirects').toUpperCase()}</div>,
      dataIndex: 'proposedFix',
      key: 'proposedFix',
      width: 130,
      render: (id, record) => {
        const redirectItems = record?.values?.filter(item => item?.issueType === 'has_internal_links_that_redirect');
        const approvedRedirectItems = redirectItems?.filter(item => item?.isApproved);
        return (
          record?.issueType === 'links' && record?.values?.length ? (
            <Dropdown
              trigger={['click']}
              overlay={() => dropdownOverlay(record, 'redirects')}>
              <div style={{cursor: 'pointer'}}>Deployed: {approvedRedirectItems?.length} of {redirectItems?.length} link{redirectItems?.length > 1 ? 's': ''}
                <FontAwesomeIcon style={{marginLeft: '4px'}} icon={faSortDown} fontSize={16} color='#121212' />
              </div>
            </Dropdown>
          ) : null
        );
      },
    },
    {
      title: <div className='column-title'>{('Broken links').toUpperCase()}</div>,
      dataIndex: 'proposedFix',
      key: 'proposedFix',
      width: 100,
      render: (id, record) => {
        const linkKeys = ['has_internal_broken_links', 'has_external_broken_links'];
        const brokenItems = record?.values?.filter(item => linkKeys.includes(item?.issueType));
        const approvedBrokenItems = brokenItems?.filter(item => item?.isApproved);
        return (
          record?.issueType === 'links' && record?.values?.length ? (
            <Dropdown
              trigger={['click']}
              visible={selectedBrokenLinkId === record?.id}
              onVisibleChange={e => toggleLinkDropdown(e, record?.id)}
              overlay={() => dropdownOverlay(record, 'brokenLinks')}>
              <div style={{cursor: 'pointer'}}>Deployed: {approvedBrokenItems?.length} of {brokenItems?.length} link{brokenItems?.length > 1 ? 's': ''}
                <FontAwesomeIcon style={{marginLeft: '4px'}} icon={faSortDown} fontSize={16} color='#121212'/>
              </div>
            </Dropdown>
          ) : null
        );
      },
    },
  ];
  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    const categories = issue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : issue;
    const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === categories || issueObj.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === category);
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === issueType);
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (searchText) {
          params['search'] = searchText;
          setOttoSearchTerm(searchText);
        } else {
          setOttoSearchTerm('');
        }
        if (searchText) {
          searchDebounce(params, itemObject?.isSitewide);
        } else {
          await loadIssueTableData(params, itemObject?.isSitewide);
        }
      }
    }
    setLoadingDetail(false);
  }, []);
  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, '', activeKey);
  };

  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      {!removeTopBar && <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter} />}
      <Collapse.Panel key='open' header={<></>}>
        <StyledIssuesTable
          loading={ottoUrlLoader && ottoIssueType === componentIssueType}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          scroll={width < 1100 ? {x: 'auto', y: 'auto'} : {y: 'auto'}}
          maxHeight={maxHeight}
        />
        <PaginationStyled
          onChange={(page, pageSize) => {
            setPageChanged(true);
            setIssueTable(url?.issueType);
            handlePaginationChange([url?.issueType], page, pageSize);
            saveOttoTablePageSize(componentIssueType, pageSize);
            setPageSize(pageSize);
          }}
          total={url?.issueTable?.count}
          pageSize={pageSize}
          current={url?.page ?? 1}
          showSizeChanger
          pageSizeOptions={['5', '10', '20', '50', '100']}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});

const ContentWrappers = styled.div`
    padding: 4px 9px 4px 0px;
    gap: 5px;
    border-radius: 4px;
    display: -ms-flexbox;
    display: flex;
    background: #F9F9FB;
    border: 1px solid #E8E8E8;
    height: 27px;
    align-items: center;
    font-size: 12px;
    padding: 0 8px;
`;
