import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faCaretDown,
  faImageSlash,
  faBook,
  faCheck,
  faSpider,
} from '@fortawesome/pro-solid-svg-icons';
import {addDefaultSrc, addProtocolToDomain, copyAndModifyQueryParams, findValidDomain, getDomain, getSingleUrlParam, getSiteName, isOTTOV2PublicPage, isValidDomain} from '@/utils/url';
import {useRouter} from 'next/router';
import {Button} from '@/components/common-components/v2';
import React, {useEffect, useRef, useState} from 'react';
import {
  Col, Row, Modal, Tabs,
  Dropdown,
  Tooltip,
  Spin,
  Form,
  Menu,
} from 'antd';
import {useStore} from '@/store/root-store';
import {BusinessInfoForm} from './KnowledgeGraph/businessInfoForm';
import {openUrl} from '@/utils/router';
import {SettingsModal} from '../../otto-page-v2-main/Component/settings-modal';
import TopPages from './topPages';
import {customapiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
import {ImportBusinessModalV2} from '../Modals/importBusinessModal';
import OttoSummary from './ottoSummary';
import {NextImg} from '@/utils/nextImg';
import OttoCtaSection from './ottoCtaSection';
import {toJS} from 'mobx';
import ImagesForm from './imagesForm';
import {TopupModal} from '../Modals/topupModal';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {faMagnifyingGlass, faEllipsisVertical} from '@fortawesome/pro-regular-svg-icons';
import {debounce} from 'lodash';
import {DropdownContainer, Img, OttoProjectSearchInput, ProjectName, BoldText, OverlayContainer} from './styleComponents';
import {LoadingOutlined} from '@ant-design/icons';
import WidgetModal from '../../otto-page-v2-main/add-site-modal';
import {StyledSpin} from '../Utils/styledSpin';
import {snakeToCamel} from '@/utils/string';
import DeepFreezeModal from './deepFreezeModal';
import {faBoxArchive, faLink} from '@fortawesome/pro-duotone-svg-icons';
import FreezeWrapper from './freezTooltip';
import moment from 'moment';
import PublicPageFreezeWrapper from './publicPageFreeze';
import {CrawlBudgetBanner} from '@/components/common-components/components/crawl-budget-banner';
import {SmallBadge} from '@/components/common-components/components/badge';

interface Props {
  isCompleted?: boolean;
}

type optionsType = 'quantity'
| 'additionally_included'
| 'current_quota'
| 'used_card'
| 'recurring_payment';

interface TopUpVisibilityProps {
  visibility: boolean;
  price: number;
  title: string;
  options: optionsType[];
  consumed: number | null;
  total: number | null;
  recurring: string;
  quotaKey: string;
  desc: string;
  nextPaymentAt?: string | null;
  buttonTxt: string;
}

export const OttoDetailPageV2Header:React.FC<Props> = observer(() => {
  const {
    ottoV2Store: {getOttoV2Project, loadingProject, setAutoPilot, loadingAutoPilot, getOttoV2ProjectsList, setDefaultParams, updateOttoV2Project, loadOttoV2Project, getKnowledgeGraphFieldsMeta, getKnowledgeGraphData, knowledgeGraph, updateKnowledgeGraph, setIsDeploying, getOttoQuotaAllocations, ottoGBPUploadImage, getOttoPlanQuotaAllocations,
      isKnowledgeModalVisible, setIsKnowledgeModalVisible, defaultParams, loadingProjects, loadmoreLoading, getExportTaskId, loadingWorkSummary, getOttoKnowledgeGraphFieldsMeta, unfreezeOttoProject, unfreezeLoading, getGenesysApiKey, setGenesysApiKey, getBlockedQuotas, getPaymentTopups},
    gsc: {googleAnalytics: {setGaDataModal, gaDataModal, getGMBSetting}, criteria},
    siteAuditorV2: {
      setGscOpenModalValue,
      siteAuditSelectedProperty,
      updateSelectedSiteSA,
      loadAuditedSitesList,
      onLoadAuditedSiteDetail,
      getAuditedSiteDetailData,
    },
    ottoStore: {
      setDomainLoading,
    },
    settings: {customer: {profile: {whitelabelOtto, isLinkgraph, isWhitelabel}}},
  } = useStore('');
  const [form] = Form.useForm();
  const [tooltipShown, setTooltipShown] = useState(localStorage.getItem('TOOLTIP_SHOWN'));
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const uuid = getSingleUrlParam('uuid');
  const router = useRouter();
  const projectId = (siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || getOttoV2Project?.siteAuditId))?.toString();
  const [currentProject, setCurrentProject] = useState(null);
  const [currentProjectLoader, setCurrentProjectLoader] = useState(true);
  const [settingsModal, setSettingsModal] = useState(false);
  const [updateOttoProjectLoading, setUpdateOttoProjectLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showAddPage, setShowAddPage] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [gbpModalVisible, setGbpModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [createProjectUuid, setCreateProjectUuid] = useState('');
  const [settingsData, setSettingsData] = useState<{
    domainToOpen: string;
    page?: string;
  }>(null);
  const [knowledgeGraphSections, setKnowledgeGraphSections] = useState([]);
  const selectedPages = tableData;
  const [tabKeys, setTabKeys] = useState('business_section');
  const formRef = useRef(null);
  const [images, setImages] = useState([]);
  const [youtubeUrl, setYoutubeUrl] = useState([]);
  const [youtubeUrlError, setYoutubeUrlError] = useState(null);
  const [logo, setLogo] = useState(null);
  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState<TopUpVisibilityProps>({
    visibility: false,
    price: 0,
    title: '',
    options: [],
    consumed: 0,
    total: 0,
    recurring: '',
    quotaKey: '',
    desc: '',
    buttonTxt: '',
  });
  const [knowledgeGraphInitialLoader, setKnowledgeGraphInitialLoader] = useState(true);
  const [knowledgeGraphModalLoader, setKnowledgeGraphModalLoader] = useState(false);

  const [serpLocation, setSerpLocation] = useState();
  const [serpLocationId, setSerpLocationId] = useState();
  const [showProjectDropdown, setShowProjectDropdown] = useState(false);

  const [isWidgetModalVisible, setIsWidgetModalVisible] = useState(false);
  const [deepFreezeModalVisible, setDeepFreezeModalVisible] = useState(false);
  const [languageCode, setLanguageCode] = useState<string | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [callLoadAudit, setCallLoadAudit] = useState(false);
  const isPublicHash = !!getSingleUrlParam('public_hash');
  const [markEmptyFields, setMarkEmptyFields] = useState(false);

  const handleLaterClick = () => {
    if (isLinkgraph) {
      localStorage.setItem('TOOLTIP_SHOWN', 'true');
      setTooltipShown('true');
    }
  };

  useEffect(() => {
    getPaymentTopups();
    setTooltipShown(localStorage.getItem('TOOLTIP_SHOWN'));
  }, []);

  useEffect(() => {
    setDefaultParams({search: '', pageSize: 20, page: 1});
    getBlockedQuotas();
    if (!isPublicHash) {
      getGMBSetting();
      getKnowledgeGraphFieldsMeta();
      getOttoQuotaAllocations();
      getOttoPlanQuotaAllocations();
      loadAuditedSitesList();
    }
    return () => setDefaultParams({...defaultParams, pageSize: 20, page: 1, search: ''}, true);
  }, []);

  const getKnowledgeGraph = async () => {
    if (getOttoV2Project?.knowledgeGraphId && !isPublicHash) {
      setKnowledgeGraphInitialLoader(true);
      await getKnowledgeGraphData(getOttoV2Project?.knowledgeGraphId);
      setKnowledgeGraphInitialLoader(false);
    }
  };

  useEffect(() => {
    getKnowledgeGraph();
  }, [getOttoV2Project?.knowledgeGraphId]);

  useEffect(() => {
    if (getOttoV2Project?.uuid && getOttoV2Project?.uuid == uuid) {
      setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
      setCurrentProjectLoader(false);
      if (isLinkgraph) getGenesysApiKey();
    }
  }, [getOttoV2Project]);

  const projectImg = <NextImg style={{height: '42px', width: '42px', borderRadius: '4px'}} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${domainName && getDomain((!/^(https?:)?\/\//i.test(domainName)) ? 'https:' + domainName : domainName)}&size=64`}/>;

  const isFromhomepage = getSingleUrlParam('fromHome');
  const handleBackRedirect = () => {
    setGenesysApiKey('');
    if (router?.pathname?.includes('public')) {
      router.push(`/otto-page-public`);
    } else {
      if (isFromhomepage) {
        router.push(`/home`);
      } else {
        router.push(`/otto-page-v2`);
      }
    }
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const beforeRadius = {
    min: 0,
    max: 0,
  };

  if (getOttoV2Project?.beforeSummary['seoOptimizationScore'] && getOttoV2Project?.beforeSummary['seoOptimizationScore'] !== 'undefined' && getOttoV2Project?.beforeSummary['seoOptimizationScore'] > 50) {
    beforeRadius.max = getOttoV2Project?.beforeSummary['seoOptimizationScore'];
    beforeRadius.min = 100 - getOttoV2Project?.beforeSummary['seoOptimizationScore'];
  } else {
    beforeRadius.min = 100 -getOttoV2Project?.beforeSummary['seoOptimizationScore'];
    beforeRadius.max = getOttoV2Project?.beforeSummary['seoOptimizationScore'];
  }

  const handleOpenGSCModal = isGaObject => {
    const connectedData = isGaObject?.connectedData;
    criteria.updateCriteria({
      ...criteria,
      property: connectedData?.gscDetails?.propertyUrl || connectedData?.gscDetails?.propertyUrl === null ? connectedData?.gscDetails?.propertyUrl : '',
    });
    setGscOpenModalValue(true, 'GSC Setting', true, projectId, false);
    setGaDataModal({
      ...gaDataModal,
      visible: true,
      siteUrl: connectedData?.gscDetails?.propertyUrl || '',
      gaConnected: isGaObject?.isGscConnected,
      settingId: null,
      settingsEnabled: false,
      hasSiteGaCredentials: null,
      saProjectId: projectId,
      countryCode: connectedData?.gscDetails?.countryCode || connectedData?.gscDetails?.countryCode === null ? connectedData?.gscDetails?.countryCode : '',
      fromOttoUuidParam: `?uuid=${uuid}`,
    });
  };

  const onChangeAutoPilot = async value => {
    setIsDeploying(true);
    await setAutoPilot(uuid, value);
  };

  const openSettings = () => {
    if (currentProject) {
      handleLaterClick();
      setSettingsModal(true);
      setSettingsData({
        domainToOpen: currentProject?.hostname,
        page: 'detail-page',
      });
    }
  };

  const resetForms = () => {
    if (formRef?.current) {
      formRef.current.resetFields();
    }
  };

  const getKnowledgeGraphDataModal = async () => {
    if (getOttoV2Project?.knowledgeGraphId && isKnowledgeModalVisible) {
      setKnowledgeGraphModalLoader(true);
      const res = await getKnowledgeGraphData(getOttoV2Project?.knowledgeGraphId);
      if (!res?.isCancel) setKnowledgeGraphModalLoader(false);
    }
  };

  useEffect(() => {
    if (!isPublicHash) getKnowledgeGraphDataModal();
  }, [getOttoV2Project?.knowledgeGraphId, isKnowledgeModalVisible]);

  const initialValues = () => {
    resetForms();
    setSearchValue('');
    setSearchResults([]);
    setShowAddPage(false);
    setYoutubeUrlError(null);
    setLogo(null);
  };

  // const handleUpdateTopPages = async () => {
  //   setUpdateOttoProjectLoading(true);
  //   try {
  //     const prePayload = {user_top_pages: {}};
  //     selectedPages?.forEach(value => {
  //       prePayload.user_top_pages[value?.url] = value?.keywords;
  //     });
  //     await updateOttoV2Project(uuid, prePayload);
  //     setUpdateOttoProjectLoading(false);
  //     notification.success('Success', 'Top Pages updated successfully');
  //     await loadOttoV2Project(uuid);
  //     initialValues();
  //   } catch (error) {
  //     setUpdateOttoProjectLoading(false);
  //     const errorMessage = apiError(error, 'Failed to update OTTO project details') as string;
  //     notification.error('', errorMessage);
  //   }
  // };

  // const onSubmit = async values => {
  //   setUpdateOttoProjectLoading(true);
  //   try {
  //     Object.entries(values)?.forEach(([key, value]) => {
  //       if ((key === 'lat' || key === 'long' || key === 'number_of_employees') && value === '') {
  //         values[key] = null;
  //       }
  //       values.serp_location_text = serpLocation;
  //       values.serp_location_id = serpLocationId;
  //     });

  //     await updateKnowledgeGraph(getOttoV2Project?.knowledgeGraphId, values);
  //     const actives = getOttoV2Project?.topPages?.filter(item => item.isSelected);
  //     if (JSON.stringify(actives) !== JSON.stringify(selectedPages)) {
  //       const prePayload = {user_top_pages: {}};
  //       selectedPages?.forEach(value => {
  //         prePayload.user_top_pages[value?.url] = value?.keywords;
  //       });
  //       await updateOttoV2Project(uuid, prePayload);
  //     }
  //     setUpdateOttoProjectLoading(false);
  //     notification.success('', 'Knowledge Graph updated successfully.');
  //     await getKnowledgeGraphDataModal();
  //     initialValues();
  //   } catch (error) {
  //     setUpdateOttoProjectLoading(false);
  //     const errorMessage = customapiError(error) as string;
  //     notification.error('', errorMessage?.includes('less than or equal to 90') ? 'Latitude: Ensure this value is less than or equal to 90.' : errorMessage);
  //   }
  // };

  // const onImageSubmit = async () => {
  //   // if (youtubeUrlError) return;
  //   setUpdateOttoProjectLoading(true);
  //   try {
  //     const imagesArr = [];
  //     for (const item of images) {
  //       if (item?.url && typeof item?.url !== 'string') {
  //         const formData = new FormData();
  //         formData.append('image', item?.url);
  //         formData.append('format', 'JPEG');
  //         const response = await ottoGBPUploadImage(formData);
  //         imagesArr.push({url: response});
  //       } else {
  //         imagesArr.push({url: item?.url});
  //       }
  //     }

  //     const youtubeUrls = youtubeUrl.map(item => ({url: item.value}))?.filter(i => i?.url?.length);
  //     let response;

  //     if (logo && typeof logo !== 'string') {
  //       const formData = new FormData();
  //       formData.append('image', logo);
  //       formData.append('format', 'JPEG');
  //       response = await ottoGBPUploadImage(formData);
  //     } else {
  //       response = logo;
  //     }

  //     const payload = {
  //       images: imagesArr,
  //       youtube_videos: youtubeUrls,
  //       logo: response || '',
  //     };

  //     await updateKnowledgeGraph(getOttoV2Project?.knowledgeGraphId, payload);
  //     setUpdateOttoProjectLoading(false);
  //     notification.success('', 'Knowledge Graph updated successfully.');
  //     await getKnowledgeGraphDataModal();
  //     initialValues();
  //   } catch (error) {
  //     setUpdateOttoProjectLoading(false);
  //     const errorMessage = customapiError(error) as string;
  //     notification.error('', errorMessage);
  //   }
  // };


  useEffect(() => {
    if (!getOttoV2Project) return;
    const data = getOttoV2Project?.topPages;
    if (getOttoV2Project?.siteAudit && !isPublicHash && (!getAuditedSiteDetailData || getAuditedSiteDetailData?.shouldRepoll || !callLoadAudit)) {
      setCallLoadAudit(true);
      onLoadAuditedSiteDetail(getOttoV2Project?.siteAudit);
    }
    setTableData(data);
  }, [getOttoV2Project, isKnowledgeModalVisible]);

  useEffect(() => {
    if (knowledgeGraph) {
      if (knowledgeGraph['youtubeVideos']) {
        setYoutubeUrl(
          (knowledgeGraph['youtubeVideos'] || []).map((item, index) => ({
            id: index + 1,
            value: item?.url || '',
          })),
        );
      } else {
        setYoutubeUrl([]);
      }

      if (knowledgeGraph['images']) {
        setImages(
          (knowledgeGraph['images'] || []).map((item, index) => ({
            id: index,
            url: item?.url || '',
            types: 'photos',
          })),
        );
      }
      if (knowledgeGraph['languageCode']) {
        setLanguageCode(knowledgeGraph['languageCode']);
      } else {
        setLanguageCode(null);
      }
      setMarkEmptyFields(knowledgeGraph?.['emptyFieldsAreCompleted']);
      setYoutubeUrlError(null);
      if (knowledgeGraph['logo']) {
        setLogo(knowledgeGraph['logo']);
      } else {
        setLogo(null);
      }
      setSerpLocation(knowledgeGraph['serpLocationText']);
    }
  }, [knowledgeGraph, isKnowledgeModalVisible]);


  useEffect(() => {
    const topPages = {name: 'top_pages', label: 'Top pages', value: []};
    const sections = toJS(getOttoKnowledgeGraphFieldsMeta?.value);
    const alreadyExist = sections?.find(item => item.name === 'top_pages');
    if (!alreadyExist) {
      sections?.splice(1, 0, topPages);
    }
    setKnowledgeGraphSections(sections);
  }, [getOttoKnowledgeGraphFieldsMeta]);

  const tableFilterHandler = debounce(async e => {
    if (e?.target?.value) {
      const passedValue = e.target.value;
      setDefaultParams({...defaultParams, pageSize: 20, page: 1, search: passedValue.toLowerCase()}, false, false);
    } else {
      setDefaultParams({search: '', pageSize: 20, page: 1});
    }
  }, 500);

  const handleSeeTask = items => {
    setDomainLoading();
    setGenesysApiKey('');
    updateSelectedSiteSA(items?.siteAudit);
    initialValues();
    loadOttoV2Project(items?.uuid);
    const params = [{
      key: 'uuid',
      value: items?.uuid,
    }, {
      key: 'subGroup', value: '',
    }];
    router.push(`${copyAndModifyQueryParams(params)}`, undefined, {shallow: true});
    setShowProjectDropdown(false);
  };
  const onExportWorkSummary = async () => {
    await getExportTaskId(uuid);
  };

  const knowledgeBaseUrl = 'https://intercom.help/search-atlas/en/collections/10864692-knowledge-base';
  const projectListRef = useRef(null);

  const ottoProjectsList = (
    <DropdownContainer>
      <div className='heading'>
        <div className='total-otto-sites'>{getOttoV2ProjectsList?.results?.length} {whitelabelOtto} Sites found</div>
        <div className='add-new-side' onClick={() => {
          setIsWidgetModalVisible(true);
          setShowProjectDropdown(false);
        }}>+Add site</div>
      </div>
      <OttoProjectSearchInput onChange={e => tableFilterHandler(e)}
        placeholder='Search ...'
        prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4'/>}
      />
      {loadingProjects ? <RingLoaderV2 style={{marginTop: '10px'}} /> :
        <div className='project-list' ref={projectListRef}>
          {getOttoV2ProjectsList?.results?.map((item, index) => {
            const url = `https://www.google.com/s2/favicons?sz=64&domain_url=${addProtocolToDomain(item?.hostname)}`;
            const isSelected = item?.uuid === uuid;
            return (
              <div className='project-container' style={{background: isSelected ? 'rgba(45, 108, 202, 0.15)' : ''}} key={index} onClick={() => handleSeeTask(item)}>
                <div className='project-image-name'>
                  <Img onError={e => addDefaultSrc(e)} src={isValidDomain(item?.hostname) ? url : `https://www.google.com/s2/favicons?sz=64&domain_url=${findValidDomain(item?.hostname)?.includes('.com') ? findValidDomain(item?.hostname) : `${findValidDomain(item?.hostname)}.com`}`} alt={item?.hostname}/>
                  <Tooltip title={item?.hostname || '-'} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}>
                    <BoldText style={isSelected ? {maxWidth: '180px'} : {}}>{item?.hostname || '-'}</BoldText>
                  </Tooltip>
                </div>
                {isSelected && <div className='selected'>Selected</div>}
              </div>
            );
          })}
          {getOttoV2ProjectsList?.results?.length < getOttoV2ProjectsList?.count && <div className='load-more'>
            <button
              className='load-more-btn'
              style={loadmoreLoading ? {cursor: 'not-allowed'} : {}}
              onClick={() => !loadmoreLoading && setDefaultParams({search: '', pageSize: 20, page: defaultParams.page + 1}, false, false, true)}
            >
            Load more {loadmoreLoading && <Spin indicator={<LoadingOutlined style={{fontSize: '12px', color: '#2D6CCA'}} spin />} />}
            </button>
          </div>}
        </div>}
    </DropdownContainer>
  );

  const onSubmitKnowledgeGraph = async values => {
    if (youtubeUrlError) return;
    setUpdateOttoProjectLoading(true);
    try {
      // images tab
      const imagesArr = [];
      for (const item of images) {
        if (item?.url && typeof item?.url !== 'string') {
          const formData = new FormData();
          formData.append('image', item?.url);
          formData.append('format', 'PNG');
          const response = await ottoGBPUploadImage(formData);
          imagesArr.push({url: response});
        } else {
          imagesArr.push({url: item?.url});
        }
      }
      const youtubeUrls = youtubeUrl.map(item => ({url: item.value}))?.filter(i => i?.url?.length);
      let response;
      if (logo && typeof logo !== 'string') {
        const formData = new FormData();
        formData.append('image', logo);
        formData.append('format', 'PNG');
        response = await ottoGBPUploadImage(formData);
      } else {
        response = logo;
      }
      // images

      // top pages
      const actives = getOttoV2Project?.topPages?.filter(item => item.isSelected);
      if (JSON.stringify(actives) !== JSON.stringify(selectedPages)) {
        const prePayload = {user_top_pages: selectedPages?.filter(i => i?.url?.includes(getSiteName(domainName)))?.map(i => ({url: i?.url, keywords: i?.keywords, is_selected: i?.isSelected}))};
        await updateOttoV2Project(uuid, prePayload);
        loadOttoV2Project(uuid);
      }
      // top pages

      // other all
      Object.entries(values)?.forEach(([key, value]) => {
        if ((key === 'lat' || key === 'long' || key === 'number_of_employees') && value === '') {
          values[key] = null;
        }
        if (key === 'language_code' && !value) {
          delete values[key];
        }
        values.serp_location_text = serpLocation;
        values.serp_location_id = serpLocationId;
        values.images = imagesArr;
        values.youtube_videos = youtubeUrls;
        values.logo = response || '';
      });
      if (languageCode === null || languageCode) values.language_code = languageCode;
      values['empty_fields_are_completed'] = markEmptyFields;
      await updateKnowledgeGraph(getOttoV2Project?.knowledgeGraphId, values);
      // other all
      setUpdateOttoProjectLoading(false);
      setIsKnowledgeModalVisible(false);
      setTabKeys('business_section');
      notification.success('', 'Knowledge Graph updated successfully.');
      // await loadOttoV2Project(uuid);
      await getKnowledgeGraphDataModal();
      initialValues();
    } catch (error) {
      setUpdateOttoProjectLoading(false);
      const errorMessage = customapiError(error) as string;
      notification.error('', errorMessage?.includes('less than or equal to 90') ? 'Latitude: Ensure this value is less than or equal to 90.' : errorMessage);
    }
  };

  const overlay = (
    <OverlayContainer>
      <Menu.Item onClick={() => setDeepFreezeModalVisible(true)}>Deep Freeze this Site (Archive)</Menu.Item>
    </OverlayContainer>
  );

  const publicUrl = `${window.location.protocol}//${window.location.host}/otto-page-v2/public?uuid=${getOttoV2Project?.uuid}&category=All&subGroup=page_title&public_hash=${getOttoV2Project?.publicShareHash}`;

  const onVisibleChange = (visible:any) => {
    if (!visible) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 300);
    }
  };

  const shareUrl = () => {
    navigator.clipboard.writeText(isOTTOV2PublicPage() ? location.href : publicUrl);
    setCopySuccess(true);
  };
  const nonFieldTabs = ['images', 'top_pages'];
  return (<>
    <HeaderWrapper>
      <HeadingContainer>

        {getAuditedSiteDetailData?.shouldAdjustCrawlBudget && <CrawlBudgetBanner onButtonClick={openSettings} pagesCount={getAuditedSiteDetailData?.totalUrlsInSitemaps} bannerStyle={{marginTop: '-26px'}}/>}
        {!isPublicHash && <BackContainer>
          <FontAwesomeIcon onClick={handleBackRedirect} icon={faArrowLeft} className='back-icon'/>
          <span onClick={handleBackRedirect} className='back-text'>Back to Sites List</span>
        </BackContainer>}
        <DomainWrapper isPublicPage={isPublicHash} isFrozen={getOttoV2Project?.isFrozen}>
          <DomainContainer>
            {
              domainName ? projectImg : <FontAwesomeIcon className='logo' icon={faImageSlash} />
            }
            <div>
              {!isPublicHash ? <Dropdown trigger={['click']} visible={showProjectDropdown} onVisibleChange={e => setShowProjectDropdown(e)} overlay={ottoProjectsList}>
                <ProjectName onClick={() => setShowProjectDropdown(true)}>
                  <div className='title'>{capitalizeFirstLetter(getSiteName(domainName)) ?? '-'}</div>
                  <FontAwesomeIcon icon={faCaretDown} />
                </ProjectName>
              </Dropdown> : (
                <ProjectName style={{cursor: 'auto'}} onClick={() => setShowProjectDropdown(true)}>
                  <div className='title'>{capitalizeFirstLetter(getSiteName(domainName)) ?? '-'}</div>
                </ProjectName>
              )}
              <Link onClick={()=>openUrl(`${addProtocolToDomain(domainName)}`, '_blank')}>{domainName}</Link>
            </div>
          </DomainContainer>
          <ButtonContainer>
            <StyledButton
              disabled={loadingProject}
              isSetting={true}
              style={{borderRadius: '6px', display: 'flex', alignItems: 'center', gap: '8px', lineHeight: '20px'}}
              onClick={() => router.push(`/site-audit/crawl-monitoring?id=${getOttoV2Project?.siteAudit}`)}>
              <FontAwesomeIcon icon={faSpider} color='#fff' fontSize={14}/>Crawl Monitoring
              <SmallBadge beta customStyle={{left: '85%', top: -6}}/>
            </StyledButton>
            {(getOttoV2Project?.uuid === uuid) ? (<FreezeWrapper><StyledButton
              disabled={loadingWorkSummary || loadingProject}
              isSetting={true}
              style={{borderRadius: '6px', display: 'flex', alignItems: 'center', gap: '8px', lineHeight: '20px'}}
              onClick={onExportWorkSummary}>
              <>{loadingWorkSummary ? <StyledSpin color={'#ffffff'} fontSize={14}/> : <img src='/icons/fileXls.svg' style={{width: '14px'}}/>}</>Export Work Summary
            </StyledButton></FreezeWrapper>) : <StyledSpin color={'#ffffff'} fontSize={14}/>}
            {(getOttoV2Project?.uuid === uuid && !isWhitelabel) ? <KnowledgeButton disabled={loadingProject}
              onClick={()=>openUrl(knowledgeBaseUrl, '_blank')}
              style={{display: 'flex', alignItems: 'center', gap: '8px', lineHeight: '20px', backgroundColor: '#4E515680'}}>
              <><FontAwesomeIcon icon={faBook} color={'#34AEF3'} /></>Knowledge Base</KnowledgeButton> : null}
            {(isLinkgraph || isPublicHash) ? (getOttoV2Project?.uuid === uuid) ? <Tooltip
              title={<>
                {copySuccess && <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{` Copied to clipboard`}</span>}
              </>}
              placement='bottom'
              visible={copySuccess}
              onVisibleChange={visible => onVisibleChange(visible)}>
              <StyledButton
                disabled={loadingProject}
                isSetting={true}
                style={{borderRadius: '6px', display: 'flex', alignItems: 'center', gap: '8px', lineHeight: '20px'}}
                onClick={() => shareUrl()}>
                <FontAwesomeIcon icon={faLink}/>Share URL
              </StyledButton>
            </Tooltip> : <StyledSpin color={'#ffffff'} fontSize={14}/> : ''}
            {(!getOttoV2Project?.isFrozen && !isPublicHash) ? <Dropdown overlay={overlay} trigger={['click']}>
              <div className='menu' style={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faEllipsisVertical} className='menu' />
              </div>
            </Dropdown> : ''}
          </ButtonContainer>
        </DomainWrapper>
        {getOttoV2Project?.isFrozen ? <FreezeBanner>
          <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
          <div className='txt'>
             The changes on this site are frozen as of {moment(getOttoV2Project?.frozenAt)?.format('MMMM DD, YYYY')}. You will be unable to make any modifications unless you {' '}
            {unfreezeLoading ? isPublicHash ? '' : <Spin indicator={<LoadingOutlined style={{fontSize: '14px', color: '#FFFFFF'}} spin />} /> :
              <PublicPageFreezeWrapper><span className='unfreeze-button' onClick={() => !unfreezeLoading && unfreezeOttoProject(uuid)}>unfreeze the site.</span></PublicPageFreezeWrapper>}
          </div>
        </FreezeBanner> : ''}
        {getOttoV2Project?.deepFreezeTask?.status === 'PENDING' ? <FreezeBanner>
          <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
          <div className='txt'>
            <span className='bold-text'>Freezing in Progress...</span> Your project is being frozen.
            <Spin style={{marginLeft: '10px'}} indicator={<LoadingOutlined style={{fontSize: '14px', color: '#FFFFFF'}} spin />} />
          </div>
        </FreezeBanner> : ''}
        <Row gutter={[34, 14]} style={{minHeight: 250}}>
          <Col md={24} lg={24} xl={12}>
            <OttoSummary
              currentProject={currentProject}
              currentProjectLoader={currentProjectLoader}
              openSettings={openSettings}
              onChangeAutoPilot={onChangeAutoPilot}
              loadingAutoPilot={loadingAutoPilot}
            />
          </Col>
          <Col md={24} lg={24} xl={12}>
            <OttoCtaSection
              setGbpModalVisible={setGbpModalVisible}
              handleOpenGSCModal={handleOpenGSCModal}
              setIsKnowledgeModalVisible={setIsKnowledgeModalVisible}
              openSettings={openSettings}
              tooltipShown={tooltipShown}
              handleLaterClick={handleLaterClick}
              knowledgeGraphInitialLoader={knowledgeGraphInitialLoader}
              currentProjectLoader={currentProjectLoader}
            />
          </Col>
        </Row>

      </HeadingContainer>
      {isKnowledgeModalVisible && <StyledKnowledgeModal
        visible={isKnowledgeModalVisible}
        onCancel={() => {
          initialValues();
          setTabKeys('business_section');
          setIsKnowledgeModalVisible(false);
        }}
        closable={false}
        footer={false}
        width={750}
      >
        {(getOttoV2Project?.isFrozen && !isPublicHash) ? <FreezeBanner>
          <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
          <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
          </div>
        </FreezeBanner> : ''}
        <div className='modal-content'>
          <div className='modal-header' style={{flexDirection: 'column', alignItems: 'baseline'}}>
            <div className='heading'>Knowledge Graph</div>
            <div className='sub-heading'>Ensure all Knowledge Graph information is perfectly accurate.
            Any mistakes here could lead to negative impacts or prevent desired results.</div>
          </div>

          <Tabs className='modal-tabs' activeKey={tabKeys} onChange={keys => setTabKeys(keys)}>
            {knowledgeGraphModalLoader ? <RingLoaderV2 style={{margin: '20px 0px'}} fontSize={50} /> : knowledgeGraphSections?.map(item => {
              if (item?.name === 'business_section' && item?.value?.length) {
                item.value = item.value.map(subItem => {
                  if (subItem?.name === 'website') {
                    return {...subItem, disabled: true};
                  }
                  return subItem;
                });
              }
              const allValues = knowledgeGraphSections?.reduce((acc, curr) => acc.concat(curr?.value), []);
              const getInitialValues = () => {
                const obj = {};
                allValues?.forEach(item => {
                  obj[item?.name] = item?.name === 'website' ? knowledgeGraph[snakeToCamel(item.name)] ?? addProtocolToDomain(domainName) : knowledgeGraph[snakeToCamel(item.name)];
                });
                return obj;
              };
              return (
                <Tabs.TabPane tab={item?.label} key={item?.name} disabled={updateOttoProjectLoading}>
                  <Form
                    form={form}
                    onFinish={onSubmitKnowledgeGraph}
                    initialValues={getInitialValues()}
                    preserve={false}
                  >
                    {
                      item?.name === 'top_pages' ? (
                        <TopPages
                          tableData={tableData}
                          setTableData={setTableData}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                          uuid={uuid}
                          showAddPage={showAddPage}
                          setShowAddPage={setShowAddPage}
                          form={form}
                          domainName={domainName}
                        />
                      ) :
                        item?.name == 'images' ? (
                          <ImagesForm
                            images={images}
                            setImages={setImages}
                            youtubeUrl={youtubeUrl}
                            setYoutubeUrl={setYoutubeUrl}
                            youtubeUrlError={youtubeUrlError}
                            setYoutubeUrlError={setYoutubeUrlError}
                            logo={logo}
                            setLogo={setLogo}
                          />
                        ) : (
                          <BusinessInfoForm
                            data={item?.value}
                            setSerpLocation={setSerpLocation}
                            serpLocation={serpLocation}
                            setSerpLocationId={setSerpLocationId}
                            languageCode={languageCode}
                            setLanguageCode={setLanguageCode}
                            form={form}
                            markEmptyFields={markEmptyFields}
                            showFieldsCompletion
                          />
                        )}
                    <StyledModalFooter style={nonFieldTabs?.includes(tabKeys) ? {justifyContent: 'flex-end'} : {}}>
                      {!nonFieldTabs?.includes(tabKeys) && <button type='button' className='mark-fields-button' onClick={() => setMarkEmptyFields(prev => !prev)}>
                        <FontAwesomeIcon icon={faCheck} color='#2D6CCA' fontSize={16} />
                        {markEmptyFields ? 'Unmark' : 'Mark'} empty fields as completed
                      </button>}
                      <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
                        <div className='cancel-btn' onClick={() => {
                          initialValues();
                          form.resetFields();
                          setTabKeys('business_section');
                          setIsKnowledgeModalVisible(false);
                        }}>
                        Cancel
                        </div>
                        <FreezeWrapper>
                          <StyledButton
                            color='green'
                            className='update-button green-btn'
                            htmlType='submit'
                            loading={updateOttoProjectLoading}
                          >
                          Update
                          </StyledButton>
                        </FreezeWrapper>
                      </div>
                    </StyledModalFooter>
                  </Form>
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </StyledKnowledgeModal>}
      <SettingsModal
        currentProject={currentProject}
        isVisible={settingsModal}
        setIsVisible={setSettingsModal}
        instructionsData={settingsData}
        setInstructionsData={setSettingsData}
      />
      {/* use this modal in another component */}
      <ImportBusinessModalV2
        onOttoGBPConnect={() => {
          loadOttoV2Project(uuid);
        }}
        visible={gbpModalVisible}
        setVisible={setGbpModalVisible}
        ottoProjectId={getOttoV2Project?.uuid}
        ottoConnectedGBPId={getOttoV2Project?.connectedData?.gbpDetailsV2}
        fromOtto={`?uuid=${uuid}`}
        isOttoV2
      />
      <WidgetModal
        isWidgetModalVisible={isWidgetModalVisible}
        setIsWidgetModalVisible={setIsWidgetModalVisible}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        uuid={createProjectUuid}
        setUuid={setCreateProjectUuid}
      />
      <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
      <DeepFreezeModal visible={deepFreezeModalVisible} setVisible={setDeepFreezeModalVisible} />
    </HeaderWrapper>
  </>
  );
});

const HeadingContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  align-items: center;
  margin-bottom: 47px;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  margin: auto;
`;
const BackContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  .back-icon {
    color: #A3A4A4;
    cursor: pointer;
  }
  .back-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #A3A4A4;
    cursor: pointer;
  }
`;


const DomainWrapper = styled.div<{isFrozen?: boolean; isPublicPage?: boolean}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  margin-bottom: ${p => p?.isPublicPage ? '20px' : p?.isFrozen ? '8px' : '30px'};
  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 20px
  }

`;

const DomainContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 10px;
  .logo {
    width: 42px;
    height: 42px;
    background-color: #A3A4A4;
  }
  .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }
  .domain {

  }
`;

export const StyledButton = styled(Button)<{isSetting?: boolean}>`
  background: ${p => p.isSetting ? '#4E515680': '#2AC15526'}!important;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  padding: 5px 19px;
  margin: 0px;
  width: fit-content;
  &:hover, &:focus,&:active{
  background: ${p => p.isSetting ? '#676b7280': '#46f17725'}!important;
}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const KnowledgeButton = styled(Button)`
  background: rgba(52, 174, 243, 0.15);
  border-radius: 6px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  padding: 5px 19px;
  margin: 0px;
   &:hover, &:focus,&:active{
  background: rgba(52, 174, 243, 0.15) !important;
}
`;

export const CompareCard = styled.div`
  display: flex;
  background-color: #282B2F;
  border-radius: 8px;
  padding: 20px 25px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .chart-detail {
    .date-wrapper {
      .date {
        p {
          font-size: 14px;
          font-weight: 400;
          font-family: 'Inter', sans-serif;
          color: #fff;
        }
      }
    }
    .details-content {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        color: #fff;
      }
      h2 {
        font-size: 24px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
        color: #fff;
        span {
          font-size: 12px;
          font-weight: 400;
          font-family: 'Inter', sans-serif;
          color: #2AC155;
          margin-left: 4px;
        }
      }
    }
  }
  .chart-wrapper {
    width: 166px;
    /* height: 245px; */
    .echarts-for-react {
      height: 220px !important;
    }
  }
`;

export const EngagedWrapper = styled.div`
  background-color: #0C9132;
  padding: 16px 22px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    margin-bottom: 8px;
  }
  &.not-engaged {
    background: rgba(241, 170, 62, 0.25) !important;
    .switch-wrapper {
    .switch-button {
      .ant-switch-handle {        
        &::before {
         background-color: #F1AA3E !important;
        }
        .anticon-loading {
          top: 5px !important;
        }
      }
      background-color: #fff;
      opacity: 30%;
    }
  }
  }
  &.not-installed {
    background: #DD0A3D !important;
  }
  &.pixel-loader {
    background: #282b2f !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .text-wrapper > div > div {
      div {
        border-color: #2D6CCA transparent transparent !important;
      }
    }
  }
  .text-wrapper {
    max-width: 374px;
    .heading {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      font-family: 'Inter', sans-serif;
      margin-bottom: 8px;
    }
    .description {
      max-width: 356px;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #E8E8E8;
    }
    .open-settings-btn {
      cursor: pointer;
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 8px 12px;
      box-shadow: 0px 3px 2px 0px #00000005;
      background: #FFFFFF;
      border-radius: 8px;
      max-width: 172px;

      span {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #121212;
      }
    }
  }
  .switch-wrapper {
    .switch-button {
      height: 16px;
      .ant-switch-handle {
        width: 24px;
        height: 24px;
        top: -4px;
        left: 0px;
        
        &::before {
          border-radius: 20px;
        }
      }
    }
    .ant-switch-checked {
      background-color: #55b26f;
      .ant-switch-handle {
        left: calc(100% - 18px - 2px);
      }
    }
    .ant-switch-checked:focus {
      box-shadow: none !important;
    }
  }
`;
export const IntegrationWrapper = styled.div`
  background-color: #282B2F;
  padding: 16px 22px;
  border-radius: 12px;

  .card-heading {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin-bottom: 10px;
  }
  .card-content {
    .gsc-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        .gsc-text-wrapper {
          .white-text {
            font-size: 14px;
            font-weight: 400;
            font-family: 'Inter', sans-serif;
            color: #fff;
          }
          .gray-text {
            font-size: 12px;
            font-weight: 400;
            font-family: 'Inter', sans-serif;
            color: #A3A4A4;
          }
        }
      }
      .gsc-button {
        display: flex;
        align-items: center;
        padding: 8px 15px;
        border-radius: 6px;
        gap: 4px;
        .button-text {
          font-size: 14px;
          font-weight: 400;
          font-family: 'Inter', sans-serif;
          color: #2ac155;
          cursor: pointer;
        }
      }
      .gbp-button {
        display: flex;
        align-items: center;
        background-color: #2D6CCA;
        padding: 8px 15px;
        border-radius: 6px;
        gap: 4px;
        .gbp-button-text {
          font-size: 14px;
          font-weight: 400;
          font-family: 'Inter', sans-serif;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }
`;
export const AutopilotWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
`;

export const DateSelectWrapper = styled.div`
  position: relative;
  .fa-calendar {
    position: absolute;
    top: 8px;
    left: 6px;
  }
  .date {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: #ffff;
    margin-bottom: 15px;
  }
`;

const StyledKnowledgeModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px !important;
    overflow: hidden !important;
    .ant-modal-body {
      padding: 30px 25px !important;
      .modal-content {
        .modal-header {
          padding: 0px !important;
          .heading {
            font-family: 'Inter', sans-serif;
            font-size: 24px;
            font-weight: 600;
            color: #121212;
          }
          .sub-heading {
            font-size: 16px;
            font-weight: 400;
            color: #4E5156 !important;
            margin-bottom: 20px;
          }
        }
        .modal-tabs {
          .ant-tabs-nav {
            margin: 0 0 8px 0 !important;
            &::before {
              border-bottom: 1px solid #D9D9D9 !important;
            }
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab-active {
                  .ant-tabs-tab-btn {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #121212;
                  }
                }
                .ant-tabs-ink-bar {
                  background-color: #2AC155 !important;
                  height: 5px !important;
                  border-radius: 4px 4px 0px 0px !important;
                }
                .ant-tabs-tab {
                  .ant-tabs-tab-btn {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #4E5156 !important;
                  }
                  &:hover {
                    color: #4E5156 !important;
                  }
                }
              }
            }
          }
          .ant-tabs-content-holder {
            .ant-tabs-content {
              .ant-tabs-tabpane {
                .input-wrapper {
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  .label {
                    font-family: 'Inter', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    color: #4E5156;
                  }
                  .ant-col {
                    .text-area {
                      height: 75px;
                      border: 1px solid #D2D2D2;
                      border-radius: 8px;
                      resize: none;
                      &:hover {
                        border: 1px solid #D2D2D2 !important;
                      }
                    }
                    .email-input {
                      height: 38px;
                      border: 1px solid #D2D2D2;
                      border-radius: 8px;
                    }
                    .react-tel-input {
                      .support-phone-input {
                        width: 100% !important;
                        height: 38px !important;
                        border-radius: 8px;

                      }
                      .flag-dropdown {
                        border-radius: 8px 0 0 8px !important;
                      }
                    }  
                  }
                }
              }
            }
          }
        }
      }
    }
    .quota-section {
      display: flex;
      gap: 22px;
    }
  }
`;

export const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
  .green-btn {
    background-color: #1FAC47 !important;
  }
  .mark-fields-button {
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #2D6CCA;
    font-size: 12px;
  }
`;

const Link = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #34AEF3;
  cursor: pointer;
`;

export const CardHeading = styled.p`
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400 !important;
`;

export const FreezeBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.88px;
  margin-bottom: 8px;
  width: 100%;
  height: 38px;
  border-radius: 12px;
  background: #F1AA3E26;
  box-shadow: 0px 3px 2px 0px #00000005;
  padding: 0px 8px;
  .deep-freeze-banner-txt {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
  }
  .txt {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;
    .unfreeze-button {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
    }
    .bold-text {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      color: #FFFFFF;
    }
  }
`;
