import {Tooltip, Rate, Progress, Select, Checkbox, Button, Switch} from 'antd';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass, faPlus, faMessagesQuestion, faListCheck} from '@fortawesome/pro-regular-svg-icons';
import {faWordpress} from '@fortawesome/free-brands-svg-icons';
import {ChevronDown, ChevronUp} from 'react-feather';
import {faCircleInfo as duotonefaCircleInfo} from '@fortawesome/pro-duotone-svg-icons';
import {faSortDown, faRotate, faGear} from '@fortawesome/pro-solid-svg-icons';
import {faCheckCircle as duotoneCheck} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {Banner, ComingSoonTag, OptionContent, RefreshWrapper, StyledRatingSelect, UrlFilterInput, ViewButton, WpButtonStyled, ToggleAutomated} from '../style';
import {ButtonStyled} from './customHtmlPopover';
import {debounce} from 'lodash';
import {snakeToCamel} from '@/utils/string';
import {getPercentage} from '@/utils/number';
import styled from 'styled-components';
import {DeployStatusFilter} from './deployStatusFilter';
import {isGBPFilters, refreshKeyMapping, showAddButton, showAddButtonLabel, showBulkActionButton, showRefreshButton, showToolTipTracker} from '../Constants';
import moment from 'moment';
import {QuotaTracker} from '@/components/common-components/v2/quotaTracker';
import {Pill} from '@/components/layoutV2/user-menu/styles';
import FreezeTooltip from '../Component/freezTooltip';
import {getOttoTablePageSize} from '@/api/common-utils';
import {LargeBadge} from '@/components/common-components/components/badge';
import {trialExpiresAtDateCheck} from '@/utils/moment';

const ratingOptions = {
  5: '5 stars',
  4: '4 stars',
  3: '3 stars',
  2: '2 stars',
  1: '1 star',
};
interface IndexationQuotaStateProps {
  googleConsumedDailyQuota: number;
  googleRemainingDailyQuota: number;
  total: number;
  loadingQuotaForIndexation: boolean;
}

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: any;
  setSearchText: (value: string) => void;
  searchText: string;
  setIsOpenSearch: (value: boolean) => void;
  isOpenSearch: boolean;
  isResponsiveHeader?: boolean;
  setIsTableOpen: (value: string) => void;
  isTableOpen: string;
  setIsActiveFilter: (value: string) => void;
  isActiveFilter: string;
  setOpenCreateNewTopicalMapModal?: (value: boolean) => void;
  setOpenCreateTopicalMap?: (value: boolean) => void;
  setOpenAddPageModal?: (value: boolean) => void;
  setOpenQAModal?: (value: boolean) => void;
  setCurrentIssueType?: (value: string) => void;
  setOpenGenerateQAModal?: (value: boolean) => void;
  setAddRecord?: (value: boolean) => void;
  setOttoProject?: any;
  inputPlaceholderText?: string;
  setOpenGenerateGbpPostsModal?: (value: boolean) => void;
  setOpenBulkPagesModal?: Dispatch<SetStateAction<boolean>>;
  setAddBulkPagesFrom?: Dispatch<SetStateAction<string>>;
  hasAccess?: boolean;
  setShowBulkBanner?: Dispatch<SetStateAction<boolean>>;
  indexationQuota?: IndexationQuotaStateProps;
  handleActive?: (value: boolean) => void;
  toggleActive?: boolean;
  setOpenAutomateSettingsModal?: (value: boolean) => void;
  showTooltip?: boolean;
  tooltipTitle?: any;
  disableBulkAction?: boolean;
  disabledTooltipText?: any;
}

export const TableTopBar = observer(({
  componentIssueType,
  setPageChanged,
  setIssueTable,
  issueTable,
  setSearchText,
  searchText,
  setIsOpenSearch,
  isOpenSearch,
  setIsTableOpen,
  isTableOpen,
  setIsActiveFilter,
  isActiveFilter,
  setOpenCreateNewTopicalMapModal,
  setOpenCreateTopicalMap,
  setOpenAddPageModal,
  setOttoProject,
  setOpenQAModal,
  setCurrentIssueType,
  setOpenGenerateQAModal,
  setAddRecord,
  inputPlaceholderText,
  setOpenGenerateGbpPostsModal,
  isResponsiveHeader,
  setOpenBulkPagesModal,
  setAddBulkPagesFrom,
  hasAccess,
  setShowBulkBanner,
  indexationQuota,
  handleActive,
  toggleActive,
  setOpenAutomateSettingsModal,
  showTooltip,
  tooltipTitle = '',
  disableBulkAction,
  disabledTooltipText,
}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    setStatusKeyValue,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    selectedIssue,
    ottoSearchTerm,
    reviewRatings,
    gbpLocationDetail,
    ottoBulkImportPostOrQuestion,
    setIssueTypeSelected,
    refreshingBulkGBPData,
    setIsKnowledgeModalVisible,
    activeQuestionsTab,
    activeQuestionsKey,
    qAndASettingsLoading,
  },
  contentOptimizer: {
    wpPublish: {wpArray, setShowInstructionsModal}},
  settings: {
    customer: {
      profile: {
        quotaUtilization,
        trialExpiresAt,
      },
    },
  },
  } = useStore('');
  const isPublicPage = !!getSingleUrlParam('public_hash');

  const refreshDateMapping = {
    'unanswered_reviews': gbpLocationDetail?.lastReviewFetchAt ? moment(gbpLocationDetail?.lastReviewFetchAt).fromNow() : null,
    'qanda_suggestions': gbpLocationDetail?.lastQuestionFetchAt ? moment(gbpLocationDetail?.lastQuestionFetchAt).fromNow() : null,
    'gbp_posts': gbpLocationDetail?.lastPostFetchAt ? moment(gbpLocationDetail?.lastPostFetchAt).fromNow() : null,
  };

  const uuid = getSingleUrlParam('uuid');
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2?.map(i => {
    if (i?.group === 'indexing') {
      const subGroups = i.subGroups.map(value => {
        if (value?.group === 'gsc_instant_indexing') {
          return {...value, subGroups: [
            {
              group: 'sitemap_indexing',
              label: 'Sitemap Indexing',
              requiredIntegration: null,
              isComingSoon: false,
              isSitewide: false,
            },
            {
              group: 'custom_url_based_indexing',
              label: 'Custom URL Based Indexing',
              requiredIntegration: null,
              isComingSoon: false,
              isSitewide: false,
            }]};
        }
        return value;
      });
      return {...i, subGroups: subGroups};
    }
    return i;
  });

  const category = selectedIssue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : selectedIssue;
  const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === category || issueObj.label === category)?.subGroups?.find(categoryObj => categoryObj?.group === selectedCategory);

  // @ts-ignore: Ignoring type error because the type of subGroups is uncertain at runtime
  const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === componentIssueType);
  const issues = getOttoV2Project?.issuesCountBreakdown['issues'] || {};

  const [ottoUrls, setOttoUrls] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);

  const onRefresh = async issueType => {
    await ottoBulkImportPostOrQuestion(refreshKeyMapping[issueType]);
    setPageChanged(true);
    setIssueTypeSelected(issueType);
  };


  const searchDebounce = debounce(async (params, isSitewide) => {
    if (params.issue_type != 'topical_maps_and_supplemental_content' && params?.issue_type !== 'dynamic_indexing' && params?.issue_type !== 'ai_landing_page_builder') {
      await loadIssueTableData(params, isSitewide);
    }
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          search: search,
          page,
          star_rating__in: rating,
          is_loading: false,
        };
        // const getDeployStatusForGbp = (key, issueType) => {
        //   if (['gbp_posts', 'qanda_suggestions'].includes(issueType)) {
        //     switch (key) {
        //       case 'deployed':
        //         return 'Published';
        //       default:
        //         return 'Pending Review, Scheduled, Publishing, Rejected, Unpublished';
        //     }
        //   } else if (issueType === 'unanswered_reviews') {
        //     switch (key) {
        //       case 'deployed':
        //         return 'true';
        //       default:
        //         return 'false';
        //     }
        //   }
        // };
        if (['gbp_posts', 'qanda_suggestions'].includes(issueType)) {
          params['status__in'] = activeKey;
        } else if (issueType === 'unanswered_reviews') {
          params['is_replied'] = activeKey;
        } else {
          setStatusKeyValue(activeKey);
          params['deploy_status'] = activeKey;
        }
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          params['search'] = '';
          setOttoSearchTerm('');
        }
        if (search) {
          if (issueType === 'images' || issueType === 'organization_schema') {
            searchDebounce(params, true);
          } else {
            searchDebounce(params, false);
          }
        } else {
          if (issueType === 'images' || issueType === 'organization_schema' || issueType === 'dynamic_indexing') {
            await loadIssueTableData(params, true);
          } else {
            await loadIssueTableData(params, false);
          }
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const onClickMethod = issueType => {
    if (issueType === 'gbp_posts') {
      setOpenGenerateGbpPostsModal(true);
    } else if (issueType === 'internal_link_suggestions') {
      setOpenAddPageModal(true);
      setOttoProject(getOttoV2Project?.id);
      setCurrentIssueType(issueType);
    } else if (issueType === 'qanda_suggestions') {
      setOpenQAModal(true);
    }
  };

  const handleRatingCheckboxChange = async (valueArray, issueArray: string[], pageSize) => {
    const ratingString = valueArray?.join(',');
    let updatedRatings = [];
    loadIssueTables(issueArray, selectedIssue, selectedCategory, 1, pageSize, true, searchText, ratingString, isActiveFilter);
    if (valueArray) {
      updatedRatings = valueArray;
    } else {
      updatedRatings = updatedRatings?.filter(r => !valueArray?.includes(r));
    }
    setSelectedRatings(updatedRatings);
  };

  const debouncedChange = debounce((issueArray: string[], pageSize, page, searchText, ratingString, category) => {
    const value = searchText.trim();
    if (value) {
      loadIssueTables(issueArray, selectedIssue, category, 1, pageSize, true, value?.toLowerCase(), ratingString, isActiveFilter);
      setIssueTable(issueArray[0]);
      setPageChanged(true);
    } else {
      loadIssueTables(issueArray, selectedIssue, category, page ?? 1, pageSize, true, '', ratingString, isActiveFilter);
    }
  }, 1000);

  const handleSearch = useCallback(
    (issueArray: string[], pageSize, page, searchText, ratingString, category) => {
      if (category=='qanda_suggestions' && searchText && searchText.length < 3) {
        return;
      }
      debouncedChange(issueArray, pageSize, page, searchText, ratingString, category);
    }, [selectedCategory]);

  const deployStatusClick = async (activeKey: any, issueType: string, page: number, pageSize: number, isSitewide: boolean, rating?: string) => {
    setIsActiveFilter(activeKey);
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      search: searchText,
      is_loading: false,
      star_rating__in: rating,
    };
    setStatusKeyValue(activeKey);
    if (['qanda_suggestions'].includes(issueType)) {
      params['status__in'] = activeKey;
    } else if (issueType === 'unanswered_reviews') {
      params['is_replied'] = activeKey;
    } else {
      params['deploy_status'] = activeKey;
    }
    await loadIssueTableData(params, isSitewide);
  };

  useEffect(() => {
    activeQuestionsTab && deployStatusClick(activeQuestionsTab, 'qanda_suggestions', 1, getOttoTablePageSize('qanda_suggestions'), false);
  }, [activeQuestionsKey]);

  const render = () => {
    return (
      <>
        <HeaderContainer customAlignment={isOpenSearch && url?.issueType=='qanda_suggestions'} className={isResponsiveHeader ? 'flex-wrap' : ''}>
          <div className='issues-collapse-header'>
            <div style={{display: 'flex', alignItems: 'center', height: isOpenSearch && 44}}>{!(url?.issueType === 'cloud_stacks' || url?.issueType === 'press_release_distribution') && itemObject?.label == 'NLP Terms' ? 'NLP Terms & Schema' : itemObject?.label}</div>
            <div>
              {isOpenSearch ? (
                <Tooltip title={issueTable?.length > 0 && !ottoSearchTerm ? 'Loading. Please wait' : ''}>
                  <div style={{display: 'flex', alignItems: 'center', height: isOpenSearch && 44}}>
                    <UrlFilterInput
                      style={{maxWidth: '360px'}}
                      placeholder={inputPlaceholderText ? inputPlaceholderText: 'Search by Page URL'}
                      prefix={<FontAwesomeIcon icon={faMagnifyingGlass}
                        color='#A3A4A4'/>}
                      onChange={e => {
                        setSearchText(e.target.value);
                        handleSearch([url?.issueType], url?.pageSize ?? 10, 1, e?.target?.value, '', selectedCategory);
                      }}
                      disabled={issueTable?.length > 0 && !ottoSearchTerm}
                      value={searchText}
                    />
                  </div>
                  {url?.issueType=='qanda_suggestions' && <div style={{color: '#A3A4A4', fontSize: 12, fontStyle: 'italic'}}>Search term must be at least 3 characters long.</div>}
                </Tooltip>
              ) : url?.issueType !== 'sitemap_indexing' ? <FontAwesomeIcon
                style={{cursor: 'pointer'}}
                icon={faMagnifyingGlass}
                onClick={() => {
                  setIsTableOpen('open');
                  setIsOpenSearch(true);
                }}
              /> : ''}
            </div>
            {
              issues[snakeToCamel(url?.issueType)]?.total === 0 ? <Progress type='circle' trailColor='rgb(229 229 229)' percent={0} strokeColor='#2D6CCA' width={16} strokeWidth={14}
                gapDegree={0}
                size='small'
                showInfo={false}
              /> :
                issues[snakeToCamel(url?.issueType)] && issues[snakeToCamel(url?.issueType)]?.approved === issues[snakeToCamel(url?.issueType)]?.total ?
                  <FontAwesomeIcon icon={duotoneCheck} color='#2AC155' fontSize={18} /> :
                  <>
                    {
                      issues[snakeToCamel(url?.issueType)] && <Progress type='circle' trailColor='rgb(229 229 229)' percent={getPercentage(issues[snakeToCamel(url?.issueType)]?.approved ?? 0, 0, issues[snakeToCamel(url?.issueType)]?.total ?? 0)} strokeColor='#2D6CCA' width={16} strokeWidth={14}
                        gapDegree={0}
                        size='small'
                        showInfo={false}
                      />
                    }
                  </>
            }
            {url?.issueType === 'unanswered_reviews' && (
              <StyledRatingSelect
                mode='multiple'
                placeholder={'Filter by Star Count'}
                suffixIcon={<FontAwesomeIcon icon={faSortDown} fontSize={12} color='#121212'/>}
                dropdownStyle={{
                  minWidth: '300px',
                  borderRadius: '16px',
                  border: '1px solid #DCDCDC',
                  boxShadow: '0px 4px 24px 0px #0000000D',
                }}
                value={selectedRatings}
                optionLabelProp='label'
                onChange={value => handleRatingCheckboxChange(value, [url?.issueType], url?.pageSize ?? 10)}
              >
                {reviewRatings?.length && reviewRatings?.map((item, idx) => (
                  <Select.Option key={idx} value={item?.starRating} label={ratingOptions[item?.starRating]}>
                    <OptionContent>
                      <Checkbox checked={selectedRatings.includes(item?.starRating)}/>
                      <div className='content'>{ratingOptions[item?.starRating]}
                        <div className='review-wrapper'>({item?.count} reviews)</div>
                        <Rate className='ratings' value={Number(item?.starRating)} disabled/>
                      </div>
                    </OptionContent>
                  </Select.Option>
                ))}
              </StyledRatingSelect>
            )}
            <div style={{display: 'flex', alignItems: 'center', height: isOpenSearch && 44}}>
              {isTableOpen === 'open' ? <ChevronUp style={{cursor: 'pointer'}} className='chevron-up-icon' onClick={() => {
                setIsTableOpen('close');
                setIsOpenSearch(false);
              }}/> :
                <ChevronDown className='chevron-up-icon' style={{cursor: 'pointer'}} onClick={() => {
                  setIsTableOpen('open');
                }} />}
            </div>
            <NlpHideDiv>
              <div className={componentIssueType === 'nlp_faq' ? 'hide-nlp-quota-tracker' : 'show-nlp-quota-tracker'}>
                {
                  showToolTipTracker.includes(url?.issueType) && !isPublicPage ? (
                    <div>
                      <QuotaTracker
                        consumedData={quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed}
                        totalData={quotaUtilization?.ca?.allowedOttoAiSuggestions?.total}
                        heading={'AI Generation'}
                        fontSize={12}
                      />
                    </div>
                  ) : null
                }
              </div>
            </NlpHideDiv>
          </div>
          <div className={`issues-btn-wrapper ${isResponsiveHeader ? 'inner-fields-wrap' : ''}`} style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap'}}>
            <NlpQuataDiv>
              <div className={componentIssueType === 'nlp_faq' ? 'responsive-nlp' : ''}>
                {
                  showToolTipTracker.includes(url?.issueType) && !isPublicPage ?
                    <div>
                      <QuotaTracker consumedData={quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed}
                        totalData= {quotaUtilization?.ca?.allowedOttoAiSuggestions?.total} heading={'AI Generation'}
                        fontSize={12}
                        showTooltip={showTooltip}
                        tooltipTitle={tooltipTitle}
                      />
                    </div> : componentIssueType === 'sitemap_indexing' ? (
                      <div>
                        <QuotaTracker consumedData={indexationQuota?.googleConsumedDailyQuota}
                          totalData= {indexationQuota?.total} heading={'Daily Indexation Quota'}
                          fontSize={12}
                          loadingQuota={indexationQuota?.loadingQuotaForIndexation}
                        />
                      </div>
                    ) : <></>
                }
              </div>
            </NlpQuataDiv>
            {!isGBPFilters.includes(componentIssueType) && <DeployStatusFilter data={url} isActiveFilter={isActiveFilter} selectedRatings={selectedRatings} isSitewide={itemObject?.isSitewide} onClick={deployStatusClick}/>}
            {(showRefreshButton.includes(url?.issueType) || showAddButton.includes(url?.issueType)) && <div style={{display: 'flex', alignItems: (isOpenSearch && url?.issueType=='qanda_suggestions') ? 'flex-start' : 'center', gap: 10, flexWrap: 'wrap'}}>
              {
                showRefreshButton.includes(url?.issueType) ? (
                  <RefreshWrapper>
                    <div style={isOpenSearch && url?.issueType === 'qanda_suggestions' ?
                      {display: 'flex', alignItems: 'center', height: '44px'} :
                      {}} className={`date ${isResponsiveHeader ? 'text-nowrap': ''}`}>{refreshDateMapping[url?.issueType] ? `Last refreshed: ${refreshDateMapping[url?.issueType]}`: ''}</div>
                    <FreezeTooltip>
                      <div className={`refresh-button ${isResponsiveHeader ? 'text-nowrap': ''}`}
                        onClick={() => !refreshingBulkGBPData && onRefresh(url?.issueType)}><FontAwesomeIcon icon={faRotate} style={{margin: '0 5px'}} fontSize={12} color='#2D6CCA' className={refreshingBulkGBPData? 'fa-spin': ''}/>Refresh</div>
                    </FreezeTooltip>
                  </RefreshWrapper>
                ) : (
                  <></>
                )
              }
              {url?.issueType === 'qanda_suggestions' ?
                (<div style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
                  <FreezeTooltip>
                    <span>
                      <ButtonStyled background='#2D6CCA' onClick={() => setOpenGenerateQAModal(true)}>
                        <FontAwesomeIcon icon={faMessagesQuestion} fontSize={14} color='#fff' />Generate Q&As
                        {/* {
                      generatingBulkQuestions ?
                        <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#fff'}} spin />} /> : <></>
                    } */}
                      </ButtonStyled>
                    </span>
                  </FreezeTooltip>
                  <FreezeTooltip>
                    <span>
                      <ToggleAutomated style={{background: toggleActive ? '#18923B26' : '#F4434326'}}>
                        <Switch loading={qAndASettingsLoading} checked={toggleActive} onChange={handleActive} />
                        <div className='text'>Automated Q&A: {toggleActive ? 'On' : 'Off'}</div>
                        <div className='custom-divider' />
                        <button className='settingsBtn' disabled={qAndASettingsLoading}><FontAwesomeIcon icon={faGear} className='setings' color='#4E5156' onClick={() => setOpenAutomateSettingsModal(true)} /><LargeBadge beta /></button>
                      </ToggleAutomated>
                    </span>
                  </FreezeTooltip>
                </div>) : (
                  <></>
                )
              }
              {
                (showAddButton.includes(url?.issueType) && !['nlp_terms', 'nlp_faq'].includes(url?.issueType)) ? (
                  url?.issueType === 'topical_maps_and_supplemental_content' ? (
                    <FreezeTooltip>
                      <span>
                        <ButtonStyled
                          background='#2D6CCA' onClick={()=> {
                            if (getOttoV2Project?.connectedData?.isGscConnected) {
                              setOpenCreateNewTopicalMapModal(true);
                            } else {
                              setOpenCreateTopicalMap(true);
                            }
                          }}>
                          <FontAwesomeIcon icon={faPlus} fontSize={14} color='#fff'/>{showAddButtonLabel[url?.issueType]}
                        </ButtonStyled>
                      </span>
                    </FreezeTooltip>
                  ) : url?.issueType === 'dynamic_indexing' ? (
                    <FreezeTooltip placement='topRight'><DynamicIndexingAddButton onClick={() => {
                      setAddRecord(true);
                    }}>
                      <FontAwesomeIcon icon={faPlus} />
                       Add Page
                    </DynamicIndexingAddButton>
                    </FreezeTooltip>
                  ) : <FreezeTooltip> <ButtonStyled background='#2D6CCA' onClick={()=> onClickMethod(url?.issueType)}>
                    <FontAwesomeIcon icon={faPlus} fontSize={14} color='#fff'/>{showAddButtonLabel[url?.issueType]}
                  </ButtonStyled></FreezeTooltip>) : (
                  <></>
                )
              }
              {['nlp_terms', 'nlp_faq'].includes(url?.issueType) && <FreezeTooltip> <ButtonStyled width='210px' background='#2D6CCA' onClick={()=> {
                setAddBulkPagesFrom(url?.issueType);
                setOpenBulkPagesModal(true);
                setCurrentIssueType(url?.issueType);
              }}>
                <FontAwesomeIcon icon={faPlus} fontSize={14} color='#fff'/>Add Bulk Pages <Pill>Beta</Pill>
              </ButtonStyled></FreezeTooltip>}
            </div>}

            {/* {(url?.issueType == 'sitemap_indexing' || url?.issueType == 'custom_url_based_indexing') && <ButtonStyled width='124px' height='28px' gap='6px' background='#2D6CCA' onClick={()=> setAddRecord(true)}>
              <FontAwesomeIcon icon={faPlus} fontSize={14} color='#fff'/>{url?.issueType == 'sitemap_indexing' ? 'Add Sitemap' : 'Index Page'}
            </ButtonStyled>} */}

            {['sitemap_indexing', 'custom_url_based_indexing'].includes(url?.issueType) ? (
              <Tooltip title={hasAccess ? '' : `You do not have access to gsc`}>
                <ButtonStyled width='124px' height='28px' gap='6px' background='#2D6CCA' onClick={()=> setAddRecord(true)} disabled={!hasAccess}>
                  <FontAwesomeIcon icon={faPlus} fontSize={14} color='#fff'/>{url?.issueType === 'sitemap_indexing' ? 'Sitemap' : 'Index Page'}
                </ButtonStyled>
              </Tooltip>) : <></>}


            {(url?.issueType === 'cloud_stacks' || url?.issueType === 'press_release_distribution') && (
              <FreezeTooltip placement='topRight'>
                <Tooltip
                  overlayStyle={url?.issueType === 'cloud_stacks' ? {maxWidth: 390} : {}}
                  overlayInnerStyle={url?.issueType === 'cloud_stacks' ? {minWidth: 390} : {}}
                  title={trialExpiresAtDateCheck(trialExpiresAt) ?
                    (url?.issueType === 'press_release_distribution' ? '' : 'The cost of one Cloud Stack is 15 HyperDrive Credits. This is a one-time charge. You will not be charged any additional fees in the future.') :
                    'Access to this feature is restricted for free trial users.'}
                >
                  <CloudStacksAddButton disabled={!trialExpiresAtDateCheck(trialExpiresAt)} width={url?.issueType === 'press_release_distribution' ? 'auto' : '242px'} onClick={() => {
                    setAddRecord(true);
                  }}>
                    <FontAwesomeIcon icon={faPlus} color={'#2D6CCA'} />
                  Add {url?.issueType === 'press_release_distribution' ? 'Press Release' : 'Cloud Stack (15 credits)'}
                  </CloudStacksAddButton>
                </Tooltip>
              </FreezeTooltip>
            )}
            {url?.issueType === 'internal_link_suggestions' && <FreezeTooltip> <ButtonStyled background='#2D6CCA' onClick={()=> onClickMethod(url?.issueType)}>
              <FontAwesomeIcon icon={faPlus} fontSize={14} color='#fff'/>{showAddButtonLabel[url?.issueType]}
            </ButtonStyled></FreezeTooltip>}
            {url?.issueType === 'topical_maps_and_supplemental_content' && (
              !wpArray?.length ? <FreezeTooltip><div>
                <WpButtonStyled
                  buttonType={'purple-opaque'}
                  style={{cursor: 'pointer', color: '#602F8E'}}
                  onClick={() => setShowInstructionsModal(true)}
                >
                  <FontAwesomeIcon icon={faWordpress} color={'#602F8E'} fontSize={16}/>Connect WordPress
                </WpButtonStyled>
              </div></FreezeTooltip> : null
            )}
            {showBulkActionButton.includes(url?.issueType) ? (
              <FreezeTooltip
                placement='topRight'
              >
                <Tooltip title={disabledTooltipText ? disabledTooltipText : ''}>
                  <ViewButton
                    style={{color: '#2D6CCA', backgroundColor: 'rgba(45, 108, 202, 0.15)', width: 'auto', cursor: disableBulkAction ? 'not-allowed' : 'pointer', ...(disableBulkAction && {opacity: '0.5'})}}
                    onClick={()=> {
                      !disableBulkAction && setShowBulkBanner(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faListCheck} color='#2D6CCA' fontSize={14}/>Bulk actions
                  </ViewButton>
                </Tooltip>
              </FreezeTooltip>) : <></>}
          </div>

        </HeaderContainer>
        {url?.issueType === 'organization_schema' && <Banner>
          <FontAwesomeIcon color='#2D6CCA' icon={duotonefaCircleInfo}/>
          <div>Schema is utilizing information about your business from the Knowledge Graph.</div>
          <div className='open-knowledge-graph' onClick={() => {
            setIsKnowledgeModalVisible(true); setIssueTypeSelected(url?.issueType);
          }}>Click here to edit the data.</div>
        </Banner>}
      </>
    );
  };

  return (
    <>{!itemObject?.isComingSoon ? render() : <ComingSoonTag>Coming soon</ComingSoonTag>}</>
  );
});


export const HeaderContainer = styled.div<{customAlignment?:boolean}>`
  padding: 0px 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.flex-wrap {
    flex-wrap: wrap;
    align-items: ${p => p?.customAlignment && 'flex-start !important'}
  }
  .inner-fields-wrap {
    flex-wrap: wrap;
    justify-content: end;
    margin-left: auto;
  }
  @media screen and (max-width: 1260px) {
    flex-wrap: wrap;
    row-gap: 8px;
    .issues-collapse-header {
      min-height: 46px;
    }
    .issues-btn-wrapper {
      margin-left: auto;
    }
  }

  .issues-collapse-header {
    display: flex;
    align-items: ${p => p?.customAlignment ? 'flex-start' : 'center'};
    gap: 10px;
  }

  div:nth-child(1) {
    .anticon-right {
      display: none;
    }
  }
  .issues-collapse-header {
    .chevron-up-icon {
      display: block;
    }
  }
`;

const CloudStacksAddButton = styled(Button)<{width?: string}>`
  width: ${p => p?.width};
  height: 32px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
  background-color: rgba(45, 108, 202, 0.15);
  border-radius: 6px;
  border-color: rgba(45, 108, 202, 0.15);
  color: #2D6CCA;
  &:hover, &:focus {
    background-color: rgba(45, 108, 202, 0.15);
    color: #2D6CCA;
    border-color: rgba(45, 108, 202, 0.15);
  }
`;
const NlpHideDiv = styled.div`
.hide-nlp-quota-tracker{
  display: none !important;
}
.show-nlp-quota-tracker{
  display : none;
}
  .hide-nlp-quota-tracker{
    @media (max-width: 1570px) {
      display: flex !important;
      height: 44px;
      align-items: center;
    }
  }
`;

const NlpQuataDiv = styled.div`
.responsive-nlp{
  display: flex;
        height: 44px;
      align-items: center;
}

.responsive-nlp{
  @media (max-width: 1570px) {
    display: none;
  }
}
`;


const DynamicIndexingAddButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 115px;
  /* height: 28px; */
  border-radius: 6px;
  background: #2D6CCA;
  color: #FFFFFF;
  &:hover, &:focus {
    background: #2D6CCA;
    color: #FFFFFF;
  }
`;
